export function entityIsDrawing(state = {}, action) {
    switch (action.type) {
        case 'FIELD_IS_DRAWING':
            return {entityIsDrawing: true, entityDrawingType: 'FIELD'};
        case 'ROUTE_IS_DRAWING':
            return {entityIsDrawing: true, entityDrawingType: 'ROUTE', parentFieldId: action.parentFieldId};
        case 'EXCLUSION_IS_DRAWING':
            return {entityIsDrawing: true, entityDrawingType: 'POSITIVE_EXCLUSION_AREA', parentFieldId: action.parentFieldId};
        case 'OBSTACLE_IS_DRAWING':
            return { entityIsDrawing: true, entityDrawingType: 'OBSTACLE', parentFieldId: action.parentFieldId };
        case 'WORKINGAREA_IS_DRAWING':
            return {entityIsDrawing: true, entityDrawingType: 'WORKING_AREA', parentFieldId: action.parentFieldId};
        case 'ROUTE_IS_SIMPLIFYING':
            return {entityIsSimplifying: true, entityType: 'ROUTE', parentFieldId: action.parentFieldId, name: action.name, coords: action.coords};
        case 'FINISH_DRAWING':
            return {entityIsDrawing: false};
        case 'SET_VISIBILITY':
            return {setVisibilityEntityId: action.entityId, setVisibilityValue: action.visible};
        default:
            return state;
    }
}

export function setVisibility(state = {}, action) {
    switch (action.type) {
        case 'SET_VISIBILITY':
            return {entityId: action.entityId, value: action.visible};
        default:
            return state;
    }
}
