import React, { Component } from "react";
import { connect } from 'react-redux';
// import { userAttributesSet } from './actions/appStatus'
import store from './index';
import { Link, withRouter } from "react-router-dom";
import { Modal, Nav, NavItem, Navbar, Button } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";
import RouteNavItem from "./components/RouteNavItem";
import { authUser, signOutUser } from "./libs/awsLib";
import ReactGA from 'react-ga';
import metadata from './metadata.json';
import ObfuscateText from "react-obfuscate-text";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import config from "./config";

class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      showModal: false
    };
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  async componentDidMount() {

    this.loadFacebookSDK();

    if (navigator.onLine){

    try {
      if (await authUser()) {
        this.userHasAuthenticated(true);
      }
    }
    catch(e) {
      alert(e);
    }
  
    }
    else{
      this.userHasAuthenticated(true);
    }

    this.setState({ isAuthenticating: false });
    ReactGA.initialize('UA-146588443-1');
    ReactGA.pageview('/homepage');
    window.addEventListener('online', this.setOfflineStatus);
    window.addEventListener('offline', this.setOfflineStatus);
  }
  
  loadFacebookSDK() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId            : config.social.FB,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.1'
      });
    };

    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setOfflineStatus = () => {
    this.setState({ offline: !navigator.onLine })
  }
  
  handleLogout = event => {
    store.dispatch({ type: "USER_LOGOUT" });
    signOutUser();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }


  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated
    };
  
    return (
      !this.state.isAuthenticating &&
      <div className="container-fluid">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
            <p style={{fontSize: '20px'}}><span style={{color: '#00ccff'}}>Cloud</span><span style={{color: '#339966',fontWeight: 'bold'}}>Farming</span></p>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem href="/userguide/index.html" target="_blank" >
              User Guide
              </NavItem>
              <NavItem onClick={this.handleOpenModal}>
              About
              </NavItem>
            </Nav>
            <Nav pullRight>
              {/* [  Replace <NavItem onClick={this.handleLogout}>Logout</NavItem> with these lines:
                <NavItem key={1}> {this.props.userAttributes.userAttrEmail} </NavItem> ,
                <NavItem key={2}  onClick={this.handleLogout}>
                  Logout
                </NavItem>
                ] */}
              {this.state.isAuthenticated
                ?
                [
                <NavItem key={1}> <FontAwesomeIcon className ='font-awesome' color={this.state.offline? "red" : "limegreen"} icon={ faCircle }/> {this.props.userAttributes.userAttrEmail} </NavItem> ,
                <NavItem key={2}  onClick={this.handleLogout}>
                  Logout
                </NavItem>
                ]
                : [
                    <RouteNavItem key={1} href="/signup">
                      Signup
                    </RouteNavItem>,
                    <RouteNavItem key={2} href="/login">
                      Login
                    </RouteNavItem>
                  ]}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
          <Modal.Body>
            <p style={{textAlign: 'center',fontSize: '2.2em' }}><span style={{color: '#00ccff'}}>Cloud</span><span style={{color: '#339966',fontWeight: 'bold'}}>Farming</span></p>
            <p style={{fontSize: '1.1em'}}>&nbsp;</p>
            <p style={{fontSize: '1.1em'}}>version: <span style={{fontWeight: 'bold'}}>{`${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`} beta</span></p>
            <p style={{fontSize: '1.1em'}}>built at: <span style={{fontWeight: 'bold'}}>{`${metadata.buildAt}`}</span></p>
            <p style={{fontSize: '1.1em'}}>author: <span style={{fontWeight: 'bold'}}>Gonzalo Rodriguez Mir</span></p>
            <p style={{fontSize: '1.1em'}}>email: <span style={{fontWeight: 'bold'}}><ObfuscateText text="grm@mobotsoft.com"/></span></p>
            <p style={{fontSize: '1.1em'}}>website: <span style={{fontWeight: 'bold'}}><a href="http://www.mobotsoft.com" target="_blank" rel="noopener">www.mobotsoft.com</a></span></p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCloseModal}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Routes childProps={childProps} />
      </div>
    );
  }
}



// export default withRouter(App);



// in case we need Redux:

const mapStateToProps = (state) => {
  return {
    userAttributes: state.userAttributes
  };
};


export default withRouter(connect(mapStateToProps)(App));


