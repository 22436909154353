import { invokeApig } from '../libs/awsLib';

export function fieldGeometriesHasErrored(bool) {
    return {
        type: 'FIELDGEOMETRIES_HAS_ERRORED',
        hasErrored: bool
    };
}

export function fieldGeometriesIsLoading(bool) {
    return {
        type: 'FIELDGEOMETRIES_IS_LOADING',
        isLoading: bool
    };
}

export function fieldGeometriesFetchDataSuccess(fieldGeometries) {
    return {
        type: 'FIELDGEOMETRIES_FETCH_DATA_SUCCESS',
        fieldGeometries
    };
}

/*
export function fieldGeometriesReset(){
    return {type: 'FIELDGEOMETRIES_RESET'};
}
*/

export function createFieldGeometry(fieldId, fieldGeometryId, geometry, name, type, origin) {
    // TODO: make this function async (see fieldsFetchData below )
    // and check if the response is ok

    //if (!origin.startsWith("RP")){  // if (!origin.startsWith("RP-sarasa")){ para que guarde la ruta en la db
        invokeApig({
            path: "/fieldgeometries",
            method: "POST",
            body: {fieldId: fieldId, fieldGeometryId: fieldGeometryId, geometry: geometry, name: name, type: type, origin: origin}
        });
    //}

    return {
        type: 'CREATE_FIELDGEOMETRY',
        payload: {fieldId: fieldId, fieldGeometryId: fieldGeometryId, geometry: geometry, name: name, type: type, origin: origin}
    };
}

export function deleteFieldGeometry(fieldId, fieldGeometryId){

    invokeApig({
        path: "/fields/" + fieldId + "/fieldgeometries/" + fieldGeometryId,
        method: "DELETE",
    });

    return {
        type: 'DELETE_FIELDGEOMETRY',
        payload: {fieldGeometryId: fieldGeometryId}
    };

}

/*
export function deleteRoutePlanner(fieldId){

     return {
        type: 'DELETE_RP',
        payload: {fieldId: fieldId}
    };

}
*/


export function fieldGeometriesFetchData(fieldIds) {

    // this one's 'async' 
    return async dispatch => {

        dispatch(fieldGeometriesIsLoading(true));

        // wrap in try to listen for Promise rejections - equivalent of '.catch()'
        try {
            // wait for the fetch to finish then dispatch the result
            var data = [];
            for (var i = 0; i < fieldIds.length; i++) {
                const fieldGeometriesInField = await fieldGeometries(fieldIds[i]); 
                data = data.concat(fieldGeometriesInField);
            }
            dispatch(fieldGeometriesFetchDataSuccess(data));
        } catch (e) {
            // catch errors from fetch
            dispatch(fieldGeometriesHasErrored(true));
        }

        dispatch(fieldGeometriesIsLoading(false));
    };
}

var fieldGeometries = function(fieldId) {
    return invokeApig({ path: "/fields/" + fieldId + "/fieldgeometries"});
}



