import React, {Component} from "react";

import Viewer from "cesium/Source/Widgets/Viewer/Viewer";
import BingMapsImageryProvider from "cesium/Source/Scene/BingMapsImageryProvider";
import BingMapsStyle from "cesium/Source/Scene/BingMapsStyle";
import CesiumTerrainProvider from "cesium/Source/Core/CesiumTerrainProvider";
import EllipsoidTerrainProvider from "cesium/Source/Core/EllipsoidTerrainProvider"
import CesiumProjectContents from "./CesiumProjectContents";
import CesiumClickHandler from "./CesiumClickHandler";
import CesiumCameraManager from "./CesiumCameraManager";
import CartographicGeocoderService from "cesium/Source/Core/CartographicGeocoderService";
import BingMapsGeocoderService from "cesium/Source/Core/BingMapsGeocoderService";
import TileMapServiceImageryProvider from "cesium/Source/Scene/TileMapServiceImageryProvider";
import buildModuleUrl from "cesium/Source/Core/buildModuleUrl";
import JulianDate from "cesium/Source/Core/JulianDate";
import ClockRange from "cesium/Source/Core/ClockRange";
import sprintf from "cesium/Source/ThirdParty/sprintf";

const BING_MAPS_URL = "//dev.virtualearth.net";
const BING_MAPS_KEY = "ArAacU05vxr4PTFmq6EcxkP6nRbKLRWjJP8WZjooig2bfra-T0OEK89_h0PS9KhQ";
const STK_TERRAIN_URL = "//assets.agi.com/stk-terrain/world";


export default class CesiumGlobe extends Component {
    state = {
        viewerLoaded : false,
    }

    componentDidMount() {
        const imageryProvider = new BingMapsImageryProvider({
            url : BING_MAPS_URL,
            key : BING_MAPS_KEY,
            mapStyle: BingMapsStyle.AERIAL // .AERIAL_WITH_LABELS
        });
/*
        const terrainProvider = new CesiumTerrainProvider({
            url : STK_TERRAIN_URL // DEPRECATED! check this: https://cesium.com/blog/2018/03/01/introducing-cesium-world-terrain/
        });
*/
        const terrainProvider2 = new EllipsoidTerrainProvider({});

        if (navigator.onLine) {
        
            this.viewer = new Viewer(this.cesiumContainer, {
                animation : true,
                baseLayerPicker : false,
                fullscreenButton : false,
                geocoder : [
                    new CartographicGeocoderService(),
                    new BingMapsGeocoderService({key:BING_MAPS_KEY})
                ],
                homeButton : false,
                infoBox : false,
                sceneModePicker : false,
                selectionIndicator : false,
                timeline : true,
                navigationHelpButton : true,
                scene3DOnly : true,
                imageryProvider,
                terrainProvider2
            });

        }
        else{

            this.viewer = new Viewer(this.cesiumContainer, {
                imageryProvider: new TileMapServiceImageryProvider({
                    url: buildModuleUrl("Assets/Textures/NaturalEarthII"),
                }),
                baseLayerPicker: false,
                geocoder: false,
            });

        }

        this.viewer._cesiumWidget._creditContainer.style.display = "none";

        // make timeline arbitrary (https://stackoverflow.com/questions/35737567/arbitrary-timeline-in-cesium)
        var clock = this.viewer.clock;
        clock.clockRange = ClockRange.CLAMPED;
        clock.startTime = JulianDate.fromIso8601('2016-01-01T00:00:00Z');
        clock.stopTime  = JulianDate.fromIso8601('2016-01-01T23:59:59Z');
        clock.currentTime = clock.startTime;
        
        function twoDigits(num) {
            return ((num < 10) ? ('0' + num.toString()) : num.toString());
        }
        
        var animationViewModel = this.viewer.animation.viewModel;
        animationViewModel.dateFormatter = function() { return ''; };
        
        animationViewModel.timeFormatter = function(date, viewModel) {
            var gregorianDate = JulianDate.toGregorianDate(date);
            var millisecond = Math.round(gregorianDate.millisecond);
            return sprintf("%02d:%02d:%02d.%03d", gregorianDate.hour,
                gregorianDate.minute, gregorianDate.second, millisecond);
        };
        
        this.viewer.timeline.makeLabel = function(time) {
            var gregorian = JulianDate.toGregorianDate(time);
            var millisecond = gregorian.millisecond, millisecondString = '';
            if ((millisecond > 0) && (this._timeBarSecondsSpan < 3600)) {
                millisecondString = Math.floor(millisecond).toString();
                while (millisecondString.length < 3) {
                    millisecondString = '0' + millisecondString;
                }
                millisecondString = '.' + millisecondString;
            }
        
            return twoDigits(gregorian.hour) + ':' + twoDigits(gregorian.minute) + ':' +
                twoDigits(gregorian.second) + millisecondString;
        };
        
        this.viewer.timeline.updateFromClock();
        this.viewer.timeline.zoomTo(clock.startTime, clock.stopTime);

        this.viewer.scene.debugShowFramesPerSecond = false;

        // Force immediate re-render now that the Cesium viewer is created
        this.setState({viewerLoaded : true}); // eslint-disable-line react/no-did-mount-set-state
    }

    componentWillUnmount() {
        if(this.viewer) {
            this.viewer.destroy();
        }
    }

    renderContents() {
        const {viewerLoaded} = this.state;
        let contents = null;

        if(viewerLoaded) {
            const {scene} = this.viewer;
            const {icons, labels, polylines, onLeftClick, flyToLocation} = this.props;

            contents = (
                <span>
                    <CesiumProjectContents
                        scene={scene}
                        icons={icons}
                        labels={labels}
                        polylines={polylines}
                    />
                    <CesiumClickHandler
                        parent = {this.cesiumContainer}  // pass element to child (in order to change mouse cursor)
                        scene={scene}
                        viewer={this.viewer}
                        onLeftClick={onLeftClick}
                    />
                    <CesiumCameraManager
                        camera={scene.camera}
                        flyToLocation={flyToLocation}
                    />
                </span>
            );
        }

        return contents;
    }

    render() {

        const containerStyle = {
            width: '100%',
            height: '100%',
            display : "flex",
            alignItems : "stretch",
        };


        const widgetStyle = {
            flexGrow : 2
        }

        const contents = this.renderContents()

        return (
            <div className="cesiumGlobeWrapper" style={containerStyle}>
                <div
                    className="cesiumWidget"
                    // in order to change mouse cursor, get the element (and thenpass it to clickhandler child as prop)
                    ref={ element => this.cesiumContainer = element }
                    style={widgetStyle}
                >
                    {contents}
                </div>
            </div>
        );
    }
}