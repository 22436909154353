import React from "react";
import { Form, Button, ButtonToolbar, FormGroup, FormControl, ControlLabel, Collapse, Modal, Grid, Row, Col } from "react-bootstrap";
import {Component} from "react";
import { connect } from 'react-redux';
import ScreenSpaceEventHandler from "cesium/Source/Core/ScreenSpaceEventHandler";
//import Event from "cesium/Source/Core/"
import SSET from "cesium/Source/Core/ScreenSpaceEventType";
import CesiumMath from "cesium/Source/Core/Math";
import HeadingPitchRange from "cesium/Source/Core/HeadingPitchRange";
import Cartesian3 from "cesium/Source/Core/Cartesian3";
import Cartographic from "cesium/Source/Core/Cartographic";
import Transforms from "cesium/Source/Core/Transforms";
import Matrix3 from "cesium/Source/Core/Matrix3";
import Quaternion from "cesium/Source/Core/Quaternion";
import JulianDate from "cesium/Source/Core/JulianDate";
import defined from "cesium/Source/Core/defined";
//import ClockRange from "cesium/Source/Core/ClockRange";
//import TimeInterval from "cesium/Source/Core/TimeInterval";
//import TimeIntervalCollection from "cesium/Source/Core/TimeIntervalCollection";
import VerticalOrigin from 'cesium/Source/Scene/VerticalOrigin';
import CallbackProperty from "cesium/Source/DataSources/CallbackProperty";
import PolygonHierarchy from "cesium/Source/Core/PolygonHierarchy";
import Color from "cesium/Source/Core/Color";
import SampledPositionProperty from "cesium/Source/DataSources/SampledPositionProperty";
import SampledProperty from "cesium/Source/DataSources/SampledProperty";
import PolylineDashMaterialProperty from "cesium/Source/DataSources/PolylineDashMaterialProperty"
import EntityCollection from "cesium/Source/DataSources/EntityCollection";
//import PositionPropertyArray from "cesium/Source/DataSources/PositionPropertyArray";
//import ConstantPositionProperty from "cesium/Source/DataSources/ConstantPositionProperty";
import "./CesiumClickHandler.css";
import uuid from "uuid";
import {fieldIsDrawing, routeIsDrawing, routeIsSimplifying, finishDrawing} from '../actions/cesiumDrawing';
import {createField, updateField} from '../actions/fields';
import {createFieldGeometry} from '../actions/fieldGeometries';
import {changeSelectedEntity} from '../actions/cesiumSelectedEntity'
import {noop} from "../utils/utils";
import Dialog from 'react-bootstrap-dialog';
import gpsIcon from "../cesium/images/location-gps-on-red.png";
import entranceIcon from "../cesium/images/entrance64.png";
import ledBlinking from "../assets/images/led-blinking-24.png"
import ledOff from "../assets/images/led-off-24.png"
import { closestPointInPolygon, smoothPolyline } from '../routeplanner/geometry';
import { Checkbox } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { takeRightWhile } from "lodash";
import { IoTThingsGraph } from "aws-sdk";
import { RDPsd } from "../utils/rdp";
import {fromLatLonArray, toLatLonArray2} from '../utils/utm';

class CesiumClickHandler extends Component {
    
    constructor(props){
        super(props);
        this.state = {
          numPoints: 0, // number of points of entity currently adding
          minPoints: 0,
          points: [], // cesium entity that is currently being drawn by user
          tmpRecordingRoute: null,
          recordingRoutePoints: [],
          tmpImportingRoute: null,
          tmpImportingRoutePoints: [], // stores the dynamically modified coords. Original coords are stored in redux state
          gpsBillboard: {billboard: {show: null}},
          vehicleModelEntityId: null,
          coveredAreaSize: "",
          uncoveredAreaSize: "",
          overlapAreaSize: "",
          travelledDistance: "",
          workedDistance: "",
          travelledTime: "",
          workedTime: "",
          isRecordingRoute: false,
          showSaveRecordedRouteModal: false,
          recordedRouteName: "",
          simplifyImportedRoute: false,
          smoothImportedRoute: false,
          simplifyTolerance: '0.1',
          smoothPercent: '0.25',
          tmpPlannedRoutes: [], // array containing info of tmp planned routes entities (array because subfields generates multiple routes)
          tmpPlannedRouteName: ""

        };
        this.tooltip = this.createTooltip(this.props.parent);
        this.tooltip.setVisible(false);
        this.dragging = false;
        this.routeAreas = new Map();
        this.coveredAreaIds = [];
        this.uncoveredAreaIds = [];
        this.overlapAreaIds = [];
        this.isCoveredAreaChecked = false;
        this.isUncoveredAreaChecked = false;
        this.isOverlapAreaChecked = false;
        this.currentRouteAreasKey = "";
        this.renderCount = 0;
        this.fieldName = '';

        //this.showTextInput = this.showTextInput.bind(this);
      }
    
    static defaultProps = {
        onLeftClick : noop
    }


    // const tooltip = this.createTooltip(this.props.parent);

    componentDidMount() {
        const {scene} = this.props;

        if(scene && scene.canvas) {
            this.screenEvents = new ScreenSpaceEventHandler(scene.canvas);
            this.createInputHandlers();
            //var xxx = process.env;
            this.setState({ gpsBillboard : this.props.viewer.entities.add({
                name : 'gps',
                id : 'gpsBillboard',
                //position : Cartesian3.fromDegrees(-75.59777, 40.03883),
                billboard :{
                    image : gpsIcon,
                    scale : 0.2,
                    show: false
                }
                })
            });
        }

    }

    componentWillUnmount() {
        if(this.screenEvents && !this.screenEvents.isDestroyed()) {
            this.screenEvents.destroy();
        }
    }

    componentDidUpdate(prevProps) {
        // this is called when any state change
        // it could be moved to a reselect so that it only runs when fields or fieldgeometries change
        //if (nextProps.fields !== this.props.fields) {

            /*
            var fieldsToAdd = inSecondOnly(this.props.fields,nextProps.fields,'fieldId');
            var fieldsToDelete = inFirstOnly(this.props.fields,nextProps.fields,'fieldId');
            var fieldsAlreadyExist = inBoth(this.props.fields,nextProps.fields,'fieldId');
            */

        if (!this.props.entityIsDrawing.entityIsDrawing){ // prevent attempt to re-add entity just drawn by user 
                                                          // Other option would be to simply check entities.getById before adding any entity
            var adjustZoom = false;
            // extract the array of field Id's from the 'fields' prop, and convert to Set
            const fieldsIds = new Set(this.props.fields.map(a => a.fieldId));
            const prevFieldsIds = new Set(prevProps.fields.map(a => a.fieldId));

            const fieldsToDelete = new Set([...prevFieldsIds].filter(x => !fieldsIds.has(x))); // diff
            const fieldsToAdd = new Set([...fieldsIds].filter(x => !prevFieldsIds.has(x))); // diff
            const fieldsAlreadyExist = new Set([...prevFieldsIds].filter(x => fieldsIds.has(x))); // intersection

            const fieldsToAdd2 = Array.from(fieldsToAdd);

            for (var i = 0; i < fieldsToAdd2.length; i++) {
                const fld = this.props.fields.find( f => f.fieldId === fieldsToAdd2[i]);
                /** this version is when I stored the GeoJSON in the field 'geometry' in the database
                var dataSource = GeoJsonDataSource.load(JSON.parse(fld.geometry),{
                    clampToGround:false,
                    stroke:Color.GREEN,
                    strokeWidth:2,
                    fill:Color.GREEN.withAlpha(0.1),
                    "name": fld.name
                });

                this.props.viewer.dataSources.add(dataSource);
                this.props.viewer.zoomTo(dataSource);
                */
                var coords = JSON.parse(fld.geometry);
                var polygon = this.props.viewer.entities.add({
                name : fld.name,
                id : fld.fieldId,
                    polygon : {
                        hierarchy : Cartesian3.fromDegreesArray(coords),
                        outline: true,
                        outlineColor: Color.LAWNGREEN,
                        clampToGround : true,
                        outlineWidth: 3,
                        fill: false
                    }
                });

                var entranceCoords = JSON.parse(fld.entrance);
                var lon = entranceCoords[0];
                var lat = entranceCoords[1];

                var entranceBillboard = this.props.viewer.entities.add({
                    position: Cartesian3.fromDegrees(lon, lat),
                    id: 'entrance-' + fld.fieldId,
                    billboard: {
                      image: entranceIcon,
                      verticalOrigin: VerticalOrigin.BOTTOM,
                      scale: 0.5,
                    },
                  });
                entranceBillboard.addProperty("type");
                entranceBillboard.type = "entrance"; 
                entranceBillboard.addProperty("fieldId");
                entranceBillboard.fieldId = fld.fieldId; 
                //adjustZoom = true;

            }

            const fieldsToDelete2 = Array.from(fieldsToDelete);
            for (var i = 0; i < fieldsToDelete2.length; i++) {
                /** this version is when I stored the GeoJSON in the field 'geometry' in the database
                var dataSources = this.props.viewer.dataSources;
                if (dataSources.length > 0) {
                    //loop through datasources to find entities with the selected object's id. 
                    for (var x=0;x<dataSources.length;x++) {
                        var matchingEntity = dataSources.get(x).entities.getById(fieldsToDelete2[i]);
                        if (matchingEntity){
                            dataSources.get(x).entities.remove(matchingEntity);
                            // there is one datasource per entity, so remove datasource too
                            dataSources.remove(dataSources.get(x));
                        }
                    }
                } */

                var deleted = this.props.viewer.entities.removeById(fieldsToDelete2[i]);
                // delete entrance billboard
                deleted = this.props.viewer.entities.removeById("entrance-" + fieldsToDelete2[i]);
            }

            
            
            //TODO: check if points of fields in fieldsAlreadyExist have changed (when fields can be edited)


            // extract the array of fieldGeometry Id's from the 'fieldsGeometries' prop, and convert to Set
            const geomIds = new Set(this.props.fieldGeometries.map(a => a.fieldGeometryId));
            const prevGeomIds = new Set(prevProps.fieldGeometries.map(a => a.fieldGeometryId));

            const geomsToDelete = new Set([...prevGeomIds].filter(x => !geomIds.has(x))); // diff
            const geomsToAdd = new Set([...geomIds].filter(x => !prevGeomIds.has(x))); // diff
            const geomsAlreadyExist = new Set([...prevGeomIds].filter(x => geomIds.has(x))); // intersection

            const geomsToAdd2 = Array.from(geomsToAdd);

            for (var i = 0; i < geomsToAdd2.length; i++) {
                const geom = this.props.fieldGeometries.find( f => f.fieldGeometryId === geomsToAdd2[i]);
                var color = "";
                switch(geom.type) {
                    case "POSITIVE_EXCLUSION_AREA":
                        var polygon = this.props.viewer.entities.add({
                            name : geom.name,
                            id : geom.fieldGeometryId,
                            polygon : {
                                hierarchy : Cartesian3.fromDegreesArray(JSON.parse(geom.geometry)),
                                outline: true,
                                outlineColor: Color.RED,
                                outlineWidth: 3,
                                fill: false
                            }
                            });
                        break;
                    case "NEGATIVE_EXCLUSION_AREA":
                        var polygon = this.props.viewer.entities.add({
                            name: geom.name,
                            id: geom.fieldGeometryId,
                            polygon: {
                                hierarchy: Cartesian3.fromDegreesArray(JSON.parse(geom.geometry)),
                                outline: true,
                                outlineColor: Color.BLUE,
                                outlineWidth: 3,
                                fill: false
                            }
                        });
                        break;
                    case "OBSTACLE":
                        var polygon = this.props.viewer.entities.add({
                            name: geom.name,
                            id: geom.fieldGeometryId,
                            polygon: {
                                hierarchy: Cartesian3.fromDegreesArray(JSON.parse(geom.geometry)),
                                outline: true,
                                outlineColor: Color.SIENNA,
                                outlineWidth: 3,
                                fill: false
                            }
                        });
                        break;
                    case "WORKING_AREA":
                        var polygon = this.props.viewer.entities.add({
                            name: geom.name,
                            id: geom.fieldGeometryId,
                            polygon: {
                                hierarchy: Cartesian3.fromDegreesArray(JSON.parse(geom.geometry)),
                                outline: true,
                                outlineColor: Color.DODGERBLUE,
                                outlineWidth: 2,
                                fill: false
                            }
                        });
                        break;
                    case "INTER_ROW_PATHS":
                        var rows = JSON.parse(geom.geometry);
                        for (var r=0; r<rows.length; r++ ){

                            var polyline = this.props.viewer.entities.add({
                                name : geom.name,
                                id : geom.fieldGeometryId + "_" + r,
                                polyline : {
                                    positions : Cartesian3.fromDegreesArray(rows[r]),
                                    clampToGround : true,
                                    material: Color.SALMON,
                                    width: 1,
                                    zIndex: 5 
                                }
                            });

                        }

                        break;
                    case "ROUTE":
                        // Cesium does not permit per segment color in entities. Only in low level scene primitives
                        // For now, the route must be split in different polylines entities
                        
                        var origRoute = JSON.parse(geom.geometry).pts;

                        //if (JSON.parse(geom.geometry).work ) {  
                        if (geom.origin == "RP_SUBFIELDS--" ) {  // only for RP subfields version 
                            
                            var wrk = JSON.parse(geom.geometry).work;
                            var splitRoute = [];

                            var prev = "";
                            for (var i=0; i<wrk.length; i++) {
                                if(wrk[i] != prev){
                                    // close polyline with first point of next one
                                    if (splitRoute.length>0){
                                        splitRoute[splitRoute.length - 1].push(origRoute[2*i]);
                                        splitRoute[splitRoute.length - 1].push(origRoute[2*i+1]);
                                    }
                                    splitRoute.push([]);
                                }
                                splitRoute[splitRoute.length - 1].push(origRoute[2*i]);
                                splitRoute[splitRoute.length - 1].push(origRoute[2*i+1]);
                                prev = wrk[i];
                            }

                            for (var i=0; i<splitRoute.length; i++){
                               
                                try{
                                    var polyline = this.props.viewer.entities.add({
                                        name : geom.name,
                                        id : geom.fieldGeometryId + "_" + i,
                                        polyline : {
                                            positions : Cartesian3.fromDegreesArray(splitRoute[i]),
                                            clampToGround : true,
                                            material: i % 2 == 0 ? Color.fromCssColorString('#FFFF33') : Color.fromCssColorString('#00FFFF'), // Color.YELLOW,
                                            width: 2
                                        }
                                    });
                                }catch(e){

                                }
                            }
                            
                            // add workable area

                            //if (JSON.parse(geom.geometry).workArea){
                                var polygon = this.props.viewer.entities.add({
                                    name: geom.name + "_wrkarea",
                                    id: geom.fieldGeometryId + "_wrkarea", // geom.fieldGeometryId,
                                    polygon: {
                                        hierarchy: Cartesian3.fromDegreesArray(JSON.parse(geom.geometry).workArea),
                                        //height : 0,
                                        outline: false,
                                        //outlineColor: Color.BLACK,
                                        //outlineWidth: 1,
                                        material: Color.GREEN.withAlpha(0.55),
                                        fill: true
                                    }
                                });
                            //}

                        }
                        else{ // no split


                            var colorArray = [Color.fromCssColorString('#FFFF55'),Color.CYAN,Color.SANDYBROWN,Color.CHARTREUSE,Color.VIOLET,Color.SILVER,Color.LIGHTPINK]
                            var colorIndex = 0;
                            var sp = geom.fieldGeometryId.split("_");
                            if (sp.length==2){ // id string has "_N" extension: it is a subfields route
                                colorIndex = Number(sp[1]) % colorArray.length;
                            }

                            var polyline = this.props.viewer.entities.add({
                                name : geom.name,
                                id : geom.fieldGeometryId,
                                polyline : {
                                    positions : Cartesian3.fromDegreesArray(JSON.parse(geom.geometry).pts),
                                    clampToGround : true,
                                    material: colorArray[colorIndex],
                                    width: 1,
                                    zIndex: 5 
                                }
                            });

                        }

                        break;
                }

                //adjustZoom = true;

                /** this version is when I stored the GeoJSON in the field 'geometry' in the database 
                var dataSource = GeoJsonDataSource.load(JSON.parse(geom.geometry),{
                    clampToGround:false,
                    stroke:color,
                    strokeWidth:2,
                    fill:color.withAlpha(0),
                    "name": geom.name
                });

                this.props.viewer.dataSources.add(dataSource);
                */
            }

            const geomsToDelete2 = Array.from(geomsToDelete);
            for (var i = 0; i < geomsToDelete2.length; i++) {
                /*
                var dataSources = this.props.viewer.dataSources;
                if (dataSources.length > 0) {
                    //loop through datasources to find entities with the selected object's id. 
                    for (var x=0;x<dataSources.length;x++) {
                        var matchingEntity = dataSources.get(x).entities.getById(geomsToDelete2[i]);
                        if (matchingEntity){
                            dataSources.get(x).entities.remove(matchingEntity);
                            dataSources.remove(dataSources.get(x));
                        }
                    }
                }*/
                
                // works when there is one cesium entity per route 
                this.props.viewer.entities.removeById(geomsToDelete2[i]);

                // since entities.add does not support polylines with per-segment color, multiples entities must be added per route
                // => entities that starts with id + "_n" must be deleted. 
                // remove until obtain false
                var n=0;
                while (this.props.viewer.entities.removeById(geomsToDelete2[i] + "_" + n )){
                    n++;
                }

                // remove possible workable areas
                this.props.viewer.entities.removeById(geomsToDelete2[i] + "_wrkarea" );

                // remove possible covered areas
                var cov = this.routeAreas.get(geomsToDelete2[i]  + "_coveredarea");
                if (cov){
                    this.props.viewer.clock.onTick.removeEventListener(this.onTickFunction);
                    for (var e=0; e<cov.length; e++){
                        this.props.viewer.entities.removeById(cov[e].cesiumId);
                    }
                    this.routeAreas.delete(geomsToDelete2[i]  + "_coveredarea");
                    // remove stats areas
                    this.removeStatsAreas();
                }
                // 

                /* // remove possible 3d model entity
                if (this.state.vehicleModelEntityId == "3Dmodel-" + geomsToDelete2[i]){
                    this.props.viewer.entities.removeById("3Dmodel-" + geomsToDelete2[i]);
                    this.setState({vehicleModelEntityId: null});
                }*/

                
            }

            if (fieldsToAdd2.length>0){ // adjust zoom only if new field was added
                this.props.viewer.zoomTo(this.props.viewer.entities, new HeadingPitchRange(0, CesiumMath.toRadians(-90)));
            }

            if (this.props.entityIsDrawing.entityIsDrawing)
                this.props.parent.style.cursor = 'crosshair';
            else
                this.props.parent.style.cursor = 'default';
        }

        if ( this.props.roslibMessageReceived.type  == "sensor_msgs/NavSatFix"){
            if (prevProps.roslibMessageReceived != this.props.roslibMessageReceived ){
                var obj = JSON.parse(this.props.roslibMessageReceived.message);

                var billboard = this.state.gpsBillboard;
                billboard.position = Cartesian3.fromDegrees(obj.longitude, obj.latitude);
                billboard.billboard.show = true;

                if (this.state.isRecordingRoute) {
                    if (this.state.recordingRoutePoints.length>0) { // if the route already exists, append point

                        this.setState({ recordingRoutePoints: [...this.state.recordingRoutePoints, ...[obj.longitude, obj.latitude] ] }) 

                    }
                    else { // create tmp recorded route cesium entity

                        this.setState({ recordingRoutePoints: [obj.longitude, obj.latitude] });
                        var recordingRoute = this.props.viewer.entities.add({
                            name: "",
                            id: uuid.v1(),
                            polyline: {
                                positions: new CallbackProperty(() => {
                                    return Cartesian3.fromDegreesArray(this.state.recordingRoutePoints);
                                }, false),
                                dashPattern: parseInt("1010101010101010", 2),
                                material: new PolylineDashMaterialProperty({
                                    color: Color.WHITE, // Color.fromCssColorString('#FFFF55'),
                                    dashPattern: parseInt("1010101010101010", 2),
                                }),
                                width: 2
                            }
                        });

                        this.setState({ tmpRecordingRoute: recordingRoute });
                    }
                }

            }
        }

        if (this.props.entityIsDrawing.entityIsSimplifying && !this.state.tmpImportingRoute){

            this.setState( {tmpImportingRoutePoints: this.props.entityIsDrawing.coords }); // copy coords to state variable, and keep original in redux state

            // create tmp imported route cesium entity
            var importingRoute = this.props.viewer.entities.add({
                name: this.props.entityIsDrawing.name,
                id: uuid.v1(),
                polyline: {
                    positions: new CallbackProperty(() => {
                        return Cartesian3.fromDegreesArray(this.state.tmpImportingRoutePoints);
                    }, false),
                    material: Color.WHITE,
                    width: 1
                }
            });

            this.setState({ tmpImportingRoute: importingRoute });
        }

        if (this.props.routeWasPlanned.id != prevProps.routeWasPlanned.id ){

            // remove previous tmp planned routes
            for (var route of this.state.tmpPlannedRoutes) {
                this.props.viewer.entities.removeById(route.id);
            }
/*
            // create tmp imported route cesium entity (for loop because SUBFIELDS returns multiple routes)
            for (var r = 0; r < this.props.routeWasPlanned.routeObject.routesCoord.length; r++) {
                var tmpPlannedRoute = this.props.viewer.entities.add({
                    name: "tmpPlannedRoute_",
                    id: this.props.routeWasPlanned.origin == "RP_SUBFIELDS" ? this.props.routeWasPlanned.id + "_" + r : this.props.routeWasPlanned.id  ,
                    polyline: {
                        clampToGround: true,
                        positions: Cartesian3.fromDegreesArray(this.props.routeWasPlanned.routeObject.routesCoord[r]),
                        material: Color.WHITE,
                        width: 1,
                        zIndex: 5
                    }
                });
            }
            
            if (this.props.routeWasPlanned.origin != "RP_SUBFIELDS") {  // "RP_OPENFIELD" or "RP_ORCHARD"
                this.setState({
                    tmpPlannedRoutes: [{
                        id: this.props.routeWasPlanned.id,
                        parentFieldId: this.props.routeWasPlanned.parentFieldId,
                        origin: this.props.routeWasPlanned.origin,
                        //vehicle: this.props.routeWasPlanned.vehicle,
                        pts: this.props.routeWasPlanned.routeObject.routesCoord[0],
                        speed: this.props.routeWasPlanned.routeObject.speed,
                        work: this.props.routeWasPlanned.routeObject.work
                    }]
                });
            }
            else {
                var plannedroutes = [];
                for (var r = 0; r < this.props.routeWasPlanned.routeObject.routesCoord.length; r++) {
                    plannedroutes.push({
                        id: this.props.routeWasPlanned.id + "_" + r,
                        parentFieldId: this.props.routeWasPlanned.parentFieldId,
                        origin: this.props.routeWasPlanned.origin,
                        //vehicle: this.props.routeWasPlanned.vehicle,
                        pts: this.props.routeWasPlanned.routeObject.routesCoord[r],
                        work: this.props.routeWasPlanned.routeObject.routesWork[r]
                    })
                }
                this.setState({ tmpPlannedRoutes: plannedroutes });
            }
*/
          // this.props.routeWasPlanned.routeObject puede ser un array de rutas en el caso de subfields (hasta que tenga la version que una las subrutas)
            if (this.props.routeWasPlanned.origin != "RP_SUBFIELDS") {  // "RP_OPENFIELD" or "RP_ORCHARD"
                
                var tmpPlannedRoute = this.props.viewer.entities.add({
                    name: "tmpPlannedRoute_",
                    id: this.props.routeWasPlanned.id,
                    polyline: {
                        clampToGround: true,
                        positions: Cartesian3.fromDegreesArray(this.props.routeWasPlanned.routeObject.routesCoord[0]),
                        material: Color.WHITE,
                        width: 1,
                        zIndex: 5
                    }
                });

                this.setState({
                    tmpPlannedRoutes: [{
                        id: this.props.routeWasPlanned.id,
                        parentFieldId: this.props.routeWasPlanned.parentFieldId,
                        origin: this.props.routeWasPlanned.origin,
                        //vehicle: this.props.routeWasPlanned.vehicle,
                        pts: this.props.routeWasPlanned.routeObject.routesCoord[0],
                        speed: this.props.routeWasPlanned.routeObject.speed,
                        work: this.props.routeWasPlanned.routeObject.work
                    }]
                });


            }
            else{

                var plannedroutes = [];
                var colorArray = [Color.PALETURQUOISE,Color.BLANCHEDALMOND,Color.PALEGREEN,Color.LIGHTGRAY,Color.THISTLE,Color.PEACHPUFF,Color.PINK]
                for (var r=0;r<this.props.routeWasPlanned.routeObject.length;r++){
                    var tmpPlannedRoute = this.props.viewer.entities.add({
                        name: "tmpPlannedRoute_",
                        id: this.props.routeWasPlanned.id + "_" + r,
                        polyline: {
                            clampToGround: true,
                            positions: Cartesian3.fromDegreesArray(this.props.routeWasPlanned.routeObject[r].routesCoord[0]),
                            material: colorArray[r % colorArray.length ],
                            width: 1,
                            zIndex: 5
                        }
                    });

                    plannedroutes.push({
                        id: this.props.routeWasPlanned.id + "_" + r,
                        parentFieldId: this.props.routeWasPlanned.parentFieldId,
                        origin: this.props.routeWasPlanned.origin,
                        //vehicle: this.props.routeWasPlanned.vehicle,
                        pts: this.props.routeWasPlanned.routeObject[r].routesCoord[0],
                        speed: this.props.routeWasPlanned.routeObject[r].speed,
                        work: this.props.routeWasPlanned.routeObject[r].work
                    })

                }
                this.setState({ tmpPlannedRoutes: plannedroutes });
               

            }

            let datetime = new Date();
            let date = ("0" + datetime.getDate()).slice(-2);
            let month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            let year = datetime.getFullYear();
            let hours = ("0" + datetime.getHours()).slice(-2);
            let minutes = ("0" + datetime.getMinutes()).slice(-2);
            let seconds = ("0" + datetime.getSeconds()).slice(-2);
            let dt = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds;

            this.setState({tmpPlannedRouteName: "RP_" + dt});

            // add animation only if route origin is "RP_OPENFIELD" or "RP_ORCHARD"

            if (this.props.routeWasPlanned.origin == "RP_OPENFIELD" || this.props.routeWasPlanned.origin == "RP_ORCHARD"){

                // CONFIGURE ANIMATION:

                // compute initial orientation

                /*
                var origin = new Cartesian3.fromDegrees(origRoute[0], origRoute[1]);
                var target = new Cartesian3.fromDegrees(origRoute[2], origRoute[3]);
                
                var direction = Cartesian3.subtract(target, origin, new Cartesian3());
                Cartesian3.normalize(direction, direction);

                var rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(origin, direction);
                //var rot90 = Matrix3.fromRotationY(CesiumMath.toRadians(90));
                //Matrix3.multiply(rotationMatrix, rot90, rotationMatrix);

                */

                // compute travel positions and orientations


                // required if you want no interpolation of position between times (https://stackoverflow.com/questions/32869814/best-and-most-performant-implementation-of-dynamic-shapes-in-cesium )
                var noInterpolation = {
                    type: 'No Interpolation',

                    getRequiredDataPoints: function (degree) {
                        return 2;
                    },

                    interpolateOrderZero: function (x, xTable, yTable, yStride, result) {
                        if (!defined(result)) {
                            result = new Array(yStride);
                        }

                        for (var i = 0; i < yStride; i++) {
                            result[i] = yTable[i];
                        }

                        return result;
                    }
                }; 
                
                var timeArray = this.props.routeWasPlanned.routeObject.time;
                var speedArray = this.props.routeWasPlanned.routeObject.speed;

                var positionSamples = new SampledPositionProperty();
                var orientationSamples = new SampledProperty(Quaternion);
                //orientationSamples.setInterpolationOptions({interpolationAlgorithm:undefined, interpolationDegree:undefined}); 
                orientationSamples.setInterpolationOptions({
                    interpolationAlgorithm: noInterpolation
                }); 


                var start = JulianDate.fromIso8601('2016-01-01T12:00:00Z');
                var orientQuat;

                var origRoute = this.props.routeWasPlanned.routeObject.routesCoord[0];

                for (var p=0; p<origRoute.length/2-2; p++){

                    var pointA = new Cartesian3.fromDegrees(origRoute[p*2], origRoute[p*2+1]);
                    var pointB = new Cartesian3.fromDegrees(origRoute[p*2+2], origRoute[p*2+3]);

                    if (pointA.equals(pointB)){
                        console.log("puntos iguales en: " + p/2 + " of " + origRoute.length/2 ); 
                    }
                    else{
                        var vel = speedArray[p];
                        var sampleTime = JulianDate.addSeconds(start, timeArray[p], new JulianDate());
                        positionSamples.addSample(sampleTime, pointA);
                        
                        var direction = Cartesian3.subtract(pointB, pointA, new Cartesian3());
                        Cartesian3.normalize(direction, direction);
                        var rotationMatrix = Transforms.rotationMatrixFromPositionVelocity(pointA, direction);

                        if (vel<0){
                            var rot180 = Matrix3.fromRotationZ(CesiumMath.toRadians(180));
                            Matrix3.multiply(rotationMatrix, rot180, rotationMatrix);
                        }
                        orientQuat = Quaternion.fromRotationMatrix(rotationMatrix);

                        orientationSamples.addSample(sampleTime, orientQuat);

                    }
                    
                }

                // add last sample
                var pt = new Cartesian3.fromDegrees(origRoute[origRoute.length-2], origRoute[origRoute.length-1]);
                var endTime = JulianDate.addSeconds(start, timeArray[timeArray.length-1], new JulianDate());
                positionSamples.addSample(endTime, pt);
                orientationSamples.addSample(endTime, orientQuat);

                this.props.viewer.clock.startTime = start.clone();
                this.props.viewer.clock.stopTime = endTime.clone();
                this.props.viewer.clock.currentTime = start.clone();

                this.props.viewer.clock.onTick.removeEventListener(this.onTickFunction);
                this.props.viewer.clock.onTick.addEventListener(this.onTickFunction);

                //Set timeline to simulation bounds
                this.props.viewer.timeline.zoomTo(start, endTime);

                var vehicleModel = this.props.routeWasPlanned.vehicle;

                var vehicle3DmodelFile = '';

                switch (this.props.routeWasPlanned.vehicle) {
                    case 'e-robotiller':
                        vehicle3DmodelFile = '/cesium/models/robotiller.glb';
                        break;
                    case 'etrac':
                        vehicle3DmodelFile = '/cesium/models/etrac.glb';
                        break;
                    case 'burro':
                        vehicle3DmodelFile = '/cesium/models/burro.glb';
                        break;
                }



                if (this.state.vehicleModelEntityId==null){
                    var ent = this.props.viewer.entities.add({
                        //availability : new TimeIntervalCollection([new TimeInterval({
                        //    start: start,
                        //    stop: prevTime
                        //    })]),
                        //id:  "3Dmodel-" + geom.fieldGeometryId,
                        position: positionSamples,
                        orientation: orientationSamples,
                        model: {
                            uri: process.env.PUBLIC_URL + vehicle3DmodelFile,
                            scale: 1
                        },
                        viewFrom: new Cartesian3(0, -15, 15) // distance of the camera from the trackedEntity (East, North, Up) in meters
                    });
                    this.setState({vehicleModelEntityId: ent.id});
                    //this.props.viewer.trackedEntity = ent;
                }
                else{
                    var ent = this.props.viewer.entities.getById(this.state.vehicleModelEntityId);
                    ent.model = {
                        uri: process.env.PUBLIC_URL + vehicle3DmodelFile,
                        scale: 1
                    };
                    ent.position = positionSamples;
                    ent.orientation = orientationSamples;
                }

                // CREATE COVERAGE AREA POLYGONS

                var cov = this.props.routeWasPlanned.routeObject.coverageAreas;

                for (var i=0; i<cov.length; i++) {

                    // convert coords to Cartesian3
                    cov[i].coords = Cartesian3.fromDegreesArray(cov[i].coords);
                    
                    /*
                    // ERROR: https://community.cesium.com/t/polygon-using-positionpropertyarray/10256/2

                    // create SampledPosition for the coverage polygon
                    var P3PositionSamples = new SampledPositionProperty();
                    var P4PositionSamples = new SampledPositionProperty();
                    // add initial position:
                    P3PositionSamples.addSample(start, cov[i].coords[0]);
                    P4PositionSamples.addSample(start, cov[i].coords[1]);
                    var covAreaTimeStart = JulianDate.addSeconds(start, cov[i].startTime, new JulianDate());
                    P3PositionSamples.addSample(covAreaTimeStart, cov[i].coords[0]);
                    P4PositionSamples.addSample(covAreaTimeStart, cov[i].coords[1]);
                    var covAreaTimeEnd = JulianDate.addSeconds(start, cov[i].endTime, new JulianDate());
                    P3PositionSamples.addSample(covAreaTimeEnd, cov[i].coords[3]);
                    P4PositionSamples.addSample(covAreaTimeEnd, cov[i].coords[2]);

                    var positions = [
                        new ConstantPositionProperty(cov[i].coords[0]),
                        new ConstantPositionProperty(cov[i].coords[1]),
                        P4PositionSamples,
                        P3PositionSamples
                    ];
                    */

                    // add new coverage rectangle
                    var area = this.props.viewer.entities.add({
                        name: this.props.routeWasPlanned.id + "_coveredarea_" + i,
                        id: this.props.routeWasPlanned.id + "_coveredarea_" + i, 
                        polygon: {
                            hierarchy : new PolygonHierarchy([
                                cov[i].coords[0],
                                cov[i].coords[1],
                                cov[i].coords[2],
                                cov[i].coords[3]]),
                            outline: false,
                            material: Color.GREEN.withAlpha(0.7),
                            fill: true,
                            height: 0
                        }
                    });
                    cov[i].cesiumId = this.props.routeWasPlanned.id + "_coveredarea_" + i;

                }

                //var areaObj = { cesiumId: geom.fieldGeometryId + "_coveredarea_" + i  }
                //this.routeAreas = new Map();
                // if there is a key of a previous RP run in ahother field => delete

                var currentCov = this.routeAreas.values().next().value;
                if (currentCov){
                    //this.props.viewer.clock.onTick.removeEventListener(this.onTickFunction);
                    for (var e=0; e<currentCov.length; e++){
                        this.props.viewer.entities.removeById(currentCov[e].cesiumId);
                    }
                }
                this.routeAreas = new Map();
                this.routeAreas.set(this.props.routeWasPlanned.id + "_coveredarea", cov);

            }
            
            // CREATE STATS AREA POLYGONS

            this.removeStatsAreas();

            // coveredArea

            var rStats;

            if (Array.isArray(this.props.routeWasPlanned.routeObject)){
                rStats = this.props.routeWasPlanned.routeObject[0].routeStats;
            }
            else{
                rStats = this.props.routeWasPlanned.routeObject.routeStats;
            }
            var totalCoveredArea = rStats.coveredArea;

            for (var pol=0; pol<totalCoveredArea.length; pol++){

                if (totalCoveredArea[pol][0].length>0){ // sometimes an anomalous polygon of 0 vertex can be generated

                    var hier = new PolygonHierarchy();
                    hier.positions = Cartesian3.fromDegreesArray(totalCoveredArea[pol][0]);
                    hier.holes = [];
                    for (var ring=1; ring<totalCoveredArea[pol].length; ring++){
                        var hole = new PolygonHierarchy();
                        hole.positions = Cartesian3.fromDegreesArray(totalCoveredArea[pol][ring]);
                        hier.holes.push(hole);
                    }

                    var area = this.props.viewer.entities.add({
                        name: this.props.routeWasPlanned.id + "_totalcoveredarea_" + pol,
                        id: this.props.routeWasPlanned.id + "_totalcoveredarea_" + pol, 
                        polygon: {
                            hierarchy : hier,
                            outline: false,
                            material: Color.DARKGREEN,
                            fill: true,
                            zIndex : 0
                        },
                        show: this.isCoveredAreaChecked
                    });
                    this.coveredAreaIds.push(area.id);
                }
            }

            // uncoveredArea
            var uncoveredArea = rStats.uncoveredArea;

            for (var pol=0; pol<uncoveredArea.length; pol++){

                if (uncoveredArea[pol][0].length>0){ // sometimes an anomalous polygon of 0 vertex can be generated
                    var hier = new PolygonHierarchy();
                    hier.positions = Cartesian3.fromDegreesArray(uncoveredArea[pol][0]);
                    hier.holes = [];
                    for (var ring=1; ring<uncoveredArea[pol].length; ring++){
                        var hole = new PolygonHierarchy();
                        hole.positions = Cartesian3.fromDegreesArray(uncoveredArea[pol][ring]);
                        hier.holes.push(hole);
                    }

                    var area = this.props.viewer.entities.add({
                        name: this.props.routeWasPlanned.id + "_uncoveredArea_" + pol,
                        id: this.props.routeWasPlanned.id + "_uncoveredArea_" + pol, 
                        polygon: {
                            hierarchy : hier,
                            outline: false,
                            material: Color.AQUA,
                            fill: true,
                            zIndex : 1
                        },
                        show: this.isUncoveredAreaChecked
                    });
                    this.uncoveredAreaIds.push(area.id);
                }
            }

            // overlapArea
            var overlapArea = rStats.overlapArea;
            if (overlapArea){
                for (var pol=0; pol<overlapArea.length; pol++){

                    if (overlapArea[pol][0].length>0){ // sometimes an anomalous polygon of 0 vertex can be generated

                        var hier = new PolygonHierarchy();
                        hier.positions = Cartesian3.fromDegreesArray(overlapArea[pol][0]);
                        hier.holes = [];
                        for (var ring=1; ring<overlapArea[pol].length; ring++){
                            var hole = new PolygonHierarchy();
                            hole.positions = Cartesian3.fromDegreesArray(overlapArea[pol][ring]);
                            hier.holes.push(hole);
                        }

                        var area = this.props.viewer.entities.add({
                            name: this.props.routeWasPlanned.id + "_overlapArea_" + pol,
                            id: this.props.routeWasPlanned.id + "_overlapArea_" + pol, 
                            polygon: {
                                hierarchy : hier,
                                outline: false,
                                material: Color.HOTPINK,
                                fill: true,
                                zIndex : 2
                            },
                            show: this.isOverlapAreaChecked
                        });
                        this.overlapAreaIds.push(area.id);
                    }
                }
            }
            this.coveredAreaSize = "(" + rStats.coveredAreaSize + "%)";
            this.uncoveredAreaSize = "(" + rStats.uncoveredAreaSize + "%)";
            this.overlapAreaSize = "(" + rStats.overlapAreaSize + "%)";
            
            this.setState({travelledDistance: rStats.travelledDistance.toFixed(2) + " m"});
            var workedDistancePercent = (100*rStats.workedDistance/rStats.travelledDistance).toFixed(2);
            this.setState({workedDistance: rStats.workedDistance.toFixed(2) + " m (" + workedDistancePercent + "%)"});

            this.setState({travelledTime: this.toHHMMSS(rStats.travelledTime)});
            var workedTimePercent = (100*rStats.workedTime/rStats.travelledTime).toFixed(2);
            this.setState({workedTime: this.toHHMMSS(rStats.workedTime) + " (" + workedTimePercent + "%)"});
        
        }

        if (this.props.setVisibility.entityId != prevProps.setVisibility.entityId ||
                this.props.setVisibility.value != prevProps.setVisibility.value) {

            var ent = this.props.viewer.entities.getById(this.props.setVisibility.entityId);
            
            if (ent){
                ent.show = this.props.setVisibility.value;
            }
            else{ // if the deleted object is Inter-row Paths, there are multiple Cesium polyline entities with ID format = CF entityId + "_" + N
                var r = 0;
                do {
                    ent = this.props.viewer.entities.getById(this.props.setVisibility.entityId + "_" + r);
                    if (ent) {
                        ent.show = this.props.setVisibility.value;
                        r++;
                    }
                } while (ent);
            }

        }

    }

    createInputHandlers() {
        this.screenEvents.setInputAction(this.onMouseLeftClick, SSET.LEFT_CLICK);
        this.screenEvents.setInputAction(this.onMouseLeftDown, SSET.LEFT_DOWN);
        this.screenEvents.setInputAction(this.onMouseLeftUp, SSET.LEFT_UP);
        //this.screenEvents.setInputAction(this.onMouseLeftUp, SSET.RIGHT_UP);
        //this.screenEvents.setInputAction(this.onMouseDoubleLeftClick, SSET.LEFT_DOUBLE_CLICK);
        // disable double click default event
        this.props.viewer.screenSpaceEventHandler.removeInputAction(SSET.LEFT_DOUBLE_CLICK);
        this.screenEvents.setInputAction(this.onMouseRightClick, SSET.RIGHT_CLICK);
        this.screenEvents.setInputAction(this.onMouseMove, SSET.MOUSE_MOVE);
        this.props.viewer.selectedEntityChanged.addEventListener(this.onSelectedEntityChanged);
    }

    onMouseLeftClick = (e) => {

        /* TODO: when the user is adding an entity and double-clicks this event is fired twice and two 
        points duplicated are added. Also, SetState is async, so numPoints is not updated in double click
        possible solution: disable this event upto the state is updated
        You can call a function after the state value has updated:
                this.setState({foo: 'bar'}, () => { 
                    // Do something here. 
                });
        */
        if (this.props.entityIsDrawing.entityIsDrawing){

            const {position : clientPos} = e;
            const mapCoordsRadians = this.pickMapCoordinates(clientPos);
    
            if(mapCoordsRadians) {
                const lat = CesiumMath.toDegrees(mapCoordsRadians.latitude);
                const lon = CesiumMath.toDegrees(mapCoordsRadians.longitude);

                if (this.state.numPoints==0){ // first point clicked => create entity

                    var entityId = uuid.v1();
                    this.setState({ entityDrawingId : entityId });
                    const colors = [
                        { name: "NEGATIVE_EXCLUSION_AREA", col: Color.BLUE, width: 3 }, 
                        { name: "POSITIVE_EXCLUSION_AREA", col: Color.RED, width: 3 },
                        { name: "OBSTACLE", col: Color.SIENNA, width: 3 },
                        { name: "WORKING_AREA", col: Color.DODGERBLUE, width: 2 },
                        { name: "FIELD", col: Color.LAWNGREEN, width: 3 }
                    ];

                    switch(this.props.entityIsDrawing.entityDrawingType) {
                        case "NEGATIVE_EXCLUSION_AREA":
                        case "POSITIVE_EXCLUSION_AREA":
                        case "OBSTACLE":
                        case "WORKING_AREA":
                        case "FIELD":
                            // trick: I add two points very close to the first one so that a polygon can be drawn from the start
                            // These two dummy points are replaced with the next two left mouse clicks 
                            this.setState({ points: [lon, lat, lon + 0.000001, lat, lon, lat + 0.000001 ] });
                            this.setState({ minPoints : 3 })
                            var style = colors.find(c => c.name == this.props.entityIsDrawing.entityDrawingType)
                            var polygon = this.props.viewer.entities.add({
                                name : "",
                                id : entityId,
                                polygon : {
                                    hierarchy : new CallbackProperty(() => {
                                        return new PolygonHierarchy(Cartesian3.fromDegreesArray(this.state.points));
                                    }, false),
                                    outline: true,
                                    outlineColor: style.col,
                                    outlineWidth: style.width,
                                    fill: false
                                }
                                });
                            break;
                        case "ROUTE":
                            this.setState({ points: [lon, lat] });
                            this.setState({ minPoints : 2 });
                            this.props.viewer.entities.add({
                                name : "",
                                id : entityId,
                                polyline : {
                                    positions : new CallbackProperty(() => {
                                        return Cartesian3.fromDegreesArray(this.state.points);
                                    }, false),
                                    material: Color.fromCssColorString('#FFFF55'), // Color.YELLOW,
                                    width: 2
                                }
                                });
                            break;
                        default:  
                    }
                }else {
                    let newPoints = this.state.points.slice(); //copy the array
                    newPoints[this.state.numPoints*2 + 0] = lon; //execute the manipulations
                    newPoints[this.state.numPoints*2 + 1] = lat;
                    this.setState({points: newPoints}) //set the new state
                }

                this.setState({ numPoints: this.state.numPoints + 1 });
                this.props.parent.style.cursor = 'crosshair';
            }
        }


    }

    onMouseRightClick = async (e) => {

        if (this.props.entityIsDrawing.entityIsDrawing){
            // remove current moving point
            let points = this.state.points; 
            points.pop();
            points.pop();
            var tmp_ent = this.props.viewer.entities.getById(this.state.entityDrawingId);

            switch(this.props.entityIsDrawing.entityDrawingType) {

                case "POSITIVE_EXCLUSION_AREA":
                case "NEGATIVE_EXCLUSION_AREA":
                    var name = await new Promise((resolve, reject) => {
                        this.dialog.show({
                            body: "Enter exclusion area name",
                            prompt: Dialog.TextPrompt(),
                            actions: [
                                Dialog.CancelAction(() => {
                                    resolve("");
                                }),
                                Dialog.OKAction((dialog) => {
                                    resolve(dialog.value);
                                })
                            ]
                        });
                    });
                    if (name !== ""){
                        tmp_ent.polygon.hierarchy = Cartesian3.fromDegreesArray(points);
                        this.props.createFieldGeometry( this.props.entityIsDrawing.parentFieldId,
                                this.state.entityDrawingId,
                                JSON.stringify(points),
                                name,
                                this.props.entityIsDrawing.entityDrawingType,
                                "CESIUM_DRAWING"
                            );
                    }else{
                        this.props.viewer.entities.remove(tmp_ent);
                    }

                break;

                case "OBSTACLE":
                    var name = await new Promise((resolve, reject) => {
                        this.dialog.show({
                            body: "Enter obstacle name",
                            prompt: Dialog.TextPrompt(),
                            actions: [
                                Dialog.CancelAction(() => {
                                    resolve("");
                                }),
                                Dialog.OKAction((dialog) => {
                                    resolve(dialog.value);
                                })
                            ]
                        });
                    });
                    if (name !== ""){
                        tmp_ent.polygon.hierarchy = Cartesian3.fromDegreesArray(points);
                        this.props.createFieldGeometry( this.props.entityIsDrawing.parentFieldId,
                                this.state.entityDrawingId,
                                JSON.stringify(points),
                                name,
                                this.props.entityIsDrawing.entityDrawingType,
                                "CESIUM_DRAWING"
                            );
                    }else{
                        this.props.viewer.entities.remove(tmp_ent);
                    }

                break;

                case "WORKING_AREA":
                    var name = await new Promise((resolve, reject) => {
                        this.dialog.show({
                            body: "Enter working area name",
                            prompt: Dialog.TextPrompt(),
                            actions: [
                                Dialog.CancelAction(() => {
                                    resolve("");
                                }),
                                Dialog.OKAction((dialog) => {
                                    resolve(dialog.value);
                                })
                            ]
                        });
                    });
                    if (name !== ""){
                        tmp_ent.polygon.hierarchy = Cartesian3.fromDegreesArray(points);
                        this.props.createFieldGeometry( this.props.entityIsDrawing.parentFieldId,
                                this.state.entityDrawingId,
                                JSON.stringify(points),
                                name,
                                this.props.entityIsDrawing.entityDrawingType,
                                "CESIUM_DRAWING"
                            );
                    }else{
                        this.props.viewer.entities.remove(tmp_ent);
                    }

                break;

                case "ROUTE":
                    var name = await new Promise((resolve, reject) => {
                        this.dialog.show({
                            body: "Enter route name",
                            prompt: Dialog.TextPrompt(),
                            actions: [
                                Dialog.CancelAction(() => {
                                    resolve("");
                                }),
                                Dialog.OKAction((dialog) => {
                                    resolve(dialog.value);
                                })
                            ]
                        });
                    });
                    if (name !== ""){
                        tmp_ent.polyline.positions = Cartesian3.fromDegreesArray(points);
                        this.props.createFieldGeometry( this.props.entityIsDrawing.parentFieldId,
                                this.state.entityDrawingId,
                                JSON.stringify({pts: points}),
                                name,
                                'ROUTE',
                                "CESIUM_DRAWING"
                            );
                    }else{
                        this.props.viewer.entities.remove(tmp_ent);
                    }
                    break;
                
                case "FIELD":
                    var name = await new Promise((resolve, reject) => {
                        this.dialog.show({
                            body: "Enter field name",
                            prompt: Dialog.TextPrompt(),
                            actions: [
                                Dialog.CancelAction(() => {
                                    resolve("");
                                }),
                                Dialog.OKAction((dialog) => {
                                    resolve(dialog.value);
                                })
                            ]
                        });
                    });
                    if (name !== ""){
                        tmp_ent.polygon.hierarchy = Cartesian3.fromDegreesArray(points);
                        // add entrance to new field
                        var entranceBillboard = this.props.viewer.entities.add({
                            position: Cartesian3.fromDegrees(points[0], points[1]),
                            id: 'entrance-' + this.state.entityDrawingId,
                            billboard: {
                              image: entranceIcon,
                              verticalOrigin: VerticalOrigin.BOTTOM,
                              scale: 0.5,
                            },
                          });
                        entranceBillboard.addProperty("type");
                        entranceBillboard.type = "entrance"; 
                        entranceBillboard.addProperty("fieldId");
                        entranceBillboard.fieldId = this.state.entityDrawingId; 

                        this.props.createField( this.props.currentFarm.currentFarmId,
                                this.state.entityDrawingId,
                                JSON.stringify(points),
                                name,
                                JSON.stringify([points[0], points[1]])
                            );

                    }else{
                        this.props.viewer.entities.remove(tmp_ent);
                    }
                    break;
                default:  
            }

            this.props.parent.style.cursor = 'default';
            this.tooltip.setVisible(false);
            this.setState({ numPoints: 0 });
            this.props.finishDrawing();

        }
    }

    onMouseMove = (e) => {
        
        if (this.props.entityIsDrawing.entityIsDrawing) {

            const {endPosition : clientPos} = e;
            const mapCoordsRadians = this.pickMapCoordinates(clientPos);
    
            if(mapCoordsRadians) {

                if (this.state.numPoints==0){ 
                    this.tooltip.showAt(clientPos,"Click to start drawing the " + this.props.entityIsDrawing.entityDrawingType.toLowerCase());
                } else {  //if (this.state.numPoints==1) {
                    this.tooltip.showAt(clientPos,"Click to add new point" + (this.state.numPoints >= this.state.minPoints ? "<br/>Right click to finish drawing" : ""));
                    const lat = CesiumMath.toDegrees(mapCoordsRadians.latitude);
                    const lon = CesiumMath.toDegrees(mapCoordsRadians.longitude);
                    
                    let newPoints = this.state.points.slice(); //copy the array
                    newPoints[this.state.numPoints*2 + 0] = lon; //execute the manipulations
                    newPoints[this.state.numPoints*2 + 1] = lat;
                    this.setState({points: newPoints}) //set the new state    
                }

            }
        }

        if (this.props.cesiumSelectedEntityChanged.cesiumSelectedEntityType == "entrance" && this.dragging ){

            const {endPosition : clientPos} = e;
            const mapCoordsRadians = this.pickMapCoordinates(clientPos);
    
            if(mapCoordsRadians) {
                const lat = CesiumMath.toDegrees(mapCoordsRadians.latitude);
                const lon = CesiumMath.toDegrees(mapCoordsRadians.longitude);

                var entranceBillboard = this.props.viewer.entities.getById(this.props.cesiumSelectedEntityChanged.cesiumSelectedEntityId); 

                const fldCoords = this.props.fields.find( f => f.fieldId === entranceBillboard.fieldId).geometry;

                // closestPointInPolygon in computed with lat lon coordinates to avoid conversion to UTM
                var res = closestPointInPolygon({x: lon, y: lat}, JSON.parse(fldCoords));

                entranceBillboard.position = Cartesian3.fromDegrees(res[0], res[1]);                

            }
        }

    }

    onMouseLeftDown = (e) => {

        if (this.props.cesiumSelectedEntityChanged.cesiumSelectedEntityId && 
            this.props.cesiumSelectedEntityChanged.cesiumSelectedEntityType == "entrance"){
            //!this.props.entityIsDrawing.entityIsDrawing){

            this.dragging = true;
            this.props.viewer.scene.screenSpaceCameraController.enableRotate = false;
        }

    }

    onMouseLeftUp = (e) => {

        this.dragging = false;
        this.props.viewer.scene.screenSpaceCameraController.enableRotate = true;
    }

    
    onTickFunction  = (clock) => {
        
        this.renderCount ++;

        if (this.renderCount>15){

            var covAreas = this.routeAreas.values().next().value;
            var elapsedTime = JulianDate.secondsDifference(clock.currentTime, clock.startTime);

            //this.props.viewer.entities.suspendEvents();
            for (var i=0; i<covAreas.length; i++ ){
                
                var ent = this.props.viewer.entities.getById(covAreas[i].cesiumId);

                if (elapsedTime>covAreas[i].startTime){ // && elapsedTime<covAreas[i].endTime){ // current line

                    if (elapsedTime<covAreas[i].endTime){ // current line)
                    var percentage = (elapsedTime-covAreas[i].startTime)/(covAreas[i].endTime-covAreas[i].startTime);

                    // coords [P1,P2,P4,P3]
                    var P1 = covAreas[i].coords[0];
                    var P2 = covAreas[i].coords[1];
                    var P4 = covAreas[i].coords[2];
                    var P3 = covAreas[i].coords[3];

                    var newP3 = new Cartesian3(P1.x + percentage*(P3.x-P1.x), P1.y + percentage*(P3.y-P1.y), P1.z + percentage*(P3.z-P1.z));
                    var newP4 = new Cartesian3(P2.x + percentage*(P4.x-P2.x), P2.y + percentage*(P4.y-P2.y), P2.z + percentage*(P4.z-P2.z));

                    //this.tmpPolygonHierarchy.positions = [this.P1,this.P2,this.P4,this.P3]; 

                    ent.polygon.hierarchy = new PolygonHierarchy([P1,P2,newP4,newP3],[]);
                    //ent.polygon.hierarchy._value.positions[2] = newP4;
                    //ent.polygon.hierarchy._value.positions[3] = newP3;
                    
                    }
                    else{ // swath already completed

                        var P1 = covAreas[i].coords[0];
                        var P2 = covAreas[i].coords[1];
                        var P4 = covAreas[i].coords[2];
                        var P3 = covAreas[i].coords[3];

                        if (!P4.equals(ent.polygon.hierarchy._value.positions[2])){
                            ent.polygon.hierarchy = new PolygonHierarchy([P1,P2,P4,P3],[]);
                        }


                    }
                    ent.show = true;
                }
                else{
                    ent.show = elapsedTime>covAreas[i].endTime;
                }
            }
            //this.props.viewer.entities.resumeEvents();
            this.renderCount = 0;

        }
    }
    

    onSelectedEntityChanged =  async (e) => {
        
        var prevSelected = this.props.cesiumSelectedEntityChanged; // prev selected
        
        if (prevSelected.cesiumSelectedEntityType == "entrance"){
            var entranceBillboard = this.props.viewer.entities.getById(prevSelected.cesiumSelectedEntityId);
            entranceBillboard.billboard.color = null;
            
            var pos = entranceBillboard.position.getValue(this.props.viewer.clock.currentTime);

            var cartographicPosition = Cartographic.fromCartesian(pos);
            var latitude = CesiumMath.toDegrees(cartographicPosition.latitude);
            var longitude = CesiumMath.toDegrees(cartographicPosition.longitude);

            this.props.updateField(this.props.currentFarm.currentFarmId,
                entranceBillboard.fieldId,
                JSON.stringify([longitude, latitude]));

        }


        if (typeof e !== 'undefined'){
            this.props.changeSelectedEntity(e.id, e.type);
            if (e.type == "entrance"){
                e.billboard.color = Color.RED.withAlpha(0.7);
            }
        }
        else {
            this.props.changeSelectedEntity("", "");
        }
    }

    handleTrackVehicle = () => {
        if (this.props.viewer.trackedEntity){
            this.props.viewer.trackedEntity = undefined;
        }
        else{
            if (this.state.vehicleModelEntityId){
                var ent = this.props.viewer.entities.getById(this.state.vehicleModelEntityId);
                this.props.viewer.trackedEntity = ent;
            }
        }
    }

    pickMapCoordinates(screenPos) {
        const {scene} = this.props;
        let mapCoords;

        if(scene) {
            const cartesianPos = scene.camera.pickEllipsoid(screenPos);

            if(cartesianPos) {
                mapCoords = scene.globe.ellipsoid.cartesianToCartographic(cartesianPos);
            }
        }

        return mapCoords;
    }

    createTooltip(frameDiv) {

        var tooltip = function(frameDiv) {

            var div = document.createElement('DIV');
            div.className = "twipsy right";

            var arrow = document.createElement('DIV');
            arrow.className = "twipsy-arrow";
            div.appendChild(arrow);

            var title = document.createElement('DIV');
            title.className = "twipsy-inner";
            div.appendChild(title);

            this._div = div;
            this._title = title;

            // add to frame div and display coordinates
            frameDiv.appendChild(div);
        }

        tooltip.prototype.setVisible = function(visible) {
            this._div.style.display = visible ? 'block' : 'none';
        }

        tooltip.prototype.showAt = function(position, message) {
            if(position && message) {
                this.setVisible(true);
                this._title.innerHTML = message;
                this._div.style.left = position.x + 20 + "px";
                this._div.style.top = (position.y - this._div.clientHeight / 2) + 50 + "px";
            }
        }

        return new tooltip(frameDiv);
    }

    removeStatsAreas = () =>{

        for (var e=0; e<this.coveredAreaIds.length; e++){
            this.props.viewer.entities.removeById(this.coveredAreaIds[e]);
        }
        this.coveredAreaIds = [];

        for (var e=0; e<this.uncoveredAreaIds.length; e++){
            this.props.viewer.entities.removeById(this.uncoveredAreaIds[e]);
        }
        this.uncoveredAreaIds = [];

        for (var e=0; e<this.overlapAreaIds.length; e++){
            this.props.viewer.entities.removeById(this.overlapAreaIds[e]);
        }
        this.overlapAreaIds = [];
    }

    showCoveredArea = (evt) => {
        for (var e=0; e<this.coveredAreaIds.length; e++){
            this.props.viewer.entities.getById(this.coveredAreaIds[e]).show = evt.target.checked;
        }
        this.isCoveredAreaChecked = evt.target.checked;
    }

    showUncoveredArea = (evt) => {
        for (var e=0; e<this.uncoveredAreaIds.length; e++){
            this.props.viewer.entities.getById(this.uncoveredAreaIds[e]).show = evt.target.checked;
        }
        this.isUncoveredAreaChecked = evt.target.checked;
    }

    showOverlapArea = (evt) => {
        for (var e=0; e<this.overlapAreaIds.length; e++){
            this.props.viewer.entities.getById(this.overlapAreaIds[e]).show = evt.target.checked;
        }
        this.isOverlapAreaChecked = evt.target.checked;
    }

    strToLatitude(strl){

        // check if is in decimal format
        if (this.isNumeric(strl)){
            var num = parseFloat(strl);
            if (num>-90 && num<=90 ) return num;
            return null;
        }

        // check if is in DMS format
        const parts = strl.split(/[^\d+(\,\d+)\d+(\.\d+)?\w-]+/);

        if (parts.length!=3 && parts.length!=4) return null;

        if (parts.length==3) parts.push(""); 

        const direction = parts[3].toLowerCase();

        if (direction!="" && direction!="n" && direction!="s") return null;

        // check degrees

        var deg = parts[0];
        if (!this.isInt(deg)) return null;
        deg = parseInt(deg);
        if (direction == "s") {
            deg = deg * -1;
        }
        if (deg<-90 || deg>90 ) return null;

        var min = parts[1];
        if (!this.isInt(min)) return null;
        min = parseInt(min);
        if (min<0 || min>59 ) return null;

        var sec = parts[2];
        if (!this.isNumeric(sec)) return null;
        sec = parseFloat(sec);
        if (sec<0 || sec>60 ) return null;

        var sign = deg < 0 ? -1 : 1;
        return (Math.abs(deg) + (min / 60.0) + (sec / 3600)) * sign;
    }

    strToLongitude(strl){

        // check if is in decimal format
        if (this.isNumeric(strl)){
            var num = parseFloat(strl);
            if (num>-180 && num<=180 ) return num;
            return null;
        }

        // check if is in DMS format
        const parts = strl.split(/[^\d+(\,\d+)\d+(\.\d+)?\w-]+/);
        
        if (parts.length!=3 && parts.length!=4) return null;

        if (parts.length==3) parts.push(""); 

        const direction = parts[3].toLowerCase();

        if (direction!="" && direction!="w" && direction!="e") return null;

        // check degrees

        var deg = parts[0];
        if (!this.isInt(deg)) return null;
        deg = parseInt(deg);
        if (direction == "w") {
            deg = deg * -1;
        }
        if (deg<-180 || deg>180 ) return null;

        var min = parts[1];
        if (!this.isInt(min)) return null;
        min = parseInt(min);
        if (min<0 || min>59 ) return null;

        var sec = parts[2];
        if (!this.isNumeric(sec)) return null;
        sec = parseFloat(sec);
        if (sec<0 || sec>60 ) return null;

        var sign = deg < 0 ? -1 : 1;
        return (Math.abs(deg) + (min / 60.0) + (sec / 3600)) * sign;
    }

    isNumeric(num){
        return !isNaN(num) && !isNaN(parseFloat(num));
    }

    isInt(value) {
        return !isNaN(value) &&
            parseInt(Number(value)) == value &&
            !isNaN(parseInt(value, 10));
    }

    toHHMMSS = function (sec_num) {
        //var sec_num = this; // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = Math.round(sec_num - (hours * 3600) - (minutes * 60));
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return hours+':'+minutes+':'+seconds;
    }


    handleStartStopRecording = async (e) => {
    
        this.setState({isRecordingRoute: !this.state.isRecordingRoute});


        if (this.state.tmpRecordingRoute && e.target.innerText == "Stop Recording"){

            //this.setState({showSaveRecordedRouteModal: true});
            this.props.routeIsSimplifying("", "", this.state.recordingRoutePoints.slice(0));

            this.props.viewer.entities.remove(this.state.tmpRecordingRoute);
            this.setState(
                {
                    tmpRecordingRoute: null,
                    recordingRoutePoints: [],
                    showSaveRecordedRouteModal: false
                });

        }
   

    }

    saveRecordedRoute = () => {

        if (this.inputFieldId.value.length<1){
            this.dialog.showAlert("Please select a Field");
            return;
        }

        if (this.state.recordedRouteName.length==0){
            this.dialog.showAlert("Please enter a name for the new Route");
            return;
        }

        this.props.viewer.entities.remove(this.state.tmpImportingRoute);

        this.props.createFieldGeometry( this.inputFieldId.value,
            uuid.v1(),
            JSON.stringify({pts: this.state.tmpImportingRoutePoints}),
            this.state.recordedRouteName,
            'ROUTE',
            "RECORDED"
        );
        
        this.setState(
            {
                tmpImportingRoute: null,
                tmpImportingRoutePoints: [],
                simplifyImportedRoute: false,
                smoothImportedRoute: false,
                showSaveRecordedRouteModal: false
            });

        this.props.finishDrawing();

    }

    cancelRecordedRoute = () => {
        this.props.viewer.entities.remove(this.state.tmpImportingRoute);
        this.setState(
            {
                tmpImportingRoute: null,
                tmpImportingRoutePoints: [],
                simplifyImportedRoute: false,
                smoothImportedRoute: false,
                showSaveRecordedRouteModal: false
            });

            this.props.finishDrawing();
    }

    handleSimplifyImportedRoute = (e) => {

        // this code does not work because setState is asynchronous. The value is not updated immediatly.
        // this.setState({ simplifyImportedRoute: e.target.checked });
        // this.simplifyAndSmooth();
        // instead, invoke the second function as a callback to setState

        this.setState({ simplifyImportedRoute: e.target.checked }, this.simplifyAndSmooth );

    }

    handleSimplifyTolerance = (e) => {

        this.setState({ simplifyTolerance: e.target.value }, this.simplifyAndSmooth );

    }

    handleSmoothImportedRoute = (e) => {

        this.setState({ smoothImportedRoute: e.target.checked }, this.simplifyAndSmooth );

    }

    handleSmoothPercent = (e) => {

        this.setState({ smoothPercent: e.target.value }, this.simplifyAndSmooth );

    }



    simplifyAndSmooth = () => {

        var simplify = this.state.simplifyImportedRoute;
        var smooth = this.state.smoothImportedRoute;
        var simplifyTol = parseFloat(this.state.simplifyTolerance);
        var smoothPer = parseFloat(this.state.smoothPercent);

        var originalCoords = this.props.entityIsDrawing.coords.slice(0); // copy original array in a new one

        var origCoordsUtm = fromLatLonArray(originalCoords);


        var simplifiedUtm = origCoordsUtm.map(function (pt) {  
            return [pt.x, pt.y];
        });

        if (simplify){
            simplifiedUtm = RDPsd(simplifiedUtm, simplifyTol);
        }

        // smooth

        if (smooth){
            simplifiedUtm = smoothPolyline(simplifiedUtm, smoothPer);
        }

        var coords2 = simplifiedUtm.map(function (pt) {
            return { x: pt[0], y: pt[1] };
        });

        let simplifiedLatLon = toLatLonArray2(coords2, origCoordsUtm[0].zoneNum, origCoordsUtm[0].zoneLetter );

        this.setState({tmpImportingRoutePoints: simplifiedLatLon});

    }

    cancelSimplifyingEntity = () => {
        this.props.viewer.entities.remove(this.state.tmpImportingRoute);
        this.setState(
            {
                tmpImportingRoute: null,
                tmpImportingRoutePoints: [],
                simplifyImportedRoute: false,
                smoothImportedRoute: false
            });

        this.props.finishDrawing();
    }

    saveSimplifyingEntity = () => {

        // if entity has name, it has been imported (the name is the filename)
        // if name = "" then the entity is a recorded route => open the modal dialog 
        
        if (this.props.entityIsDrawing.name.length > 0) {

            //var name = await new Promise((resolve, reject) => {
                this.dialog.show({
                    body: "Enter route name",
                    prompt: Dialog.TextPrompt({initialValue: this.props.entityIsDrawing.name}),
                    actions: [
                        Dialog.CancelAction(() => this.cancelSimplifyingEntity() ),
                        Dialog.OKAction((dialog) => {
                            this.props.createFieldGeometry(this.props.entityIsDrawing.parentFieldId,
                                uuid.v1(),
                                JSON.stringify({ pts: this.state.tmpImportingRoutePoints }),
                                dialog.value,
                                this.props.entityIsDrawing.entityType,
                                "IMPORTED"
                            );
            
                            this.props.viewer.entities.remove(this.state.tmpImportingRoute);
                            this.setState(
                                {
                                    tmpImportingRoute: null,
                                    tmpImportingRoutePoints: [],
                                    simplifyImportedRoute: false,
                                    smoothImportedRoute: false
                                });
                
                            this.props.finishDrawing();
                        })
                    ]
                });
            //});

            //if (name !== ""){

            //}

        }
        else {

            this.setState({ showSaveRecordedRouteModal: true });
        }
    }

    saveTmpPlannedRoute = () => {

        for (var r of this.state.tmpPlannedRoutes) {

            this.props.viewer.entities.removeById(r.id);

            this.props.createFieldGeometry(r.parentFieldId,
                r.id,
                r.origin == "RP_SUBFIELDS" ?
                    JSON.stringify({
                        pts: r.pts,
                        work: r.work,
                        speed: r.speed
                    }) :
                    JSON.stringify({
                        pts: r.pts,
                        work: r.work,
                        speed: r.speed
                    }),
                this.state.tmpPlannedRouteName,
                "ROUTE",
                r.origin
            );
        }

        this.setState({ tmpPlannedRoutes: [] });

    }



    render() {
        /*      Distance travelled
                Distance worked
                Time travelled
                Time worked
                Area of field
                Covered area
                Overlap area
        */

        return(
            <div className="wrapper">
                <Dialog ref={(el) => { this.dialog = el }} />

                <div className="statsbox">
                    <div>
                        <button className="button" onClick={() => this.setState({ open: !this.state.open })}>
                            Stats<FontAwesomeIcon className ='font-awesome' color="cyan" style={{marginLeft: '3px'}} icon={ this.state.open? faAngleUp : faAngleDown }/>
                        </button>
                        <button className="button" onClick={this.handleTrackVehicle}>
                            Tracking Camera
                        </button>
                    </div>
                    <Collapse in={this.state.open}>
                        <div>
                            <div>
                                <Checkbox checked={this.state.cb1} onChange={ e => this.showCoveredArea(e) } value="1" >
                                Covered Area  {this.coveredAreaSize}
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox checked={this.state.cb1} onChange={ e => this.showUncoveredArea(e) } value="1" >
                                Uncovered Area  {this.uncoveredAreaSize}
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox checked={this.state.cb1} onChange={ e => this.showOverlapArea(e) } value="1" >
                                Overlap Area  {this.overlapAreaSize}
                                </Checkbox>
                            </div>
                            <div>
                                Travelled Distance: {this.state.travelledDistance}
                            </div>
                            <div style={{marginTop: '3px'}}>
                                Worked Distance: {this.state.workedDistance}
                            </div>
                            <div style={{marginTop: '3px'}}>
                                Travelled Time: {this.state.travelledTime}
                            </div>
                            <div style={{marginTop: '3px'}}>
                                Worked Time: {this.state.workedTime}
                            </div>
                        </div>
                    </Collapse>
                </div>

                {
                    this.state.gpsBillboard.billboard.show == true && !this.props.entityIsDrawing.entityIsSimplifying ?

                    <div className="routeRecording">
                        <button className="button" onClick={ this.handleStartStopRecording }>
                            {this.state.isRecordingRoute ? "Stop Recording" : "Start Recording"}
                        </button>
                        < img src={this.state.isRecordingRoute ? ledBlinking : ledOff}  style={{marginLeft: '3px'}}/>

                    </div>
                   
                    : null
                }

                {
                    this.props.entityIsDrawing.entityIsSimplifying == true ?

                    <div className="routeRecording">

                        <Row className="rowMargin" >
                            <Col className="colPadding" sm={4}>
                                <Checkbox checked={this.state.simplifyImportedRoute}
                                    inputRef={ref => this.myCheckbox = ref}
                                    onChange={(e) => this.handleSimplifyImportedRoute(e)}>
                                    Simplify
                                </Checkbox>
                            </Col>
                            <Col className="colPadding" sm={5} style={{ display: 'flex', justifyContent: 'right' }}>
                                Tolerance(m):
                            </Col>
                            <Col className="colPadding" sm={1}>
                                <input type="number" className="numericInput"
                                    value={this.state.simplifyTolerance}
                                    min={0.05}
                                    max={1}
                                    step={0.05}
                                    onChange={(e) => this.handleSimplifyTolerance(e)}
                                    disabled={this.myCheckbox ? !this.myCheckbox.checked : true} />
                            </Col>
                        </Row>
                        <Row className="rowMargin" >
                            <Col className="colPadding" sm={4}>
                                <Checkbox checked={this.state.smoothImportedRoute}
                                    inputRef={ref => this.myCheckbox2 = ref}
                                    onChange={(e) => this.handleSmoothImportedRoute(e)}>
                                    Smooth
                                </Checkbox>
                            </Col>
                            <Col className="colPadding" sm={5} style={{ display: 'flex', justifyContent: 'right' }}>
                                Percent:
                            </Col>
                            <Col className="colPadding" sm={1}>
                                <input type="number" className="numericInput"
                                    value={this.state.smoothPercent}
                                    min={0.05}
                                    max={0.3}
                                    step={0.05}
                                    onChange={(e) => this.handleSmoothPercent(e)}
                                    disabled={this.myCheckbox2 ? !this.myCheckbox2.checked : true} />
                            </Col>
                        </Row>
                        <div>
                            <button className="button" onClick={this.saveSimplifyingEntity}>
                                Save
                            </button>
                            <button className="button" onClick={this.cancelSimplifyingEntity}>
                                Cancel
                            </button>
                        </div>

                    </div>

                    : null
                }

                {
                    this.state.tmpPlannedRoutes.length > 0 ?

                    <div className="routeRecording">
{/* 
                        <Row className="rowMargin" >
                            <Col className="colPadding"  style={{ display: 'flex', justifyContent: 'center' }}>
                                ROUTE PLANNER
                            </Col>
                        </Row>
*/}
                        <Row className="rowMargin" >
                            <Col className="colPadding" sm={4} style={{ display: 'flex', justifyContent: 'right' }}>
                                route name:
                            </Col>
                            <Col className="colPadding" sm={3}>
                                <input type="text" className="textInput"
                                    value={this.state.tmpPlannedRouteName}
                                    onChange={(e) => this.setState({tmpPlannedRouteName: e.target.value}) } />
                            </Col>
                        </Row>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button className="button" onClick={this.saveTmpPlannedRoute}  >
                                Save calculated route
                            </button>
                        </div>

                    </div>

                    : null
                }


                <Modal show={this.state.showSaveRecordedRouteModal} dialogClassName="saveRecordedRoute-modal" >
                    <Modal.Header>
                        <Modal.Title>Create new Route with recorded data</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{marginTop: '3px', marginBottom: '3px'}}>
                            <Col componentClass={ControlLabel} sm={4}>
                            Field:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select" inputRef={el => this.inputFieldId = el} >
                                    {
                                        this.props.fields.map((option, index) => {
                                            return (<option key={index} value={option.fieldId}>{option.name}</option>)
                                        })
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '3px', marginBottom: '3px'}}>
                            <Col componentClass={ControlLabel} sm={4}>
                            Route name:
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    type="text"
                                    onChange={(e) => this.setState({recordedRouteName: e.target.value}) }
                                />
                            </Col>
                        </Row>
                        <br/><br/><br/>
                        <ButtonToolbar>
                            <Button
                                bsStyle="primary"
                                bsSize="small"
                                onClick={() => this.saveRecordedRoute()} 
                            >
                                Create Route
                            </Button>
                            <Button
                                bsStyle="primary"
                                bsSize="small"
                                onClick={() => this.cancelRecordedRoute()}
                            >
                                Discard recorded data
                            </Button>
                        </ButtonToolbar>
                    </Modal.Body>
                </Modal>



            </div>
        )        
    }
    
}



const mapStateToProps = (state) => {
    return {
        farmsHasErrored: state.farmsHasErrored,
        farmsIsLoading: state.farmsIsLoading,
        fields: state.fields,
        fieldsHasErrored: state.fieldsHasErrored,
        fieldsIsLoading: state.fieldsIsLoading,
        fieldGeometries: state.fieldGeometries,
        fieldGeometriesHasErrored: state.fieldGeometriesHasErrored,
        fieldGeometriesIsLoading: state.fieldGeometriesIsLoading,
        entityIsDrawing: state.entityIsDrawing,
        setVisibility: state.setVisibility,
        currentFarm: state.currentFarm,
        cesiumSelectedEntityChanged: state.cesiumSelectedEntityChanged,
        roslibMessageReceived: state.roslibMessageReceived,
        routeWasPlanned: state.routeWasPlanned
        
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //fieldIsDrawing: () => dispatch(fieldIsDrawing()),
        //routeIsDrawing: parentFieldId => dispatch(routeIsDrawing(parentFieldId)),
        finishDrawing: () => dispatch(finishDrawing()),
        createField: (farmId, fieldId, geometry, name, entrance) => dispatch(createField(farmId, fieldId, geometry, name, entrance)),
        updateField: (farmId, fieldId, entrance) => dispatch(updateField(farmId, fieldId, entrance)),
        createFieldGeometry: (fieldId, fieldGeometryId, geometry, name, type, origin) => dispatch(createFieldGeometry(fieldId, fieldGeometryId, geometry, name, type, origin)),
        changeSelectedEntity: (entityId, type) => dispatch(changeSelectedEntity(entityId, type)),
        routeIsSimplifying: (parentFieldId, name, coords) => dispatch(routeIsSimplifying(parentFieldId, name, coords))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CesiumClickHandler);