import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Radio, Button, ButtonToolbar, Modal, FormGroup, FormControl, ControlLabel, Overlay, Row, Col, Checkbox} from "react-bootstrap";
// import {Treebeard, decorators} from 'react-custom-treebeard';
import {Treebeard, decorators} from 'react-treebeard';
import { getFarmTree } from '../selectors';
//import {ContextMenu, Item, Submenu, Separator, ContextMenuProvider} from 'react-contexify'; // old version
import { Menu, Item, Separator, Submenu, contextMenu } from 'react-contexify';
import {fieldIsDrawing, routeIsDrawing, exclusionIsDrawing, obstacleIsDrawing, workingareaIsDrawing, routeIsSimplifying, setVisibility } from '../actions/cesiumDrawing';
import {createFieldGeometry, deleteFieldGeometry} from '../actions/fieldGeometries';
import {createField, deleteField} from '../actions/fields';
import {fromLatLonArray, toLatLonArray, toLatLonArray2} from '../utils/utm';
import { polylineToGeojsonLineString, simplifyRoute, fromGeojson, instersectPolygonPolyline } from "../routeplanner/geometry";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSeedling, faFolder, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { xml2js }  from "xml-js";
import shpwrite from "shp-write";
// import shp from "shpjs";
import * as shp from 'shpjs';
//import JSZip from "jszip";
import AdmZip from "adm-zip";
import { saveAs }  from 'file-saver';
import { DBFFile } from '../utils/dbf/dbf-file';
import uuid from "uuid";
import { Portal } from 'react-portal';
import Dialog from 'react-bootstrap-dialog';
import { readFile, writeFile } from '../utils/importexportfiles';

//import { Button } from "react-bootstrap";

import "./OpenedFarm.css";
import "react-contexify/dist/ReactContexify.min.css";

class OpenedFarm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            showExportRouteOptions: { show: false, routeId: null },
            showGenerateInterrowpaths: { show: false },
            generateIrpName: "",
            //smoothGeneratedIrp: false,
            visible: {}

        };

        this.onToggle = this.onToggle.bind(this);
    }

    downloadAgGPS = () => {

        var dataDictionary = `[Client]
*Client*

[Farm]
*Farm*

[Field]
*Field*
[Event]

[Crop]

[Material]

[Weather]
Cloudy
Partly Cloudy
Mostly Cloudy
Sunny
Partly Sunny
Mostly Sunny
Hazy
Overcast
Rainy
Clear

[Operator]

[Operator EPA#]

[Harvest Year]

[Vehicle]

[Implement]

[Application Method]
Pre-emergence
Pre-plant incorporated
Post-emergence

[Wind Direction]
North
North-northeast
Northeast
East-northeast
East
East-southeast
Southeast
South-southeast
South
South-southwest
Southwest
West-southwest
West
West-northwest
Northwest
North-northwest

[Sky Conditions]
Cloudy
Partly Cloudy
Mostly Cloudy
Sunny
Partly Sunny
Mostly Sunny
Hazy
Overcast
Rainy
Clear

[Soil Conditions]
Wet
Dry

[Soil Type]
Sandy
Sandy Loam
Clay
Clay Loam
Loam
Silt

[Target Pests]

`;

        // generate dbf fields the same way as the dbf files genereated by Farm Works Office
        
        let fieldDescriptorsforFields = [
            { name: 'Date', type: 'D', size: 8, decimalPlaces: 0 },
            { name: 'Time', type: 'C', size: 10, decimalPlaces: 0 },
            { name: 'Version', type: 'C', size: 8, decimalPlaces: 0 },
            { name: 'Id', type: 'N', size: 15, decimalPlaces: 0 },
            { name: 'Name', type: 'C', size: 32, decimalPlaces: 0 },
            { name: 'Area', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'Perimeter', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'SwathsIn', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'Dist1', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'Dist2', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'PrefWeight', type: 'N', size: 15, decimalPlaces: 4 }
        ];

        let fieldDescriptorsforRoutes = [
            { name: 'Date', type: 'D', size: 8, decimalPlaces: 0 },
            { name: 'Time', type: 'C', size: 40, decimalPlaces: 0 },
            { name: 'Version', type: 'C', size: 25, decimalPlaces: 0 },
            { name: 'Id', type: 'N', size: 15, decimalPlaces: 0 },
            { name: 'Name', type: 'C', size: 40, decimalPlaces: 0 },
            { name: 'Length', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'Dist1', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'Dist2', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'PrefWidth', type: 'N', size: 15, decimalPlaces: 4 },
            { name: 'UniqueID', type: 'C', size: 16, decimalPlaces: 0 }
        ];
    
        var farmName = this.props.currentFarm.currentFarmName;
        var fieldNames = '';

        var zip = new AdmZip();

        for(var f=0; f < this.props.fields.length; f++){

            var fieldName = this.props.fields[f].name;
            let dbfField = new DBFFile();
            
            var dbfBuff = dbfField.create('', fieldDescriptorsforFields);
            let records = [
                { Id: 1, Name: fieldName }
            ];
   
            var dbfBuff2 = dbfField.appendRecords(dbfBuff, fieldDescriptorsforFields, records);

            var exteriorRingPts = JSON.parse(this.props.fields[f].geometry);
            fieldNames += fieldName + '\n';
            var ogcRing = [[[]]];

            for (var p=0; p<exteriorRingPts.length; p=p+2){
                ogcRing[0][0].push([exteriorRingPts[p], exteriorRingPts[p+1]]);
            }

            shpwrite.write(
                // feature data
                [{ id: 0 }],
                // geometry type
                'POLYGON',
                // geometries
                ogcRing,
                finish);

            function finish(err, files ) {
                zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/Boundary.shp', Buffer.from(files.shp.buffer));
                zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/Boundary.shx', Buffer.from(files.shx.buffer));
                zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/Boundary.dbf', Buffer.from(dbfBuff2));
            }

            var lat = exteriorRingPts[0];
            var lng = exteriorRingPts[1];
            var posFilename = lat.toFixed(5) + 'E' +  lng.toFixed(5) + 'N.pos';
            zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/' + posFilename, Buffer.from([]));

            let dbfRoutes = new DBFFile();
            
            var dbfRoutesBuff = dbfRoutes.create('', fieldDescriptorsforRoutes);
            let recordsRoute = [];

            var fieldRoutes = this.props.fieldGeometries.filter(fg => fg.type == 'ROUTE' &&  fg.fieldId == this.props.fields[f].fieldId);
            if (fieldRoutes.length>0){
                var ids = [];
                var ogcLineStrings = [[]];
                for (var r=0; r<fieldRoutes.length; r++){
                    ogcLineStrings[0].push([]);
                    var routePts = JSON.parse(fieldRoutes[r].geometry).pts;
                    for (var p=0; p<routePts.length; p=p+2){
                        ogcLineStrings[0][r].push([routePts[p], routePts[p+1]]);
                    }

                    ids.push({ id: r});
                    recordsRoute.push({
                        Date: new Date(), //.toISOString().slice(0,10).replace(/-/g,""),
                        Time: formatAMPM(new Date()),
                        Id: 1,
                        Name: fieldRoutes[r].name,
                        Length: 0,
                        Dist1: 0,
                        Dist2: 0,
                        PrefWidth: 0
                    })
                }

                var dbfRoutesBuff2 = dbfRoutes.appendRecords(dbfRoutesBuff, fieldDescriptorsforRoutes, recordsRoute);

                shpwrite.write(
                    // feature data
                    ids,
                    // geometry type
                    'POLYLINE',
                    // geometries
                    ogcLineStrings,
                    finish2);

                function finish2(err, files ) {
                    zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/Swaths.shp', Buffer.from(files.shp.buffer));
                    zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/Swaths.shx', Buffer.from(files.shx.buffer));
                    zip.addFile('AgGPS/Data/' + farmName + '/' + farmName + '/' + fieldName + '/Swaths.dbf', Buffer.from(dbfRoutesBuff2));
                }
                function formatAMPM(date) {
                    var hours = date.getHours();
                    var minutes = date.getMinutes();
                    var seconds = date.getSeconds();
                    var ampm = hours >= 12 ? 'pm' : 'am';
                    hours = hours % 12;
                    hours = hours ? hours : 12; // the hour '0' should be '12'
                    minutes = minutes < 10 ? '0'+minutes : minutes;
                    seconds = seconds < 10 ? '0'+seconds : seconds;
                    var strTime = hours + ':' + minutes + ':' + seconds + ampm;
                    return strTime;
                }
            }

        }

        // replace
        dataDictionary = dataDictionary.replace('*Client*', farmName);
        dataDictionary = dataDictionary.replace('*Farm*', farmName);
        dataDictionary = dataDictionary.replace('*Field*', fieldNames);

        zip.addFile('AgGPS/Data Dictionary/EZOffice_DataDictionary.txt', Buffer.from(dataDictionary));

        var buff = zip.toBuffer();
        
        saveAs(new Blob([buff], {type: "application/zip"}), farmName + "_AgGPS.zip");




        /* test exportar shapefile for trimble fmx
        shpwrite.write()
        write(
            // feature data
            [{ id: 0 }],
            // geometry type
            'POLYGON',
            // geometries
            points,
            finish);
        
        function finish(err, files) {
            fs.writeFileSync('polygon.shp', toBuffer(files.shp.buffer));
            fs.writeFileSync('polygon.shx', toBuffer(files.shx.buffer));
            fs.writeFileSync('polygon.dbf', toBuffer(files.dbf.buffer));
            var xx
        }
        
        function toBuffer(ab) {
            var buffer = new Buffer(ab.byteLength),
                view = new Uint8Array(ab);
            for (var i = 0; i < buffer.length; ++i) { buffer[i] = view[i]; }
            return buffer;
        }
*/
/*
        var zip = new AdmZip();

        var content = "inner content of the file";
        zip.addFile("test.txt", Buffer.alloc(content.length, content), "entry comment goes here");
        
        var buff = zip.toBuffer();

        saveAs(new Blob([buff], {type: "application/zip"}, "sarasa.zip"));
*/
    }

    exportRouteToGeoJSON = () => {

        var routeId = this.state.showExportRouteOptions.routeId;

        var exportAsPoints = this.inputExportGeojsonAsPoints.checked;

        var route = this.props.fieldGeometries.find(fg => fg.fieldGeometryId === routeId);

        var routeGeom = JSON.parse(route.geometry); //.pts;

        if (exportAsPoints){ // save GeoJSON file containing route as collection of Points

            var coords = "";

            for (var p = 0; p < routeGeom.pts.length/2; p++) {


                if (routeGeom.speed && routeGeom.work){

                    coords += `
                    {
                        "type": "Feature",
                        "properties": {
                            "speed": ` + routeGeom.speed[p] + `,
                            "work": "` + routeGeom.work[p] + `"
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [
                            ` + routeGeom.pts[2*p] + `,
                            ` + routeGeom.pts[2*p + 1] + `
                            ]
                        }
                    },`

                }
                else{

                    coords += `
                    {
                        "type": "Feature",
                        "properties": {
                        },
                        "geometry": {
                            "type": "Point",
                            "coordinates": [
                            ` + routeGeom.pts[2*p] + `,
                            ` + routeGeom.pts[2*p + 1] + `
                            ]
                        }
                    },`
                    
                }
            }

            coords = coords.slice(0, -1);

            coords = `{"type": "FeatureCollection","features": [` + coords + "]}";

            var convertedData = new Blob([coords], {type: "text/plain;charset=utf-8"});
            saveAs(convertedData, route.name + ".geojson");

        }
        else{ // save GeoJSON file containing route as LineString

            var coords = "";

            for (var p = 0; p < routeGeom.pts.length/2; p++) {
                coords += "[" + routeGeom.pts[2*p] + "," + routeGeom.pts[2*p + 1] + "],";
            }

            coords = coords.slice(0, -1);

            coords = `{"type": "LineString","coordinates": [` + coords + "]}";

            var convertedData = new Blob([coords], {type: "text/plain;charset=utf-8"});
            saveAs(convertedData, route.name + ".geojson");
        }

        // close de modal window with export options
        this.setState( {showExportRouteOptions: {show: false, routeId: null} }); 

    }

    exportFieldToGeoJSON = (fieldId) => {

        var field = this.props.fields.find(f => f.fieldId === fieldId);

        var exclusionAreas = this.props.fieldGeometries
            .filter(fg => fg.fieldId == fieldId && fg.type.includes('EXCLUSION'))
            .map(function (fg) {
                return JSON.parse(fg.geometry);
            });

        var fieldPts = JSON.parse(field.geometry); 

        var pts = [fieldPts, ...exclusionAreas]; // first ring is exterior boundary, rest are exclusion areas

        var txt = writeFile("geojson","polygon", pts);

        var convertedData = new Blob([txt], {type: "text/plain;charset=utf-8"});
        saveAs(convertedData, field.name + ".geojson");

    }

    generateInterrowpaths = () => {

        if (this.generateIrpRouteId.value.length<1){
            this.dialog.showAlert("Please select a Route");
            return;
        }

        if (this.generateIrpWorkingareaId.value.length<1){
            this.dialog.showAlert("Please select a Working Area");
            return;
        }

        if (this.state.generateIrpName.length==0){
            this.dialog.showAlert("Please enter a name for the new Inter-Row Paths");
            return;
        }

        var selRoute = this.props.fieldGeometries.find(fg => fg.fieldGeometryId === this.generateIrpRouteId.value);
        var route = JSON.parse(selRoute.geometry).pts;
        var utmRoute = fromLatLonArray(route);

        var selWorkingArea = this.props.fieldGeometries.find(fg => fg.fieldGeometryId === this.generateIrpWorkingareaId.value);
        var workingarea = JSON.parse(selWorkingArea.geometry);
        var utmWorkingarea = fromLatLonArray(workingarea);

        var pathsUtm = instersectPolygonPolyline(utmWorkingarea, utmRoute, false);

        if (pathsUtm.length == 0){
            this.dialog.showAlert("The intersection does not result in a collection of paths");
            return;
        }

        // convert back to lat lon

        var zNum = utmRoute[0].zoneNum; //polygonsUtm[0][0].zoneNum;
        var zLet = utmRoute[0].zoneLetter; //polygonsUtm[0][0].zoneLetter;

        var pathsLatLon = [];
        for (var p=0; p<pathsUtm.length; p++){
            pathsLatLon.push(toLatLonArray2(pathsUtm[p], zNum, zLet));
        }

        var newFgId = uuid.v1();
        this.props.createFieldGeometry(selRoute.fieldId,
            newFgId,
            JSON.stringify(pathsLatLon),
            this.state.generateIrpName,
            "INTER_ROW_PATHS",
            "INTERSECTION_ROUTE_WORKINGAREA"
        );

        // close de modal window with export options
        this.setState( {showGenerateInterrowpaths: {show: false} });

    }

    onChangeFileImportFieldGAOSkml(event){

        /* customized version for swarmfarm JSON format

        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        if (file){
            //console.log(file);
            let reader = new FileReader();
            reader.onload = r => {
                try{
                    var jsontxt = r.target.result;
                    var obj = JSON.parse(jsontxt);
                    var fieldName = obj["paddock_name"];
                    var points = obj["paddock"]["boundaries"];
                    var ptsArray = [];
                    for (var p=0; p<points.length; p++){
                        ptsArray.push(points[p]["lon"]);
                        ptsArray.push(points[p]["lat"]);
                    }
                    var newFieldId = uuid.v1();
                    this.props.createField( this.props.currentFarm.currentFarmId,
                        newFieldId,
                        JSON.stringify(ptsArray),
                        fieldName
                    );

                    var obstacles = obj["obstacles"];
                    for (var h=0; h<obstacles.length; h++){
                        ptsArray = [];
                        for (var p=0; p< obstacles[h]["boundaries"].length; p++){
                            ptsArray.push(obstacles[h]["boundaries"][p]["lon"]);
                            ptsArray.push(obstacles[h]["boundaries"][p]["lat"]);
                        }
                        var newFieldGeometryId = uuid.v1();
                        this.props.createFieldGeometry( newFieldId,
                            newFieldGeometryId,
                            JSON.stringify(ptsArray),
                            "obstacle " + h,
                            "POSITIVE_EXCLUSION_AREA",
                            "IMPORTED"
                        );
                    }
                }catch(e){

                }
            };
            reader.readAsText(file);
        }

        */

        // customized version for kml file exported from GAOS

        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        if (file){
            //console.log(file);
            let reader = new FileReader();
            reader.onload = r => {
                try{
                    var kmltxt = r.target.result;
                    var obj = xml2js(kmltxt);
                    //var obj = JSON.parse(jsontxt);
                    var fieldName = obj.elements[0].elements[0].elements[0].elements[2].elements[0].text;
                    //var points = obj["paddock"]["boundaries"];
                    var ptsArray = [];
                    //obj.elements[0].elements[0].elements[0]
                    for (var p=0; p<obj.elements[0].elements[0].elements[0].elements.length; p++){
                        if (obj.elements[0].elements[0].elements[0].elements[p].name == 'Placemark' && obj.elements[0].elements[0].elements[0].elements[p].elements.length > 2){
                            if (obj.elements[0].elements[0].elements[0].elements[p].elements[2].name == 'LineString'){
                                var textCoord = obj.elements[0].elements[0].elements[0].elements[p].elements[2].elements[0].elements[0].text;
                                var coords = textCoord.trim().split(' ').map(e => e.split(','));

                                for (var c=0; c<coords.length-1; c++){
                                    ptsArray.push(parseFloat(coords[c][0]));
                                    ptsArray.push(parseFloat(coords[c][1]));
                                }
                            }
                        } 
                        //ptsArray.push(obj.elements[p]["lon"]);
                        //ptsArray.push(obj.elements[p]["lat"]);
                    }
                    var newFieldId = uuid.v1();
                    this.props.createField( this.props.currentFarm.currentFarmId,
                        newFieldId,
                        JSON.stringify(ptsArray),
                        fieldName,
                        JSON.stringify([ptsArray[0], ptsArray[1]])
                    );

                }catch(e){

                }
            };
            reader.readAsText(file);
        }

    }

    onChangeFileImportFieldShapefile(event){

        // customized version for kml file exported from GAOS

        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        if (file){
            //console.log(file);

            let reader = new FileReader();
            reader.fileName = file.name;    
            reader.onload = async r => {
                try{

                    var buffer = r.target.result;
                    const geoJson = shp.parseShp(buffer);
                    const pol = geoJson[0];

                    var res = [];
                    var fieldName = r.target.fileName.replace(/\.[^/.]+$/, ""); // regex to remove file extension
    
                    if (pol.type == "Polygon"){
                        for (var pt=0; pt<pol.coordinates[0].length-1; pt ++){
                            res.push(pol.coordinates[0][pt][0]);
                            res.push(pol.coordinates[0][pt][1]);
                        }
                    }
                    else{
                        throw new Error("The shapefile has different geometries than Polygon");
                    }

                    if (res.length==0){
                        throw new Error("An error occurred while importing the shapefile");
                    }

                    var newFieldId = uuid.v1();
                    this.props.createField( this.props.currentFarm.currentFarmId,
                        newFieldId,
                        JSON.stringify(res),
                        fieldName,
                        JSON.stringify([res[0], res[1]])
                    );
/*
                   
                    var obj = xml2js(kmltxt);
                    //var obj = JSON.parse(jsontxt);
                    var fieldName = obj.elements[0].elements[0].elements[0].elements[2].elements[0].text;
                    //var points = obj["paddock"]["boundaries"];
                    var ptsArray = [];
                    //obj.elements[0].elements[0].elements[0]
                    for (var p=0; p<obj.elements[0].elements[0].elements[0].elements.length; p++){
                        if (obj.elements[0].elements[0].elements[0].elements[p].name == 'Placemark' && obj.elements[0].elements[0].elements[0].elements[p].elements.length > 2){
                            if (obj.elements[0].elements[0].elements[0].elements[p].elements[2].name == 'LineString'){
                                var textCoord = obj.elements[0].elements[0].elements[0].elements[p].elements[2].elements[0].elements[0].text;
                                var coords = textCoord.trim().split(' ').map(e => e.split(','));

                                for (var c=0; c<coords.length-1; c++){
                                    ptsArray.push(parseFloat(coords[c][0]));
                                    ptsArray.push(parseFloat(coords[c][1]));
                                }
                            }
                        } 
                        //ptsArray.push(obj.elements[p]["lon"]);
                        //ptsArray.push(obj.elements[p]["lat"]);
                    }
                    var newFieldId = uuid.v1();
                    this.props.createField( this.props.currentFarm.currentFarmId,
                        newFieldId,
                        JSON.stringify(ptsArray),
                        fieldName
                    );
                    */

                }catch(e){
                    this.dialog.showAlert(e.message);
                }


            };
            reader.readAsArrayBuffer(file);

        }

    }

    onChangeFileImportRouteFromNmea(event) {

        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];

        if (file){
            //console.log(file);
            let reader = new FileReader();
            reader.fileName = file.name; // trick to get the filename. Add a variable to reader
            reader.onload = r => {
                try{

                    var filetxt = r.target.result;

                    var res = readFile("nmea", "polyline", filetxt);

                    if (res.err.length > 0){
                        throw new Error(res.err);
                    }

                    var coords = res.pts;

                    var routeName = r.target.fileName.replace(/\.[^/.]+$/, ""); // regex to remove file extension

                    // no crear la ruta todavia
                    // enviar notificacion por redux para que se dibuje una ruta temporaria en el mapa
                    /*
                    var newFgId = uuid.v1();
                    this.props.createFieldGeometry(this.state.selectedField,
                        newFgId,
                        JSON.stringify({pts: coords}),
                        routeName,
                        "ROUTE",
                        "IMPORTED"
                    );
                    */

                    this.props.routeIsSimplifying(this.state.selectedField, routeName, coords);

                }catch(e){
                    this.dialog.showAlert(e.message);
                }
            };
            reader.readAsText(file);
            this.importRouteFromNmea.value = '';  // required if you want to load the same file twice. Otherwise, the OnChange event of the <input> element is not fired because the file does not change

        }
    }

    
    onChangeFileImportInterrowpathsFromShapefile(event){

        // 

        //event.stopPropagation();
        //event.preventDefault();
        var file = event.target.files[0];
        if (file){

            let reader = new FileReader();
            reader.fileName = file.name;    
            reader.onload = async r => {
                try{

                    var buffer = r.target.result;
                    const geoJson = shp.parseShp(buffer);

                    if (geoJson.length == 0){
                        throw new Error("The shapefile has 0 PolyLine");
                    }

                    var res = [];

                    for (var i=0; i<geoJson.length; i++){

                        if (geoJson[i].type != "LineString"){
                            throw new Error("The shapefile has different geometries than PolyLine");
                        } 

                        var coords = [];
                        for (var j=0; j<geoJson[i].coordinates.length; j++){
                            coords.push(geoJson[i].coordinates[j][0]);
                            coords.push(geoJson[i].coordinates[j][1]);
                        }
                        res.push(coords);
                    }
                    
                    var rowpathsName = r.target.fileName.replace(/\.[^/.]+$/, ""); // regex to remove file extension

                    var newFgId = uuid.v1();
                    this.props.createFieldGeometry(this.state.selectedField,
                        newFgId,
                        JSON.stringify(res),
                        rowpathsName,
                        "INTER_ROW_PATHS",
                        "IMPORTED"
                    );


                }catch(e){
                    this.dialog.showAlert(e.message);
                }


            };
            reader.readAsArrayBuffer(file);
            this.importInterrowpathsFromShapefile.value = '';  // required if you want to load the same file twice. Otherwise, the OnChange event of the <input> element is not fired because the file does not change

        }

    }

    onChangeFileImportInterrowpathsFromGeoJson(event){

        // 

        //event.stopPropagation();
        //event.preventDefault();
        var file = event.target.files[0];
        if (file){

            let reader = new FileReader();
            reader.fileName = file.name;    
            reader.onload = async r => {
                try{

                    var jsontxt = r.target.result;
                    const geoJson = JSON.parse(jsontxt);

                    if (geoJson.type =! 'FeatureCollection'){
                        throw new Error("The geoJson is not of type FeatureCollection");
                    }

                    if (geoJson.features.length == 0){
                        throw new Error("The geoJson has 0 features");
                    }

                    var res = [];

                    for (var i = 0; i < geoJson.features.length; i++) {

                        if (geoJson.features[i].geometry.type != "LineString" && geoJson.features[i].geometry.type != "MultiLineString") {
                            throw new Error("The geoJson has different geometries than LineString or MultiLineString");
                        }

                        if (geoJson.features[i].geometry.type == "LineString") {
                            
                            var coords = [];

                            for (var j = 0; j < geoJson.features[i].geometry.coordinates.length; j++) {
                                coords.push(geoJson.features[i].geometry.coordinates[j][0]);
                                coords.push(geoJson.features[i].geometry.coordinates[j][1]);
                            }

                            res.push(coords);
                        }
                        else{  // geoJson.features[i].geometry.type == "MultiLineString"

                            for (var m = 0; m < geoJson.features[i].geometry.coordinates.length; m++) {

                                var coords = [];

                                for (var j = 0; j < geoJson.features[i].geometry.coordinates[m].length; j++) {
                                    coords.push(geoJson.features[i].geometry.coordinates[m][j][0]);
                                    coords.push(geoJson.features[i].geometry.coordinates[m][j][1]);
                                }
    
                                res.push(coords);

                            }
                        }
                    }
                    
                    var rowpathsName = r.target.fileName.replace(/\.[^/.]+$/, ""); // regex to remove file extension

                    var newFgId = uuid.v1();
                    this.props.createFieldGeometry(this.state.selectedField,
                        newFgId,
                        JSON.stringify(res),
                        rowpathsName,
                        "INTER_ROW_PATHS",
                        "IMPORTED"
                    );


                }catch(e){
                    this.dialog.showAlert(e.message);
                }


            };
            reader.readAsText(file); 
            this.importInterrowpathsFromGeoJson.value = '';  // required if you want to load the same file twice. Otherwise, the OnChange event of the <input> element is not fired because the file does not change

        }

    }

    onChangeFileImportRouteFromGeoJson(event){

        var file = event.target.files[0];
        if (file){

            let reader = new FileReader();
            reader.fileName = file.name;    

            reader.onload = async r => {
                try{

                    var jsontxt = r.target.result;

                    var res = readFile("geojson", "polyline", jsontxt);

                    if (res.err.length > 0){
                        throw new Error(res.err);
                    }

                    var coords = res.pts;

                    var routeName = r.target.fileName.replace(/\.[^/.]+$/, ""); // regex to remove file extension

                    // no crear la ruta todavia
                    // enviar notificacion por redux para que se dibuje una ruta temporaria en el mapa
                    /*
                    var newFgId = uuid.v1();
                    this.props.createFieldGeometry(this.state.selectedField,
                        newFgId,
                        JSON.stringify({pts: coords}),
                        routeName,
                        "ROUTE",
                        "IMPORTED"
                    );
                    */

                    this.props.routeIsSimplifying(this.state.selectedField, routeName, coords);


                }catch(e){
                    this.dialog.showAlert(e.message);
                }


            };
            reader.readAsText(file); 
            this.importRouteFromGeoJson.value = '';  // required if you want to load the same file twice. Otherwise, the OnChange event of the <input> element is not fired because the file does not change

        }

    }

   


/*
    onChangeFile = e => {
        let files = e.target.files;
        console.log(files);
        let reader = new FileReader();
        reader.onload = r => {
             console.log(r.target.result);
        };
        reader.readAsText(files[0]);
        var sarasa =
    };
*/
/*
    onContextMenu(e) {
        this.rightClickedNodeId=e.target.id;
        this.rightClickedNodeName=e.target.textContent;
    }
    change de line below <div id={node.id}> by <div id={node.id} onContextMenu={this.onContextMenu}>
*/   

/*
    CustomHeader = ({ node, style, prefix }) =>{
    const iconType = node.children ? 'folder' : 'file-text';
    const iconClass = 'fa fa-${iconType}';
    const iconStyle = {marginRight: '5px'};
    return(
    <div style={style.base}>
        <div id={node.id}>
        <i className={iconClass} style={iconStyle}/>
        {node.name.toUpperCase()}
        </div>
    </div>
    )
    };
*/

// required to show icons: https://stackoverflow.com/questions/23116591/how-to-include-a-font-awesome-icon-in-reacts-render

    CustomHeader = ({ node, style, prefix }) => {
        var icon;
        var iconStyle = {marginRight: '5px'};
        var textStyle = {color: 'white'};

        //const iconStyle =  node.children ? {marginRight: '5px'} : { marginRight: '5px', visibility:'hidden' };

        if (node.id == 'farm:'){
            icon = <FontAwesomeIcon className ='font-awesome' color="lime" style={iconStyle} icon={ faSeedling }/>;
        }
        else if (node.id == 'fields:'){
            icon = <FontAwesomeIcon className ='font-awesome' color="lime" style={iconStyle} icon={ faFolder }/>;
        }
        else if ( node.id.startsWith('fld:')  ) {
            iconStyle = {marginRight: '5px', display:'none'};
            textStyle = {color: 'lime'};
            icon = <FontAwesomeIcon className ='font-awesome' style={iconStyle} icon={ faFolder } />;
        }
        else if ( node.id.startsWith('routes:fld:')  ) {
            icon = <FontAwesomeIcon className ='font-awesome' color="yellow" style={iconStyle} icon={ faFolder }/>;
        }
        else if ( node.id.startsWith('exclusions:fld:')  ) {
            icon = <FontAwesomeIcon className ='font-awesome' color="red" style={iconStyle} icon={ faFolder }/>;
        }
        else if ( node.id.startsWith('obstacles:fld:')  ) {
            icon = <FontAwesomeIcon className ='font-awesome' color="sienna" style={iconStyle} icon={ faFolder }/>;
        }
        else if ( node.id.startsWith('interrowpaths:fld:')  ) {
            icon = <FontAwesomeIcon className ='font-awesome' color="salmon" style={iconStyle} icon={ faFolder }/>;
        }
        else if ( node.id.startsWith('workingareas:fld:')  ) {
            icon = <FontAwesomeIcon className ='font-awesome' color="dodgerblue" style={iconStyle} icon={ faFolder }/>;
        }
        else{
            // iconStyle = {marginRight: '5px', visibility:'hidden'};
            if (node.id in this.state.visible){
                if (this.state.visible[node.id])
                    icon = <FontAwesomeIcon className='font-awesome' style={iconStyle} icon={faEye} onClick={this.handleChangeVisibility(node.id)} />;
                else
                    icon = <FontAwesomeIcon className='font-awesome' style={iconStyle} icon={faEye} color="grey" onClick={this.handleChangeVisibility(node.id)} />;
            }
            else
                icon = <FontAwesomeIcon className ='font-awesome' style={iconStyle} icon={ faEye } onClick={this.handleChangeVisibility(node.id)}/>;

            if ( node.id.startsWith('rou:')  ) {
                textStyle = {color: 'yellow'};
            }
            else if ( node.id.startsWith('exc:')  ) {
                textStyle = {color: 'red'};
            }
            else if ( node.id.startsWith('obs:')  ) {
                textStyle = {color: 'sienna'};
            }
            else if ( node.id.startsWith('wor:')  ) {
                textStyle = {color: 'dodgerblue'};
            }
            else if ( node.id.startsWith('int:')  ) {
                textStyle = {color: 'salmon'};
            }
        }

        return(

            <div style={style.base}>
                <div id={node.id} style={textStyle}
                    onContextMenu={this.handleContextMenu}
                    onClick={this.handleContextMenu}
                    ref={ (node.id == 'fields:') ? element => {this.target = element;}:undefined }
                >
                    {icon}
                    {node.name }

                </div>
            </div>

        )

    }


    onToggle(node, toggled){
        if(this.state.cursor){this.state.cursor.active = false;}
        node.active = true;
        if(node.children){ node.toggled = toggled; }
        this.setState({ cursor: node });
    }


    
    
    
    handleContextMenu = e => {

        e.preventDefault();

        // first part of e.target.id contains the type of node
        // open context menu according to type of node

        // node types are: farm, fields, fld, routes, rou, exclusions, exc, obstacles, obs, workingareas, wor, interrowpaths, int 
        const splitArray = e.target.id.split(":");
        const nodeType = splitArray[0];

        if (nodeType == "farm"){ 
            return; // there is no menu for main node "farm"
        }

        var props = {};
        
        switch (nodeType) {

            case "fld": // format example: "fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e:frm:a5beaf10-ad23-11eb-b5e3-8390da8be1cc"
                props.fieldId = splitArray[1];
                props.farmId = splitArray[3];
                break;

            case "routes": // "routes:fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e"
                props.fieldId = splitArray[2];
                break;

            case "rou": // "rou:341252c0-6e5e-11ec-af3d-af1803cc97e7:fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e"
                props.routeId = splitArray[1];
                props.fieldId = splitArray[3];
                break;

            case "exclusions": // "exclusions:fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e"
                props.fieldId = splitArray[2];
                break;       
                
            case "exc": // "exc:d82823d0-6f0d-11ec-9dcd-bd94c7344047:fld:fc72b1b0-ae6f-11eb-8d74-0757e2d4198e"
                props.exclusionId = splitArray[1];
                props.fieldId = splitArray[3];
                break;

            case "obstacles": // "obstacles:fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e"
                props.fieldId = splitArray[2];
                break;

            case "obs": // "obs:d82823d0-6f0d-11ec-9dcd-bd94c7344047:fld:fc72b1b0-ae6f-11eb-8d74-0757e2d4198e"
                props.obstacleId = splitArray[1];
                props.fieldId = splitArray[3];
                break;

            case "workingareas": // "workingareas:fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e"
                props.fieldId = splitArray[2];
                break;       
                
            case "wor": // "wor:d82823d0-6f0d-11ec-9dcd-bd94c7344047:fld:fc72b1b0-ae6f-11eb-8d74-0757e2d4198e"
                props.workingareaId = splitArray[1];
                props.fieldId = splitArray[3];
                break;

            case "interrowpaths": // "interrowpaths:fld:53a4dcb0-550a-11ec-b07e-8f6798600a9e"
                props.fieldId = splitArray[2];
                break; 

            case "int": // "int:d82823d0-6f0d-11ec-9dcd-bd94c7344047:fld:fc72b1b0-ae6f-11eb-8d74-0757e2d4198e"
                props.interrowpathsId = splitArray[1];
                props.fieldId = splitArray[3];
                break;

        }

        var menuId = nodeType + "_context_menu";

        contextMenu.show({
            id: menuId,
            event: e,
            props: props
        });

    };

    handleChangeVisibility  = nodeId => e => {

        var visible = false;  // default value if key was not defined yet

        if (nodeId in this.state.visible){
            visible = !this.state.visible[nodeId];
        }

        const splitArray = nodeId.split(":");
        const entityId = splitArray[1];
        
        this.props.setVisibility(entityId, visible);

        var obj = {[nodeId]: visible};

        this.setState(
            prev => ({
                ...prev,
                visible: {
                    ...prev.visible,
                    ...obj
                }
            })
        );

    }

    /*
    handleItemClick = ({ event, props, triggerEvent, data }) => {
        console.log(event, props, triggerEvent, data );
    }
    */

    render() {

        decorators.Header = this.CustomHeader;

        // Fields Context Menu

        const handleFieldsItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'add': 
                    this.props.fieldIsDrawing();
                    break;
            }
        }
        const FieldsContextMenu = () => (
            <Menu id={"fields_context_menu"} theme="none"  >
                <Item data="add" onClick={handleFieldsItemClick}>
                    Draw new Field
                </Item>
                <Item onClick={this.downloadAgGPS}>
                    Download AgGPS folder
                </Item>
                <Submenu label="Import">
                    <Item onClick={(e) => this.importFieldInputShapefile.click()}>
                        Shapefile (.shp)
                    </Item>
                    <Item onClick={(e) => this.importFieldInputGAOSkml.click()}>
                        GAOS kml file
                    </Item>
                </Submenu>
            </Menu>
        );

        // Fld Context Menu

        const handleFldItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'delete':
                    this.dialog.show({
                        body: "Delete this field?",
                        actions: [
                            Dialog.CancelAction(),
                            Dialog.DefaultAction(
                                'Delete',
                                () => { this.props.deleteField(props.farmId, props.fieldId) },
                                'btn-danger'
                            )
                        ]
                    })
                    break;
                case 'export':
                    //this.setState({showExportRouteOptions: { show: true, routeId: props.routeId}});
                    this.exportFieldToGeoJSON(props.fieldId);
                    break;
            }
        }
        const FldContextMenu = () => (
            <Menu id={"fld_context_menu"} theme="none" >
                <Item data="export" onClick={handleFldItemClick}>
                    Export as GeoJSON
                </Item>
                <Item data="delete" onClick={handleFldItemClick}>
                    Delete
                </Item>
            </Menu>
        );

        // Routes Context Menu

        const handleRoutesItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'add': 
                    this.props.routeIsDrawing(props.fieldId);
                    break;
                case 'import NMEA': 
                    this.setState({selectedField: props.fieldId});
                    this.importRouteFromNmea.click();
                    break;
                case 'import geoJson': 
                    this.setState({selectedField: props.fieldId});
                    this.importRouteFromGeoJson.click();
                    break;
            }
        }
        const RoutesContextMenu = () => (
            <Menu id={"routes_context_menu"} theme="none" >
                <Item data="add" onClick={handleRoutesItemClick}>
                    Draw new Route
                </Item>
                <Submenu label="Import">
                    <Item data="import NMEA" onClick={handleRoutesItemClick} >
                        NMEA GPS log file
                    </Item>
                    <Item data="import geoJson" onClick={handleRoutesItemClick} >
                        GeoJSON
                    </Item>
                </Submenu>
            </Menu>
        );

        // Rou Context Menu

        const handleRouItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'delete':
                    this.dialog.show({
                        body: "Delete this route?",
                        actions: [
                            Dialog.CancelAction(),
                            Dialog.DefaultAction(
                                'Delete',
                                () => { this.props.deleteFieldGeometry(props.fieldId, props.routeId) },
                                'btn-danger'
                            )
                        ]
                    })
                    break;
                case 'export':
                    this.setState({showExportRouteOptions: { show: true, routeId: props.routeId}});
                    //this.exportRouteToGeoJSON(props.routeId);
                    break;
            }
        }
        const RouContextMenu = () => (
            <Menu id={"rou_context_menu"} theme="none" >
                <Item data="export" onClick={handleRouItemClick}>
                    Export as GeoJSON
                </Item>
                <Item data="delete" onClick={handleRouItemClick}>
                    Delete
                </Item>
            </Menu>
        );

        // Exclusions Context Menu

        const handleExclusionsItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'add': 
                    this.props.exclusionIsDrawing(props.fieldId);
                    break;
            }
        }
        const ExclusionsContextMenu = () => (
            <Menu id={"exclusions_context_menu"} theme="none" >
                <Item data="add" onClick={handleExclusionsItemClick}>
                    Draw new Exclusion Area
                </Item>
            </Menu>
        );

        // Exc Context Menu

        const handleExcItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'delete':
                    this.dialog.show({
                        body: "Delete this exclusion area?",
                        actions: [
                            Dialog.CancelAction(),
                            Dialog.DefaultAction(
                                'Delete',
                                () => { this.props.deleteFieldGeometry(props.fieldId, props.exclusionId) },
                                'btn-danger'
                            )
                        ]
                    })
                    break;
            }
        }
        const ExcContextMenu = () => (
            <Menu id={"exc_context_menu"} theme="none" >
                <Item data="delete" onClick={handleExcItemClick}>
                    Delete
                </Item>
            </Menu>
        );

        // Obstacles Context Menu

        const handleObstaclesItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'add': 
                    this.props.obstacleIsDrawing(props.fieldId);
                    break;
            }
        }
        const ObstaclesContextMenu = () => (
            <Menu id={"obstacles_context_menu"} theme="none" >
                <Item data="add" onClick={handleObstaclesItemClick}>
                    Draw new Obstacle
                </Item>
            </Menu>
        );

        // Obs Context Menu

        const handleObsItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'delete':
                    this.dialog.show({
                        body: "Delete this obstacle?",
                        actions: [
                            Dialog.CancelAction(),
                            Dialog.DefaultAction(
                                'Delete',
                                () => { this.props.deleteFieldGeometry(props.fieldId, props.obstacleId) },
                                'btn-danger'
                            )
                        ]
                    })
                    break;
            }
        }
        const ObsContextMenu = () => (
            <Menu id={"obs_context_menu"} theme="none" >
                <Item data="delete" onClick={handleObsItemClick}>
                    Delete
                </Item>
            </Menu>
        );



        // Working Areas Context Menu

        const handleWorkingareasItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'add': 
                    this.props.workingareaIsDrawing(props.fieldId);
                    break;
            }
        }
        const WorkingAreasContextMenu = () => (
            <Menu id={"workingareas_context_menu"} theme="none" >
                <Item data="add" onClick={handleWorkingareasItemClick}>
                    Draw new Working Area
                </Item>
            </Menu>
        );

        // Wor Context Menu

        const handleWorItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'delete':
                    this.dialog.show({
                        body: "Delete this working area?",
                        actions: [
                            Dialog.CancelAction(),
                            Dialog.DefaultAction(
                                'Delete',
                                () => { this.props.deleteFieldGeometry(props.fieldId, props.workingareaId) },
                                'btn-danger'
                            )
                        ]
                    })
                    break;
            }
        }
        const WorContextMenu = () => (
            <Menu id={"wor_context_menu"} theme="none" >
                <Item data="delete" onClick={handleWorItemClick}>
                    Delete
                </Item>
            </Menu>
        );


        // Inter-row paths Context Menu

        const handleInterrowpathsItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'import shapefile': 
                    this.setState({selectedField: props.fieldId});
                    this.importInterrowpathsFromShapefile.click();
                    break;
                case 'import geojson': 
                    this.setState({selectedField: props.fieldId});
                    this.importInterrowpathsFromGeoJson.click();
                    break;
                case 'generate':
                    this.setState({showGenerateInterrowpaths: { show: true, selectedFieldId: props.fieldId}});
                    break;
            }
        }
        const InterrowpathsContextMenu = () => (
            <Menu id={"interrowpaths_context_menu"} theme="none" >
                <Submenu label="Import">
                    <Item data="import shapefile" onClick={handleInterrowpathsItemClick} >
                        Shapefile (.shp)
                    </Item>
                    <Item data="import geojson" onClick={handleInterrowpathsItemClick} >
                        GeoJSON
                    </Item>
                </Submenu>
                <Item data="generate" onClick={handleInterrowpathsItemClick}>
                    Generate from Route and Working Area...
                </Item>
            </Menu>
        );

        // Int Context Menu

        const handleIntItemClick = ({ event, props, triggerEvent, data }) => {
            switch (data) {
                case 'delete':
                    this.dialog.show({
                        body: "Delete these inter-row paths?",
                        actions: [
                            Dialog.CancelAction(),
                            Dialog.DefaultAction(
                                'Delete',
                                () => { this.props.deleteFieldGeometry(props.fieldId, props.interrowpathsId) },
                                'btn-danger'
                            )
                        ]
                    })
                    break;
            }
        }
        const IntContextMenu = () => (
            <Menu id={"int_context_menu"} theme="none" >
                <Item data="delete" onClick={handleIntItemClick}>
                    Delete
                </Item>
            </Menu>
        );



        return (

            <div   >
                <form>
                    <FormGroup>
<div >
                        <Treebeard 
                            data={this.props.tree}
                            onToggle={this.onToggle}
                            decorators={decorators}
                            separateToggleEvent = {true}
                        />

                        <Portal >
                            <FieldsContextMenu/>
                            <FldContextMenu/>
                            <RoutesContextMenu/>
                            <RouContextMenu/>
                            <ExclusionsContextMenu/>
                            <ExcContextMenu/>
                            <ObstaclesContextMenu/>
                            <ObsContextMenu/>
                            <WorkingAreasContextMenu/>
                            <WorContextMenu/>
                            <InterrowpathsContextMenu/>
                            <IntContextMenu/>
                        </Portal>

                        <Overlay
                            show={this.props.fields.length == 0 && this.props.currentFarm.currentFarmId}
                            /*animation
                            delayShow={5000} */ 
                            placement="right"
                            container={this}
                            target={() => ReactDOM.findDOMNode(this.target)}
                        >
                            <CustomPopover />
                        </Overlay>
</div>
                      
               

                        <input id="importFieldInputGAOSkml" type="file" ref={(ref) => this.importFieldInputGAOSkml = ref} style={{ display: 'none' }} onChange={this.onChangeFileImportFieldGAOSkml.bind(this)} />
                        <input id="importFieldInputShapefile" type="file" ref={(ref) => this.importFieldInputShapefile = ref} style={{ display: 'none' }} onChange={ (e) => this.onChangeFileImportFieldShapefile(e) } />
                        <input id="importRouteFromNmea" type="file" ref={(ref) => this.importRouteFromNmea = ref} style={{ display: 'none' }} onChange= { (e) => this.onChangeFileImportRouteFromNmea(e) } /> 
                        <input id="importInterrowpathsFromShapefile" type="file" ref={(ref) => this.importInterrowpathsFromShapefile = ref} style={{ display: 'none' }} onChange= { (e) => this.onChangeFileImportInterrowpathsFromShapefile(e) } />
                        <input id="importInterrowpathsFromGeoJson" type="file" ref={(ref) => this.importInterrowpathsFromGeoJson = ref} style={{ display: 'none' }} onChange= { (e) => this.onChangeFileImportInterrowpathsFromGeoJson(e) } />
                        <input id="importRouteFromGeoJson" type="file" ref={(ref) => this.importRouteFromGeoJson = ref} style={{ display: 'none' }} onChange= { (e) => this.onChangeFileImportRouteFromGeoJson(e) } />
                        <Dialog ref={(el) => { this.dialog = el }} />
                    </FormGroup>
                </form>

                <Modal show={this.state.showExportRouteOptions.show} >
                    <Modal.Header>
                        <Modal.Title>Export Route as GeoJSON</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            GeoJSON file format: 
                        </p>
                        <FormGroup>
                            <Radio name="radioGroup" checked inputRef={el => this.inputExportGeojsonAsPoints = el}>
                                Collection of Points with properties
                            </Radio>
                            <Radio name="radioGroup">
                                LineString
                            </Radio>
                        </FormGroup>
                        <p>
                        </p>
                        <ButtonToolbar>
                            <Button
                                bsStyle="primary"
                                bsSize="small"
                                onClick={() => this.exportRouteToGeoJSON()} // {() =>  { this.props.routePlanIsProcessing(true); }}  
                            >
                                Export Route
                            </Button>
                            <Button
                                bsStyle="primary"
                                bsSize="small"
                                onClick={() => this.setState({ showExportRouteOptions: { show: false, routeId: null } })}
                            >
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </Modal.Body>

                </Modal>

                <Modal show={this.state.showGenerateInterrowpaths.show} dialogClassName="generaterows-modal" >
                    <Modal.Header>
                        <Modal.Title>Generate Inter-Row Paths by intersecting Route and Working Area</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{marginTop: '3px', marginBottom: '3px'}}>
                            <Col componentClass={ControlLabel} sm={4}>
                            Route:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    inputRef={el => this.generateIrpRouteId = el}
                                     >
                                    {
                                        this.props.fieldGeometries
                                            .filter(fg => fg.type == 'ROUTE' && fg.fieldId === this.state.showGenerateInterrowpaths.selectedFieldId)
                                            .map((option, index) => {
                                                return (<option key={index} value={option.fieldGeometryId}>{option.name}</option>)
                                            })
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '3px', marginBottom: '3px'}}>
                            <Col componentClass={ControlLabel} sm={4}>
                            Working Area:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    inputRef={el => this.generateIrpWorkingareaId = el}
                                    > 
                                    {
                                        this.props.fieldGeometries
                                        .filter(fg => fg.type == 'WORKING_AREA' && fg.fieldId === this.state.showGenerateInterrowpaths.selectedFieldId)
                                        .map((option, index) => {
                                            return (<option key={index} value={option.fieldGeometryId}>{option.name}</option>)
                                        })
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                        {/*
                        <Row style={{marginTop: '3px', marginBottom: '3px'}}>
                            <Col componentClass={ControlLabel} sm={4}>
                                Smooth Paths
                            </Col>
                            <Col sm={8}>
                                <Checkbox checked={this.state.smoothGeneratedIrp} onChange={(e) => this.setState({smoothGeneratedIrp: e.target.checked}) }  >
                                </Checkbox>
                            </Col>
                        </Row>
                        */}
                        <Row style={{marginTop: '3px', marginBottom: '3px'}}>
                            <Col componentClass={ControlLabel} sm={4}>
                            Inter-Row Paths name:
                            </Col>
                            <Col sm={8}>
                                <FormControl
                                    type="text"
                                    onChange={(e) => this.setState({generateIrpName: e.target.value}) }
                                />
                            </Col>
                        </Row>
                        <br/><br/><br/>
                        <ButtonToolbar>
                            <Button
                                bsStyle="primary"
                                bsSize="small"
                                onClick={() => this.generateInterrowpaths()} 
                            >
                                Create Inter-Row Paths
                            </Button>
                            <Button
                                bsStyle="primary"
                                bsSize="small"
                                onClick={() => this.setState({ showGenerateInterrowpaths: { show: false } })}
                            >
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </Modal.Body>
                </Modal>


            </div>


        )
    }
}
/*
OpenedFarm.propTypes = {
    fields: PropTypes.array.isRequired,
    routes: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
    return {
        farms: state.farms,
        fields: state.fields,
        routes: state.routes
    };
};*/

const mapStateToProps = (state) => {
    return {
      tree: getFarmTree(state),
      currentFarm: state.currentFarm,
      fields: state.fields,
      fieldGeometries: state.fieldGeometries 
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fieldIsDrawing: () => dispatch(fieldIsDrawing()),
        routeIsDrawing: parentFieldId => dispatch(routeIsDrawing(parentFieldId)),
        exclusionIsDrawing: parentFieldId => dispatch(exclusionIsDrawing(parentFieldId)),
        obstacleIsDrawing: parentFieldId => dispatch(obstacleIsDrawing(parentFieldId)),
        workingareaIsDrawing: parentFieldId => dispatch(workingareaIsDrawing(parentFieldId)),
        deleteFieldGeometry: (parentFieldId, fieldGeometryId) => dispatch(deleteFieldGeometry(parentFieldId, fieldGeometryId)),
        deleteField: (parentFarmId, fieldId) => dispatch(deleteField(parentFarmId, fieldId)),
        createField: (farmId, fieldId, geometry, name, entrance) => dispatch(createField(farmId, fieldId, geometry, name, entrance)),
        createFieldGeometry: (fieldId, fieldGeometryId, geometry, name, type, origin) => dispatch(createFieldGeometry(fieldId, fieldGeometryId, geometry, name, type, origin)),
        routeIsSimplifying: (parentFieldId, name, coords) => dispatch(routeIsSimplifying(parentFieldId, name, coords)),
        setVisibility: (entityId, visible) => dispatch(setVisibility(entityId,visible))
    }
};

function CustomPopover({ className, style }) {
    return (
      <div
        className={className}
        style={{
          ...style,
          textAlign: 'right',
          position: 'absolute',
          backgroundColor: 'rgba(255, 255, 255)',
          boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.9)',
          border: '4px solid #F00',
          borderRadius: 10,
          marginLeft: 10,
          marginTop: 30,
          padding: 20
        }}
      >
        <strong>&lt;&lt;  Navigate the map to the desired area and<br/> click on [FIELDS] to add a Field to your Farm</strong>
      </div>
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(OpenedFarm);