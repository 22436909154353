import React from "react";
import { connect } from 'react-redux';
import { Form, Button, FormGroup, FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import ROSLIB from "roslib";
import { roslibUpdateMessageReceived } from '../actions/ros'
import ReactTable from 'react-table';
import { Authentication, Fleet } from "@formant/data-sdk";

import "./Formant.css";

class Formant extends React.Component {

    constructor(props) {
        super(props);

        this.handleChangeConnectUrl = this.handleChangeConnectUrl.bind(this);
        //this.handleClickConnect = this.handleClickConnect.bind(this);
        this.handleGetTopics = this.handleGetTopics.bind(this);
        this.handleSubscribeTopic = this.handleSubscribeTopic.bind(this);
        

        this.state = {
            username: '',
            password:'',
            formantConnected: false,
            onlineDevices: [],

            websocketUrl: 'ws://localhost:9090',
            topics: {topics:[], types:[]},
            rosConnected: false,
            subscriptionType: 'sensor_msgs/CompressedImage',
            subscriptionName: ''
        };

        this.ros = new ROSLIB.Ros();
        //this.topics = [];
        this.visualizableRosTopicTypes = ["sensor_msgs","geometry_msgs","nav_msgs","visualization_msgs"];
        this.implementedDisplayTopicTypes = ['sensor_msgs/CompressedImage','sensor_msgs/Imu','sensor_msgs/LaserScan','sensor_msgs/NavSatFix']
    }

    // detect when new ROS message must be published
    componentDidUpdate(prevProps) {

        if (prevProps.roslibMessagePublished.message != this.props.roslibMessagePublished.message ){
            
            var msg = new ROSLIB.Message(JSON.parse(this.props.roslibMessagePublished.message));
            
            var rosTopic = new ROSLIB.Topic({
                ros: this.ros,
                name: this.props.roslibMessagePublished.name,
                messageType: this.props.roslibMessagePublished.type
            });

            rosTopic.publish(msg);
            //console.log("wp sent");
        }

    }

    validateForm() {
        return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleChangeConnectUrl(e) {
        this.setState({ websocketUrl: e.target.value });
    }

    handleClickLogin = async (e) => {

        try {
            await Authentication.login(this.state.username, this.state.password);
            this.setState({ formantConnected: true });

            // Get all devices
            const allDevices = await Fleet.getDevices();

            // Get all online devices only
            const onlineDevices = await Fleet.getOnlineDevices();

            this.setState({onlineDevices: onlineDevices });



        }
        catch (err) {
            alert(err);
        }

    }



    handleGetTopics() {
        
        this.ros.getTopics( (tpcs) => {
            //console.log("tpcs: " + tpcs);
            //var a = JSON.stringify(tpcs);
            //console.log(a);
            //this.topics = tpcs;
            this.setState({ topics: tpcs});
            //console.log("state.topics: " +this.state.topics);
        });
    }

    handleSubscribeTopic(topicName, topicType) {

        // Queue length needs to be used in combination with throttle rate (in ms)
        // This value must be tuned to the expected duration of the slow consumer
        // and ideally bigger than the max of it,
        // otherwise message will be older than expected (up to a limit)
        // (https://buildmedia.readthedocs.org/media/pdf/roslibpy/latest/roslibpy.pdf)

        var rosTopic = new ROSLIB.Topic({
            ros: this.ros,
            name: topicName,
            messageType: topicType,
            queue_length:1
            //throttle_rate:600
        });

        // important: the arrow format allows to access to 'this.props' in the callback function
        // i.e    rosTopic.subscribe(function(message) {    does not work
        rosTopic.subscribe((message) => {
            this.props.updateMessageReceived(rosTopic.name, rosTopic.messageType, JSON.stringify(message));
        });
    }


    render() {

        var tableData = [];

        for (var i=0; i<this.state.topics.topics.length; i++){
            // check if topic is in visualizable list
            for (var t=0; t<this.visualizableRosTopicTypes.length;t++){
                if (this.state.topics.types[i].startsWith(this.visualizableRosTopicTypes[t]) ) {
                    tableData.push({topic: this.state.topics.topics[i], type: this.state.topics.types[i]});
                    break;
                }
            }
            
        }
        const columns = [
                { 
                    Header: 'name',
                    accessor: 'topic', // String-based value accessors!
                    Cell: row => <div><span title={row.value}>{row.value}</span></div>
                }, {
                    Header: 'subscribe',
                    accessor: 'topic',
                    // check if the type of the topic has a diplay already implemented
                    Cell: props => (this.implementedDisplayTopicTypes.includes(props.original.type))? <Button bsStyle="link" onClick={this.handleSubscribeTopic.bind(this,props.original.topic, props.original.type)}>subscribe</Button>
                                            : <Button bsStyle="link" >display not implemented</Button>
                } /*, {
                    Header: '',
                    accessor: 'farmId',
                    Cell: props => <Button bsStyle="link" onClick={this.handleClickDeleteFarm.bind(this,props.value)}>delete</Button>
                    
                }*/
            ]
        return (

            <div className="Formant">

                <Form>
                    
                    <Row >

                        <Col md={3} >
                            Username:
                        </Col>
                        <Col md={6}>
                            <FormControl
                                type="email"
                                value={this.state.username}
                                onChange={e => this.setState({ username: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row >
                        <Col md={3} >
                            Password:
                        </Col>
                        <Col md={6}>
                            <FormControl
                                type="password"
                                autoComplete="new-password"
                                value={this.state.password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                        </Col>

                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Row >
                            <Col>
                                <Button
                                    bsStyle="primary"
                                    bsSize="xsmall"
                                    disabled={!this.validateForm()}
                                    onClick={this.handleClickLogin} >
                                    Login
                                </Button>
                            </Col>
                        </Row>
                    </div>
<br/><br/>
                    <Row>
                        <Col componentClass={ControlLabel} sm={3}>
                        Online devices:
                        </Col>
                        <Col sm={6}>
                            <FormControl componentClass="select" inputRef={ el => this.inputRouteId=el}  > 
                            {
                                this.state.onlineDevices
                                .map((option, index) => {
                                    return (<option key={index} value={option.id}>{option.name}</option>)
                                })
                            }
                            </FormControl>
                        </Col>
                    </Row>

{/*
                    <Row style={{marginTop: '15px', marginBottom: '5px'}}>
                        <Col md={12}>
                        <Button 
                            bsStyle="primary"
                            bsSize="xsmall"                            
                            onClick={this.handleGetTopics} 
                            disabled={!this.state.rosConnected}
                            >
                        Get Topics
                        </Button>
                        </Col>
                    </Row>
                    
                    <ReactTable
                        NoDataComponent={() => null}
                        //noDataText='No topics found'
                        data={tableData}
                        columns={ columns }
                        className= "-striped -highlight"
                        showPagination = {false}
                        pageSize = {tableData.length} /> 

                </Form>
                <Form>
                    <Row>
                        <Col className="text-center" md={12}>
                        Manual Subscription
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} >
                            Message Type:
                        </Col>
                        <Col md={7} >
                        <FormControl componentClass="select"
                                value={this.state.subscriptionType}
                                onChange={e => this.setState({ subscriptionType: e.target.value })}>
                            <option value='sensor_msgs/CompressedImage'>sensor_msgs/CompressedImage</option>
                            <option value='sensor_msgs/Imu'>sensor_msgs/Imu</option>
                            <option value='sensor_msgs/LaserScan'>sensor_msgs/LaserScan</option>
                            <option value='sensor_msgs/NavSatFix'>sensor_msgs/NavSatFix</option>
                        </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={5} >
                            Topic Name:
                        </Col>
                        <Col md={7} >
                            <FormControl type="text"
                                    value={this.state.subscriptionName}
                                    onChange={e => this.setState({ subscriptionName: e.target.value })}>
                            </FormControl>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px', marginBottom: '5px'}}>
                        <Col md={12}>
                        <Button 
                            bsStyle="primary"
                            bsSize="xsmall"                            
                            //onClick={this.handleSubscribeTopic}
                            onClick={this.handleSubscribeTopic.bind(this,this.state.subscriptionName, this.state.subscriptionType)} 
                            disabled={!this.state.rosConnected}
                            >
                        Subscribe Topic
                        </Button>
                        </Col>
                    </Row>

*/}
                </Form>
            </div>
        )


    }

}

const mapStateToProps = (state) => {
    return {
        roslibMessagePublished: state.roslibMessagePublished

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMessageReceived: (topicName, topicType, message) => dispatch(roslibUpdateMessageReceived(topicName, topicType, message)),
        //deleteFarm: (fId, isOpened) => dispatch(deleteFarm(fId, isOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Formant);