export function cesiumSelectedEntityChanged(state = {}, action) {
    switch (action.type) {
        case 'CHANGE_SELECTED_ENTITY':
            if (action.payload.entityId != ""){
                return {
                    cesiumSelectedEntityId: action.payload.entityId,
                    cesiumSelectedEntityType: action.payload.type
                };
            }
            else{
                return {};
            }
        default:
            return state;
    }
}