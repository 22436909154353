import React from "react";
import { connect } from 'react-redux';
import { Form, Button, FormGroup, FormControl, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import ROSLIB from "roslib";
import { roslibPublishMessage } from '../actions/ros';
import {fromLatLonArray, toLatLonArray2} from '../utils/utm';

import "./RouteFollowing.css";

class RouteFollowing extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            frameId: 'map',
            rosversion: 'ROS',
            coordSystem: 'WGS84'
        }; 
    }

    onChangeField(e){
        this.setState({ selectedField: this.inputFieldId.value });
    }

    onChangeRoute(e){
        this.setState({ selectedRoute: this.inputRouteId.value });
    }

    handleChangeFrameId(e) {
        this.setState({ frameId: e.target.value });
    }

    handleFollowRoute = async () => {

        if (this.inputRouteId.value.length<1){
            this.dialog.showAlert("Please select a Route");
            return;
        }

        var selRoute = this.props.fieldGeometries.find(fg => fg.fieldGeometryId === this.inputRouteId.value);

        var route = JSON.parse(selRoute.geometry).pts;
        var utmRoute = fromLatLonArray(route);

        // version coordenadas UTM relativas al primer waypoint
        //var utmRouteRelative = utmRoute.map((pt) => ({x:pt.x-utmRoute[0].x, y:pt.y-utmRoute[0].y}) );

        /* version for publishing waypoints (PoseWithCovarianceStamped)

        var name =  '/initialpose';
        var type = 'geometry_msgs/PoseWithCovarianceStamped';

        for (var pt=1; pt<51; pt++){ //utmRouteRelative.length

            var difx = utmRouteRelative[pt].x - utmRouteRelative[pt-1].x;
            var dify = utmRouteRelative[pt].y - utmRouteRelative[pt-1].y;

            var yaw = Math.atan2(dify, difx);


            var q = new Object(); // orientation quaternion
            //q.x = 0;
            //q.y = 0;
            q.z = Math.sin(yaw * 0.5);
            q.w = Math.cos(yaw * 0.5);

            /* in case I want to put stamp in message header
            var currentTime = new Date();
            var secs = Math.floor(currentTime.getTime()/1000);
            var nsecs = Math.round(1000000000*(currentTime.getTime()/1000-secs));
            // add this line to header:  "stamp": {"secs": ${secs}, "nsecs": ${nsecs}},
            * /

            var message = `{
                "header": {
                  "frame_id": "odom"
                },
                "pose": {
                  "pose": {
                    "position": {
                      "x": ${utmRouteRelative[pt-1].x},
                      "y": ${utmRouteRelative[pt-1].y},
                      "z": 0.0
                    },
                    "orientation": {
                      "x": 0.0,
                      "y": 0.0,
                      "z": ${q.z},
                      "w": ${q.w}
                    }
                  },
                  "covariance": [0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.06853892326654787]
                }
              }`

            await new Promise(r => setTimeout(r, 200));  // pause between waypoint messages
            await this.props.publishMessage(name, type, message); // publish messages asynchronously within the for loop


        }

        */

        // version for publishing complete route (PoseArray)

        var name =  '/route';
        var type = 'geometry_msgs/PoseArray';
/*
        var message = `{
            "header": {
              "frame_id": "` + this.state.frameId + `"
            },
            "poses": [`;
*/

        var message = '';

        switch (this.state.rosversion){
            case "ROS":
                message = `{
                    "header": {
                      "frame_id": "` + this.state.frameId + `"
                    },
                    "poses": [`;
                break;
            case "ROS2":
                var currentTime = new Date();
                var secs = Math.floor(currentTime.getTime()/1000);
                var nsecs = Math.round(1000000000*(currentTime.getTime()/1000-secs));
                message = `{
                    "header": {
                      "stamp": {"sec": ${secs}, "nanosec": ${nsecs}},
                      "frame_id": "` + this.state.frameId + `"
                    },
                    "poses": [`;
                break;

        }
        
        var difx = 0;
        var dify = 0;

        for (var pt=0; pt<utmRoute.length; pt++){ //utmRouteRelative.length

            if (pt<utmRoute.length-1){  // when last point, keep same orientation that previous
                difx = utmRoute[pt+1].x - utmRoute[pt].x;
                dify = utmRoute[pt+1].y - utmRoute[pt].y;
            }

            var yaw = Math.atan2(dify, difx);

            var q = new Object(); // orientation quaternion
            //q.x = 0;
            //q.y = 0;
            q.z = Math.sin(yaw * 0.5);
            q.w = Math.cos(yaw * 0.5);


            /* in case I want to put stamp in message header
            var currentTime = new Date();
            var secs = Math.floor(currentTime.getTime()/1000);
            var nsecs = Math.round(1000000000*(currentTime.getTime()/1000-secs));
            // add this line to header:  "stamp": {"secs": ${secs}, "nsecs": ${nsecs}},
            */

            message += `{
                "position": {
                    "x": ${this.state.coordSystem=='UTM'? utmRoute[pt].x : route[2*pt] },
                    "y": ${this.state.coordSystem=='UTM'? utmRoute[pt].y : route[2*pt+1] } ,
                    "z": 0.0
                },
                "orientation": {
                    "x": 0.0,
                    "y": 0.0,
                    "z": ${q.z},
                    "w": ${q.w}
                }
            },`

        }

        message = message.slice(0, -1); 
        message += `]
    }`;

        //await new Promise(r => setTimeout(r, 200));  // pause between waypoint messages
        await this.props.publishMessage(name, type, message); // publish messages asynchronously within the for loop


        // message with Field and Holes

        var field = this.props.fields.find(f => f.fieldId === selRoute.fieldId);

        var fieldPts = JSON.parse(field.geometry);
        var utmField = fromLatLonArray(fieldPts);

        var holes = this.props.fieldGeometries.filter(fg => fg.fieldId === field.fieldId && fg.type == "POSITIVE_EXCLUSION_AREA" );

        var workingAreas = this.props.fieldGeometries.filter(fg => fg.fieldId === field.fieldId && fg.type == "WORKING_AREA" );

        name =  '/field';
        type = 'geofencing_msgs/msg/PolygonArrayStamped';
        
        var currentTime2 = new Date();
        var secs2 = Math.floor(currentTime2.getTime()/1000);
        var nsecs2 = Math.round(1000000000*(currentTime2.getTime()/1000-secs2));

        var message2 = `{
                "header": {
                    "stamp": {"sec": ${secs2}, "nanosec": ${nsecs2}},
                    "frame_id": "` + this.state.frameId + `"
                },
                    "polygons": [ {"points": [`;

        for (var pt=0; pt<utmField.length; pt++) {
            message2 += `{
                "x": ${this.state.coordSystem=='UTM'? utmField[pt].x : fieldPts[2*pt] },
                "y": ${this.state.coordSystem=='UTM'? utmField[pt].y : fieldPts[2*pt+1] },
                "z": 0.0
            },`;
        }
        message2 = message2.slice(0, -1); 
        message2 += `]},`;

        for (var w=0; w < 1; w++){ // for (var w=0; w < workingAreas.length; w++){

            var workingAreaPts = JSON.parse(workingAreas[w].geometry);
            var utmWorkingArea = fromLatLonArray(workingAreaPts);

            message2 += `{ "points": [`;

            for (var pt=0; pt<utmWorkingArea.length; pt++) {
                message2 += `{
                    "x": ${this.state.coordSystem=='UTM'? utmWorkingArea[pt].x : workingAreaPts[2*pt] },
                    "y": ${this.state.coordSystem=='UTM'? utmWorkingArea[pt].y : workingAreaPts[2*pt+1] },
                    "z": 0.0
                },`;
            }
            message2 = message2.slice(0, -1); 
            message2 += `]},`;
        }

        for (var w=0; w < holes.length; w++){

            var holePts = JSON.parse(holes[w].geometry);
            var utmHole = fromLatLonArray(holePts);

            message2 += `{ "points": [`;

            for (var pt=0; pt<utmHole.length; pt++) {
                message2 += `{
                    "x": ${this.state.coordSystem=='UTM'? utmHole[pt].x : holePts[2*pt] },
                    "y": ${this.state.coordSystem=='UTM'? utmHole[pt].y : holePts[2*pt+1] },
                    "z": 0.0
                },`;
            }
            message2 = message2.slice(0, -1); 
            message2 += `]},`;
        }

        message2 = message2.slice(0, -1); 
        message2 += `]}`;

        await this.props.publishMessage(name, type, message2); // publish messages asynchronously within the for loop

    }

    render() {
        return (

            <div className="RouteFollowing">
                <Form>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                        Field:
                        </Col>
                        <Col sm={8}>
                            <FormControl componentClass="select" inputRef={ el => this.inputFieldId=el} onChange={this.onChangeField.bind(this)} > 
                            {
                                this.props.fields.map((option, index) => {
                                    return (<option key={index} value={option.fieldId}>{option.name}</option>)
                                })
                            }
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                        Route:
                        </Col>
                        <Col sm={8}>
                            <FormControl componentClass="select" inputRef={ el => this.inputRouteId=el} onChange={this.onChangeRoute.bind(this)} > 
                            {
                                this.props.fieldGeometries
                                .filter(fg => fg.type == 'ROUTE' && fg.fieldId === this.inputFieldId.value)
                                .map((option, index) => {
                                    return (<option key={index} value={option.fieldGeometryId}>{option.name}</option>)
                                })
                            }
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                        frame_id:
                        </Col>
                        <Col sm={8}>
                            <FormControl
                                type="text"
                                value={this.state.frameId}
                                //placeholder="map"
                                onChange={this.handleChangeFrameId.bind(this)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                        ROS version:
                        </Col>
                        <Col sm={8}>
                            <FormControl componentClass="select"
                                    value={this.state.rosversion}
                                    onChange={e => this.setState({ rosversion: e.target.value })}>
                                <option value="ROS">ROS</option>
                                <option value="ROS2">ROS2</option>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col componentClass={ControlLabel} sm={4}>
                        Coordinates:
                        </Col>
                        <Col sm={8}>
                            <FormControl componentClass="select"
                                    value={this.state.coordSystem}
                                    onChange={e => this.setState({ coordSystem: e.target.value })}>
                                <option value="WGS84">Lat/Lon(WGS84)</option>
                                <option value="UTM">UTM</option>
                            </FormControl>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '15px', marginBottom: '5px'}}>
                        <Col md={12}>
                        <Button 
                            bsStyle="primary"
                            bsSize="xsmall"                            
                            onClick={this.handleFollowRoute} 
                            //disabled={this.state.selectedRoute=='sarasa'}
                            >
                        Publish Route
                        </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        )


    }

}

const mapStateToProps = (state) => {
    return {
        fields: state.fields,
        fieldGeometries: state.fieldGeometries
  
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        publishMessage: (topicName, topicType, message) => dispatch(roslibPublishMessage(topicName, topicType, message)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteFollowing);