'use strict'

//import * as polybool from 'polybooljs';
// import { Edge, trapezoidation } from './polygonTrapezoidation';

import {
    intersectLinePolygon, rotatePoint, rotatePolygon, rotatePolygons, polygonBuffer,
    simplifyAndNormalizePolygon, ringToGeojsonPolygon, intersectLineSegment,
    closestPointInSegment, distanceAroundPolygon, closestPointInPolygon2,
    simplifyRoute, polylineToGeojsonLineString, computeAreas, pointFromEndOfLine,
    distToPolygonSquared, GetPolygonLongestSide, distToSegmentSquared, removeNearPoints,
    getBufferedObstacles, intersectLineLine2, findCircle
} from './geometry';

import {fromLatLonArray, fromLatLon, toLatLonArray2} from '../utils/utm';
//import {connectedComponents } from 'graphology-components';
//import {Graph} from 'graphology';

//var simplepolygon = require('simplepolygon');


export default function routeplannerSingleOpenField(params){

    var polygonsUtm = params.polygonsUtm;
    var obstaclesUtm = params.obstaclesUtm;
    var turningRadius = params.turningRadius; // (m)
    var workingWidth = params.workingWidth; // (m)
    var workingSpeed = params.workingSpeed; // (m/s)
    var headlandSwaths = params.headlandSwaths; // (N) 
    var workingAngle = params.workingAngle;
    var utmEntrance = params.fieldEntrance;
    var pattern = params.pattern;
    var patternStart = params.patternStart;
    var alignmentZone = params.alignmentZone;
    var turningSpeedPercentage = params.turningSpeed;
    var fullConcaveTurns = params.fullConcaveTurns; // boolean
    var circuitTurnsMinimumAngle = params.circuitTurnsMinimumAngle; // degrees

    var turningSpeed = turningSpeedPercentage*workingSpeed/100;

    // compute headlandWidth according to number of swaths
    var headlandWidth = headlandSwaths * workingWidth;

    /* each polygon coordinates in Cesium format [lon,lat,lon,lat...]
    / first polygon: outer border - following polygons: holes

    polygonslatlon = [
        [-58.014035224914544,-34.94903469588605,-58.01261901855469,-34.95084624366132,-58.00973296165466,-34.9508726251293,-58.008756637573235,-34.949412837805205,-58.00855278968811,-34.94821684809537,-58.009260892868035,-34.94914901804555,-58.00969004631043,-34.947654023371236,-58.01260828971862,-34.947645229204255],
        [-58.0133056640625,-34.94906107793701,-58.012522459030144,-34.950098765207045,-58.01255464553833,-34.948383935941564],
        [-58.01321983337402,-34.94857740565382,-58.013198375701904,-34.94875328681423,-58.01301598548889,-34.948656552222694,-58.013026714324944,-34.94852464123212]
    ];
    */

    /*
    var polygonsUtm = [];

    for(var k=0; k < polygonslatlon.length; k++)
    {
        // fill obstacles with polygons in UTM coordinates

        var utmPoly = fromLatLonArray(polygonslatlon[k]);

        // simplify polygon
        var geoJsonPoly = ringToGeojsonPolygon(utmPoly);
        var sp = simplifyAndNormalizePolygon(geoJsonPoly, 0.5);

        polygonsUtm.push(sp);
    }

    if (typeof (workingAngle) == 'string') {

        switch (workingAngle) {
            case "LONGEST_SIDE":
                workingAngle = GetPolygonLongestSide(polygonsUtm[0]);
                break;
        }
    }

    */
    // save zone letter and num to convert back later
    var zNum = utmEntrance.zoneNum;
    var zLet = utmEntrance.zoneLetter;

    
    var ringGeoJson = ringToGeojsonPolygon(polygonsUtm[0]);

    var mainland = polygonBuffer(ringGeoJson, headlandSwaths*workingWidth);

    // For now add only the obstacles that are within the mainland
    if (turningRadius<workingWidth/2){
        var bufferedObstacles = getBufferedObstacles(obstaclesUtm, workingWidth/2, mainland);
    }
    else{
        var bufferedObstacles = getBufferedObstacles(obstaclesUtm, turningRadius, mainland);
    }



    //var mainland = headlandRings[headlandSwaths-1];
    var rotationPoint = mainland[0];

    var rotatedPolygonFieldUtm = rotatePolygon(polygonsUtm[0], rotationPoint, (90-workingAngle) * Math.PI/180);

    var rotatedBufferedObstaclesUtm = rotatePolygons(bufferedObstacles,rotationPoint, (90-workingAngle) * Math.PI/180);

    var rotatedMainlandUtm = rotatePolygon(mainland, rotationPoint, (90-workingAngle) * Math.PI/180);
    var rotatedUtmEntrance = rotatePoint(utmEntrance, rotationPoint, (90-workingAngle) * Math.PI/180);

    var xMin =Math.min.apply(Math, rotatedMainlandUtm.map(function(o) { return o.x; })) // minimum x value of all polygon points
    var xMax =Math.max.apply(Math, rotatedMainlandUtm.map(function(o) { return o.x; })) // maximum x value of all polygon points

    //var nSwaths = Math.floor((xMax-xMin)/workingWidth); // compute number of swaths
    var nSwaths = Math.ceil((xMax-xMin)/workingWidth); // compute number of swaths

    var regionRoute=[];

    var q = [];

    var firstTurningPoint = null;
    var secondTurningPoint = null;

    var center = null;
    var intersectionPoints;
    var turnUpDown = "";

    // compute sequence of method 'Skip and Fill Pattern' (SFP) (https://www.researchgate.net/publication/283843391_Quantifying_the_benefits_of_alternative_fieldwork_patterns_in_a_potato_cultivation_system)

    switch (pattern) {

        case "SFP":

            for (var i = 1; i <= nSwaths; i++) {
                if (i == 1) {
                    q.push(1);
                }
                else if (i == 2) {
                    q.push(3);
                }
                else if (i % 2 == 1) {
                    q.push(q[i - 2] - 1);
                }
                else if (i % 2 == 0 && i < nSwaths) {
                    q.push(q[i - 2] + 3);
                }
                else if (i % 2 == 0 && i == nSwaths) {
                    q.push(i);
                }

            }
            break;

        case "SFP3":
        case "SFP5":
        case "SFP7":

            var skip = parseInt(pattern.slice(-1));

            var ns = (skip + 1) / 2; // number of swaths before skipping back

            for (var i = 1; i <= nSwaths; i++) {

                if (i <= ns) {
                    q.push(2 * i - 1);
                }
                else if (nSwaths - i < ns - 1 - (nSwaths % 2)) {
                    q.push(nSwaths - 2 * (nSwaths - i) - (nSwaths % 2));
                }
                else if ((i + ns) % 2 == 1) {
                    q.push(q[i - 2] - (skip - 2));
                }
                else if ((i + ns) % 2 == 0) {
                    q.push(q[i - 2] + skip);
                }

            }
            
            break;

        case "SAP":
            q = [...Array(nSwaths + 1).keys()].splice(1);
            break;
    }

    // check it is no concave
    /*
    for (var i=0; i<nSwaths; i++){

        var xSwath = xMin + 0.5*workingWidth + (q[i]-1)*workingWidth;

        intersectionPoints = intersectLinePolygon( {x: xSwath, y:0}, {x: xSwath, y: 10000000}, rotatedMainlandUtm );
        
        if (intersectionPoints.length>2){
            return {error: "At this stage, all lines parallel to the working angle must intersect the field boundary only at two points"};
        }
    }
    */


    // Intersect the polygon near the left and right extremes to determine at which extreme to place a swath perfectly adjacent to the boundary.
    // On the opposite side there will be overlap with the headland contour.
    // This minimizes the overlapping area. 
    // See swaths.odg

    var leftIntersPts = intersectLinePolygon( {x: xMin + 0.5*workingWidth, y:0}, {x: xMin + 0.5*workingWidth, y: 10000000}, rotatedMainlandUtm );
    var rightInterPts = intersectLinePolygon( {x: xMax - 0.5*workingWidth, y:0}, {x: xMax - 0.5*workingWidth, y: 10000000}, rotatedMainlandUtm );

    var leftLength = Math.sqrt((leftIntersPts[0].x-leftIntersPts[1].x)**2 + (leftIntersPts[0].y-leftIntersPts[1].y)**2);
    var rightLength = Math.sqrt((rightInterPts[0].x-rightInterPts[1].x)**2 + (rightInterPts[0].y-rightInterPts[1].y)**2);

    var xOffset = 0;
    if (rightLength>leftLength){
        // put swathsRemainder on the left side
        var swathsRemainder = (xMax-xMin) % workingWidth;
        xOffset = workingWidth - swathsRemainder;
    }


    var startingPt = getStartingPoint(patternStart);

    // if the selected starting point is on the right side, reverse the SFP sequence
    if (startingPt.side == 'right'){
        q = q.map(function(x) {
            return (q.length-x) + 1;
        });
    }

    // if the selected starting point is on top, start the route on the upper side
    var startUp = startingPt.vertical == 'top' ? true : false;
    var s1 = startUp ? 0 : 1;

    // compute headlandrings by polygon buffering the field boundary:
    // (JSTS returns polygons in canonical form, so rings are always clockwise. Canonical form: outer ring CW, holes CCW)

    var headlandRings = [];

    for (var n=headlandSwaths-1; n>=0; n--){  // start from most inner ring
        var origRing = polygonBuffer(ringGeoJson, (n+0.5)*workingWidth);
        var rotatedRing = rotatePolygon(origRing, rotationPoint, (90-workingAngle) * Math.PI/180);
        headlandRings.push(rotatedRing);
    }

    // start the route with the path from the field entrance to the starting point of the SFP pattern

    var travelHeadlandRing = Math.floor(headlandSwaths/2);

    // check if the shortest path is following the contour cw or ccw
    var distAround = distanceAroundPolygon(rotatedUtmEntrance, startingPt, headlandRings[travelHeadlandRing]);

    var orientation = distAround.cw<distAround.ccw ? 'cw' : 'ccw';

    var polylineEntraceToStart = entranceToStartPolyline(rotatedUtmEntrance, startingPt, startingPt.oppositePoint, headlandRings[travelHeadlandRing], orientation, turningRadius);
    
    // this version call the headlandPolylineToRoute function, which implements the convex and concave full coverage turns.
    // These turns are not necessary in this path, where the vehicle is not working (just travelling)
    
    //regionRoute.push(rotatedUtmEntrance); // headlandPolylineToRoute does not add the first point 
    //regionRoute.push(... headlandPolylineToRoute(polylineEntraceToStart));


    regionRoute.push(... polylineToRoute(polylineEntraceToStart));

    var swaths = [];

    for (var i=0; i<nSwaths; i++){

        // var xSwath = utmA.x + direction * i * workingWidth;
        var xSwath = xMin - xOffset + 0.5 * workingWidth + i * workingWidth;

        var swath = getSwathWorkingModeLimits(xSwath, workingWidth, rotatedMainlandUtm);

        swaths.push(swath);

    }


    for (var i=0; i<nSwaths; i++){

        var pUp = {x: swaths[q[i]-1].x, y: swaths[q[i]-1].upperLimit, angle: swaths[q[i]-1].upperAngle, segment: swaths[q[i]-1].upperSegment};
        var pDown = {x: swaths[q[i]-1].x, y: swaths[q[i]-1].lowerLimit, angle: swaths[q[i]-1].lowerAngle, segment: swaths[q[i]-1].lowerSegment};
        //var pUp = {x: swaths[i].x, y: swaths[i].upperLimit};
        //var pDown = {x: swaths[i].x, y: swaths[i].lowerLimit};

        if (i==0){ // add starting point

            if (startUp){
                regionRoute.push( { x: pUp.x, y: pUp.y, w: "on", speed: workingSpeed } );
                firstTurningPoint = pDown;
            }
            else{
                regionRoute.push( { x: pDown.x, y: pDown.y, w: "on", speed: workingSpeed } );
                firstTurningPoint = pUp;
            }

        }
        else{ // add turning

            var turningPts = null;
            var turn = null;
            if ((i + s1) % 2 == 1){  // add turning down
                turnUpDown = "down";
                secondTurningPoint = pDown;
                turn = getAutoTurningPoints2(firstTurningPoint, secondTurningPoint, turningRadius, workingWidth, alignmentZone, "down", rotatedMainlandUtm, rotatedPolygonFieldUtm, workingSpeed, turningSpeed); 
                turningPts = turn.pts;
                firstTurningPoint = pUp;
            }
            else{  // add turning up
                turnUpDown = "up";
                secondTurningPoint = pUp;
                turn = getAutoTurningPoints2(firstTurningPoint, secondTurningPoint, turningRadius, workingWidth, alignmentZone, "up", rotatedMainlandUtm, rotatedPolygonFieldUtm, workingSpeed, turningSpeed);
                turningPts = turn.pts;
                firstTurningPoint = pDown;
            }

            regionRoute.push(... turningPts);

            // After adding the turn, check if the next row intersects any obstacles

            var intersectedObstacles = [];
            for (var obs=0; obs<rotatedBufferedObstaclesUtm.length; obs++){
                var res = intersectLinePolygon( pUp, pDown, rotatedBufferedObstaclesUtm[obs]);
                if (res.length>0){ 
                    // The buffered obstacles are convex and do not overlap, so I can take any point of the intersection to determine the order
                    intersectedObstacles.push({index:obs, y:res[0].y});
                }
            }

            if (intersectedObstacles.length > 0) {

                var direction = turnUpDown == "up" ? "down" : "up"

                if (direction == "up") {
                    intersectedObstacles.sort((a, b) => a.y - b.y); // sort the obstacles from the bottom up
                }
                else {
                    intersectedObstacles.sort((a, b) => b.y - a.y);
                }

                var avoidObstaclePts = [];
                for (var io = 0; io < intersectedObstacles.length; io++) {
                    var obsIndex = intersectedObstacles[io].index;
                    avoidObstaclePts.push(...avoidObstacleUsingWorkingWidth(rotatedBufferedObstaclesUtm[obsIndex], pUp.x, turningRadius, direction, workingSpeed, turningSpeed));
                }

                regionRoute.push(...avoidObstaclePts);
            }
/*

            for (var obs=0; obs<rotatedBufferedObstaclesUtm.length; obs++){
                var res = intersectLinePolygon( pUp, pDown, rotatedBufferedObstaclesUtm[obs]);
                if (res.length>0){ 
                    var avoidObstaclePts = [];
                    var direction = turnUpDown == "up"?"down":"up" 
                    //if (turningRadius<workingWidth/2){
                        avoidObstaclePts = avoidObstacleUsingWorkingWidth(rotatedBufferedObstaclesUtm[obs],pUp.x, turningRadius, direction, workingSpeed, turningSpeed);
                    //}
                    //else{
                        //avoidObstaclePts = avoidObstacleUsingTurningRadius(rotatedBufferedObstaclesUtm[obs],pUp.x, turningRadius);
                    //}
                    regionRoute.push(... avoidObstaclePts);
                }
            }
*/
        }

    }
    
    // add point to complete last swath
    if (turnUpDown == "up"){
        regionRoute.push( { ...pDown, speed: workingSpeed, w: "off"} ); 
    }
    else{
        regionRoute.push( { ...pUp, speed: workingSpeed, w: "off"} );
    }
    /*
    if (turnUpDown == "up"){
        regionRoute.push( { ...intersectionPoints[1], speed: workingSpeed, w: "off"} ); 
    }
    else{
        regionRoute.push( { ...intersectionPoints[0], speed: workingSpeed, w: "off"} );
    }*/


    // Once the internal pattern is completed, the vehicle makes a reverse turn and begins the first circuit.
    // Depending on where it ends, it must be determined whether it should be CW or CCW.
    // turnUpDown: this variable determines whether the vehicle finished up or down
    /* 
        startingPt.side="left"	turnUpDown="up"	circuit_orientation

	            1			          1		            CCW
	            1			          0		            CW
	            0			          1		            CW
	            0			          0		            CCW
    */
    
    var circuitsOrientation =  startingPt.side=="left" ^ turnUpDown=="up" ? "CCW":"CW";

    // canonical form of polygons is CW. Change to CCW if required
    if (circuitsOrientation == "CCW"){
        for (var i=0; i<headlandRings.length; i++){
            headlandRings[i].reverse();
        }
    }

    // find smallest interior angle of field polygon 
    var minInteriorAngle = getSmallestAngle(polygonsUtm[0]);

    // This angle determines the worst case space required for the vehicle to make a convex turn.
    var requiredSpace = turningRadius*(1+Math.cos(minInteriorAngle));

    // Minimum number of circuits to guarantee this space:
    var inverseCircuits = Math.ceil(requiredSpace/workingWidth + 0.5)-1;

    // Note: If workingwidth is very large, the number of reverse circuits can be 0. To avoid an error, the minimum is 1.
    // The code could be adapted so that no reverse circuit is made. 
    if (inverseCircuits==0){
        inverseCircuits=1;
    }

    if (inverseCircuits>headlandRings.length){
        inverseCircuits = headlandRings.length;
        console.log("The number of circuits is insufficient to make the convex turns in the circuits within the field boundary");
    }

    // sequence not used now
    //var circuitSequence = [];
    /*for (var i=0; i<headlandRings.length; i++){
        if (i<headlandRings.length-inverseCircuits){
            circuitSequence.push(i);
            headlandRings[i].innerToOuter = true;
        }
        else{
            circuitSequence.push(2*headlandRings.length-inverseCircuits-i-1);
            headlandRings[i].innerToOuter = false;
        }
    }
    */

    // Points to do the raytracing to the next circuit

    var a1 = regionRoute[regionRoute.length-2]; // the first time a1->a2 is the last line added to the route
    var a2 = regionRoute[regionRoute.length-1];

//********* NUEVA VERSION *********
    var circuitsPolyline = [];

    circuitsPolyline.push(a2);

    var raytrace;
    
    // iterate the first N inner to outer circuits
    // inverseCircuits = 3; // **** only for testing!! ******
    for (var c=0; c<headlandSwaths-inverseCircuits; c++){

        raytrace = raytracePolygon(a1, a2, headlandRings[c]);
        
        circuitsPolyline.push(raytrace.p);
        circuitsPolyline.push(... arrayRotate(headlandRings[c].slice(0), raytrace.segment+1)); // arrayRotate alters the original array. Clone it first using slice, and remove first element

        a1 = circuitsPolyline[circuitsPolyline.length-2];
        a2 = circuitsPolyline[circuitsPolyline.length-1];

        if (c<headlandSwaths-inverseCircuits-1){
            circuitsPolyline.pop();
        }

    } 

    var headlandCircuitsRoute = headlandPolylineToRoute(circuitsPolyline, true, circuitsOrientation, fullConcaveTurns, circuitTurnsMinimumAngle); 
    headlandCircuitsRoute[headlandCircuitsRoute.length-1].w = "off"; // change to w="off" when travelling to the outermost circuit

    // transition inner_to_outer circuits to outer_to_inner
    raytrace = raytracePolygon(a1, a2, headlandRings[headlandSwaths-1]); // raytrace last segment of inner_to_outer to the outermost circuit

    // make a concave turn at that point
    var len = headlandRings[headlandSwaths-1].length;
    var pts = getConcaveTurn(a2, raytrace.p, headlandRings[headlandSwaths-1][(raytrace.segment+1) % len], turningRadius, turningSpeed, workingSpeed, workingWidth, "on", false, 0);

    headlandCircuitsRoute.push(... pts);
    headlandCircuitsRoute[headlandCircuitsRoute.length-1].w = "off"; // change to w="off" when travelling backwards to the beginning of outermost circuit

    // The vehicle must go to the starting point of the first segment. Evaluate if after the reverse turn you were in front of or behind the point
    var d = distToSegmentSquared(headlandCircuitsRoute[headlandCircuitsRoute.length-1],
        headlandRings[headlandSwaths-1][raytrace.segment],
        headlandRings[headlandSwaths-1][(raytrace.segment+1) % len]);

    // If the distance is 0, the last point is on the segment and the vehicle will go backwards.
    headlandCircuitsRoute[headlandCircuitsRoute.length-1].speed = (d<0.001)? -workingSpeed:workingSpeed;

    // add first point of outermost circuit
    headlandCircuitsRoute.push({x: headlandRings[headlandSwaths-1][raytrace.segment].x,
        y: headlandRings[headlandSwaths-1][raytrace.segment].y,
        speed: workingSpeed,
        w: "on"}
        ); 

    var circuitsPolyline = []; // reset the polyline
    // add the last point of the partial route as initial point 
    circuitsPolyline.push(headlandCircuitsRoute[headlandCircuitsRoute.length-1]);

    var startingPtInverseCircuit = (raytrace.segment + 1) % len;

    for (var c=headlandSwaths-1; c>=headlandSwaths-inverseCircuits; c--){


        var d2=Infinity;
        var ptForRaytrace;
        var ptIndexForSearchClosest = (startingPtInverseCircuit==0)? headlandRings[c].length-1 : startingPtInverseCircuit-1;

        for (var pt=0; pt<headlandRings[c-1].length; pt++){
            //var d2pt= (headlandRings[c][raytrace.segment].x-headlandRings[c-1][pt].x)**2 + (headlandRings[c][raytrace.segment].y-headlandRings[c-1][pt].y)**2;
            
            var d2pt= (headlandRings[c][ptIndexForSearchClosest].x-headlandRings[c-1][pt].x)**2 + (headlandRings[c][ptIndexForSearchClosest].y-headlandRings[c-1][pt].y)**2;

            if (d2pt<d2){
                d2 = d2pt;
                ptForRaytrace = pt;
            }
        }


        //ray trace the circuit with the found segment of the following circuit (backwards)
        var len = headlandRings[c-1].length;
        a1 = headlandRings[c-1][(ptForRaytrace+1) % len];
        a2 = headlandRings[c-1][ptForRaytrace];
        raytrace = raytracePolygon(a1, a2, headlandRings[c]); 

        var lastPtInverseCircuit = raytrace.segment;

        var i=startingPtInverseCircuit;
        do{
            circuitsPolyline.push(headlandRings[c][i]);
            i = (i+1) % headlandRings[c].length;

        } while (i!=(lastPtInverseCircuit+1) % headlandRings[c].length );



        circuitsPolyline.push(raytrace.p);
        /*
        if (raytrace.segment==headlandRings[c].length-1){
            raytrace.segment=0;
        }
        else{
            raytrace.segment++;
        }
        */

        // primer punto para el polyline del next circuit: ptForRaytrace (ptForRaytrace+1 se eliminan porque va a quedar colinear)
        startingPtInverseCircuit = (ptForRaytrace + 1) % len;

    }

    headlandCircuitsRoute.push(...headlandPolylineToRoute(circuitsPolyline, false, circuitsOrientation, fullConcaveTurns, circuitTurnsMinimumAngle)); 


    //var xxx = graficar1(headlandCircuitsRoute);
//**********************************
    
    // for the most inner headland swath, join the mainland route with a turn the same way that headland turns
    // create a polyline joining:
    // a) last point of mainland route
    // b) raytracing point of last segment of that route on most inner headland polygon ring
    // c) most inner headland polygon ring (after rotating point array so that segmentNumber second extreme is the first point)
    // d) add intersection point b) as last point

/*    var headlandPolyline = [];

    headlandPolyline.push(a2);
    headlandPolyline.push(raytrace.p);
    headlandPolyline.push(... arrayRotate(headlandRings[0].slice(0), raytrace.segment+1)); // arrayRotate alters the original array. Clone it first using slice
    headlandPolyline.push(raytrace.p);

    var headlandSpiralRoute = headlandPolylineToRoute(headlandPolyline, headlandRings[0].innerToOuter, circuitsOrientation); 

    //var headlandRoute = [];
    //hheadlandRings.push(rr);

    // for the rest of the headland swaths, make a lane change first

    for (var sq=1; sq<headlandSwaths; sq++){

        var n = circuitSequence[sq];

        // add lane change 
        // for left->right swaths order, if the last turn was up, the last point is up => make reverse first

        var a = headlandSpiralRoute[headlandSpiralRoute.length-1]; // last point
        var b = headlandRings[n-1][raytrace.segment==headlandRings[n-1].length-1?0:raytrace.segment+1];
        if (turnUpDown == "down"){
            headlandSpiralRoute.push(... laneChange(a, b, true ) );
        }
        else{
            headlandSpiralRoute.push(... laneChange(a, b, false ) );
        }
        
        // take the last point of headlandSpiralRoute and find the closest segment on the next headland ring
        // (normally it should be on a segment)
        
        var minDist = Infinity;
        var segmentNumber;
        for (var p=0; p<headlandRings[n].length;p++){
            var b1 = headlandRings[n][p];
            var b2 = headlandRings[n][p==headlandRings[n].length-1?0:p+1]; 

            var res = closestPointInSegment(headlandSpiralRoute[headlandSpiralRoute.length-1], b1, b2);
            if (res.distance<minDist){  
                segmentNumber = p;
                minDist = res.distance;
            }
        }

        headlandPolyline = [];
        headlandPolyline.push(headlandSpiralRoute[headlandSpiralRoute.length-1]); // first point to give correct direction to first headland turn
        headlandPolyline.push(... arrayRotate(headlandRings[n].slice(0), segmentNumber+1)); // arrayRotate alters the original array. Clone it first using slice
        headlandPolyline.push(headlandSpiralRoute[headlandSpiralRoute.length-1]);

        headlandSpiralRoute.push(... headlandPolylineToRoute(headlandPolyline, headlandRings[n].innerToOuter, circuitsOrientation));

    }

*/

    // ATENCION: DESHABILITO TEMPORALMENTE EL TRAMO SPIRAL TO ENTRANCE
    // CUANDO LA OPCION ES "END THE PATTERN NEAR THE ENTRANCE" HASTA REESCRIBIR EL CODIGO

    if (patternStart == "StartNearEntrance"){


        // add the path from the last spiral point to entrance

        // check if the shortest path is following the contour cw or ccw

        var lastSpiralPoints = [headlandCircuitsRoute[headlandCircuitsRoute.length-2], headlandCircuitsRoute[headlandCircuitsRoute.length-1]]; // last two points
        distAround = distanceAroundPolygon(lastSpiralPoints[1], rotatedUtmEntrance, headlandRings[travelHeadlandRing]);

        orientation = distAround.cw<distAround.ccw ? 'cw' : 'ccw';

        var polylineSpiralToEntrace = SpiralToEntrancePolyline(lastSpiralPoints, rotatedUtmEntrance, headlandRings[travelHeadlandRing], orientation, turningRadius);

        // delete last two points of headlandSpiralRoute to avoid repeated points
        //headlandSpiralRoute.pop();
        //headlandSpiralRoute.pop();

        headlandCircuitsRoute.push(... polylineToRoute(polylineSpiralToEntrace));

        //var sarasa = graficar1(headlandCircuitsRoute);

        // polylineToRoute does not include the last point. Only the curve upto it
        headlandCircuitsRoute.push( { ...polylineSpiralToEntrace[polylineSpiralToEntrace.length-1], speed: workingSpeed, w:"off"} );
        
        headlandCircuitsRoute.push( { ...rotatedUtmEntrance, speed: workingSpeed, w:"off"} );

    }

    //regionRoute.push(rotatedUtmEntrance); // headlandPolylineToRoute does not add the first point 
    //headlandSpiralRoute.push(... headlandPolylineToRoute(polylineSpiralToEntrace));




    //var headlandSpiralRoute = headlandCircuitsRoute;

    // convert back UTM to LatLon

    var routes = [];

    // routeWork.push( regionRoute.map(a => a.w) );

    // complete the whole route by adding headlandSpiralRoute to regionRoute

    regionRoute.push( ...headlandCircuitsRoute);

    var rotatedRegionRoute = rotatePolygon(regionRoute, rotationPoint, -(90-workingAngle) * Math.PI/180);

    // compute time
    var travelledDistance=0;
    var workedDistance=0;
    var travelledTime=0;
    var workedTime=0;

    rotatedRegionRoute[0].time = 0;
    for (var p=1; p<rotatedRegionRoute.length; p++){
        var dx = rotatedRegionRoute[p].x - rotatedRegionRoute[p-1].x;
        var dy = rotatedRegionRoute[p].y - rotatedRegionRoute[p-1].y;
        var distToPrevWp = Math.sqrt(dx*dx + dy*dy);
        var dt = distToPrevWp/Math.abs(rotatedRegionRoute[p-1].speed);
        travelledTime += dt;
        if (rotatedRegionRoute[p-1].w == "on") {
            workedTime += dt;
        }
        travelledDistance += distToPrevWp;
        if (rotatedRegionRoute[p-1].w == "on") {
            workedDistance += distToPrevWp;
        }
        rotatedRegionRoute[p].time = travelledTime;
    }

    // compute coverage areas before converting from UTM to LL

    var coverageAreas = [];
    var coverageAreasUtm = []; // covered rectangles in UTM to compute total covered area, uncovered area and overlap area
    
    for (var p=0; p<rotatedRegionRoute.length-1; p++){

        if (rotatedRegionRoute[p].w == "on"){

            try{
                // compute rectangle coords

                var dx = rotatedRegionRoute[p+1].x - rotatedRegionRoute[p].x;
                var dy = rotatedRegionRoute[p+1].y - rotatedRegionRoute[p].y;
                var angle = Math.atan2(dy,dx);

                // rectangle: P1->P2->P4->P3  
                var P1 = { x: Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p].x , y: -Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p].y };
                var P2 = { x: -Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p].x , y: Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p].y };
                var P3 = { x: Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p+1].x , y: -Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p+1].y };
                var P4 = { x: -Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p+1].x , y: Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p+1].y };

                coverageAreasUtm.push([P1,P2,P4,P3]);

                var rectLatLon = toLatLonArray2([P1,P2,P4,P3], zNum, zLet);

                var covArea = {wp: p, coords: rectLatLon, startTime: rotatedRegionRoute[p].time, endTime: rotatedRegionRoute[p+1].time };

                coverageAreas.push(covArea);
            }catch(e){
                var sarasa =45;
            }

        }
    }

    try{
        var routeStats = computeAreas(polygonsUtm, coverageAreasUtm);
    }
    catch{
        return {error: "An error occurred while calculating the statistics areas"};

    }


    // convert different areas to LatLon
    // Each area is an array of polygons, each polygon is an array of ring coordinates: first element is the exterior ring, rest are holes

    var coveredAreaLatLon = []; 
    for (var pol=0; pol<routeStats.coveredArea.length; pol++){
        var ringsLatLon = [];
        for (var ring=0; ring<routeStats.coveredArea[pol].length; ring++){
            ringsLatLon.push(toLatLonArray2(routeStats.coveredArea[pol][ring], zNum, zLet));
        }
        coveredAreaLatLon.push(ringsLatLon);
    }

    var overlapAreaLatLon = []; 
    for (var pol=0; pol<routeStats.overlapArea.length; pol++){
        var ringsLatLon = [];
        for (var ring=0; ring<routeStats.overlapArea[pol].length; ring++){
            ringsLatLon.push(toLatLonArray2(routeStats.overlapArea[pol][ring], zNum, zLet));
        }
        overlapAreaLatLon.push(ringsLatLon);
    }

    var uncoveredAreaLatLon = []; 
    for (var pol=0; pol<routeStats.uncoveredArea.length; pol++){
        var ringsLatLon = [];
        for (var ring=0; ring<routeStats.uncoveredArea[pol].length; ring++){
            ringsLatLon.push(toLatLonArray2(routeStats.uncoveredArea[pol][ring], zNum, zLet));
        }
        uncoveredAreaLatLon.push(ringsLatLon);
    }

    // replace areas by LatLon version
    routeStats.coveredArea = coveredAreaLatLon;
    routeStats.uncoveredArea = uncoveredAreaLatLon;
    routeStats.overlapArea = overlapAreaLatLon;

    routeStats.travelledDistance = travelledDistance;
    routeStats.workedDistance = workedDistance;
    routeStats.travelledTime = travelledTime;
    routeStats.workedTime = workedTime;
    
    var rLatLon = toLatLonArray2(rotatedRegionRoute, zNum, zLet);
    //routes.push(rLatLon); 

    //var rotatedSpiral = rotatePolygon(headlandSpiralRoute, rotationPoint, -(90-workingAngle) * Math.PI/180);
    //var rLatLon2 = toLatLonArray2(rotatedSpiral, zNum, zLet);

    //rLatLon.push(... rLatLon2);
    //var sarasa = graficar1(regionRoute);

    routes.push(rLatLon);
    /*
    for (var n=0; n<hheadlandRings.length; n++){
        if (hheadlandRings[n].length>=2){
            var routeLatLon = toLatLonArray2(hheadlandRings[n], zNum, zLet);
            // add last point equal to first one
            routeLatLon.push(routeLatLon[0]);
            routeLatLon.push(routeLatLon[1]);
            routes.push(routeLatLon);
        }
    }
    */

    var speedArray = rotatedRegionRoute.map(a => a.speed);
    var workArray = rotatedRegionRoute.map(a => a.w);
    var timeArray = rotatedRegionRoute.map(a => a.time);

    var routePlan = {routesCoord: routes,
        time: timeArray,
        speed: speedArray,
        work: workArray,
        coverageAreas: coverageAreas,
        routeStats: routeStats
    };

    return routePlan;





    function getAutoTurningPoints(deltaX_, deltaY, direction){

        var deltaX = Math.abs(deltaX_);
        var type = null;
        var points = [];
    
        
        var theta = Math.abs(Math.atan(deltaX/deltaY));
        
        if (theta<0.05){
            theta=0.05;
        } 
        

   
    /*
        var A = null;
        var B = null;
        var M = null;
        var angleAB = null;
        var distAB = null;
        var lMO = null;
        var O = null;
        var alpha = null;
        var beta = null; 
    */
        var a = null;
        var h = (deltaX/2+workingWidth/2) /Math.tan(theta); // ó 2*p1.y
    
        var A = {x:-deltaX/2-turningRadius, y: h};
        var B = {x: -A.x, y:0};
    
        // determine point O equidistant of A and B at a distance = 2*r
    
        var M = {x:0, y: h/2}; // punto medio entre A y B
    
        var angleAB = Math.atan(-h/(2*A.x)); // angle A-B
    
        var distAB = Math.sqrt((B.x-A.x)*(B.x-A.x) + (B.y-A.y)*(B.y-A.y));
    
        if ((2*turningRadius)*(2*turningRadius) - (distAB/2)*(distAB/2) > 0){
            var lMO = Math.sqrt((2*turningRadius)*(2*turningRadius) - (distAB/2)*(distAB/2));
    
            var O = {x: M.x + lMO*Math.sin(angleAB), y: M.y + lMO*Math.cos(angleAB)};
    
            var alpha = Math.atan((O.y-h)/(O.x-A.x));
    
            var beta = Math.atan(O.y/(B.x-O.x)); 
            a = turningRadius * (Math.sin(theta)*Math.sin(alpha) + Math.cos(theta)*Math.cos(alpha) - Math.cos(theta));
        }
    
        // revisar esta ecuacion está en los papers de Jin (gráfico de seleccion del tipo de turning)
        //if (  headlandWidth >= turningRadius*(1 + Math.cos(theta)) + (deltaX/2)*(1+Math.sin(theta)*Math.cos(theta))  ) {
    
        // la reemplazo por esta simple: headland width requerido para u-turn
        // le agrego || turningRadius < deltaX/2 porque cuando r < w/2 no puedo usar fishtail tal como está programado
        if ( headlandWidth >= deltaX*(1+Math.cos(theta)) || turningRadius < deltaX/2 ) {
            if (turningRadius == deltaX/2)
                type = "u turn"
            else if ( turningRadius < deltaX/2)
                type = "flat turn"
            else if ( turningRadius > deltaX/2){
                /*
                var h = deltaX/Math.tan(theta); // ó 2*p1.y
    
                var A = {x:-deltaX/2-turningRadius, y: h};
                var B = {x: -A.x, y:0};
                
                // determine point O equidistant of A and B at a distance = 2*r
                
                var M = {x:0, y: h/2}; // punto medio entre A y B
                
                var angleAB = Math.atan(-h/(2*A.x)); // angle A-B
                
                var distAB = Math.sqrt((B.x-A.x)*(B.x-A.x) + (B.y-A.y)*(B.y-A.y));
                var lMO = Math.sqrt((2*turningRadius)*(2*turningRadius) - (distAB/2)*(distAB/2));
                
                var O = {x: M.x + lMO*Math.sin(angleAB), y: M.y + lMO*Math.cos(angleAB)};
    
                var alpha = Math.atan((O.y-h)/(O.x-A.x));
                var beta = Math.atan(O.y/(B.x-O.x)); 
                var a = turningRadius * (Math.sin(theta)*Math.sin(alpha) + Math.cos(theta)*Math.cos(alpha) - Math.cos(theta));
                */
    
                
                //if (a!=null && alpha>0){
                    // this equation uses the variable "a" as in the decision tree of Jin papers
                    if ( a!=null && alpha>0 && turningRadius < (headlandWidth-2*a-deltaX/2)/(1+Math.cos(theta)) ){
                    //if (headlandWidth > turningRadius * ( 1 + 2*Math.sin(theta)*Math.sin(alpha) + 2*Math.cos(alpha)*Math.cos(alpha) - Math.cos(theta)) + deltaX/2){
                        if (turningRadius > deltaX/(2*Math.sin(theta)) ){
                            type = "bulb"; 
                        }
                        else{
                            type = "bulb"; // or "hook"
                        }
                    }
                    else{
                        if (turningRadius > deltaX/(2*Math.sin(theta)) ){
                            type = "fishtail"; 
                        }
                        else{
                            type = "hook";
                        }
                    }
                //}
    
            }
        }
        else{
            type = "fishtail";
        }
    
        // TODO: temporary until implementation of fishtail turn
        //if (type == "fishtail"){
        //    type = "hook";
        //}
    
        //console.log(type);
        
        var stepDegrees = 15;
    
        switch(type){
    
            case "bulb":
                
                // add alpha arc
                var steps = Math.round(alpha/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (alpha/steps);
                    points.push( { x: A.x + turningRadius*Math.cos(arc), y: A.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
        
                // add gamma arc
                var gammaFrom = -alpha;
                var gammaTo = Math.PI+beta;
                steps = Math.round((gammaTo-gammaFrom)/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
    
                for (s=0; s< steps; s++ ){
                    arc = gammaFrom + (s+1) * ((gammaTo-gammaFrom)/steps);
                    points.push( { x: O.x - turningRadius*Math.cos(arc), y: O.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                // add beta arc
                steps = Math.round(beta/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (s=0; s< steps; s++ ){
                    var arc = beta - (s+1) * (beta/steps);
                    points.push( { x: B.x - turningRadius*Math.cos(arc), y: B.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                break;
    
            case "hook":
    
                var O = {x:-deltaX/2+turningRadius, y:h};
                var r2 = ( (deltaX*deltaX)*(1/Math.tan(theta))*(1/Math.tan(theta)) + deltaX*deltaX - 2*deltaX*turningRadius ) / (4*turningRadius - 2*deltaX);
                var B = {x: deltaX/2 + r2, y: 0};
                var beta = Math.atan(O.y/(B.x-O.x));  
                var alpha = Math.PI + beta;
                
                // add alpha arc
                var steps = Math.round(alpha/(stepDegrees*Math.PI/180));
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (alpha/steps);
                    points.push( { x: O.x - turningRadius*Math.cos(arc), y: O.y + turningRadius*Math.sin(arc), speed: turningSpeed } );
                }
                
                // add beta arc
                steps = Math.round(beta/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (s=0; s< steps; s++ ){
                    var arc = beta - (s+1) * (beta/steps);
                    points.push( { x: B.x - r2*Math.cos(arc), y: B.y + r2*Math.sin(arc), speed: turningSpeed} );
                }
    
                break;
    
            case "flat turn":
    
                var C1 = {x:-deltaX/2+turningRadius, y: h}; // first center
                var C2 = {x: deltaX/2-turningRadius, y: h};  // second center
                
                // add first arc
                var steps = Math.round(90/stepDegrees);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI/(2*steps));
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
                // add second arc
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI/(2*steps));
                    points.push( { x: C2.x + turningRadius*Math.sin(arc), y: C2.y + turningRadius*Math.cos(arc), speed: turningSpeed} );
                }
    
                // add extra point where vehicle re-enters field to change work to "on"
                points.push( { x: deltaX/2, y: -(deltaX/2 - workingWidth/2) /Math.tan(theta), speed: workingSpeed} );
    
                break;
    
            case "u turn":
    
                var C1 = {x:0, y: h}; // center
                
                // add arc
                var steps = Math.round(180/stepDegrees);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI/steps);
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                // add extra point where vehicle re-enters field to change work to "on"
                points.push( { x: deltaX/2, y: 0, speed: workingSpeed} );
    
                break;
    
            case "fishtail":
                var C1 = {x:-deltaX/2+turningRadius, y:h}; // first center
                var C3 = {x: C1.x+turningRadius*Math.cos(theta), y: C1.y+turningRadius*Math.sin(theta)};
                var C4 = {x: deltaX/2-turningRadius, y: (C3.x - (deltaX/2-turningRadius))/Math.tan(theta) + C3.y };
                var l = turningRadius/Math.cos(Math.PI/2-theta);
                var C2 = {x: deltaX/2-turningRadius, y: C4.y-l};
    
                // add first arc
                var steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
                for (var s=0; s<= steps; s++ ){
                    var arc = s * ((Math.PI-theta)/steps);
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
                // change speed of last point to reverse
                points[points.length-1].speed = - turningSpeed; 

                // add second arc
                var gammaFrom = Math.PI-theta;
                var gammaTo = Math.PI;
                steps = Math.round((gammaTo-gammaFrom)/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = gammaFrom + s * ((gammaTo-gammaFrom)/steps);
                    points.push( { x: C2.x - turningRadius*Math.cos(arc), y: C2.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                // add extra point where vehicle re-enters field to change work to "on" (TODO: review y value)
                points.push( { x: deltaX/2, y: 0, speed: workingSpeed} );
    
                break;
        }
    
        //if (type != "fishtail"){  // for now fishtail not implemented
        if (direction == "up"){
            if (deltaY>0){
                for (var p=0; p<points.length; p++){
                    points[p].x = -points[p].x;
                }
                points.reverse();
            }
        }
        if (direction == "down"){
            for (var p=0; p<points.length; p++){
                points[p].y = -points[p].y;
                if (deltaY<0){
                    points[p].x = -points[p].x;
                }
            }
            if (deltaY<0){
                points.reverse();
            }
        }

        if (deltaX_<0){
            for (var p=0; p<points.length; p++){
                points[p].x = -points[p].x;
            }
        }


        //}
    
        // Add work property
        for (var p=0; p<points.length-1; p++){
            points[p].w = "off"
        }
        points[points.length-1].w = "on";
        points[points.length-1].speed = workingSpeed;
    
        return points;
        
    }


    function headlandPolylineToRoute(pol, innerToOuter, orientation, fullConcaveTurns, circuitTurnsMinimumAngle){

        var route = [];

        /* version for headlandRingToRoute (circular route)
        for (var i=0; i<pol.length;i++){

            /* 
            var a = pol[i==0?pol.length-1:i-1];
            var b = pol[i];
            var c = pol[i==pol.length-1?0:i+1];
            */

        for (var i=0; i<pol.length-2;i++){

            var a = pol[i];
            var b = pol[i+1];
            var c = pol[i+2];

            var turnPoints = [];

            var isClockwise = (orientation == "CW"); 
            var isTurnToLeft = isTurnLeft(a,b,c); 

            // perform XOR logical operation between these three variables
            // in order to decide if the circuit turn must be internal (concave) or external (convex)

            /*
                Clockwise	Inner to outer	Turn to the left  =>	type of turn
                    1		      1		          1		            INTERNAL (CONCAVE)
                    1		      1		          0		            EXTERNAL (CONVEX)
                    1		      0		          1		            EXTERNAL
                    1		      0		          0		            INTERNAL
                    0		      1		          1		            EXTERNAL
                    0		      1		          0		            INTERNAL
                    0		      0		          1		            INTERNAL
                    0		      0		          0		            EXTERNAL
            */

            var internalTurn = !!(isClockwise ^ innerToOuter ^ isTurnToLeft); 

            if (internalTurn){ // add concave turn
    
                turnPoints = getConcaveTurn(a, b, c, turningRadius, turningSpeed, workingSpeed, workingWidth, "on", fullConcaveTurns, circuitTurnsMinimumAngle);
    
            }
            else{ // add convex turn

                turnPoints = getConvexTurn(a, b, c, turningRadius, turningSpeed, workingSpeed, "on", circuitTurnsMinimumAngle);
                
            }

            route.push(... turnPoints);
  
        }

        

        // add last point
        route.push({ ...pol[pol.length-1], speed: workingSpeed, w:"on"} );

        return route;
    
    }

    function polylineToRoute(pol) {

        // generates a route with simple turns according to turningRadius

        // this version does not include the last point of pol in the result

        var route = [];

        route.push( {...pol[0], speed: workingSpeed, w: "off"} );

        for (var i = 0; i < pol.length - 2; i++) {

            var a = pol[i];
            var b = pol[i + 1];
            var c = pol[i + 2];

            // rotate points a-b-c so that ab is vertical
            var rotationAngle = Math.atan2(b.x - a.x, b.y - a.y);
            var rotatedPoints = rotatePolygon([a, b, c], b, rotationAngle);


            // translate the points so that b is [0,0]

            var rot_c = { x: rotatedPoints[2].x - rotatedPoints[1].x, y: rotatedPoints[2].y - rotatedPoints[1].y };

            // if turn left, make an horizontal flip before compute

            var turnLeft = (rot_c.x < 0 ? true : false);

            if (turnLeft) {
                rot_c.x = -rot_c.x;
            }

            var points = [];


            var alpha = Math.atan(rot_c.y / rot_c.x);

            var beta = (Math.PI / 2 - alpha) / 2;


            var C = { x: turningRadius, y: -turningRadius * Math.tan(beta) }; // center

            var stepDegrees = 15;

            var arcLen = 2 * beta;

            // add arc
            var steps = Math.round(arcLen / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (var s = 0; s <= steps; s++) {
                var arc = s * arcLen / steps;
                points.push({ x: C.x - turningRadius * Math.cos(arc), y: C.y + turningRadius * Math.sin(arc), speed: turningSpeed, w: "off" });
            }

            if (turnLeft) { // horizontal flip back
                for (var p = 0; p < points.length; p++) {
                    points[p].x = -points[p].x;
                }
            }


            // rotate back points
            var rotatedTurn = rotatePolygon(points, { x: 0, y: 0 }, -rotationAngle);

            for (var p = 0; p < rotatedTurn.length; p++) {
                // translate back and add to route
                route.push({ ...rotatedTurn[p],  x: rotatedTurn[p].x + rotatedPoints[1].x, y: rotatedTurn[p].y + rotatedPoints[1].y })
            }

        }

        return route;
    }


    function laneChange(a, b, reverseFirst){

        // a: origin point
        // b: next point in original lane
        // The function computes the lane change to left (lane width = working width)
    
        var points = [];
    
        var stepDegrees = 15;
        var shiftY = 0;
        var rotationAngle = Math.atan2(b.x-a.x, b.y-a.y);
        var firstPt = 1;

        if (turningRadius<=workingWidth/2){
          
          var arcLen = Math.PI/2;
          var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));

          if (reverseFirst){
            // add reverse point - not needed?
            //points.push( { x: 0, y: -2*turningRadius } );
            shiftY = -2*turningRadius;
            firstPt = 0;
          }
          
          var C1 = {x: -turningRadius, y:0};
          for (var s=firstPt; s<= steps; s++ ){
            var arc = s * arcLen/steps;
            points.push( { x: C1.x + turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
          var C2 = {x: -workingWidth+turningRadius, y:2*turningRadius};
          for (var s=0; s<= steps; s++ ){
            var arc = s * arcLen/steps;
            points.push( { x: C2.x - turningRadius*Math.sin(arc), y: C2.y - turningRadius*Math.cos(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
          //if (!reverseFirst){ 
            // add reverse point // not needed?
            //points.push( { x: -workingWidth, y: 0 } );
          //}
        }

        else{
          
          var h = Math.sqrt(turningRadius**2 - (turningRadius-workingWidth/2)**2);

          if (reverseFirst){
            // add reverse point - not needed?
            //points.push( { x: 0, y: -2*turningRadius } );
            shiftY = -2*turningRadius;
            firstPt = 0;
          }

          var arcLen = Math.acos((turningRadius-workingWidth/2)/turningRadius);
          var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));
          
          var C1 = {x: -turningRadius, y:0};
          for (var s=firstPt; s<= steps; s++ ){
            var arc = s * arcLen/steps;
            points.push( { x: C1.x + turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
         
          var C2 = {x: -workingWidth+turningRadius, y:2*h};
          for (var s=1; s<= steps; s++ ){
            var arc = (Math.PI/2 - arcLen) + s * arcLen/steps;
            points.push( { x: C2.x - turningRadius*Math.sin(arc), y: C2.y - turningRadius*Math.cos(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
          if (!reverseFirst){
            // add reverse point
            points.push( { x: -workingWidth, y: 0, speed: workingSpeed, w: "on" } );
          }

        }

        // rotate back points
        var rotatedTurn = rotatePolygon(points, {x:0,y:0}, -rotationAngle);

        var path = [];
        for (var p=0;p<rotatedTurn.length;p++){
            // translate back and add to route
            path.push({...rotatedTurn[p], x: rotatedTurn[p].x+a.x, y: rotatedTurn[p].y+a.y})
        }

        // correct speed to reverse and last points
        if (reverseFirst){
            // reverse speed and working=off is applied to object point 'a', passed as a parameter to the function.
            // Changing a member of the object modifies the object passed (https://stackoverflow.com/a/5314911)
            a.speed = -turningSpeed; 
            a.w = "off";
        }
        else{
            path[path.length-2].speed = -turningSpeed;
            path[path.length-2].w = "off";
        }
        path[path.length-1].speed = workingSpeed;
        path[path.length-1].w = "on";

        return path;
    }

    function getStartingPoint(patternStartOption){

        // Measure the distance from the entrance to each possible starting point of the route.
        // Depending on whether the number of swaths is odd or even, the end of the path can be in swath N or swath N-1 (SFP pattern)
        // So the 4 possible starting points can be different if the route goes from left to right or vice versa.
        // Both scenarios are evaluated, therefore two sets of 4 points are analyzed to find the one closest to the entrance.
        // The distance must be measured following the contour of the polygon

        var startingPtsCandidates = [];
        var intersectionPoints;
        var endIntersectionPoints;

        for (var i=0; i<2; i++){  // i=0 -> q left to right; i=1 -> q right to left

            var seq = q.slice();
            if (i==1){
                seq = q.map(function(x) {
                    return (q.length-x) + 1;
                 });
            }

            // compute 

            var xStartSwath = xMin - xOffset + 0.5*workingWidth + (seq[0]-1)*workingWidth;

            intersectionPoints = intersectLinePolygon( {x: xStartSwath, y:0}, {x: xStartSwath, y: 10000000}, rotatedMainlandUtm );

            if (intersectionPoints.length==0){
                if (i==0){  // The start swath is on the left side (q sequence left to right)

                    // compute y coordinate of up and down points by intersecting with line left extreme + working width
                    intersectionPoints = intersectLinePolygon( {x: xMin+workingWidth, y:0}, {x: xMin+workingWidth, y: 10000000}, rotatedMainlandUtm );
                }
                else{ // The start swath is on the right side

                    // compute y coordinate of up and down points by intersecting with line right extreme - working width
                    intersectionPoints = intersectLinePolygon( {x: xMax-workingWidth, y:0}, {x: xMax-workingWidth, y: 10000000}, rotatedMainlandUtm );
                }
                // restore x value of intersection
                intersectionPoints[0].x = xStartSwath;
                intersectionPoints[1].x = xStartSwath;
            }

            // add opposite point in swath. Useful to compute raytracing with some contour headlands later
            intersectionPoints[0].oppositePoint = {x:intersectionPoints[1].x, y: intersectionPoints[1].y};
            intersectionPoints[1].oppositePoint = {x:intersectionPoints[0].x, y: intersectionPoints[0].y};

            if (i==0){
                intersectionPoints[0].side = "left";
                intersectionPoints[1].side = "left";
            }
            else{
                intersectionPoints[0].side = "right";
                intersectionPoints[1].side = "right";
            }
            
            if (intersectionPoints[0].y > intersectionPoints[1].y){
                intersectionPoints[0].vertical = "top"; 
                intersectionPoints[1].vertical = "bottom";
            }
            else {
                intersectionPoints[0].vertical = "bottom"; 
                intersectionPoints[1].vertical = "top";
            }

            // add distance to entrance property
            // measure distance for each starting point candidate
            // ideally the distance must be measured following the contour of the polygon or a contour headland

            intersectionPoints[0].distanceToEntrance = Math.sqrt((intersectionPoints[0].x-rotatedUtmEntrance.x)**2 + (intersectionPoints[0].y-rotatedUtmEntrance.y)**2);
            intersectionPoints[1].distanceToEntrance = Math.sqrt((intersectionPoints[1].x-rotatedUtmEntrance.x)**2 + (intersectionPoints[1].y-rotatedUtmEntrance.y)**2);

            // add End Point data

            var xEndSwath = xMin - xOffset + 0.5*workingWidth + (seq[seq.length-1]-1)*workingWidth;

            endIntersectionPoints = intersectLinePolygon( {x: xEndSwath, y:0}, {x: xEndSwath, y: 10000000}, rotatedMainlandUtm );

            if (endIntersectionPoints.length==0){
                if (i==0){  // The start swath is on the left side (q sequence left to right)

                    // compute y coordinate of up and down points by intersecting with line right extreme + working width
                    endIntersectionPoints = intersectLinePolygon( {x: xMax-workingWidth, y:0}, {x: xMax-workingWidth, y: 10000000}, rotatedMainlandUtm );
                }
                else{ // The start swath is on the right side

                    // compute y coordinate of up and down points by intersecting with line left extreme - working width
                    endIntersectionPoints = intersectLinePolygon( {x: xMin+workingWidth, y:0}, {x: xMin+workingWidth, y: 10000000}, rotatedMainlandUtm );
                }
                // restore x value of intersection
                endIntersectionPoints[0].x = xEndSwath;
                endIntersectionPoints[1].x = xEndSwath;
            }

            // set side to end point
            if (i==0){  // The start swath is on the left side (q sequence left to right)
                endIntersectionPoints[0].side = "right";
                endIntersectionPoints[1].side = "right";
            }
            else{ // The start swath is on the right side
                endIntersectionPoints[0].side = "left";
                endIntersectionPoints[1].side = "left";
            }


            if (intersectionPoints[0].y > intersectionPoints[1].y && q.length % 2 == 0  // pt [0] on top && even number of swaths
                || intersectionPoints[0].y < intersectionPoints[1].y && q.length % 2 == 1 // OR pt [0] on bottom && odd number of swaths
            ) {
                // end on inverse (top -> bottom, or bottom -> top)
                intersectionPoints[0].endPoint = endIntersectionPoints.reduce((prev, current) => (prev.y > current.y) ? prev : current);
                intersectionPoints[1].endPoint = endIntersectionPoints.reduce((prev, current) => (prev.y < current.y) ? prev : current);
                intersectionPoints[0].endPoint.vertical = "top";
                intersectionPoints[1].endPoint.vertical = "bottom";

            }
            else {
                // end on same extreme (top -> top, or bottom -> bottom)
                intersectionPoints[0].endPoint = endIntersectionPoints.reduce((prev, current) => (prev.y < current.y) ? prev : current);
                intersectionPoints[1].endPoint = endIntersectionPoints.reduce((prev, current) => (prev.y > current.y) ? prev : current);
                intersectionPoints[0].endPoint.vertical = "bottom";
                intersectionPoints[1].endPoint.vertical = "top";
            }

            intersectionPoints[0].endPoint.distanceToEntrance = Math.sqrt((intersectionPoints[0].endPoint.x-rotatedUtmEntrance.x)**2 + (intersectionPoints[0].endPoint.y-rotatedUtmEntrance.y)**2);
            intersectionPoints[1].endPoint.distanceToEntrance = Math.sqrt((intersectionPoints[1].endPoint.x-rotatedUtmEntrance.x)**2 + (intersectionPoints[1].endPoint.y-rotatedUtmEntrance.y)**2);

            startingPtsCandidates.push(intersectionPoints[0]);
            startingPtsCandidates.push(intersectionPoints[1]);

        }

        var closestStaringPoint;
        if (patternStartOption == "StartNearEntrance"){
            closestStaringPoint = startingPtsCandidates.reduce((min, pt) => pt.distanceToEntrance < min.distanceToEntrance ? pt : min);
        }
        else{
            closestStaringPoint = startingPtsCandidates.reduce((min, pt) => pt.endPoint.distanceToEntrance < min.endPoint.distanceToEntrance ? pt : min);
        }

        return closestStaringPoint;
    }


}

function entranceToStartPolyline(entrance, start, swathEnd, pol, orientation, turningRadius){

    var polyline = [];

    polyline.push(entrance);

    // find closest point to entrance on polygon
    var aa = closestPointInPolygon2(entrance, pol);
    polyline.push(aa.point);

    var entranceSegmentNumber = aa.segmentNumber;

    var rotatedPts = arrayRotate(pol.slice(0), entranceSegmentNumber);


    // raytrace the swath to find the intersection with the pol

    var startSegmentNumber;  // for ex. p=3 means the segments is p3->p4 
    var inters;
    for (var p = 0; p < rotatedPts.length; p++) {
        var b1 = rotatedPts[p];
        var b2 = rotatedPts[p == rotatedPts.length - 1 ? 0 : p + 1];
        inters = intersectLineSegment(swathEnd, start, b1, b2);
        if (inters.length > 0) { // length must be 1 
            startSegmentNumber = p;
            break;
        }
    }

    // if both entrance and start point are on the same segment, don't compute intermediate points
    // It doesn't matter if it is cw or ccw. In this function obviously the path is direct (not around all the polygon)

    var intermediatePts = [];

    if (startSegmentNumber != 0) {

        if (orientation == 'ccw') {
            rotatedPts = arrayRotate(rotatedPts.slice(0), 2);
            rotatedPts.reverse();
            startSegmentNumber = pol.length - startSegmentNumber;
        }

        for (var i = 0; i < startSegmentNumber; i++) {
            intermediatePts.push(rotatedPts[i + 1]);
        }
    }

    // apply Douglas-Peucker simplification to intermediate points

    if (intermediatePts.length>1){
        var geoJsonLineString = polylineToGeojsonLineString(intermediatePts);
        intermediatePts = simplifyRoute(geoJsonLineString, turningRadius);
    }

    /*
    if (polyline[polyline.length-1].x == intermediatePts[0].x && polyline[polyline.length-1].y == intermediatePts[0].y)  {
        polyline.pop();
    }
    */

    if (intermediatePts.length>0){
        var dist = Math.sqrt( (polyline[polyline.length-1].x - intermediatePts[0].x)**2 + (polyline[polyline.length-1].y - intermediatePts[0].y)**2 );
        if (dist<2*turningRadius){
            polyline.pop();
        }
    }

    polyline.push(... intermediatePts);

    // add swath/polygon intersection point to polyline
    // (only if the distance with the previous point is > 2*turningRadius)

    
    var dist = Math.sqrt( (inters[0].x - polyline[polyline.length-1].x)**2 + (inters[0].y - polyline[polyline.length-1].y)**2 );

    if (dist>2*turningRadius){
        polyline.push(inters[0]);
    }

    polyline.push(start); 

    return polyline;

}

function SpiralToEntrancePolyline(lastSpiralPoints, entrance, pol, orientation, turningRadius){

    var polyline = [];
    
    polyline.push(lastSpiralPoints[1]);

    // end of covered area. Calculate the point in front of the vehicle
    // at a distance of one turning radius to calculate the perpendicular path

    var pt = pointFromEndOfLine(lastSpiralPoints[0], lastSpiralPoints[1], turningRadius);
    
    polyline.push(pt);

    // find closest point to last point on polygon
    var aa = closestPointInPolygon2(pt, pol);
    
    polyline.push(aa.point);
    
    var lastPointSegmentNumber = aa.segmentNumber;
    
    var rotatedPts = arrayRotate(pol.slice(0), lastPointSegmentNumber );
  
  
    // find closest point to entrance on polygon
    var bb = closestPointInPolygon2(entrance, rotatedPts);
    
    var entranceSegmentNumber = bb.segmentNumber;
    // if both lastpoint and entrance are on the same segment, don't compute intermediate points
    // It doesn't matter if it is cw or ccw. In this function obviously the path is direct (not around all the polygon)
    
    var intermediatePts = [];

    if (entranceSegmentNumber!=0){
    
      if (orientation == 'ccw'){
        rotatedPts = arrayRotate(rotatedPts.slice(0), 2);
        rotatedPts.reverse();
        entranceSegmentNumber = pol.length-entranceSegmentNumber;
      }
  
      for (var i=0; i<entranceSegmentNumber; i++){
        intermediatePts.push(rotatedPts[i+1]);
      }
    }

    // apply Douglas-Peucker simplification to intermediate points

    if (intermediatePts.length>1){
        var geoJsonLineString = polylineToGeojsonLineString(intermediatePts);
        intermediatePts = simplifyRoute(geoJsonLineString, turningRadius);
    }

    polyline.push(... intermediatePts);
    
    // add entrance intersection point to polyline
    polyline.push(bb.point);
    
    polyline.push(entrance);

    removeNearPoints(polyline,0.001); // remove duplicate consecutive points that may have been generated

    return polyline;

}

function arrayRotate(arr, count) {
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));
    return arr;
}

function graficar1(pol){
    var str="";
    for (var j=0; j<pol.length; j++){

        str +=  pol[j].x + ',' + pol[j].y + '\n';

    }
    str +=  pol[0].x + ',' + pol[0].y + '\n';
    str += '\n';
    return str;
}


function getSwathWorkingModeLimits(xSwath, workingWidth, poly){

    // Function that determines in which points of the swath
    // the implement changes the working mode on <=> off before and after the turn
    // See figure "implement_entry_exit_points.odg"

    var limitCandidates = [];

    // intersect left limit of the swath with the polygon
    var intersectionPointsAtLeft = intersectLinePolygon( {x: xSwath-workingWidth/2, y:0}, {x: xSwath-workingWidth/2, y: 10000000}, poly );
        
    //The intersection may not exist if the remainder is less than workingwidth / 2 (see swahts.odg)
    //This can happen on the first or last swath, depending on which side the adjacent swath is placed.
    for (var i=0; i<intersectionPointsAtLeft.length; i++){
        limitCandidates.push(intersectionPointsAtLeft[i].y); // only the y limit value matters
    }

    // intersect right limit of the swath with the polygon
    var intersectionPointsAtRight = intersectLinePolygon( {x: xSwath+workingWidth/2, y:0}, {x: xSwath+workingWidth/2, y: 10000000}, poly );
    
    //The intersection may not exist if the remainder is less than workingwidth / 2 (see swahts.odg)
    //This can happen on the first or last swath, depending on which side the adjacent swath is placed.
    for (var i=0; i<intersectionPointsAtRight.length; i++){
        limitCandidates.push(intersectionPointsAtRight[i].y); // only the y limit value matters
    }

    // Add the vertices of the polygon that fall within the infinite swath
    for (var p=0; p<poly.length; p++){
        if (poly[p].x > xSwath-workingWidth/2 && poly[p].x < xSwath+workingWidth/2){
            limitCandidates.push(poly[p].y);
        }
    }

    // Get also the number of segment that the center of the swath intersects up and down
    var intersectionPointsAtCenter = intersectLinePolygon( {x: xSwath, y:0}, {x: xSwath, y: 10000000}, poly );
    if (intersectionPointsAtCenter.length == 0){
        if (intersectionPointsAtLeft.length>0){
            intersectionPointsAtCenter = intersectionPointsAtLeft;
        }
        else{
            intersectionPointsAtCenter = intersectionPointsAtRight;
        }
    }

    var upperSegment, lowerSegment, upperAngle, lowerAngle;
    if (intersectionPointsAtCenter[0].y>intersectionPointsAtCenter[1].y){
        upperSegment = intersectionPointsAtCenter[0].segmentN;
        upperAngle = intersectionPointsAtCenter[0].angle;
        lowerSegment = intersectionPointsAtCenter[1].segmentN;
        lowerAngle = intersectionPointsAtCenter[1].angle;
    }
    else{
        upperSegment = intersectionPointsAtCenter[1].segmentN;
        upperAngle = intersectionPointsAtCenter[1].angle;
        lowerSegment = intersectionPointsAtCenter[0].segmentN;
        lowerAngle = intersectionPointsAtCenter[0].angle;
    }

    return { x: xSwath, upperLimit: Math.max(...limitCandidates), lowerLimit: Math.min(...limitCandidates),
        upperSegment: upperSegment, lowerSegment: lowerSegment, upperAngle: upperAngle, lowerAngle: lowerAngle };

}

function getAutoTurningPoints2(firstTurningPoint, secondTurningPoint, r, workingWidth, alignment, direction, rotatedMainlandUtm, polygonField, workingSpeed, turningSpeed){

    // This function calculates the space available to make the turn.
    // Measures the distance from point O to the field boundary.
    // See figure "headland_space_calculation.odg"


    // Determine which of the two points will be used as origin.
    // If the turn is up, the highest. If it is down, the lowest.
    var origin, destination, alignmentOffset, directionUp;
    if (direction=="up"){
        if (firstTurningPoint.y>secondTurningPoint.y){
            origin = firstTurningPoint;
            destination = secondTurningPoint;
        }
        else{
            origin = secondTurningPoint;
            destination = firstTurningPoint;
        }
        alignmentOffset = alignment;
        directionUp = 1;
    }
    else{ // direction = "down"
        if (firstTurningPoint.y<secondTurningPoint.y){
            origin = firstTurningPoint;
            destination =secondTurningPoint;
        }
        else{
            origin = secondTurningPoint;
            destination = firstTurningPoint;
        }
        alignmentOffset = -alignment;
        directionUp = -1;
    }


    // compute point O in direction to destination
    // Este valor de O sirve para todos los turns excepto 'bulb'
    var O = { x: origin.x + r*Math.sign(destination.x-origin.x), y: origin.y };
    
    // compute distance d from point O + alignment to limit of boundary
    // the space condition is  d > r+w/2
    var d = Math.sqrt(distToPolygonSquared({x:O.x, y:O.y + alignmentOffset}, polygonField));

    // compute values for 'bulb'
    var A = {x: origin.x - r*Math.sign(destination.x-origin.x), y: origin.y};
    var B = {x: destination.x + r*Math.sign(destination.x-origin.x), y: destination.y};

    // determine point O_bulb equidistant of A and B at a distance = 2*r

    var M = {x:(A.x + B.x)/2, y: (A.y + B.y)/2}; // punto medio entre A y B
    var angleAB = Math.atan(Math.abs((B.y-A.y)/(B.x-A.x))); // angle A-B
    var distAB = Math.sqrt((B.x-A.x)*(B.x-A.x) + (B.y-A.y)*(B.y-A.y));

    var O_bulb;
    var d_bulb = 0;

    if ( (2*r)*(2*r) - (distAB/2)*(distAB/2) > 0 ){
        var lMO = Math.sqrt((2*r)*(2*r) - (distAB/2)*(distAB/2));
        O_bulb = {x: M.x + lMO*Math.sin(angleAB)*Math.sign(destination.x-origin.x), y: M.y + lMO*Math.cos(angleAB)*directionUp};
        d_bulb = Math.sqrt(distToPolygonSquared({x:O_bulb.x, y:O_bulb.y + alignmentOffset}, polygonField));
    }

    var type;
    var dx = Math.abs(destination.x-origin.x);
    var theta = Math.abs(Math.atan((destination.x-origin.x)/(destination.y-origin.y)));

    // le agrego || turningRadius < deltaX/2 porque cuando r < w/2 no puedo usar fishtail tal como está programado
    if (d_bulb > r+workingWidth/2 || r < dx/2 ){  // enough space to make any turn (even bulb)
        if (r == dx/2)
            type = "u turn"
        else if ( r < dx/2)
            type = (firstTurningPoint.segment == secondTurningPoint.segment) ? "flat turn" : "copy headland"; // "flat turn"
            //type = (firstTurningPoint.segment == secondTurningPoint.segment) ? "flat turn" : "flat turn"; // "flat turn"

        else if ( r > dx/2){
            if ((2*r)*(2*r) - (distAB/2)*(distAB/2) > 0 && (O_bulb.y-A.y)*directionUp > 0  ) { // conditions required for bulb. Second one: angle alpha>0
                type = "bulb";
            }
            else {
                type = "hook";
            }
        }
    }
    else{ // no headland space to turn

        // el fishtail implementado dispara el valor de l cuando el ángulo es muy pronunciado
        // Tambien podria reemplazarse por otro tipo de fishtail: "circle-back turning"
        // Hay que revisar la condición para hacerla más rigurosa chequeando el espacio requerido en cada caso
        if (r < dx / (2 * Math.sin(theta)) && (4*r - 2*dx)>0 ){ // condicion para poder hacer el hook
            type = "hook";
        }
        else{
            type = "fishtail";
        }
    }

    var stepDegrees = 15;
    //var turningSpeed = 2;
    //var workingSpeed = 12;
    var points = [];


    switch (type) {

        case "bulb":

            var alpha = Math.atan(Math.abs((O_bulb.y - A.y) / (O_bulb.x - A.x)));

            var beta = Math.atan(Math.abs((O_bulb.y - B.y) / (B.x - O_bulb.x)));

            // add alpha arc
            var steps = Math.round(alpha / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (var s = 0; s <= steps; s++) {
                var arc = s * (alpha / steps);
                points.push({ x: A.x + r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: A.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add gamma arc
            var gammaFrom = -alpha;
            var gammaTo = Math.PI + beta;
            steps = Math.round((gammaTo - gammaFrom) / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);

            for (s = 0; s < steps; s++) {
                arc = gammaFrom + (s + 1) * ((gammaTo - gammaFrom) / steps);
                points.push({ x: O_bulb.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: O_bulb.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add beta arc
            steps = Math.round(beta / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (s = 0; s < steps; s++) {
                var arc = beta - (s + 1) * (beta / steps);
                points.push({ x: B.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: B.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            break;

        case "hook":

            var r2 = ((dx*dx) * (1 / Math.tan(theta)) * (1 / Math.tan(theta)) + dx*dx - 2*dx*r) / (4*r - 2*dx);

            var B_hook = {x: destination.x + r2*Math.sign(destination.x-origin.x), y: destination.y};

            var beta = Math.atan(Math.abs((O.y - B_hook.y) / (B_hook.x - O.x)));
            
            var alpha = Math.PI + beta;

            // add alpha arc
            var steps = Math.round(alpha / (stepDegrees * Math.PI / 180));
            for (var s = 0; s <= steps; s++) {
                var arc = s * (alpha / steps);
                points.push({ x: O.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: O.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add beta arc
            steps = Math.round(beta / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (s = 0; s < steps; s++) {
                var arc = beta - (s + 1) * (beta / steps);
                points.push({ x: B_hook.x - r2 * Math.cos(arc) * Math.sign(destination.x - origin.x), y: B_hook.y + r2 * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            break;

        case "u turn":

            // add arc (with center at previously calculated point O)
            var steps = Math.round(180/stepDegrees);
            for (var s=0; s<= steps; s++ ){
                var arc = s * (Math.PI/steps);
                points.push({ x: O.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: O.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add extra point where vehicle re-enters field to change work to "on"
            // points.push( { x: destination.x, y: destination.y, speed: workingSpeed} );

            break;

        case "copy headland":

            // compute offset for polygon buffering

            var offsetFirstPoint, offsetSecondPoint;

            if (direction == "up"){

                if ( firstTurningPoint.x<secondTurningPoint.x){ // left --> right
                    if (firstTurningPoint.angle>0){
                        offsetFirstPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }
                    else{
                        offsetFirstPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }

                    if (secondTurningPoint.angle>0){
                        offsetSecondPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                    else{
                        offsetSecondPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                }
                else{  // left <-- right
                    if (firstTurningPoint.angle>0){
                        offsetFirstPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }
                    else{
                        offsetFirstPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }

                    if (secondTurningPoint.angle>0){
                        offsetSecondPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                    else{
                        offsetSecondPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                }
                
            }
            else{ // direction = "down"

                if ( firstTurningPoint.x<secondTurningPoint.x){ // left --> right
                    if (firstTurningPoint.angle>0){
                        offsetFirstPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }
                    else{
                        offsetFirstPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }

                    if (secondTurningPoint.angle>0){
                        offsetSecondPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                    else{
                        offsetSecondPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                }
                else{  // left <-- right
                    if (firstTurningPoint.angle>0){
                        offsetFirstPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }
                    else{
                        offsetFirstPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(firstTurningPoint.angle));
                    }

                    if (secondTurningPoint.angle>0){
                        offsetSecondPoint = r + (workingWidth/2 - r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                    else{
                        offsetSecondPoint = r + (workingWidth/2 + r)*Math.sin(Math.abs(secondTurningPoint.angle));
                    }
                }
            }

            var polygonOffset = Math.max(offsetFirstPoint,offsetSecondPoint, 2*r);

            var ringGeoJson = ringToGeojsonPolygon(rotatedMainlandUtm);

            var bufferedMainland = polygonBuffer(ringGeoJson, -polygonOffset);

            //var xx = graficar1(rotatedMainlandUtm);
            //var xxx = graficar1(bufferedMainland);

            // intersect first swath center with buffered polygon
            var inter1, inter2;
            if (direction == "up"){
                inter1 = intersectLinePolygon( firstTurningPoint, { x: firstTurningPoint.x, y: 10000000 }, bufferedMainland );
                inter2 = intersectLinePolygon( secondTurningPoint, {x: secondTurningPoint.x, y: 10000000 }, bufferedMainland );
            }
            else{
                inter1 = intersectLinePolygon( firstTurningPoint, {x: firstTurningPoint.x, y: 0 }, bufferedMainland );
                inter2 = intersectLinePolygon( secondTurningPoint, {x: secondTurningPoint.x, y: 0 }, bufferedMainland );
            }


            if (!inter1[0] || !inter2[0]){
                console.log("error!");
            }

            var segmentInit = inter1[0].segmentN;
            var segmentEnd = inter2[0].segmentN;

            var pts = [];
            pts.push(firstTurningPoint);
            pts.push({x: inter1[0].x, y: inter1[0].y});

            // field polygon are normalized (cw).
            // check if turn is cw or ccw

            var orientation = (direction == "up" && firstTurningPoint.x<secondTurningPoint.x || 
                direction == "down" && firstTurningPoint.x>secondTurningPoint.x) ? "cw" : "ccw";

            if (orientation == "cw"){
                if (segmentEnd<segmentInit){
                    segmentEnd += bufferedMainland.length;
                }
            }
            else{ // ccw
                if (segmentEnd>segmentInit){
                    segmentEnd -= bufferedMainland.length;
                }
            }

            if (segmentEnd>=segmentInit){
                for (var i=segmentInit+1; i<=segmentEnd; i++){
                    pts.push(bufferedMainland[i % bufferedMainland.length]);
                }
            }
            else{
                for (var i=segmentInit; i>segmentEnd; i--){
                    pts.push(bufferedMainland[(i + bufferedMainland.length) % bufferedMainland.length]); // add lenght just in case i<0
                }
            }

            pts.push({x: inter2[0].x, y: inter2[0].y});
            pts.push(secondTurningPoint);
            var geoJsonLineString = polylineToGeojsonLineString(pts);
            var pts2 = simplifyRoute(geoJsonLineString, r);
            points = polylineToRoute(pts2, workingSpeed, turningSpeed, r);

            //var bb = graficar1(aa);

            break;
        
        case "flat turn":

            // original copy headland (on unique segment)

            var C1 = { x: origin.x + r*Math.sign(destination.x-origin.x), y: origin.y }; 
            var C2 = { x: destination.x - r*Math.sign(destination.x-origin.x), y: C1.y - (dx-2*r)/Math.tan(theta) * directionUp }; 
            
            // add first arc
            steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
            for (s=0; s<= steps; s++ ){
                var arc = s * (Math.PI-theta)/steps;
                points.push( { x: C1.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C1.y + r*Math.sin(arc) * directionUp, speed: turningSpeed} );
            }

            // add second arc

            steps = Math.round(theta/(stepDegrees*Math.PI/180));
            steps = Math.max(steps,1);
            for (var s=0; s<= steps; s++ ){
                var arc = Math.PI-theta + s * (theta/steps);
                points.push( { x: C2.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C2.y + r*Math.sin(arc) * directionUp, speed: turningSpeed} );
            }

            // add extra point where vehicle re-enters field to change work to "on"
            points.push( { x: destination.x, y: destination.y, speed: workingSpeed} );


            break;

        case "fishtail":

            var C1 = O; // first center
            var C3 = {x: C1.x + r*Math.cos(theta)*Math.sign(destination.x-origin.x), y: C1.y + r*Math.sin(theta)* directionUp};
          //var C4 = {x: destination.x-r*Math.sign(destination.x-origin.x), y: (Math.abs(C3.x - destination.x)+r)/Math.tan(theta)* directionUp  + C3.y };
            var C4 = {x: destination.x-r*Math.sign(destination.x-origin.x), y: Math.abs(C3.x - (destination.x-r*Math.sign(destination.x-origin.x)))/Math.tan(theta)* directionUp  + C3.y };

            var l = r/Math.cos(Math.PI/2-theta);
            var C2 = {x: destination.x-r*Math.sign(destination.x-origin.x), y: C4.y-l*directionUp};

            // add first arc
            var steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
            for (var s=0; s<= steps; s++ ){
                var arc = s * ((Math.PI-theta)/steps);
                points.push( { x: C1.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C1.y + r*Math.sin(arc)* directionUp, speed: turningSpeed} );
            }
            // change speed of last point to reverse
            points[points.length-1].speed = -turningSpeed; 

            // add second arc
            var gammaFrom = Math.PI-theta;
            var gammaTo = Math.PI;
            steps = Math.round((gammaTo-gammaFrom)/(stepDegrees*Math.PI/180));
            steps = Math.max(steps,1);
            for (var s=0; s<= steps; s++ ){
                var arc = gammaFrom + s * ((gammaTo-gammaFrom)/steps);
                points.push( { x: C2.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C2.y + r*Math.sin(arc)* directionUp, speed: turningSpeed} );
            }

            // add extra point where vehicle re-enters field to change work to "on" (TODO: review y value)
            points.push( { x: destination.x, y: destination.y, speed: workingSpeed} );

            break;

    }

    // add alignment zone: Insert two points to generate the alignment area.
    // One after the first and one before the last.
    // Shift the points between these two. (see alignment_zone.png).

    if (alignmentOffset != 0) {
        for (var p = 1; p < points.length - 1; p++) {
            points[p].y = points[p].y + alignmentOffset;
        }
        points.splice(1, 0, { x: points[0].x, y: points[0].y + alignmentOffset, speed: turningSpeed });
        points.splice(points.length - 1, 0, { x: points[points.length - 1].x, y: points[points.length - 1].y + alignmentOffset, speed: turningSpeed });
    }

    // the order of the turn points must be reversed when firstTurningPoint = destination point
    if ( firstTurningPoint.x == destination.x && type !="copy headland" ){
        points.reverse();
    }

    // Add work property
    for (var p = 0; p < points.length - 1; p++) {
        points[p].w = "off"
    }

    if (points.length > 0) {
        points[points.length - 1].w = "on";
        points[points.length - 1].speed = workingSpeed;
    }

    return {d:d,d_bulb:d_bulb,pts:points};
}

function polylineToRoute(pol, workingSpeed, turningSpeed, turningRadius) {

    // generates a route with simple turns according to turningRadius

    // this version includes first and last points of pol in the result

    var route = [];

    route.push( {...pol[0], speed: workingSpeed, w: "off"} );

    for (var i = 0; i < pol.length - 2; i++) {

        var a = pol[i];
        var b = pol[i + 1];
        var c = pol[i + 2];

        // rotate points a-b-c so that ab is vertical
        var rotationAngle = Math.atan2(b.x - a.x, b.y - a.y);
        var rotatedPoints = rotatePolygon([a, b, c], b, rotationAngle);


        // translate the points so that b is [0,0]

        var rot_c = { x: rotatedPoints[2].x - rotatedPoints[1].x, y: rotatedPoints[2].y - rotatedPoints[1].y };

        // if turn left, make an horizontal flip before compute

        var turnLeft = (rot_c.x < 0 ? true : false);

        if (turnLeft) {
            rot_c.x = -rot_c.x;
        }

        var points = [];


        var alpha = Math.atan(rot_c.y / rot_c.x);

        var beta = (Math.PI / 2 - alpha) / 2;


        var C = { x: turningRadius, y: -turningRadius * Math.tan(beta) }; // center

        var stepDegrees = 15;

        var arcLen = 2 * beta;

        // add arc
        var steps = Math.round(arcLen / (stepDegrees * Math.PI / 180));
        steps = Math.max(steps, 1);
        for (var s = 0; s <= steps; s++) {
            var arc = s * arcLen / steps;
            points.push({ x: C.x - turningRadius * Math.cos(arc), y: C.y + turningRadius * Math.sin(arc), speed: turningSpeed, w: "off" });
        }

        if (turnLeft) { // horizontal flip back
            for (var p = 0; p < points.length; p++) {
                points[p].x = -points[p].x;
            }
        }


        // rotate back points
        var rotatedTurn = rotatePolygon(points, { x: 0, y: 0 }, -rotationAngle);

        for (var p = 0; p < rotatedTurn.length; p++) {
            // translate back and add to route
            route.push({ ...rotatedTurn[p],  x: rotatedTurn[p].x + rotatedPoints[1].x, y: rotatedTurn[p].y + rotatedPoints[1].y })
        }

    }

    route.push( {...pol[pol.length-1], speed: workingSpeed, w: "off"} );

    return route;
}

function isTurnLeft(a, b, c){
    // given three points A->B->C, returns TRUE if point C is on the LEFT of AB
    return (b.x-a.x)*(c.y-a.y)-(b.y-a.y)*(c.x-a.x) > 0
}

function getConvexTurn(a, b, c, turningRadius, turningSpeed, workingSpeed, workingMode, minimumAngle) {

    // rotate points a-b-c so that ab is vertical
    var rotationAngle = Math.atan2(b.x-a.x, b.y-a.y); 
    var rotatedPoints = rotatePolygon([a,b,c], b, rotationAngle);


    // translate the points so that b is [0,0]
    // the geometric algorithm was developed for a turn to the left. In case it is to the right,
    // just use the Math.abs value and make a mirror reflection of the x values later
    var rot_c = {x: -Math.abs(rotatedPoints[2].x-rotatedPoints[1].x), y: rotatedPoints[2].y-rotatedPoints[1].y};


    var points = [];

    var alpha = Math.atan(-rot_c.y/rot_c.x);

    if (alpha>Math.PI*(90-minimumAngle)/180){
        return [{x:b.x, y:b.y, speed:workingSpeed, w: "on"}];
    }
/*
    if (alpha<-Math.PI*89/180){
        return [];
    }
*/
    var beta = (Math.PI/2-alpha)/2;
    var C = {x:turningRadius, y: turningRadius*Math.tan(beta)}; // center
    var stepDegrees = 15;
    var arcLen = 2*beta;

    // add arc
    var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));
    steps = Math.max(steps,1);
    for (var s=0; s<= steps; s++ ){
        var arc = s * arcLen/steps;
        // last point of arc in working speed, rest in reverse
        points.push( { x: C.x - turningRadius*Math.cos(arc), y: C.y - turningRadius*Math.sin(arc), speed: s==steps ? workingSpeed : -turningSpeed, w: s==steps ? workingMode : "off" } );
    }  

    if (rotatedPoints[2].x-rotatedPoints[1].x > 0){ // change
        for (var pt=0; pt<points.length; pt++){
            points[pt].x = -points[pt].x;
        }
    }

    // rotate back points
    var rotatedTurn = rotatePolygon(points, {x:0,y:0}, -rotationAngle);


    // translate back 
    for (var p=0;p<rotatedTurn.length;p++){
        //route.push({x: rotatedTurn[p].x+rotatedPoints[1].x, y: rotatedTurn[p].y+rotatedPoints[1].y, speed: rotatedTurn[p].speed, w: rotatedTurn[p].w })
        rotatedTurn[p].x += rotatedPoints[1].x;
        rotatedTurn[p].y += rotatedPoints[1].y;
    }

    return rotatedTurn;

}

function getConcaveTurn(a, b, c, turningRadius, turningSpeed, workingSpeed, workingWidth, workingMode, fullCoverageMode, minimumAngle){

    // rotate points a-b-c so that ab is vertical
    var rotationAngle = Math.atan2(b.x - a.x, b.y - a.y);
    var rotatedPoints = rotatePolygon([a, b, c], b, rotationAngle);


    // translate the points so that b is [0,0]
    // the geometric algorithm was developed for a turn to the right. In case it is to the left,
    // just use the Math.abs value and make a mirror reflection of the x values later
    var rot_c = { x: Math.abs(rotatedPoints[2].x - rotatedPoints[1].x), y: rotatedPoints[2].y - rotatedPoints[1].y };

    var points = [];

    var alpha = Math.atan(rot_c.y / rot_c.x);

    if (alpha>Math.PI*(90-minimumAngle)/180){
        return [{x:b.x, y:b.y, speed:workingSpeed, w: "on"}];
    }

/*
    if (alpha<-Math.PI*89/180){
        return [];
    }
*/
    var beta = (Math.PI / 2 - alpha) / 2;

    var h;
    if (rot_c.y > 0) {  // obtuse angle
        h = (workingWidth / 2) * Math.tan(beta);
    }
    else { // acute angle
        h = (workingWidth / 2) * Math.tan(-beta + Math.PI / 2);
    }

    var C = { x: turningRadius, y: -turningRadius * Math.tan(beta) }; // center
    var p1 = { x: 0, y: h };
    var p2 = { x: -h * Math.cos(alpha), y: -h * Math.sin(alpha) }

    var stepDegrees = 15;

    if (fullCoverageMode){
        points.push({ ...p1, speed: -turningSpeed, w: "off" });
    }

    var arcLen = 2 * beta;

    // add arc
    var steps = Math.round(arcLen / (stepDegrees * Math.PI / 180));
    steps = Math.max(steps, 1);
    for (var s = 0; s <= steps; s++) {
        var arc = s * arcLen / steps;
        // last point of arc in reverse speed, rest forward
        points.push({ x: C.x - turningRadius * Math.cos(arc), y: C.y + turningRadius * Math.sin(arc), speed: s == steps ? -turningSpeed : turningSpeed, w: "off" });
    }

    // P2 forward (see concave turn drawing)
    points.push({ ...p2, speed: workingSpeed, w: workingMode });

    if (rotatedPoints[2].x - rotatedPoints[1].x < 0) { // change
        for (var pt = 0; pt < points.length; pt++) {
            points[pt].x = -points[pt].x;
        }
    }

    // rotate back points
    var rotatedTurn = rotatePolygon(points, { x: 0, y: 0 }, -rotationAngle);

    for (var p = 0; p < rotatedTurn.length; p++) {
        // translate back and add to route
        //route.push({x: rotatedTurn[p].x+rotatedPoints[1].x, y: rotatedTurn[p].y+rotatedPoints[1].y, speed: rotatedTurn[p].speed, w: rotatedTurn[p].w })
        rotatedTurn[p].x += rotatedPoints[1].x;
        rotatedTurn[p].y += rotatedPoints[1].y;
    }

    return rotatedTurn;
    
}

function raytracePolygon(a1, a2, polygon){

    var segmentNumber;  // for ex. p=3 means the segment is p3->p4 
    var i;
    for (var p=0; p<polygon.length;p++){
        var b1 = polygon[p];
        var b2 = polygon[p==polygon.length-1?0:p+1];        
        i = intersectLineSegment(a1,a2,b1,b2);
        if (i.length>0){ // length must be 1 
            segmentNumber = p;
            break;
        }
    }
    return { p:{x: i[0].x, y: i[0].y}, segment: segmentNumber };
}

function getSmallestAngle(polygon) {

    var minAngle = 2*Math.PI;

    for (var pt = 0; pt < polygon.length; pt++) {

        var next = (pt + 1) % polygon.length;
        var prev = (pt + polygon.length - 1) % polygon.length;

        var pPrev = polygon[prev];
        var pPt = polygon[pt];
        var pNext = polygon[next];

        var angle = getAngle(pPrev,pPt,pNext);

        if (angle<minAngle){
            minAngle = angle;
        }

    }

    return minAngle;

}

function getAngle(A,B,C) {

    var AB = Math.sqrt(Math.pow(B.x-A.x,2)+ Math.pow(B.y-A.y,2));    
    var BC = Math.sqrt(Math.pow(B.x-C.x,2)+ Math.pow(B.y-C.y,2)); 
    var AC = Math.sqrt(Math.pow(C.x-A.x,2)+ Math.pow(C.y-A.y,2));
    
    return Math.acos((BC*BC+AB*AB-AC*AC) / (2*BC*AB));   

}


function avoidObstacleUsingTurningRadius(obs, xRow, r) {

    // Determine the circle that contains all the points. If the radius is less than "turning_radius", expand it.

    var stepDegrees = 15;
    var xc = xRow - r;

    // https://math.stackexchange.com/questions/3904873/enclosing-circle-for-a-set-of-n-points

    const leftmostPt = obs.reduce(function(prev, current) {
        return (prev && prev.x < current.x) ? prev : current
    });

    let leftmostPtIndex = obs.indexOf(leftmostPt);

    var maxAngle = -Infinity;
    var p2Index = -1;

    for (var p=0; p<obs.length; p++){
        if (p!=leftmostPtIndex){
            var angle = Math.atan2(obs[p].y-obs[leftmostPtIndex].y, obs[p].x-obs[leftmostPtIndex].x);
            if (angle>maxAngle){
                p2Index = p;
                maxAngle = angle;
            }
        }
    }

    var largerCircle = {center: { x: 0, y: 0 }, radius: 0}; 

    for (var p=0; p<obs.length; p++){
        if (p!=leftmostPtIndex && p!=p2Index){
            var circle = findCircle(leftmostPt.x, leftmostPt.y, obs[p2Index].x, obs[p2Index].y, obs[p].x, obs[p].y );
            if (circle.radius>largerCircle.radius){
                largerCircle = circle;
            }
        }
    }

    // if the radius is less than the turning_radius, expand the circle by moving the center to the right
    if (largerCircle.radius<r){
        var dif = r-largerCircle.radius;
        largerCircle.center.x += dif;
        largerCircle.radius += dif;  
    }

    var ycLower = largerCircle.center.y - Math.sqrt((r+largerCircle.radius)*(r+largerCircle.radius) - (largerCircle.center.x-xc)*(largerCircle.center.x-xc));
    var ycUpper = largerCircle.center.y + Math.sqrt((r+largerCircle.radius)*(r+largerCircle.radius) - (largerCircle.center.x-xc)*(largerCircle.center.x-xc));

    var tgPtAngle = Math.atan2(largerCircle.center.y-ycLower,largerCircle.center.x-xc);

    var points = [];

    var steps = Math.round(tgPtAngle / (stepDegrees * Math.PI/180));
    steps = Math.max(steps, 1);

    for (var s = 0; s <= steps; s++) {
        var arc = s * (tgPtAngle / steps);
        points.push({ x: xc + r * Math.cos(arc), y: ycLower + r * Math.sin(arc) });
    }

    for (var s = 1; s < 2*steps; s++) {
        var arc = s * (tgPtAngle / steps) - tgPtAngle;
        points.push({ x: largerCircle.center.x - largerCircle.radius * Math.cos(arc), y: largerCircle.center.y + largerCircle.radius * Math.sin(arc) });
    }

    for (var s = -steps; s <= 0; s++) {
        var arc = s * (tgPtAngle / steps);
        points.push({ x: xc + r * Math.cos(arc), y: ycUpper + r * Math.sin(arc) });
    }
    
    return points;
}


function avoidObstacleUsingWorkingWidth(obs1, xRow, r, direction, workingSpeed, turningSpeed){

   
    var obs = obs1.map(a => {return {...a}}); // clone original array (spread operator or slice() make a shallow copy, so if you modify an object, it is modified in the original array)

    var stepDegrees = 15;
    var xc = xRow - r;

    var minLowerYc = Infinity;
    var maxUpperYc = -Infinity;

    var lowerYc = 0;
    var lowerAngle = 0;
    var upperYc = 0;
    var upperAngle = 0;
    var upperN = 0;
    var lowerN = 0;

    var maxX = Math.max(...obs.map(p => p.x));
    var minX = Math.min(...obs.map(p => p.x));

    if ( (minX+maxX)/2 < xRow){  // The vehicle must pass to the right of the obstacle 
        for (var p = 0; p < obs.length; p++) {  // Obtain the mirror obstacle with respect to xRow
            obs[p].x = xRow+xRow-obs[p].x;  
        }
        obs.reverse(); // reverse the points so that the polygon is again clockwise oriented 
    } 

    for (var p = 0; p < obs.length; p++) {

        var p1 = obs[p];
        var p2 = obs[p==obs.length-1?0:p+1];

        if (p1.x < p2.x) {
            [p1, p2] = [p2, p1];
        }

        var alpha = Math.atan2(p2.y - p1.y, p1.x - p2.x);

        var int = intersectLineLine2(p1, p2, { x: xc, y: 0 }, { x: xc, y: 10 });

        lowerYc = int[0].y - r / Math.cos(alpha);
        upperYc = int[0].y + r / Math.cos(alpha);

        var tgPtLowerCircle = { x: xc + r * Math.sin(alpha), y: lowerYc + r * Math.cos(alpha) };
        var tgPtUpperCircle = { x: xc - r * Math.sin(alpha), y: upperYc - r * Math.cos(alpha) };

        var d = Math.sqrt(distToSegmentSquared(tgPtLowerCircle, p1, p2));

        if (d < 0.0001) { //  el circulo es tangente a algun punto dentro del segmento

            if (lowerYc < minLowerYc) {
                minLowerYc = lowerYc;
                lowerAngle = Math.atan2(tgPtLowerCircle.y - lowerYc, tgPtLowerCircle.x - xc);
                lowerN = p;
            }

        }

        d = Math.sqrt(distToSegmentSquared(tgPtUpperCircle, p1, p2));

        if (d < 0.0001) { //  el circulo es tangente a algun punto dentro del segmento

            if (upperYc > maxUpperYc) {
                maxUpperYc = upperYc;
                upperAngle = Math.atan2(tgPtUpperCircle.y - upperYc, tgPtUpperCircle.x - xc);
                upperN = p;
            }

        }

        // calculate for each point
        if (obs[p].x > xc - r && obs[p].x < xc + r) {

            lowerYc = -Math.sqrt(r * r - (xc - obs[p].x) * (xc - obs[p].x)) + obs[p].y;
            if (lowerYc < minLowerYc) {
                minLowerYc = lowerYc;
                lowerAngle = Math.atan2(obs[p].y - lowerYc, obs[p].x - xc);
                lowerN = p;
            }

            upperYc = Math.sqrt(r * r - (xc - obs[p].x) * (xc - obs[p].x)) + obs[p].y;
            if (upperYc > maxUpperYc) {
                maxUpperYc = upperYc;
                upperAngle = -Math.atan2(obs[p].y - lowerYc, obs[p].x - xc);
                upperN = p;
            }

        }

    }

    var points = [];

    var steps = Math.round(lowerAngle / (stepDegrees * Math.PI / 180));
    steps = Math.max(steps, 1);

    for (var s = 0; s <= steps; s++) {
        var arc = s * (lowerAngle / steps);
        points.push({ x: xc + r * Math.cos(arc), y: minLowerYc + r * Math.sin(arc) });
    }

    if (upperN<lowerN){
        upperN+=obs.length;
    }
    for (var i = lowerN; i < upperN; i++) {
        points.push(obs[(i + 1) % obs.length ] );
    }

    steps = Math.round(Math.abs(upperAngle) / (stepDegrees * Math.PI / 180));
    steps = Math.max(steps, 1);

    for (var s = -steps; s <= 0; s++) {
        var arc = -s * (upperAngle / steps);
        points.push({ x: xc + r * Math.cos(arc), y: maxUpperYc + r * Math.sin(arc) });
    }

    if ( (minX+maxX)/2 < xRow){  //  
        for (var p = 0; p < points.length; p++) {  // mirror the resulting path with respect to xRow
            points[p].x = xRow+xRow-points[p].x;  
        }
    } 

    if (direction=="down"){
        points.reverse(); // reverse the points so that the polygon is again clockwise oriented 
    }

    for (var p = 0; p < points.length-1; p++) {
        points[p].speed = turningSpeed;
        points[p].w = "off";
    }

    points[points.length-1].speed = workingSpeed;
    points[points.length-1].w = "on";

    return points;
}


