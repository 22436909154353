
export function fieldIsDrawing() {
    return {
        type: 'FIELD_IS_DRAWING'
    };
}

export function routeIsDrawing(parentFieldId) {
    return {
        type: 'ROUTE_IS_DRAWING',
        parentFieldId: parentFieldId
    };
}

export function exclusionIsDrawing(parentFieldId) {
    return {
        type: 'EXCLUSION_IS_DRAWING',
        parentFieldId: parentFieldId
    };
}

export function obstacleIsDrawing(parentFieldId) {
    return {
        type: 'OBSTACLE_IS_DRAWING',
        parentFieldId: parentFieldId
    };
}

export function workingareaIsDrawing(parentFieldId) {
    return {
        type: 'WORKINGAREA_IS_DRAWING',
        parentFieldId: parentFieldId
    };
}

export function routeIsSimplifying(parentFieldId, name, coords) {
    return {
        type: 'ROUTE_IS_SIMPLIFYING',
        parentFieldId: parentFieldId,
        name: name,
        coords: coords
    };
}

export function finishDrawing() {
    return {
        type: 'FINISH_DRAWING'
    };
}

export function setVisibility(entityId, visible) {
    return {
        type: 'SET_VISIBILITY',
        entityId: entityId,
        visible: visible
    }
}