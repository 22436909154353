'use strict'

//import * as polybool from 'polybooljs';
// import { Edge, trapezoidation } from './polygonTrapezoidation';

import {
    intersectLinePolygon, rotatePoint, rotatePolygon, polygonBuffer,
    simplifyAndNormalizePolygon, ringToGeojsonPolygon, intersectLineSegment,
    closestPointInSegment, distanceAroundPolygon, closestPointInPolygon2,
    simplifyRoute, polylineToGeojsonLineString, computeAreas, pointFromEndOfLine,
    distToPolygonSquared, GetPolygonLongestSide, distToSegmentSquared, polygonArea,
    isXMonotone, SubtractExclusionAreas
} from './geometry';

import {fromLatLonArray, fromLatLon, toLatLonArray2 } from '../utils/utm';
import routeplannerSingleOpenField from './routeplannerSingleOpenField';
//import getSubfields from './getSubfields';
//import routePlanner from './routePlanner';
import getSubfields2 from './getSubfields2';

//import {connectedComponents } from 'graphology-components';
//import {Graph} from 'graphology';

//var simplepolygon = require('simplepolygon');


export default function routeplannerOpenField(params){

    var polygonslatlon = params.polygonslatlon;
    var obstacles = params.obstacles;
    var turningRadius = params.turningRadius; // (m)
    var workingWidth = params.workingWidth; // (m)
    var workingSpeed = params.workingSpeed; // (m/s)
    var headlandSwaths = params.headlandSwaths; // (N) 
    var workingAngle = params.workingAngle;
    var fieldEntrance = params.fieldEntrance;
    var pattern = params.pattern;
    var patternStart = params.patternStart;
    var alignmentZone = params.alignmentZone;
    var turningSpeedPercentage = params.turningSpeed;
    var fullConcaveTurns = params.fullConcaveTurns; // boolean
    var circuitTurnsMinimumAngle = params.circuitTurnsMinimumAngle; // degrees
    var subdivide = params.subdivide; // boolean


    ///var turningSpeed = turningSpeedPercentage*workingSpeed/100;

    ///var utmEntrance = fromLatLon(fieldEntrance[1], fieldEntrance[0]);

    // compute headlandWidth according to number of swaths
    ///var headlandWidth = headlandSwaths * workingWidth;

    /* each polygon coordinates in Cesium format [lon,lat,lon,lat...]
    / first polygon: outer border - following polygons: holes

    polygonslatlon = [
        [-58.014035224914544,-34.94903469588605,-58.01261901855469,-34.95084624366132,-58.00973296165466,-34.9508726251293,-58.008756637573235,-34.949412837805205,-58.00855278968811,-34.94821684809537,-58.009260892868035,-34.94914901804555,-58.00969004631043,-34.947654023371236,-58.01260828971862,-34.947645229204255],
        [-58.0133056640625,-34.94906107793701,-58.012522459030144,-34.950098765207045,-58.01255464553833,-34.948383935941564],
        [-58.01321983337402,-34.94857740565382,-58.013198375701904,-34.94875328681423,-58.01301598548889,-34.948656552222694,-58.013026714324944,-34.94852464123212]
    ];
    */

    var polygonsUtm = [];

    for(var k=0; k < polygonslatlon.length; k++)
    {
        // fill exclusion areas with polygons in UTM coordinates

        var utmPoly = fromLatLonArray(polygonslatlon[k]);

        // simplify polygon
        var geoJsonPoly = ringToGeojsonPolygon(utmPoly);
        var sp = simplifyAndNormalizePolygon(geoJsonPoly, 0.5);

        polygonsUtm.push(sp);
    }

    var obstaclesUtm = [];
    for(var k=0; k < obstacles.length; k++)
    {
        var utmPoly = fromLatLonArray(obstacles[k]);

        // simplify polygon
        //var geoJsonPoly = ringToGeojsonPolygon(utmPoly);
        //var sp = simplifyAndNormalizePolygon(geoJsonPoly, 0.5);

        obstaclesUtm.push(utmPoly);
    }


    if (polygonslatlon.length>1){

        var multipolygon = SubtractExclusionAreas(polygonsUtm);

        if (multipolygon.length>1){  

            return {error: "The exclusion areas have split the field into two or more separate fields. Verify that the field remains with a single outer polygon."};
    
        }

        polygonsUtm = [...multipolygon[0]];   // clone array  

    }

    //* lo cambio a partir de considerar obstacles. Chequear el tamaño de cada area de exc

    if (polygonsUtm.length>1 && !subdivide ){  // The field must be subdivided because it has exclusion areas, or the user wants to subdivide it
        
        return {error: "The field contains exclusion areas and must be divided into subfields. Select the 'subdivide' option"};

    }
    //*/

    // Analyze exclusion areas
    //var large_exc_areas = 0;
    //var obstacles = 0;
    //if (polygonsUtm.length>1){



    //}


    var utmEntrance = fromLatLon(fieldEntrance[1], fieldEntrance[0]);
    var zNum = utmEntrance.zoneNum;
    var zLet = utmEntrance.zoneLetter;

    if (!subdivide){

        /*
        headlandSwaths = calculateNumberOfCircuits(polygonsUtm[0], headlandSwaths, workingWidth);
        if (headlandSwaths==0){
            return {error: "There is not enough space to generate a route"};
        }
        */

        // check if the value is a numeric value passed as string and change it to numeric
        if (typeof (workingAngle) == 'string' && workingAngle!="LONGEST_SIDE" && workingAngle!="OPTIMAL"){

            workingAngle = Number(workingAngle);
            
        }


        if (typeof (workingAngle) == 'string') {

            switch (workingAngle) {

                case "LONGEST_SIDE":
                    workingAngle = GetPolygonLongestSide(polygonsUtm[0]);
                    if (!isMonotone(polygonsUtm[0], headlandSwaths, workingWidth, workingAngle)){
                        return {error: "The boundary of the field is not monotone with respect to the line of the longest side (that is, all lines parallel to the working direction must intersect the field boundary polygon only at two points). Try 'Optimal' option for working angle"};
                    }
                    break;

                case "OPTIMAL":

                    workingAngle = calculateOptimalWorkingAngle(polygonsUtm[0], headlandSwaths, workingWidth);
                    if (workingAngle == null){
                        return {error: "Could not find a working angle with respect to which the field boundary is monotone (that is, all lines parallel to the working direction must intersect the field boundary polygon only at two points). Try selecting the 'Subdivide' option"};
                    }

                    break;
            }
        }
        else{ // the parameter 'workingAngle' was specified as a number
            
            if (!isMonotone(polygonsUtm[0], headlandSwaths, workingWidth, workingAngle)){
                return {error: "The field boundary is not monotone with respect to the specified working angle (that is, all lines parallel to the working direction must intersect the field boundary polygon only at two points). Try 'Optimal' option for working angle"};
            }
        }

        // call SingleOpenField method
        var params2=[];
        params2.polygonsUtm = polygonsUtm;
        params2.obstaclesUtm = obstaclesUtm;
        params2.turningRadius = params.turningRadius; // (m)
        params2.workingWidth = params.workingWidth; // (m)
        params2.workingSpeed = params.workingSpeed; // (m/s)
        params2.headlandSwaths = headlandSwaths; // (N) 
        params2.workingAngle = workingAngle;
        params2.fieldEntrance = utmEntrance;
        params2.pattern = params.pattern;
        params2.patternStart = params.patternStart;
        params2.alignmentZone = params.alignmentZone;
        params2.turningSpeed = params.turningSpeed;
        params2.fullConcaveTurns = params.fullConcaveTurns; // boolean
        params2.circuitTurnsMinimumAngle = params.circuitTurnsMinimumAngle; // degrees


        //return routeplannerSingleOpenField(params2);

        do { // there is enough space
            try{
                var r = routeplannerSingleOpenField(params2);
                return r; 
            }
            catch{
                params2.headlandSwaths--;
            }
        }while (params2.headlandSwaths>0);

        return {error: "There is not enough space to generate a route"};


    }
    else{ // divide

        // TODO: llamar SingleOpenField para cada SubField

        var params3 = [];

        params3.polygonsUtm = polygonsUtm;
        params3.workingWidth = params.workingWidth; // (m)
        params3.workingSpeed = params.workingSpeed; // (m/s)
        params3.turningTime = 30; // (s)
        params3.areaCostCoefficient = params.areaCostCoefficient;
        params3.timeCostCoefficient = params.timeCostCoefficient;
        params3.thresholdRightAngle = params.thresholdRightAngle; // (degrees)
        params3.thresholdCollinearAngle = params.thresholdCollinearAngle; // (degrees)

        var subFields = getSubfields2(params3);
        
        var params2=[];

        params2.obstaclesUtm = obstaclesUtm;
        params2.turningRadius = params.turningRadius; // (m)
        params2.workingWidth = params.workingWidth; // (m)
        params2.workingSpeed = params.workingSpeed; // (m/s)
        params2.pattern = params.pattern;
        params2.patternStart = params.patternStart;
        params2.alignmentZone = params.alignmentZone;
        params2.turningSpeed = params.turningSpeed;
        params2.fullConcaveTurns = params.fullConcaveTurns; // boolean
        params2.circuitTurnsMinimumAngle = params.circuitTurnsMinimumAngle; // degrees

        var routes = [];

        var sarasa = graficar1(polygonsUtm[0]);

        for (var sf=0; sf<subFields.length; sf++){
            params2.polygonsUtm = [subFields[sf].polygon];
            params2.fieldEntrance = subFields[sf].polygon[0];
            params2.fieldEntrance.zoneLetter = zLet;
            params2.fieldEntrance.zoneNum = zNum;
            params2.workingAngle = subFields[sf].workingAngle;
            //params2.headlandSwaths = calculateNumberOfCircuits(subFields[sf].polygon, headlandSwaths, workingWidth);
            params2.headlandSwaths = headlandSwaths;
            do { // there is enough space
                try{
                    routes.push(routeplannerSingleOpenField(params2));
                    break; 
                }
                catch{
                    params2.headlandSwaths--;
                }
            }while (params2.headlandSwaths>0);
            if (params2.headlandSwaths==0){
                console.log("Could not generate a route for the subfield " + sf)
            }
        }

        if (routes.length==0){  // there is no space even for the first subField attempt
            return {error: "Could not generate any subfield with space to create a route"};
        }

        // compute statistics for the combination of all subroutes

        var coveredArea=[];
        var overlapArea=[];
        var uncoveredArea=[];
        var travelledDistance=0;
        var workedDistance=0;
        var travelledTime=0;
        var workedTime=0;
        var coveredAreaSizeM2=0;
        var overlapAreaSizeM2=0;
        var uncoveredAreaSizeM2=0;

        for (var r=0; r<routes.length; r++){
            coveredArea.push(...routes[r].routeStats.coveredArea);
            overlapArea.push(...routes[r].routeStats.overlapArea);
            uncoveredArea.push(...routes[r].routeStats.uncoveredArea);
            travelledDistance += routes[r].routeStats.travelledDistance;
            workedDistance += routes[r].routeStats.workedDistance;
            travelledTime += routes[r].routeStats.travelledTime;
            workedTime +=routes[r].routeStats.workedTime;
            coveredAreaSizeM2 += routes[r].routeStats.coveredAreaSizeM2;
            overlapAreaSizeM2 += routes[r].routeStats.overlapAreaSizeM2;
            uncoveredAreaSizeM2 += routes[r].routeStats.uncoveredAreaSizeM2;
        }

        var fieldSizeM2 = polygonArea(polygonsUtm[0]);
        //subtract exclusion areas
        for (var h=1; h<polygonsUtm.length; h++){
            fieldSizeM2 -= polygonArea(polygonsUtm[h]);
        }

        var stats = {
            coveredArea: coveredArea,
            overlapArea: overlapArea,
            uncoveredArea: uncoveredArea,
            coveredAreaSize: (100*coveredAreaSizeM2/fieldSizeM2).toFixed(2),
            uncoveredAreaSize: (100*(fieldSizeM2-coveredAreaSizeM2)/fieldSizeM2).toFixed(2),
            overlapAreaSize: (100*overlapAreaSizeM2/fieldSizeM2).toFixed(2),
            travelledDistance: travelledDistance,
            workedDistance:workedDistance,
            travelledTime: travelledTime,
            workedTime: workedTime
        }

        routes[0].routeStats = stats;

        // put the combined statistics in the first subroute and delete the rest
        for (var r=1; r<routes.length; r++){
            routes[r].routeStats = {};
        }

        return routes;

    }

}


function calculateNumberOfCircuits(polygon, maxNumberOfCircuits, workingWidth){

    // la aproximacion del área mayor a (2*workingwidth)^2 no sirve.
    // hay que calcular el ancho maximo del poligono (en getSubFields.js, antes de ser rotado) 
    // y sin hacer buffer, tomar que el ancho del poligono debe ser > (2*n+1)*workingwidth

    var ringGeoJson = ringToGeojsonPolygon(polygon);
    var minArea = (2*workingWidth)**2; // TODO: buscar un método más preciso para buscar area minima. Tal vez examinar la forma tambien

    for (var n=maxNumberOfCircuits; n>0; n--){
        var mainland = polygonBuffer(ringGeoJson, n*workingWidth);
        if (polygonArea(mainland)>minArea){
            break;
        }
    }

    return n; // n=0 when there is no space to calculate a route
}

function calculateOptimalWorkingAngle(polygon, numberOfCircuits, workingWidth){

    var ringGeoJson = ringToGeojsonPolygon(polygon);
    var mainland = polygonBuffer(ringGeoJson, numberOfCircuits*workingWidth);

    var rotationPoint = mainland[0];

    var optimalAngle = null;
    var smallerWidth = Infinity;
    for (var angle=0; angle<180; angle++){

        var rotatedPolygon = rotatePolygon(mainland, rotationPoint, (90-angle) * Math.PI/180);

        if (isXMonotone(rotatedPolygon)){
            var xMin =Math.min.apply(Math, rotatedPolygon.map(function(o) { return o.x; })) // minimum x value of all polygon points
            var xMax =Math.max.apply(Math, rotatedPolygon.map(function(o) { return o.x; })) // maximum x value of all polygon points
        
            if ( (xMax-xMin)<smallerWidth ){
                smallerWidth = xMax-xMin;
                optimalAngle = angle;
            }
        }
    }

    return optimalAngle;

}

function isMonotone(polygon, numberOfCircuits, workingWidth, angle){ 

    // check if the polygon is monotone for a certain angle
    var ringGeoJson = ringToGeojsonPolygon(polygon);
    var mainland = polygonBuffer(ringGeoJson, numberOfCircuits*workingWidth);

    var rotationPoint = mainland[0];

    var rotatedPolygon = rotatePolygon(mainland, rotationPoint, (90-angle) * Math.PI/180);

    return isXMonotone(rotatedPolygon);
}

function graficar1(pol){
    var str="";
    for (var j=0; j<pol.length; j++){

        str +=  pol[j].x + ',' + pol[j].y + '\n';

    }
    str +=  pol[0].x + ',' + pol[0].y + '\n';
    str += '\n';
    return str;
}


