import React from "react";
import { connect } from 'react-redux';

import "./RosImu.css";

class RosImu extends React.Component {

    constructor(props) {
        super(props);
        this.imuCanvasRef = React.createRef();
    }

    componentDidMount() {
        this.ctx = this.imuCanvasRef.current.getContext('2d');
    }

    // detect when image has changed
    componentDidUpdate(prevProps) {
        if ( this.props.roslibMessageReceived.type  == "sensor_msgs/Imu"){
            if (prevProps.roslibMessageReceived != this.props.roslibMessageReceived ){
                var obj = JSON.parse(this.props.roslibMessageReceived.message);
                // swap x and z to use the cell in landscape
                var fXg = obj.linear_acceleration.x;
                var fYg = obj.linear_acceleration.y;
                var fZg = obj.linear_acceleration.z;
                var roll  = (Math.atan2(-fYg, fZg)*180.0)/Math.PI;
                var pitch = (Math.atan2(fXg, Math.sqrt(fYg*fYg + fZg*fZg))*180.0)/Math.PI;

                // console.log("roll: " + roll + "   pitch: " + pitch  );

                var horizonOffset = 120 - pitch * 4;  // compute horizon https://stackoverflow.com/questions/14099158/correct-translation-for-artificial-horizon
                var rollOffset = 120 * Math.atan(roll* Math.PI/180);

                this.ctx.fillStyle = "DeepSkyBlue";
                this.ctx.fillRect(0, 0, 320, 240);

                this.ctx.beginPath();
                this.ctx.moveTo(0, horizonOffset - rollOffset);
                this.ctx.lineTo(320, horizonOffset + rollOffset);
                this.ctx.lineTo(320, 240);
                this.ctx.lineTo(0, 240);
                this.ctx.closePath();
                this.ctx.fillStyle = "ForestGreen";
                this.ctx.fill();

                this.ctx.fillStyle = "Yellow";
                this.ctx.fillRect(30, 118, 80, 4);
                this.ctx.fillRect(210, 118, 80, 4);

                this.ctx.fillStyle = "white";
                this.ctx.fillRect(130, 120, 60, 1);
                this.ctx.fillRect(130, 80, 60, 1);
                this.ctx.fillRect(130, 40, 60, 1);
                this.ctx.fillRect(130, 160, 60, 1);
                this.ctx.fillRect(130, 200, 60, 1);

                this.ctx.font = "13px Arial";
                this.ctx.fillStyle = "black";
                this.ctx.fillText("Pitch: " + pitch.toFixed(1), 3, 16 + "°" ); 
                this.ctx.fillText("Roll: " + roll.toFixed(1), 3, 32 + "°" ); 
            }
        }
    }


    render() {
        return (

            <div className="RosImu">
                <canvas ref={this.imuCanvasRef} style={{ height: '100%', width: '100%'}} width={320} height={240}/>
            </div>
        )


    }

}

const mapStateToProps = (state) => {
    return {
        roslibMessageReceived: state.roslibMessageReceived
  
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //createFarm: (fId, fName) => dispatch(createFarm(fId, fName)),
        //deleteFarm: (fId, isOpened) => dispatch(deleteFarm(fId, isOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosImu);