import React from "react";
import { connect } from 'react-redux';

import "./RosLaserScan.css";

class RosLaserScan extends React.Component {

    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.lrfBitmapHorizontalRange = 8; // meters
    }

    componentDidMount() {
        this.ctx = this.canvasRef.current.getContext('2d');
    }

    // detect when image has changed
    componentDidUpdate(prevProps) {
        if ( this.props.roslibMessageReceived.type  == "sensor_msgs/LaserScan"){
            if (prevProps.roslibMessageReceived != this.props.roslibMessageReceived ){
                var obj = JSON.parse(this.props.roslibMessageReceived.message);

                var pixelsPerMeter = this.ctx.canvas.width / this.lrfBitmapHorizontalRange;
                var originpixX = Math.round(0.5 * this.ctx.canvas.width);
                var originpixY = Math.round(0.8 * this.ctx.canvas.height);

                this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

                // create rectangle device
                this.ctx.beginPath();
                this.ctx.fillStyle = "red";
                this.ctx.fillRect(originpixX - 5, originpixY - 4, 10, 8);

                //draw grid
                this.ctx.beginPath();       // Start a new path
                this.ctx.lineWidth = 1;
                this.ctx.strokeStyle = 'gray';
                // X+
                for (var pt_x = originpixX; pt_x < this.ctx.canvas.width; pt_x += pixelsPerMeter){
                    this.ctx.moveTo(pt_x, 0);    
                    this.ctx.lineTo(pt_x, this.ctx.canvas.height); 
                }
                // X-
                for (pt_x = originpixX - pixelsPerMeter; pt_x > 0; pt_x -= pixelsPerMeter){
                    this.ctx.moveTo(pt_x, 0);    
                    this.ctx.lineTo(pt_x, this.ctx.canvas.height); 
                }
                // Y+
                for (var pt_y = originpixY; pt_y < this.ctx.canvas.height; pt_y += pixelsPerMeter){
                    this.ctx.moveTo(0, pt_y);    
                    this.ctx.lineTo(this.ctx.canvas.width, pt_y); 
                }
                // Y-
                for (pt_y = originpixY - pixelsPerMeter; pt_y > 0; pt_y -= pixelsPerMeter){
                    this.ctx.moveTo(0, pt_y);    
                    this.ctx.lineTo(this.ctx.canvas.width, pt_y); 
                }

                this.ctx.stroke();          // Render the path

                this.ctx.beginPath();

                this.ctx.fillStyle = "blue";

                for (var r = 0; r < obj.ranges.length; r++)
                {
                    if (obj.ranges[r] > obj.range_min && obj.ranges[r] < obj.range_max)
                    {
                        var dist_pix = pixelsPerMeter * obj.ranges[r];
                        var angle = obj.angle_min + r * obj.angle_increment;
                        // var pt_x = (originpixX + dist_pix * Math.cos(angle));
                        // var pt_y = (originpixY - dist_pix * Math.sin(angle));
                        // consistent with husky simulation:
                        var pt_x = (originpixX + dist_pix * Math.sin(angle));
                        var pt_y = (originpixY - dist_pix * Math.cos(angle));

                        if (pt_x >= 0 && pt_x < this.ctx.canvas.width && pt_y >= 0 && pt_y < this.ctx.canvas.height){
                            this.ctx.moveTo(pt_x, pt_y);
                            this.ctx.arc(pt_x, pt_y, 3, 0, 2 * Math.PI);
                        }
                    }
                }

                this.ctx.fill();

            }
        }
    }


    render() {
        return (

            <div className="RosLaserScan">
                <canvas ref={this.canvasRef} style={{ height: '100%', width: '100%', backgroundColor: 'white'}} width={320} height={240}/>
            </div>
        )


    }

}

const mapStateToProps = (state) => {
    return {
        roslibMessageReceived: state.roslibMessageReceived
  
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //createFarm: (fId, fName) => dispatch(createFarm(fId, fName)),
        //deleteFarm: (fId, isOpened) => dispatch(deleteFarm(fId, isOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosLaserScan);