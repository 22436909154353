export function routePlanIsProcessing(state = false, action) {
    switch (action.type) {
        case 'ROUTEPLAN_IS_PROCESSING':
            return action.isProcessing;
        default:
            return state;
    }
}

export function routeWasPlanned(state = {id:""}, action) {
    switch (action.type) {
        case 'ROUTE_WAS_PLANNED':
            return {
                parentFieldId: action.parentFieldId,
                id: action.id,
                origin: action.origin,
                vehicle: action.vehicle,
                routeObject: action.routeObject
            };
        default:
            return state;
    }
}


export function userAttributes(state = {}, action) {
    switch (action.type) {
        case 'USER_ATTRIBUTES_SET':
            return {
                userAttrSub: action.payload.userAttributesSub,
                userAttrEmail: action.payload.userAttributesEmail
            };
        default:
            return state;
    }
}

