export function shallowEqual(objA, objB) {
    if (objA === objB) {
        return true;
    }

    const aKeys = Object.keys(objA);
    const bKeys = Object.keys(objB);
    const len = aKeys.length;

    if (bKeys.length !== len) {
        return false;
    }

    for (let i = 0; i < len; i++) {
        const key = aKeys[i];

        if (objA[key] !== objB[key]) {
            return false;
        }
    }

    return true;
};

export const noop = () => {};

export function isUndefined(value) {
    return value === undefined;
}

////////
// Generic helper function that can be used for the three operations (inBoth, inFirstOnly, inSecondOnly): 
// key is the Object key to be used as filter
function operation(list1, list2, key, isUnion) {
    return list1.filter( a => isUnion === list2.some( b => a[key] === b[key]) );
}
// Following functions are to be used:
export function inBoth(list1, list2, key) {
    return operation(list1, list2, key, true);
}
export function inFirstOnly(list1, list2, key) {
    return operation(list1, list2, key, false);
}
export function inSecondOnly(list1, list2, key) {
    return inFirstOnly(list2, list1, key);
}
/////