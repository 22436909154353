
import {closestPointInArray, intersectLinePolygons, isPointInPolygon} from './geometry';

class PolygonPoint {
    constructor(pt) {
        var p = {x:pt.x, y:pt.y};
        this.point = p;
        this.obstacleIndex = pt.obstacleIndex;
        this.obstaclePointIndex = pt.obstaclePointIndex;
        this.type = ''; // in, middle, out
        this.edgeExtension = ''; // up, down, both
        this.vector = null; // get vector to the region if available
        this.intPointUp = null;
        this.intPointDown = null; // intersection in the upper extension and intersection on the lower extension

        /*
        var up = new Path.Line(new Point(p.x, p.y - 5), new Point(p.x, 0))
        var down = new Path.Line(new Point(p.x, p.y + 5), new Point(p.x, canvas.height))
        */

        var up1 = {x: p.x, y: p.y + 1e-12};
        var up2 = {x: p.x, y: 10000000};
        var down1 = {x: p.x, y: p.y - 1e-12};
        var down2 = {x: p.x, y: 0};

        //var sa= length(up, {units: 'kilometers'});
        //var sb= length(down, {units: 'kilometers'});
        var interUp = intersectLinePolygons(up1,up2,obstacles);
        var interDown = intersectLinePolygons(down1,down2,obstacles); // it has lower extension
        if (interUp.length % 2 == 1) // it has upper extension
        {
            this.intPointUp = closestPointInArray(p, interUp);
            this.edgeExtension = 'up';
        }
        if (interDown.length % 2 == 1) {
            this.intPointDown = closestPointInArray(p, interDown);
            this.edgeExtension = 'down';
        }
        if (this.intPointUp && this.intPointDown) // there are upper edge extension and lower edge extension
        {
            this.edgeExtension = 'both';
            // when there are both edge extensions the vertex is "in" or "out".
            // "middle" vertexes have only one extension (up or down)
            
            /*
            var cirInter = intersectCirclePolygons({x: p.x, y: p.y}, 0.25, obstacles);
            var vec1 = { x: cirInter[0].x-this.point.x, y: cirInter[0].y-this.point.y }; // this vector shows point to one edge
            var vec2 = { x: cirInter[1].x-this.point.x, y: cirInter[1].y-this.point.y }; // this vector shows point to other edge
            */
            var nextPointIndex = this.obstaclePointIndex+1;
            if (nextPointIndex == obstacles[this.obstacleIndex].length){
                nextPointIndex = 0;
            }

            var prevPointIndex = this.obstaclePointIndex - 1;
            if (prevPointIndex == -1){
                prevPointIndex = obstacles[this.obstacleIndex].length-1 ;
            }

            var vec1 = { x: obstacles[this.obstacleIndex][nextPointIndex].x-this.point.x, y: obstacles[this.obstacleIndex][nextPointIndex].y-this.point.y }; // this vector shows point to one edge
            var vec2 = { x: obstacles[this.obstacleIndex][prevPointIndex].x-this.point.x, y: obstacles[this.obstacleIndex][prevPointIndex].y-this.point.y }; // this vector shows point to other edge

            this.vector = { x: vec1.x + vec2.x, y: vec1.y + vec2.y }  //(vec1 + vec2);
            
            if(this.vector.x > 0)
                this.type = 'in';
            else if(this.vector.x < 0)
                this.type = 'out';
            
        }
    }
}

export class Edge {
    constructor(p1, p2, ref) {
        if (typeof Edge.counter == 'undefined')
            Edge.counter = 0;
        this.id = Edge.counter++;
        this.p1 = p1;
        this.p2 = p2;
        this.middle = (p1 + p2) / 2;
        this.refP = ref; // reference point related to polygon point
        this.getLength = function () { return (p1 - p2).length; };
    }
}
;

export class Trapezoid {
    constructor(edge1, edge2) {
        if (typeof Trapezoid.counter == 'undefined')
            Trapezoid.counter = 0;
        this.id = Trapezoid.counter++;
        this.edge1 = edge1;
        this.edge2 = edge2;
        var edge1Point = (edge1 instanceof Edge ? edge1.middle : edge1); // can be instanceof a point instead of Edge
        var edge2Point = (edge2 instanceof Edge ? edge2.middle : edge2);
        this.center = (edge1Point + edge2Point) / 2;
        this.leftNeighbours = [];
        this.rightNeighbours = [];
        this.group = this.id;
    }
}
;


var obstacles =[];
var polygonPoints = [];
var trapezoidlist = [];

export function trapezoidation(polygons){
    trapezoidlist = [];
    polygonPoints = [];
    Edge.counter = 0;
    Trapezoid.counter = 0;
    obstacles = polygons;

    obtainAllPolygonPoints();
    for(var i=0; i < polygonPoints.length; i++)
        findAvailablePaths(i);

    //var xx = JSON.stringify(trapezoidlist);
    trapezoidNeighbouring();
    return trapezoidlist;

}

function trapezoidNeighbouring(){
    
    for(var i=0; i<trapezoidlist.length; i++)
        for(var j=i+1; j<trapezoidlist.length; j++)
        {
            if(trapezoidlist[i].edge2.refP == trapezoidlist[j].edge1.refP)
             {
                 trapezoidlist[i].rightNeighbours.push(trapezoidlist[j])
                 trapezoidlist[j].leftNeighbours.push(trapezoidlist[i])
             }   
        }
}

function obtainAllPolygonPoints(){    

    var tempPoints = [];
    
    for(var i=0; i < obstacles.length; i++){ // obtain polygonPoints from obstacles

        for(var k=0; k < obstacles[i].length; k++){
            var x = obstacles[i][k].x;
            var y = obstacles[i][k].y;

            tempPoints.push({x: x, y: y, obstacleIndex: i, obstaclePointIndex: k})
        }
    }  
    
    tempPoints.sort(function (a, b) {  // sort according to x value
      return a.x > b.x ? 1 : -1;
    });
    
    for(i=0; i < tempPoints.length; i++)
        polygonPoints.push(new PolygonPoint(tempPoints[i]))
} // returns all points in order according to their x value


function findAvailablePaths(i){

    function obtainTrueStartingEdge(i, j){
        
        var vec = polygonPoints[i].vector;
        
        if(polygonPoints[i].type == 'in')
        {
            var vec2 = { x: polygonPoints[j].point.x - polygonPoints[i].point.x, y: polygonPoints[j].point.y - polygonPoints[i].point.y};

            if(vec.x * vec2.y - vec.y * vec2.x > 0)   // OJO
                return getEdge(i, 'up');
            else
                return getEdge(i, 'down');          
        }
        else
            return getEdge(i, 'both');
    }
    
    function obtainTrueEndingEdge(i, j){

        var vec = polygonPoints[j].vector;
        
        if(polygonPoints[j].type == 'out') // find where previous point comes from
        {
            var vec2 = { x: polygonPoints[i].point.x - polygonPoints[j].point.x, y: polygonPoints[i].point.y - polygonPoints[j].point.y }
            if(vec.x * vec2.y - vec.y*vec2.x > 0)   // OJO
                return getEdge(j, 'down');
            else
                return getEdge(j, 'up');
        }
        else
            return getEdge(j, 'both');
    }
    
    var j = getNextAvailablePaths(i) // if next point is dead end, it returns null
 
    if(j == null)
        return
    if (typeof(j) == 'number') // there is only one way
        //console.log(i+' -> '+j)
        createTrapezoid(i, j)
    else { // there are two ways such as 6-> 11,7
        //console.log(i+' -> '+j[0] +','+j[1]);
        createTrapezoid(i, j[0])
        createTrapezoid(i, j[1])  
    }
    
    function createTrapezoid(a, b){
        var edge1 = obtainTrueStartingEdge(a, b)
        var edge2 = obtainTrueEndingEdge(a, b)
        
        /************************
              agregado al original: normalizar todos los segmentos
              hacer siempre p1.y > p2.y
        
        
        if (edge1 instanceof Edge){
            if (edge1.p1.y<edge1.p2.y){
                var tmp = edge1.p1.y;
                edge1.p1.y = edge1.p2.y;
                edge1.p2.y = tmp;
            }
        }
        if (edge2 instanceof Edge){
            if (edge2.p1.y<edge2.p2.y){
                var tmp = edge2.p1.y;
                edge2.p1.y = edge2.p2.y;
                edge2.p2.y = tmp;
            }
        }
        */
                 
        trapezoidlist.push(new Trapezoid(edge1, edge2)) 
    }    
}

function getNextAvailablePaths(i){
    
    var paths = [] // stores all available edges if it seperating, otherwise returns first available one
    var vector = polygonPoints[i].vector;
    
    for(var j=i+1; j<polygonPoints.length; j++)
    {
        var lineVec = {x: polygonPoints[j].point.x - polygonPoints[i].point.x, y: polygonPoints[j].point.y - polygonPoints[i].point.y};
        var vlength = Math.sqrt(lineVec.x*lineVec.x+lineVec.y*lineVec.y);
        var unitVec = {x: lineVec.x/vlength, y: lineVec.y/vlength};

        var testPoint = {x: polygonPoints[i].point.x + unitVec.x*0.001, y: polygonPoints[i].point.y + unitVec.y*0.001 };

        var hit = false;
        ///for(var k=1; k < obstacles.length; k++){ // except first obstacle, cause it is outer obstacle
            
        // Para saber si el vertice i "ve" al j primero chequeo si el punto a una distancia corta de i apuntando a j
        // está dentro de un polígono. Esto para el caso de que caiga dentro del poligono del cual i es un vertice.
        // Por ejemplo vertices 1->7 en el ejemplo de metu
        // Además, los algoritmos pointinpolygon son ambiguos en casos en que el punto está sobre
        // un lado del poligono. Por error del redondeo en el flotaing pueden devolver true or false.
        // Esto produce un error cuando evaluo por ejemplo 5->7
        // Para eliminar el posible error, chequeo también que la distancia del punto al polígono sea mayor que un epsilon

        var nextPointIndex = polygonPoints[i].obstaclePointIndex+1;
        if (nextPointIndex == obstacles[polygonPoints[i].obstacleIndex].length){
            nextPointIndex = 0;
        }

        var prevPointIndex = polygonPoints[i].obstaclePointIndex - 1;
        if (prevPointIndex == -1){
            prevPointIndex = obstacles[polygonPoints[i].obstacleIndex].length-1 ;
        }

        var isJnextPoint = polygonPoints[i].obstacleIndex == polygonPoints[j].obstacleIndex && nextPointIndex == polygonPoints[j].obstaclePointIndex;
        var isJprevPoint = polygonPoints[i].obstacleIndex == polygonPoints[j].obstacleIndex && prevPointIndex == polygonPoints[j].obstaclePointIndex;

        if (!isJnextPoint && !isJprevPoint && polygonPoints[i].obstacleIndex>0)
            hit = isPointInPolygon( testPoint, obstacles[polygonPoints[i].obstacleIndex] );
            /*
            if (hit){
                var borrar = distToPolygonSquared(testPoint, obstacles[k]);
                if ( distToPolygonSquared(testPoint, obstacles[k]) < 0.000001 ){ // 0.000001 = 0.001^2  dist<1 mm 
                    hit = null;
                }
            }

            if(hit) 
                break;
            */
        ///}


        if(!hit) // starting from outside of the obstacle and passes through an obstacle
        { 

            var inter = intersectLinePolygons(polygonPoints[i].point,polygonPoints[j].point,obstacles);
            // algunas veces al hacer el substract de la mejor region, en el field restante queda un hole con un vertice
            // tocando el outer polygon. Para no considerar esa intersección pongo un epsilon
            var distSquaredi = 1;
            var distSquaredj = 1;
            if(inter.length == 1){
                distSquaredi = (inter[0].x - polygonPoints[i].point.x)*(inter[0].x - polygonPoints[i].point.x) + (inter[0].y - polygonPoints[i].point.y)*(inter[0].y - polygonPoints[i].point.y);
                distSquaredj = (inter[0].x - polygonPoints[j].point.x)*(inter[0].x - polygonPoints[j].point.x) + (inter[0].y - polygonPoints[j].point.y)*(inter[0].y - polygonPoints[j].point.y);
            }


            if(inter.length == 0 || distSquaredi< 0.000001 || distSquaredj< 0.000001){ // 0.000001 = 0.001^2  dist<1 mm  ]){  // no segments between points


                //*** Agregado para polygono exterior concavo (metu funciona solo para poligono exterior convexo)
                //    chequeo caso en que un punto ve a otro por fuera del poligono exterior (una concavidad)
                //    También hay que resolver con un epsilon la ambigüedad en el caso en que p1 y p2 son extremos de un segmento del poligono
                
                var hit2 = true;
                if (!isJnextPoint && !isJprevPoint && polygonPoints[i].obstacleIndex==0)
                    hit2 = isPointInPolygon(testPoint,obstacles[0]);

                if (hit2){
                    if(polygonPoints[i].type == 'in')
                        paths.push(j) // it is like 6->11,7   (they are actually determined in next for)     
                    else    
                        return j // it can go like 4->6 in guide.png 
                }

            }
        }        

    } 
    
    var up = null, down = null; // this point is sperating, up and down must be filled
    for(var k=0;k < paths.length; k++)
    {   
        var temp = polygonPoints[paths[k]].point
        var p = {x: temp.x - polygonPoints[i].point.x, y: temp.y - polygonPoints[i].point.y}
        
        if((p.x * vector.y - p.y*vector.x) > 0) //  direction is left
        {
            if(!up)  up = paths[k] // store only first found one
        }
        else // direction is right
        {
            if (!down)  down = paths[k]
        }
    }
    if (!up || !down)
        return null
    return [up, down];
}

function getEdge(i, str){ // up, down or both
    
    var p = polygonPoints[i].point;
    var up = polygonPoints[i].intPointUp;
    var down = polygonPoints[i].intPointDown;

    if(str == 'up')
        return new Edge(up, p, i)

    if(str == 'down')
        return new Edge(p, down, i) 

    // below code for str is 'both'
    if (up && !down)
        return new Edge(p, up, i)
    else if (!up && down)
        return new Edge(p, down, i)    
    else if (up && down)
        return new Edge(up, down, i)
    else 
        return p // return points itself
}

