export function roslibStatus(state = {}, action) {
    switch (action.type) {
        case 'ROSLIB_CHANGE_STATUS':
            return action.newStatus;
        default:
            return state;
    }
}

export function roslibMessageReceived(state = {}, action) {
    switch (action.type) {
        case 'ROSLIB_UPDATE_MESSAGE_RECEIVED':
            return action.payload;
        default:
            return state;
    }
}

export function roslibMessagePublished(state = {}, action) {
    switch (action.type) {
        case 'ROSLIB_PUBLISH_MESSAGE':
            return action.payload;
        default:
            return state;
    }
}

