import React from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, ControlLabel, Row, Col, Checkbox, Radio} from "react-bootstrap";
import { Button } from "react-bootstrap";
import ReactTable from 'react-table';
import NumericInput from 'react-numeric-input';
import Dialog from 'react-bootstrap-dialog';
import 'react-table/react-table.css';
import "./RoutePlanner.css";
import routePlannerGAOS from "../routeplanner/routeplannerGAOS2";
import routeplannerOpenField from "../routeplanner/routeplannerOpenField";
import {createFieldGeometry} from '../actions/fieldGeometries';
import {routePlanIsProcessing, routeWasPlanned} from '../actions/appStatus';
import uuid from "uuid";
import { invokeApig } from '../libs/awsLib';
import {fromLatLonArray} from '../utils/utm';
import {shallowEqual} from "../utils/utils";


class RobotillerRoutePlanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    parameter: 'Turning Radius',
                    value: 3,
                    minValue: 0,
                    maxValue: 100,
                    step: 0.1,
                    units: 'meters',
                    index: 0
                },
                {
                    parameter: 'Working Width',
                    value: 3,
                    minValue: 0,
                    maxValue: 100,
                    step: 1,
                    units: 'meters',
                    index: 1
                },
                {
                    parameter: 'Working Speed',
                    value: 6,
                    minValue: 0,
                    maxValue: 100,
                    step: 0.1,
                    units: 'speedunit',
                    index: 2
                },
                {
                    parameter: 'Turning Speed',
                    value: 50,
                    minValue: 0,
                    maxValue: 100,
                    step: 1,
                    units: '% of working speed',
                    index: 3
                },
                {
                    parameter: 'Max Headland Circuits',
                    value: 5,
                    minValue: 1,
                    maxValue: 100,
                    step: 1,
                    units: '',
                    index: 4
                },
                /*{
                    parameter: 'Working Angle',
                    value: 90.0,
                    minValue: 0,
                    maxValue: 180,
                    step: 1,
                    units: 'degress',
                    precision: 6,
                    index: 5
                },
                {
                   parameter: 'Turning Type',
                   options:[{value: "auto"}, {value: "straight line"}],
                   value: "auto",
                   units: '',
                   index: 5
                },*/
                {
                    parameter: 'Turn Offset',
                    value: 0,
                    minValue: 0,
                    maxValue: 100,
                    step: 0.1,
                    units: 'meters',
                    index: 5
                },
                {
                    parameter: 'Circuit turns angle threshold',
                    value: 25.0,
                    minValue: 0,
                    maxValue: 45,
                    step: 1,
                    units: 'degress',
                    index: 6
                },
                {
                   parameter: 'Circuit concave turns',
                   options:[{value: "full coverage"}, {value: "simple"}],
                   value: "full coverage",
                   units: '',
                   index: 7
                }
            ],
            data2: [
                {
                    parameter: 'Area Cost Coefficient',
                    value: 0.25,
                    minValue: 0,
                    maxValue: 1,
                    step: 0.05,
                    units: '',
                    index: 0
                },
                {
                    parameter: 'Time Cost Coefficient',
                    value: 0.5,
                    minValue: 0,
                    maxValue: 1,
                    step: 0.05,
                    units: '',
                    index: 1
                },
                {
                    parameter: 'Right Angle Threshold',
                    value: 60,
                    minValue: 0,
                    maxValue: 90,
                    step: 1,
                    units: 'degress',
                    index: 2
                },
                {
                    parameter: 'Collinear Angle Threshold',
                    value: 70,
                    minValue: 0,
                    maxValue: 180,
                    step: 1,
                    units: 'degress',
                    index: 3
                }
            ],
            selectedField: '',
            pattern: "SAP",
            patternStart: "EndNearEntrance",
            workingAngleMode: "OPTIMAL",
            workingAngleEntered: 0,
            vehicle: "e-robotiller",
            speedUnit: "km/h",
            subdivide: false
        };
        
        this.createRoute = this.createRoute.bind(this);

        //this.state.data = ;
    }

    
    componentDidUpdate(prevProps) {

        // el evento onChange del FormControl select (dropdown) para fields solo
        // se dispara cuando el usuario clickea en el control con el mouse
        // Por lo tanto necesito esto para detectar cuando se abre un farm al iniciar la app
        // o cuando se abre un nuevo Farm sobre otro ya abierto

        if(!shallowEqual(this.props.fields, prevProps.fields)) {
            this.handleFieldChange(null);
        }

    }
    

    async createRoute(){

        if (this.inputEl.value.length<1){
            this.dialog.showAlert("Please select a Field");
            return;
        }

        this.props.routePlanIsProcessing(true);

        var selectedField = this.props.fields.find(field => field.fieldId === this.inputEl.value);

        var exteriorRing = JSON.parse(selectedField.geometry);

        var exclusionAreas = this.props.fieldGeometries
            .filter(fg => fg.fieldId == this.inputEl.value && fg.type.includes('EXCLUSION'))
            .map(function(fg) {
                return JSON.parse(fg.geometry);
              });

        var polygons = [exteriorRing].concat(exclusionAreas);

        var obstacles = this.props.fieldGeometries
            .filter(fg => fg.fieldId == this.inputEl.value && fg.type == 'OBSTACLE')
            .map(function (fg) {
                return JSON.parse(fg.geometry);
            });

        var entranceCoords = JSON.parse(selectedField.entrance);

/* TODO: probar esto para correr version debug o produccion (web worker)

    if(process.env.NODE_ENV === 'production') {
        // We are running in production mode
    } else {
    // We are running in development mode
    }

*/


        // **************** 
        // VERSION DEBUG: llamo al RP directamente (ni web worker ni lambda) para depurar fácilmente

        try{

            var rr = routeplannerOpenField({polygonslatlon: polygons,
                obstacles: obstacles,
                turningRadius: this.state.data[0].value, // (m)
                workingWidth: this.state.data[1].value, // (m)
                workingSpeed: (this.state.speedUnit == "km/h") ? this.state.data[2].value/3.6 : this.state.data[2].value, // if unit expressed in km/h, convert to m/s
                turningSpeed: this.state.data[3].value, // (percentage)
                headlandSwaths: this.state.data[4].value, // (N)
                //workingAngle: this.state.data[5].value, // (degrees)
                //fieldName: selectedField.name,
                //fieldId: selectedField.fieldId,
                fieldEntrance: entranceCoords,
                pattern: this.state.pattern,
                patternStart: this.state.patternStart,
                alignmentZone: this.state.data[5].value,
                circuitTurnsMinimumAngle: this.state.data[6].value, // degrees
                fullConcaveTurns: (this.state.data[7].value == "full coverage"), 
                areaCostCoefficient: this.state.data2[0].value,
                timeCostCoefficient: this.state.data2[1].value,
                thresholdRightAngle: this.state.data2[2].value, // (degrees)
                thresholdCollinearAngle: this.state.data2[3].value, // (degrees)
                subdivide: this.state.subdivide,
                workingAngle: this.state.workingAngleMode=="NUMBER" ? this.state.workingAngleEntered : this.state.workingAngleMode
            });


            if (rr.error){
                this.props.routePlanIsProcessing(false);
                this.dialog.showAlert(rr.error);
                return;
            }


            /* version anterior al 'route preview' antes de guardarla en la db

            this.props.deleteRoutePlanner(selectedField.fieldId);
            
            // "await" is important to avoid sync problems. If it is removed, entities.add in "CesiumClickHandler" sometimes uses the same uuid
            for (var r=0; r<rr.routesCoord.length; r++){
                await this.props.createFieldGeometry( selectedField.fieldId,
                    uuid.v1(),
                    // JSON.stringify({pts: rr.routesCoord[r], work: rr.routesWork[r], workArea: rr.workableAreas[r] }),
                    JSON.stringify({pts: rr.routesCoord[r],
                        time: rr.time,
                        speed: rr.speed,
                        work: rr.work,
                        coverageAreas: rr.coverageAreas,
                        routeStats: rr.routeStats,
                        vehicle: this.state.vehicle
                    }),
                    "RP_" + r,
                    'ROUTE',
                    "RP_OPENFIELD"
                );
            }

            */

            if (Array.isArray(rr)){  // if the response is an array, it is a SubFields case

                this.props.routeWasPlanned(selectedField.fieldId, uuid.v1(), "RP_SUBFIELDS", this.state.vehicle, rr );
            
            }
            else{

                this.props.routeWasPlanned(selectedField.fieldId, uuid.v1(), "RP_OPENFIELD", this.state.vehicle, rr );
                
            }


        }
        catch(e){
            this.dialog.showAlert("An error occurred during the generation or the route. Please verify that the parameters are correct");
 
        }

        this.props.routePlanIsProcessing(false);

        

        

        /*
        //  VERSION WEB WORKER: el RP se ejecuta localmente en el cliente. 
        //  Se usa un web worker para no bloquear la UI
        //  Requiere la linea import routePlanner from "../routeplanner/routePlanner";

        const rpWorker = new Worker("../routeplanner/routeplannerworkerRobotiller.js", { type: 'module' });
        rpWorker.onmessage = event => {

            if (event.data.error == "error"){
                this.props.routePlanIsProcessing(false);
                this.dialog.showAlert("At this stage, all lines parallel to the working angle must intersect the field boundary only at two points");
                return;
            }

            this.props.deleteRoutePlanner(selectedField.fieldId);
        
            for (var r=0; r<event.data.routesCoord.length; r++){
                this.props.createFieldGeometry( selectedField.fieldId,
                    uuid.v1(),
                    JSON.stringify({pts: event.data.routesCoord[r],
                        time: event.data.time,
                        speed: event.data.speed,
                        coverageAreas: event.data.coverageAreas,
                        routeStats: event.data.routeStats
                    }),
                    "RP_" + r,
                    'ROUTE',
                    "RP_OPENFIELD"
                );
            }
    
            this.props.routePlanIsProcessing(false);
        };

        var msg = {polygonslatlon: polygons,
            turningRadius: this.state.data[0].value, // (m)
            workingWidth: this.state.data[1].value, // (m)
            workingSpeed: this.state.data[2].value, // (m/s)
            headlandSwaths: this.state.data[3].value, // (N)
            workingAngle: this.state.data[4].value, // (degrees)
            turningType: this.state.data[5].value,
            fieldName: selectedField.name,
            fieldId: selectedField.fieldId,
            fieldEntrance: entranceCoords
        }

        rpWorker.postMessage(msg);

        /* /
        //  VERSION LAMBDA: el RP se ejecuta remotamente en AWS lambda. 
        //  Borrar la linea import routePlanner from "../routeplanner/routePlanner"; para no incluir el código en el cliente
        //  Requiere que esta función (createRoute) sea async

        var rr = await invokeApig({
            path: "/routeplanner",
            method: "POST",
            body: {polygonslatlon: polygons,
                turningRadius: this.state.data[0].value, // (m)
                workingWidth: this.state.data[1].value, // (m)
                workingSpeed: this.state.data[2].value, // (m/s)
                turningTime: this.state.data[3].value, // (s)
                headlandWidth: this.state.data[4].value, // (m)   // -1 for variable headland width (depending on border/track angle)
                areaCostCoefficient: this.state.data[5].value,
                timeCostCoefficient: this.state.data[6].value,
                thresholdRightAngle: this.state.data[7].value, // (degrees)
                thresholdCollinearAngle: this.state.data[8].value, // (degrees)
                turningType: this.state.data[9].value,
                fieldName: selectedField.name,
                fieldId: selectedField.fieldId}
            });

        this.props.deleteRoutePlanner(selectedField.fieldId);
    
        // "await" is important to avoid sync problems. If it is removed, entities.add in "CesiumClickHandler" sometimes uses the same uuid
        for (var r=0; r<rr.routesCoord.length; r++){
            await this.props.createFieldGeometry( selectedField.fieldId,
                uuid.v1(),
                JSON.stringify({pts: rr.routesCoord[r], work: rr.routesWork[r], workArea: rr.workableAreas[r] }),
                "RP_" + r,
                'ROUTE',
                "RP_OPENFIELD"
            );
        }

        this.props.routePlanIsProcessing(false);

        // FIN VERSION LAMBDA
        */
    }

    handleSetOptimal = (e) => {

        this.setState({ workingAngleMode: "OPTIMAL" } );

    }

    handleSetLongestSide = (e) => {

        this.setState({ workingAngleMode: "LONGEST_SIDE" } );

    }

    handleSetEnterAngle = (e) => {

        this.setState({ workingAngleMode: "NUMBER" } );

    }

    handleChangeData(newValue, index){

        const newData = [...this.state.data];
        newData[index].value = newValue;
        this.setState({ data: newData });

    }

    handleChangeData2(newValue, index){

        const newData = [...this.state.data2];
        newData[index].value = newValue;
        this.setState({ data2: newData });

    }

    handleSpeedUnitChange(e){

        this.setState({ speedUnit: e.target.value });

        const newData = [...this.state.data];

        if (e.target.value == "km/h"){
            newData[2].value = newData[2].value * 3.6;
        }
        else if (e.target.value == "m/s"){
            newData[2].value = newData[2].value / 3.6;
        }
        
        this.setState({ data: newData });

    }

    handleFieldChange(e){
        this.setState({ selectedField: this.inputEl.value });

        // compute best working angle (angle of longest polygon side)
/*
        if (this.inputEl.value != ''){

            var selectedField = this.props.fields.find(field => field.fieldId === this.inputEl.value);

            var exteriorRing = JSON.parse(selectedField.geometry);

            var utmPoly = fromLatLonArray(exteriorRing);

            var len = utmPoly.length;

            var maxLength = -1;
            var angle;
            for (var p=0; p<len; p++){

                var q = (p+1)%len;  // next point. 0 if p==len

                var p1 = utmPoly[p];
                var p2 = utmPoly[q];

                var segmentLength = Math.sqrt((p1.x-p2.x)**2 + (p1.y-p2.y)**2);

                if (segmentLength>maxLength){
                    maxLength = segmentLength;
                    angle = Math.atan2(p2.y-p1.y,p2.x-p1.x);
                }
            }

            if (angle<0){
                angle += Math.PI;
            }

            const newData = [...this.state.data];
            newData[5].value = angle*180/Math.PI;
            this.setState({ data:newData });
        }
*/
    }



    render() {

        const columns = [{
            //Header: '',
            accessor: 'parameter' // String-based value accessors!
          }, {
            //Header: '',
            //accessor: 'values',
            //id: 'values1',
            //Cell: JSON.stringify(this.props.values.v)
            //Cell: props => <Button bsStyle="link" onClick={this.handleClick.bind(this,props.value)}>open</Button>
            // Cell: props => <NumericInput className="form-control" value={props.original.value} min={props.original.minValue} max={props.original.maxValue} step={props.original.step} onChange={(e) => this.handleChange(e, props.original.index)} />
            Cell: props => {
                if (props.original.parameter != 'Circuit concave turns')
                    return <NumericInput className="form-control" value={props.original.value} min={props.original.minValue} max={props.original.maxValue} step={props.original.step} precision={props.original.precision} onChange={(e) => this.handleChangeData(e, props.original.index)} />
                else
                    return <FormControl componentClass="select" onChange={(e) => this.handleChangeData(e.target.value, props.original.index)} > 
                    {
                        props.original.options.map((option, index) => {
                            return (<option key={index} value={option.value}>{option.value}</option>)
                        })
                    }
                    </FormControl>
                           }
          },
            {
                //Header: '',
                accessor: 'units', // String-based value accessors!
                Cell: props => {
                    if (props.original.units == 'speedunit')
                        return <FormControl
                            componentClass="select"
                            value={this.state.speedUnit}
                            onChange={e => this.handleSpeedUnitChange(e)}>

                            <option value="km/h">km/h</option>
                            <option value="m/s">m/s</option>

                        </FormControl>
                    else
                        return props.original.units
                }
            }
        ]

        const columns2 = [{
            //Header: '',
            accessor: 'parameter' // String-based value accessors!
        }, {
            //Header: '',
            //accessor: 'values',
            //id: 'values1',
            //Cell: JSON.stringify(this.props.values.v)
            //Cell: props => <Button bsStyle="link" onClick={this.handleClick.bind(this,props.value)}>open</Button>
            // Cell: props => <NumericInput className="form-control" value={props.original.value} min={props.original.minValue} max={props.original.maxValue} step={props.original.step} onChange={(e) => this.handleChange(e, props.original.index)} />
            Cell: props => {
                return <NumericInput className="form-control" value={props.original.value} min={props.original.minValue} max={props.original.maxValue} step={props.original.step} precision={props.original.precision} onChange={(e) => this.handleChangeData2(e, props.original.index)} />
            }
        },
        {
            //Header: '',
            accessor: 'units', // String-based value accessors!
            Cell: props => {
                return props.original.units
            }
        }
        ]
        
        //this.state.data[0].value = JSON.stringify(this.props.fields);

        return (



            <div className="RoutePlanner">
                <form>
                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Field:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select" inputRef={el => this.inputEl = el} onChange={(e) => this.handleFieldChange(e)} >
                                    {
                                        this.props.fields.map((option, index) => {
                                            return (<option key={index} value={option.fieldId}>{option.name}</option>)
                                        })
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Vehicle Model:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    value={this.state.vehicle}
                                    onChange={e => this.setState({ vehicle: e.target.value })}>
                                    <option value="e-robotiller">e-robotiller</option>
                                    {/* <option value="burro">Burro</option> */}
                                    <option value="etrac">eTrac</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Fieldwork Pattern:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    value={this.state.pattern}
                                    onChange={e => this.setState({ pattern: e.target.value })}>
                                    <option value="SAP">Straight Alternation (no skipping)</option>
                                    <option value="SFP3">Skip and Fill Pattern (SFP) - skip 3 rows</option>
                                    <option value="SFP5">Skip and Fill Pattern (SFP) - skip 5 rows</option>
                                    <option value="SFP7">Skip and Fill Pattern (SFP) - skip 7 rows</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Pattern Start:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    value={this.state.patternStart}
                                    onChange={e => this.setState({ patternStart: e.target.value })}>
                                    <option value="StartNearEntrance">Start the pattern near the entrance</option>
                                    <option value="EndNearEntrance">End the pattern near the entrance</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Col sm={12}>
                            <ReactTable
                                data={this.state.data}
                                sortable={false}
                                columns={columns}
                                className="-striped -highlight"
                                showPagination={false}
                                pageSize={this.state.data.length} />
                        </Col>
                    </FormGroup>

                    <FormGroup >
                        <Col sm={12}>
                            <div style={{ margin: "10px 0 10px 0" }}>
                                <Checkbox checked={this.state.subdivide} onChange={e => this.setState({subdivide:e.target.checked})} value="1" >
                                    Divide into Subfields
                                </Checkbox>
                            </div>
                        </Col>
                    </FormGroup>
{ !this.state.subdivide &&
                    <FormGroup >
                        <Row>
                            <Col sm={12} >
                            Working angle:
                            </Col>
                        </Row>
                        <Col sm={12}>
                            <div style={{ margin: "10px 0 10px 0" }}>
                                <Radio name="radioGroup" checked={this.state.workingAngleMode == "OPTIMAL"} onChange={(e) => this.setState({ workingAngleMode: "OPTIMAL" })} inline >
                                    Optimal
                                </Radio>
                                <Radio name="radioGroup" checked={this.state.workingAngleMode == "LONGEST_SIDE"} onChange={(e) => this.setState({ workingAngleMode: "LONGEST_SIDE" })} inline >
                                    Longest side
                                </Radio>
                                <Radio name="radioGroup" checked={this.state.workingAngleMode == "NUMBER"} onChange={(e) => this.setState({ workingAngleMode: "NUMBER" })} inline >
                                    Enter angle:  
                                </Radio>
                                <input type="number" style={{ margin: "0 0 0 10px" }}
                                    value={this.state.workingAngleEntered}
                                    min={0}
                                    max={179}
                                    step={1}
                                    onChange={e => this.setState({ workingAngleEntered: e.target.value })}
                                    disabled={this.state.workingAngleMode != "NUMBER"} />

                            </div>
                        </Col>
                    </FormGroup>
}

{ this.state.subdivide &&
                    <FormGroup>
                        <Col sm={12}>
                            <ReactTable
                                data={this.state.data2}
                                sortable={false}
                                columns={columns2}
                                className="-striped -highlight"
                                showPagination={false}
                                pageSize={this.state.data2.length} />
                        </Col>
                    </FormGroup>
}
                    <FormGroup>
                        <Col sm={12}>
                            <div style={{ margin: "10px 0 10px 0" }}>
                                <Button
                                    bsStyle="primary"
                                    bsSize="small"
                                    onClick={this.createRoute} // {() =>  { this.props.routePlanIsProcessing(true); }}  
                                >
                                    Create Route
                                </Button>
                            </div>
                            <Dialog ref={(el) => { this.dialog = el }} />
                        </Col>
                    </FormGroup>
                </form>

            </div>
        )
    }
}
/*
RoutePlanner.propTypes = {
    fields: PropTypes.array.isRequired,
    routes: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
    return {
        farms: state.farms,
        fields: state.fields,
        routes: state.routes
    };
};*/

const mapStateToProps = (state) => {
    return {
        fields: state.fields,
        fieldGeometries: state.fieldGeometries
        //rpIsProcessing: state.routePlanIsProcessing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFieldGeometry: (fieldId, fieldGeometryId, geometry, name, type, origin) => dispatch(createFieldGeometry(fieldId, fieldGeometryId, geometry, name, type, origin)),
        routePlanIsProcessing: (status) => dispatch(routePlanIsProcessing(status)),
        routeWasPlanned: (parentFieldId, id, origin, vehicle, routeObject) => dispatch(routeWasPlanned(parentFieldId, id, origin, vehicle, routeObject))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RobotillerRoutePlanner);