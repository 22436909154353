import { combineReducers } from 'redux';
import { farms, farmsHasErrored, farmsIsLoading, currentFarm } from './farms';
import { fields, fieldsHasErrored, fieldsIsLoading, createField } from './fields';
import { fieldGeometries, fieldGeometriesHasErrored, fieldGeometriesIsLoading } from './fieldGeometries';
import { entityIsDrawing, setVisibility } from './cesiumDrawing';
import { cesiumSelectedEntityChanged } from './cesiumSelectedEntity';
import { routePlanIsProcessing, userAttributes, routeWasPlanned } from './appStatus';
import { roslibStatus, roslibMessageReceived, roslibMessagePublished } from './ros';

// rootReducer renamed as appReducer: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store 

const appReducer = combineReducers({
    farms,
    userAttributes,
    currentFarm,
    farmsHasErrored,
    farmsIsLoading,
    fields,
    fieldsHasErrored,
    fieldsIsLoading,
    fieldGeometries,
    fieldGeometriesHasErrored,
    fieldGeometriesIsLoading,
    entityIsDrawing,
    setVisibility,
    cesiumSelectedEntityChanged,
    routePlanIsProcessing,
    routeWasPlanned,
    roslibStatus,
    roslibMessageReceived,
    roslibMessagePublished
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer;