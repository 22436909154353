import React, { Component } from "react";
import { connect } from 'react-redux';
import "./Home.css";
import CesiumGlobe from "../cesium/CesiumGlobe";
import Farms from "../components/Farms";
import OpenedFarm from "../components/OpenedFarm";
//import RoutePlanner from "../components/RoutePlanner";
import RobotillerRoutePlanner from "../components/RobotillerRoutePlanner";
import OrchardRoutePlanner from "../components/OrchardRoutePlanner";
import RGL, { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Button } from "react-bootstrap";
import { Modal, FormGroup, FormControl, ControlLabel, Tab, Tabs } from "react-bootstrap";
import { ScaleLoader } from 'react-spinners';
import RosConnection from "../components/RosConnection";
import Formant from "../components/Formant";
import RosCamera from "../components/RosCamera";
import RosImu from "../components/RosImu";
import RosLaserScan from "../components/RosLaserScan";
import RouteFollowing from "../components/RouteFollowing";

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = WidthProvider(RGL);

const defaultLayout = [
  {i: 'myFarms', x: 0, y: 0, w: 5, h: 6},
  {i: 'farm', x: 0, y: 6, w: 5, h: 5},
  {i: 'routePlanner', x: 0, y: 11, w: 5, h: 5},
  {i: 'map', x: 5, y: 0, w: 7, h: 16},
  {i: 'ros-connection', x: 0, y: 16, w: 3, h: 7},
  {i: 'ros-video', x: 3, y: 16, w: 3, h: 7},
  {i: 'ros-imu', x: 6, y: 16, w: 3, h: 7},
  {i: 'ros-laserscan', x: 9, y: 16, w: 3, h: 7},
  {i: 'routeFollowing', x: 0, y: 19, w: 3, h: 7}
];

const customLayout = getFromLS("layout") || defaultLayout;



const modalStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0, bottom: 0, left: 0, right: 0,
};

const backdropStyle = {
  ...modalStyle,
  zIndex: 'auto',
  backgroundColor: '#000',
  opacity: 0.6
};

const dialogStyle = function() {
  return {
    position: 'absolute',
    width: 200,
    top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #e5e5e5',
    backgroundColor: 'white',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    padding: 20
    
  };
};


class Home extends Component {


  constructor(props) {
    super(props);
  
    this.state = {
      showModal: false,
      layout: JSON.parse(JSON.stringify(customLayout))
    };
  
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.onLayoutChange = this.onLayoutChange.bind(this);
    //  this.resetLayout = this.resetLayout.bind(this); // TODO: add a "reset layout" menu option

  }


  close(){
    this.setState({ showModal: false });
  }

  open(){
    this.setState({ showModal: true });
  }

  onLayoutChange(layout) {
    /*eslint no-console: 0*/
    saveToLS("layout", layout);
    this.setState({ layout });
    //this.props.onLayoutChange(layout); // updates status display
  }


  render() {
    // layout is an array of objects, see the demo for more complete usage
    var layout = [
      {i: 'myFarms', x: 0, y: 0, w: 5, h: 6},
      {i: 'farm', x: 0, y: 6, w: 5, h: 5},
      {i: 'routePlanner', x: 0, y: 11, w: 5, h: 5},
      {i: 'map', x: 5, y: 0, w: 7, h: 16},
      {i: 'ros-connection', x: 0, y: 16, w: 3, h: 7},
      {i: 'ros-video', x: 3, y: 16, w: 3, h: 7},
      {i: 'ros-imu', x: 6, y: 16, w: 3, h: 7},
      {i: 'ros-laserscan', x: 9, y: 16, w: 3, h: 7},
      {i: 'routeFollowing', x: 0, y: 19, w: 3, h: 7}
    ];
    return(
    <div>
      <ReactGridLayout layout={this.state.layout} onLayoutChange={this.onLayoutChange} cols={12} rowHeight={30} width={1200} draggableHandle=".custom-button" draggableCancel="input,textarea" >
        <div className="layoutItem"  key="myFarms">
          <Button className="custom-button">
            My Farms
          </Button>
          <div className={this.props.entityIsDrawing ? 'disableditem' : ''} style={{height:"100%", overflow:"auto" }}>
            <Farms/>
          </div>
        </div>

        <div className="layoutItem" key="farm" >
          <Button className="custom-button">
            Farm{this.props.currentFarm.currentFarmName ? ": '" + this.props.currentFarm.currentFarmName + "'" : ""} 
          </Button>
          <div className={this.props.entityIsDrawing || !this.props.currentFarm.currentFarmName ? 'disableditem' : ''} style={{height:"100%", overflow:"auto"}}>
            <OpenedFarm/>
          </div>
        </div>

        <div className="layoutItem" key="routePlanner">
          <Button className="custom-button">
            Route Planner
          </Button>
          <div className={this.props.entityIsDrawing || !this.props.currentFarm.currentFarmName ? 'disableditem' : ''}  style={{height:"100%", overflow:"auto" }}>
          <Tabs defaultActiveKey={1} bsStyle="pills">
            {/* html format to make the title multiline
              <Tab eventKey={2} title={<p>Headland Circuit +<br/>Interior Straight-Lines</p>}>comments
            */
            }
            <Tab eventKey={1} title="Open Field">
              <RobotillerRoutePlanner/>
            </Tab>
            <Tab eventKey={2} title="Orchard">
              <OrchardRoutePlanner/>
            </Tab>
          </Tabs>
          </div>
        </div>

        <div className="layoutItem" key="map" >
          <Button className="custom-button">
            Map
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <CesiumGlobe/>
          </div>
        </div>
{/* 
        <div className="layoutItem" key="ros-connection" >
          <Button className="custom-button">
            Connection
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <Tabs defaultActiveKey={1} bsStyle="pills">
              <Tab eventKey={1} title="ROS Bridge">
                <RosConnection/>
              </Tab>
              <Tab eventKey={2} title="Formant">
                <Formant/>
              </Tab>
            </Tabs>
            
          </div>
        </div>
*/}
        <div className="layoutItem" key="ros-connection" >
          <Button className="custom-button">
            ROS - Connection
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <RosConnection/>
          </div>
        </div>

        <div className="layoutItem" key="ros-video" >
          <Button className="custom-button">
            ROS - camera
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <RosCamera/>
          </div>
        </div>

        <div className="layoutItem" key="ros-imu" >
          <Button className="custom-button">
            ROS - IMU
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <RosImu/>
          </div>
        </div>

        <div className="layoutItem" key="ros-laserscan" >
          <Button className="custom-button">
            ROS - LaserScan
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <RosLaserScan/>
          </div>
        </div>

        <div className="layoutItem" key="routeFollowing" >
          <Button className="custom-button">
            Route Following
          </Button>
          <div style={{height:"100%", overflow:"auto" }}>
            <RouteFollowing/>
          </div>
        </div>

{/*
      comments
*/
}

      </ReactGridLayout>
      <Modal
        aria-labelledby='modal-label'
        keyboard={false}
        style={modalStyle}
        backdropStyle={backdropStyle}
        show={this.props.routePlanIsProcessing}
        onHide={this.close}
        backdrop='static'
        >
        <div style={{textAlign: "center", padding: '10px'}} >
          <ScaleLoader
            height = {60}
            width = {3}
            margin = {'6px'}
            radius = {16}
            color={'#36D7B7'}
            loading={true}
          />
        </div> 
      </Modal>
    </div>
    )
  }

}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-cf")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-cf",
      JSON.stringify({
        [key]: value
      })
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentFarm: state.currentFarm,
    entityIsDrawing: state.entityIsDrawing.entityIsDrawing || state.entityIsDrawing.entityIsSimplifying ,
    routePlanIsProcessing: state.routePlanIsProcessing
  }
}

export default connect(mapStateToProps) (Home);
