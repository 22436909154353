export function fieldGeometriesHasErrored(state = false, action) {
    switch (action.type) {
        case 'FIELDGEOMETRIES_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function fieldGeometriesIsLoading(state = false, action) {
    switch (action.type) {
        case 'FIELDGEOMETRIES_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}
/*
export function fieldGeometries(state = [], action) {
    switch (action.type) {
        case 'FIELDGEOMETRIES_FETCH_DATA_SUCCESS':
            return action.fieldGeometries;

        default:
            return state;
    }
}
*/
export function fieldGeometries(state = [], action) {
    switch (action.type) {
        case 'FIELDGEOMETRIES_FETCH_DATA_SUCCESS':
            return action.fieldGeometries;
        case 'CREATE_FIELDGEOMETRY':
            /* en el caso que el RP genere solo una ruta
            // if the origin is RP => delete existing routes from RP for that field
            //state = state.filter( fg => !(fg.fieldId == action.payload.fieldId && fg.origin == "RP"));
            return [ ...state.filter( fg => !(fg.fieldId == action.payload.fieldId && fg.origin == "RP")), action.payload]
            */
           return [ ...state, action.payload];
        case 'DELETE_FIELDGEOMETRY':
            return state.filter(fg => fg.fieldGeometryId !== action.payload.fieldGeometryId);
        //case 'DELETE_RP':
        //    return state.filter(fg => !(fg.fieldId == action.payload.fieldId && fg.origin.startsWith("RP")));
        case 'DELETE_FIELD':
            return state.filter(fg => fg.fieldId !== action.payload.fieldId);
        //case 'FIELDGEOMETRIES_RESET':
        //    return [];
        case 'CREATE_FARM':
            return [];
        case 'DELETE_FARM':
            // if the farm to delete is opened, the fieldgeometries must be removed
            if (action.payload.isOpened)
                return [];
            else
                return state;
        default:
            return state;
    }
}