import { invokeApig } from '../libs/awsLib';

export function fieldsHasErrored(bool) {
    return {
        type: 'FIELDS_HAS_ERRORED',
        hasErrored: bool
    };
}

export function fieldsIsLoading(bool) {
    return {
        type: 'FIELDS_IS_LOADING',
        isLoading: bool
    };
}

export function fieldsFetchDataSuccess(fields) {
    return {
        type: 'FIELDS_FETCH_DATA_SUCCESS',
        fields
    };
}

/*
export function fieldsReset(){
    return {type: 'FIELDS_RESET'};
}
*/

export function createField(farmId, fieldId, geometry, name, entrance) {
    // TODO: make this function async (see fieldsFetchData below )
    // and check if the response is ok
    invokeApig({
        path: "/fields",
        method: "POST",
        body:{farmId: farmId, fieldId: fieldId, geometry: geometry, name: name}
    });

    return {
        type: 'CREATE_FIELD',
        payload: {farmId: farmId, fieldId: fieldId, geometry: geometry, name: name, entrance: entrance}
    };
}

export function deleteField(farmId, fieldId){

    invokeApig({
        path: "/farms/" + farmId + "/fields/" + fieldId,
        method: "DELETE",
    });

    return {
        type: 'DELETE_FIELD',
        payload: {fieldId: fieldId}
    };

}

export function updateField(farmId, fieldId, entrance){

    invokeApig({
        path: "/farms/" + farmId + "/fields/" + fieldId,
        method: "PUT",
        body:{entrance: entrance}
    });

    return {
        type: 'UPDATE_FIELD',
        payload: {fieldId: fieldId, entrance: entrance}
    };

}


export function fieldsFetchData(farmId) {

    // this one's 'async' 
    return async dispatch => {

        dispatch(fieldsIsLoading(true));

        // wrap in try to listen for Promise rejections - equivalent of '.catch()'
        try {
            // wait for the fetch to finish then dispatch the result
            const data = await fields(farmId);
            for (var f=0;f<data.length;f++){

                // if the entrance is not defined in dynamodb, assign the first point of geometry
                // this can be eliminated in the future when all fields have the entrance defined 
                if (!data[f].entrance){
                    var firstcomma = data[f].geometry.indexOf(",");
                    var secondcomma = data[f].geometry.indexOf(",", firstcomma + 1);
                    data[f].entrance = data[f].geometry.substring(0, secondcomma) + ']';
                }
            }
            dispatch(fieldsFetchDataSuccess(data));
        } catch (e) {
            // catch errors from fetch
            dispatch(fieldsHasErrored(true));
        }

        dispatch(fieldsIsLoading(false));
    };
}

var fields = function(farmId) {
    return invokeApig({ path: "/farms/" + farmId + "/fields" });
}



