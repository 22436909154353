export function fieldsHasErrored(state = false, action) {
    switch (action.type) {
        case 'FIELDS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function fieldsIsLoading(state = false, action) {
    switch (action.type) {
        case 'FIELDS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function fields(state = [], action) {
    switch (action.type) {
        case 'FIELDS_FETCH_DATA_SUCCESS':
            return action.fields;
        case 'CREATE_FIELD':
            //return {farmId: action.payload.farmId, fieldId: action.payload.fieldId, geometry: action.payload.geometry, name: action.payload.name};
            return [ ...state, action.payload]
        case 'DELETE_FIELD':
            return state.filter(f => f.fieldId !== action.payload.fieldId);
        //case 'FIELDS_RESET':
        //    return [];
        case "UPDATE_FIELD":
            return state.map(f => f.fieldId === action.payload.fieldId ?
                // transform the one with a matching id
                { ...f, entrance: action.payload.entrance } :
                // otherwise return original field
                f
            );
        case 'CREATE_FARM':
            return [];
        case 'DELETE_FARM':
            // if the farm to delete is opened, the fields must be removed
            if (action.payload.isOpened)
                return [];
            else
                return state;
        default:
            return state;
    }
}
