import { createSelector } from 'reselect'

// selector
const getFields = (state) => state.fields;
const getFieldGeometries = (state) => state.fieldGeometries;


// reselect function
export const getFarmTree = createSelector(
    getFields,
    getFieldGeometries,
    (fields, fieldGeometries) => {

        var tree = {name:'[FARM]', id:'farm:', toggled:true,
            children:[{name:'[FIELDS]', id:'fields:',toggled:true,children:[]}]
        };

        try{

        // Add Field nodes
        for(var i = 0; i < fields.length; i++){
            tree.children[0].children.push({
                name: fields[i].name, id: 'fld:' + fields[i].fieldId + ':frm:' + fields[i].farmId,
                toggled: true,
                children:[
                    {name:'[ROUTES]', id:'routes:fld:' + fields[i].fieldId, toggled:true,children:[]},
                    {name:'[EXCLUSION AREAS]', id:'exclusions:fld:' + fields[i].fieldId, toggled:true,children:[]},
                    {name:'[OBSTACLES]', id:'obstacles:fld:' + fields[i].fieldId, toggled:true,children:[]},
                    {name:'[WORKING AREAS]', id:'workingareas:fld:' + fields[i].fieldId, toggled:true,children:[]},
                    {name:'[INTER-ROW PATHS]', id:'interrowpaths:fld:' + fields[i].fieldId, toggled:true,children:[]}
                ]}
            );
        }

        for(var i = 0; i < fieldGeometries.length; i++){
            switch(fieldGeometries[i].type) {
                case 'ROUTE':
                    var node1 = tree.children[0].children.find(x => x.id.startsWith('fld:' + fieldGeometries[i].fieldId));
                    var node2 = node1.children.find(x => x.name === '[ROUTES]')
                    node2.children.push({ name: fieldGeometries[i].name, id: 'rou:' + fieldGeometries[i].fieldGeometryId + ':fld:' + fieldGeometries[i].fieldId});
                    break;
                case 'POSITIVE_EXCLUSION_AREA':
                    var node1 = tree.children[0].children.find(x => x.id.startsWith('fld:' + fieldGeometries[i].fieldId));
                    var node2 = node1.children.find(x => x.name === '[EXCLUSION AREAS]')
                    node2.children.push({ name: fieldGeometries[i].name, id: 'exc:' + fieldGeometries[i].fieldGeometryId + ':fld:' + fieldGeometries[i].fieldId});                   
                    break;
                case 'OBSTACLE':
                    var node1 = tree.children[0].children.find(x => x.id.startsWith('fld:' + fieldGeometries[i].fieldId));
                    var node2 = node1.children.find(x => x.name === '[OBSTACLES]')
                    node2.children.push({ name: fieldGeometries[i].name, id: 'obs:' + fieldGeometries[i].fieldGeometryId + ':fld:' + fieldGeometries[i].fieldId });
                    break;
                case 'WORKING_AREA':
                    var node1 = tree.children[0].children.find(x => x.id.startsWith('fld:' + fieldGeometries[i].fieldId));
                    var node2 = node1.children.find(x => x.name === '[WORKING AREAS]')
                    node2.children.push({ name: fieldGeometries[i].name, id: 'wor:' + fieldGeometries[i].fieldGeometryId + ':fld:' + fieldGeometries[i].fieldId});                   
                    break;
                case 'INTER_ROW_PATHS':
                    var node1 = tree.children[0].children.find(x => x.id.startsWith('fld:' + fieldGeometries[i].fieldId));
                    var node2 = node1.children.find(x => x.name === '[INTER-ROW PATHS]')
                    node2.children.push({ name: fieldGeometries[i].name, id: 'int:' + fieldGeometries[i].fieldGeometryId + ':fld:' + fieldGeometries[i].fieldId});                   
                    break;
            }

        }
        }catch(error){
            return {name:'[FARM]',id:'farm:',toggled:true,
            children:[{name:'[FIELDS]',id: 'fld:',toggled:true,children:[]}]
            }
        }
        return  tree;
/*
        var tree = {
            name: 'react-treebeard',
            toggled: true,
            children: [
                {
                    name: 'example',
                    children: [
                        { name: 'app.js' },
                        { name: 'data.js' },
                        { name: 'index.html' },
                        { name: 'styles.js' },
                        { name: 'webpack.config.js' }
                    ]
                },
                {
                    name: 'node_modules',
                    loading: true,
                    children: []
                },
                {
                    name: 'src',
                    children: [
                        {
                            name: 'components',
                            children: [
                                { name: 'decorators.js' },
                                { name: 'treebeard.js' }
                            ]
                        },
                        { name: 'index.js' }
                    ]
                },
                {
                    name: 'themes',
                    children: [
                        { name: 'animations.js' },
                        { name: 'default.js' }
                    ]
                },
                { name: 'Gulpfile.js' },
                { name: 'index.js' },
                { name: 'package.json' }
            ]
        }
*/
    //var node = tree.children.find(x => x.id === 'asdfasdf-asdfasdf');
    //node.children.push({ name: 'new nested child!'});

    //return tree;


    
    }
)




