

// action creators

export function roslibConnect(url) {
    return {
        type: 'ROSLIB_CONNECT',
        url: url
    };
}

export function roslibDisconnect() {
    return {
        type: 'ROSLIB_DISCONNECT'
    };
}

export function roslibChangeStatus(newStatus) {
    return {
        type: 'ROSLIB_CHANGE_STATUS',
        newStatus: newStatus
    };
}

export function roslibUpdateMessageReceived(topicName, topicType, topicMessage) {
    return {
        type: 'ROSLIB_UPDATE_MESSAGE_RECEIVED',
        payload: {name: topicName, type: topicType, message: topicMessage}
    };
}

export function roslibPublishMessage(topicName, topicType, topicMessage) {
    return {
        type: 'ROSLIB_PUBLISH_MESSAGE',
        payload: {name: topicName, type: topicType, message: topicMessage}
    };
}