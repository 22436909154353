import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { farmsFetchData, currentFarmSet, createFarm, deleteFarm } from '../actions/farms'
import { fieldsFetchData } from '../actions/fields'
import { fieldGeometriesFetchData } from '../actions/fieldGeometries'
import { userAttributesSet } from '../actions/appStatus'
import { Button } from "react-bootstrap";
import { Form, FormGroup, FormControl, ControlLabel} from "react-bootstrap";
import { invokeApig, getUserAttrib } from "../libs/awsLib";
import ReactTable from 'react-table';
import "./Farms.css";
import 'react-table/react-table.css';
import uuid from "uuid";
import Dialog from 'react-bootstrap-dialog';

class Farms extends React.Component {

    async componentDidMount() {

        try {
            var userAttr = await getUserAttrib();
            if (userAttr) {
                this.props.setUserAttributes(userAttr.sub, userAttr.email);
            }
        }
        catch (e) {
            alert(e);
        }

        this.props.fetchFarms();
    }

    constructor(props) {
		super(props);

        //this.handleChange = this.handleChange.bind(this);
        this.createFarm = this.createFarm.bind(this);
        this.handleClickOpenFarm = this.handleClickOpenFarm.bind(this);
        this.handleClickDeleteFarm = this.handleClickDeleteFarm.bind(this);
        
/*
		this.state = {
			farmName: ''
        };
*/
    }
/*
    handleChange(e) {
        this.setState({ farmName: e.target.value });
    }
*/
    async handleClickOpenFarm (farmId){
        var res = this.props.farms.find( farm => farm.farmId === farmId );  
        this.props.setCurrentFarm(farmId, res.name);
        // async/await to wait for the resulting array containing the field Id's
        await this.props.fetchFields(farmId);
        // extract the array of field Id's from the 'fields' prop
        var fieldsIds = this.props.fields.map(a => a.fieldId); 
        this.props.fetchFieldGeometries(fieldsIds);
    }

    async handleClickDeleteFarm (farmId){

        this.dialog.show({
            body: "Delete this farm?",
            actions: [
                Dialog.CancelAction(),
                Dialog.DefaultAction(
                    'Delete',
                    () => { this.props.deleteFarm(farmId, this.props.currentFarm.currentFarmId == farmId) },
                    'btn-danger'
                )
            ]
        })

    }

    async createFarm() {

        var name = await new Promise((resolve, reject) => {
            this.dialog.show({
                body: 'Enter farm name',
                prompt: Dialog.TextPrompt(),
                actions: [
                    Dialog.CancelAction(() => {
                        resolve("");
                    }),
                    Dialog.OKAction((dialog) => {
                        resolve(dialog.value);
                    })
                ]
            });
        });

        if (name !== ""){

            var newFarmId = uuid.v1();
            this.props.createFarm(newFarmId, name, this.props.userAttributes.userAttrSub, this.props.userAttributes.userAttrEmail);
            /*
            invokeApig({
                path: "/farms",
                method: "POST",
                body:{"name": name, "id": newFarmId}
            });

            this.props.setCurrentFarm(newFarmId, name);
            this.props.resetFields();
            this.props.resetFieldGeometries();
            */
        }
        else{

        }

        

    }


    getFarm(){
        const results =  invokeApig({ path: `/farms/6a3957b0-0dfd-11e8-954c-2135e3cfb285` });
        return results;

    }

    getFarms(){
        const results =  invokeApig({ path: `/farms` });
        return results;

    }


    render() {
        if (this.props.farmsHasErrored) {
            return <p>Sorry! There was an error loading the farms</p>;
        }

        if (this.props.farmsIsLoading) {
            return <p>Loading farms…</p>;
        }

        if (this.props.fieldsHasErrored) {
            return <p>Sorry! There was an error loading the fields</p>;
        }

        if (this.props.fieldsIsLoading) {
            return <p>Loading fields…</p>;
        }

        if (this.props.fieldGeometriesHasErrored) {
            return <p>Sorry! There was an error loading the fieldGeometries</p>;
        }

        if (this.props.fieldGeometriesIsLoading) {
            return <p>Loading field geometries…</p>;
        }


        const columns = [
        {
                Header: 'farms',
                accessor: 'name' // String-based value accessors!
            }, {
                Header: '',
                accessor: 'farmId',
                Cell: props => <Button bsStyle="link" onClick={this.handleClickOpenFarm.bind(this,props.value)}>open</Button>
            }, {
                Header: '',
                accessor: 'farmId',
                Cell: props => <Button bsStyle="link" onClick={this.handleClickDeleteFarm.bind(this,props.value)}>delete</Button>
            }
        ]

        return (
            <div className="Farms">
                <Form >

                    <FormGroup>
                        <div style={{margin: "10px 0 10px 0"}} >
                            <Button 
                                bsStyle="primary"
                                bsSize="small"                            
                                onClick={this.createFarm} 
                                >
                            Create new Farm
                            </Button>
                        </div>
                        <ReactTable
                            noDataText='No farms found. Create one'
                            data={this.props.farms}
                            columns={ columns }
                            className= "-striped -highlight"
                            showPagination = {false}
                            pageSize = {this.props.farms.length} />
                        <Dialog ref={(el) => { this.dialog = el }} />
                    </FormGroup>
                </Form>
            </div>
        )
    }
}


Farms.propTypes = {
    fetchFarms: PropTypes.func.isRequired,
    fetchFields: PropTypes.func.isRequired,
    fetchFieldGeometries: PropTypes.func.isRequired,
    farms: PropTypes.array.isRequired,
    currentFarm: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    fieldGeometries: PropTypes.array.isRequired,
    farmsHasErrored: PropTypes.bool.isRequired,
    fieldsHasErrored: PropTypes.bool.isRequired,
    fieldGeometriesHasErrored: PropTypes.bool.isRequired,
    farmsIsLoading: PropTypes.bool.isRequired,
    fieldsIsLoading: PropTypes.bool.isRequired,
    fieldGeometriesIsLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        farms: state.farms,
        currentFarm: state.currentFarm,
        userAttributes: state.userAttributes,
        farmsHasErrored: state.farmsHasErrored,
        farmsIsLoading: state.farmsIsLoading,
        fields: state.fields,
        fieldsHasErrored: state.fieldsHasErrored,
        fieldsIsLoading: state.fieldsIsLoading,
        fieldGeometries: state.fieldGeometries,
        fieldGeometriesHasErrored: state.fieldGeometriesHasErrored,
        fieldGeometriesIsLoading: state.fieldGeometriesIsLoading    
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFarms: () => dispatch(farmsFetchData()),
        setCurrentFarm: (fId,fName) => dispatch(currentFarmSet(fId,fName)),
        fetchFields: (fId) => dispatch(fieldsFetchData(fId)),
        fetchFieldGeometries: (fieldsIds) => dispatch(fieldGeometriesFetchData(fieldsIds)),
        createFarm: (fId, fName, fUserSub, fUserEmail) => dispatch(createFarm(fId, fName, fUserSub, fUserEmail)),
        deleteFarm: (fId, isOpened) => dispatch(deleteFarm(fId, isOpened)),
        setUserAttributes: (sub,email) => dispatch(userAttributesSet(sub,email))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Farms);