export function farmsHasErrored(state = false, action) {
    switch (action.type) {
        case 'FARMS_HAS_ERRORED':
            return action.hasErrored;

        default:
            return state;
    }
}

export function farmsIsLoading(state = false, action) {
    switch (action.type) {
        case 'FARMS_IS_LOADING':
            return action.isLoading;

        default:
            return state;
    }
}

export function farms(state = [], action) {
    switch (action.type) {
        case 'FARMS_FETCH_DATA_SUCCESS':
            return action.farms.sort(function(a, b) {
                return b.openedAt - a.openedAt;
            });
        case 'CREATE_FARM':
            return [ ...state, action.payload];
        case 'DELETE_FARM':
            return state.filter(f => f.farmId !== action.payload.farmId);
        default:
            return state;
    }
}


export function currentFarm(state = {}, action) {
    switch (action.type) {
        case 'CREATE_FARM':
        return {
            currentFarmId: action.payload.farmId,
            currentFarmName: action.payload.name
        };
        case 'CURRENT_FARM_SET':
        return {
            currentFarmId: action.payload.currentFarmId,
            currentFarmName: action.payload.currentFarmName
        };
        case 'DELETE_FARM':
            if (action.payload.isOpened) // farm to delete is currently opened
                return {};
            else
                return state;
        default:
            return state;
    }
}

