import React from "react";
import { connect } from 'react-redux';

import "./RosCamera.css";

class RosCamera extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            picture: ''
        };
        
    }

    // detect when image has changed
    componentDidUpdate(prevProps) {
        if ( this.props.roslibMessageReceived.type  == "sensor_msgs/CompressedImage"){
            if (prevProps.roslibMessageReceived != this.props.roslibMessageReceived ){
                var obj = JSON.parse(this.props.roslibMessageReceived.message);
                this.setState({ picture: 'data:image/jpg;base64,' + obj.data });
            }
        }
    }
    
    render() {
        return (

            <div className="RosCamera">
                <img id="imgElem" src={this.state.picture} img style={{height: '100%', width: '100%'}} />
            </div>
        )


    }

}

const mapStateToProps = (state) => {
    return {
        roslibMessageReceived: state.roslibMessageReceived
  
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //createFarm: (fId, fName) => dispatch(createFarm(fId, fName)),
        //deleteFarm: (fId, isOpened) => dispatch(deleteFarm(fId, isOpened))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosCamera);