
export function routePlanIsProcessing(bool) {
    return {
        type: 'ROUTEPLAN_IS_PROCESSING',
        isProcessing: bool
    };
}

export function routeWasPlanned(parentFieldId, id, origin, vehicle, routeObject) {
    return {
        type: 'ROUTE_WAS_PLANNED',
        parentFieldId: parentFieldId,
        id: id,
        origin: origin,
        vehicle: vehicle,
        routeObject: routeObject
    };
}

export function userAttributesSet(userSub, userEmail) {
    return {
        type: 'USER_ATTRIBUTES_SET',
        payload: {userAttributesSub: userSub, userAttributesEmail: userEmail}
    };
}

