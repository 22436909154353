'use strict'

import { distancePointPoint, intersectLinePolygon, polygonArea, rotatePoint, rotatePolygon, rotatePolygons, intersectLineLine2,
     removeSpikes, removeCollinearPoints, isPointInPolygon, simplifyPolygonWithBuffer, polygonBuffer,
      removeNearPoints, trapezoidsToJsts, simplifyAndNormalizePolygon, ringToGeojsonPolygon, intersectLineSegment,
       closestPointInPolygon, closestPointInSegment, distanceAroundPolygon, closestPointInPolygon2,
        simplifyRoute, polylineToGeojsonLineString, computeAreas, pointFromEndOfLine, distToSegmentSquared, distToPolygonSquared } from './geometry';

import {fromLatLonArray, fromLatLon, toLatLonArray2} from '../utils/utm';


export default function routePlannerOrchard(params){

    var polygonField = params.polygonlatlonField;
    //var polygonslatlon = params.polygonslatlon;
    var interrowpaths = params.interrowpaths;
    var turningRadius = params.turningRadius; // (m)
    //var workingWidth = params.workingWidth; // (m)
    var workingSpeed = params.workingSpeed; // (m/s)
    var headlandSwaths = params.headlandSwaths; // (N) 
    //var workingAngle = params.workingAngle;
    var turningType = params.turningType;
    var fieldName = params.fieldName;
    var fieldId = params.fieldId; 
    var fieldEntrance = params.fieldEntrance;
    var pattern = params.pattern;
    var patternStart = params.patternStart;
    var alignmentZone = params.alignmentZone;
    // var speedUnit = params.speedUnit;
    var turningSpeedPercentage = params.turningSpeed;

    if (params.patternStart == undefined){
        patternStart = "StartNearEntrance";
    }


    var turningSpeed = turningSpeedPercentage*workingSpeed/100;

    var utmEntrance = fromLatLon(fieldEntrance[1], fieldEntrance[0]);

    // compute headlandWidth according to number of swaths
    // var headlandWidth = headlandSwaths * workingWidth;

    /* each polygon coordinates in Cesium format [lon,lat,lon,lat...]
    / first polygon: outer border - following polygons: holes

    polygonslatlon = [
        [-58.014035224914544,-34.94903469588605,-58.01261901855469,-34.95084624366132,-58.00973296165466,-34.9508726251293,-58.008756637573235,-34.949412837805205,-58.00855278968811,-34.94821684809537,-58.009260892868035,-34.94914901804555,-58.00969004631043,-34.947654023371236,-58.01260828971862,-34.947645229204255],
        [-58.0133056640625,-34.94906107793701,-58.012522459030144,-34.950098765207045,-58.01255464553833,-34.948383935941564],
        [-58.01321983337402,-34.94857740565382,-58.013198375701904,-34.94875328681423,-58.01301598548889,-34.948656552222694,-58.013026714324944,-34.94852464123212]
    ];
    */

    // field


    var polygonFieldUtm = fromLatLonArray(polygonField);

    // save zone letter and num to convert back later
    var zNum = polygonFieldUtm[0].zoneNum; //polygonsUtm[0][0].zoneNum;
    var zLet = polygonFieldUtm[0].zoneLetter; //polygonsUtm[0][0].zoneLetter;

    // simplify polygon
    var geoJsonPoly = ringToGeojsonPolygon(polygonFieldUtm);
    polygonFieldUtm = simplifyAndNormalizePolygon(geoJsonPoly, 0.5);

    var rotationPoint = polygonFieldUtm[0];

    // convert inter-row paths to UTM
    var pathsUtm = [];

    for(var p=0; p < interrowpaths.length; p++)
    {
        var pathUtm = fromLatLonArray(interrowpaths[p]);
        pathsUtm.push(pathUtm);
    }

    // When moving to a version that supports curved rows, the fixed "working angle" no longer exists.
    // However, the average angle is still calculated to rotate the rows and make the curves as "vertical"
    // as possible in order to sort them and also sort the sequence of points in each row.

    var workingAngle = getPathsAverageAngle(pathsUtm);

    var rotatedPathsUtm = rotatePolygons(pathsUtm, rotationPoint, Math.PI/2-workingAngle);

    var orderedPaths = orderPaths(rotatedPathsUtm);

    var nSwaths = orderedPaths.length;

    // compute row to row distance (workingWidth)
    // TODO: ahora que son curvas, calcular mejor el workingwidth:
    // Para cada polyline, tomar el segmento más largo, y en el punto medio calcular la distancia
    // a los polylines previos y siguiente. Luego promediar todos los valores 

    var x0 = (orderedPaths[0].pts[0].x + orderedPaths[0].pts[1].x) / 2;
    var xN = (orderedPaths[nSwaths-1].pts[0].x + orderedPaths[nSwaths-1].pts[1].x) / 2;

    var workingWidth = (xN-x0)/(nSwaths-1);


/*
    var str="";
    for (var p = 0; p < orderedPaths.length; p++) {
        str += orderedPaths[p].pts[0].x + "," + orderedPaths[p].pts[0].y + "\n"; 
        str += orderedPaths[p].pts[1].x + "," + orderedPaths[p].pts[1].y + "\n"; 
    }
*/
    var rotatedPolygonFieldUtm = rotatePolygon(polygonFieldUtm, rotationPoint, Math.PI/2-workingAngle);

    var rotatedUtmEntrance = rotatePoint(utmEntrance, rotationPoint, Math.PI/2-workingAngle);

    var regionRoute=[];

    var q = [];

    var firstTurningPoint = null;
    var secondTurningPoint = null;

    var turnUpDown = "";

    // compute sequence of method 'Skip and Fill Pattern' (SFP) (https://www.researchgate.net/publication/283843391_Quantifying_the_benefits_of_alternative_fieldwork_patterns_in_a_potato_cultivation_system)

    switch (pattern) {

        case "SFP":

            for (var i = 1; i <= nSwaths; i++) {
                if (i == 1) {
                    q.push(1);
                }
                else if (i == 2) {
                    q.push(3);
                }
                else if (i % 2 == 1) {
                    q.push(q[i - 2] - 1);
                }
                else if (i % 2 == 0 && i < nSwaths) {
                    q.push(q[i - 2] + 3);
                }
                else if (i % 2 == 0 && i == nSwaths) {
                    q.push(i);
                }

            }
            break;

        case "SFP3":
        case "SFP5":
        case "SFP7":

            var skip = parseInt(pattern.slice(-1));

            var ns = (skip + 1) / 2; // number of swaths before skipping back

            for (var i = 1; i <= nSwaths; i++) {

                if (i <= ns) {
                    q.push(2 * i - 1);
                }
                else if (nSwaths - i < ns - 1 - (nSwaths % 2)) {
                    q.push(nSwaths - 2 * (nSwaths - i) - (nSwaths % 2));
                }
                else if ((i + ns) % 2 == 1) {
                    q.push(q[i - 2] - (skip - 2));
                }
                else if ((i + ns) % 2 == 0) {
                    q.push(q[i - 2] + skip);
                }

            }
            
            break;

        case "SAP":
            q = [...Array(nSwaths + 1).keys()].splice(1);
            break;
    }


    // distances from Entrance to the ends of the first path 
    var dA = distancePointPoint(rotatedUtmEntrance,orderedPaths[0].pts[0]);
    var dB = distancePointPoint(rotatedUtmEntrance,orderedPaths[0].pts[orderedPaths[0].pts.length-1]);
    // distances from Entrance to the ends of the last path 
    var dC = distancePointPoint(rotatedUtmEntrance,orderedPaths[orderedPaths.length-1].pts[0]);
    var dD = distancePointPoint(rotatedUtmEntrance,orderedPaths[orderedPaths.length-1].pts[orderedPaths[orderedPaths.length-1].pts.length-1]);


    // The sequence must be mirror reversed if
    // the entrance is closer to the first path AND the vehicle must EndNearEntrance
    // OR
    // the entrance is closer to the last path AND the vehicle must StartNearEntrance

    if (((Math.min(dA, dB) < Math.min(dC, dD)) && patternStart == "EndNearEntrance") ||
        ((Math.min(dA, dB) > Math.min(dC, dD)) && patternStart == "StartNearEntrance")) {

        q = q.map(function (x) {
            return (q.length - x) + 1;
        });

    }

    var startUp;
    if (patternStart == "StartNearEntrance"){
        dA = distancePointPoint(rotatedUtmEntrance,orderedPaths[q[0]-1].pts[0]);
        dB = distancePointPoint(rotatedUtmEntrance,orderedPaths[q[0]-1].pts[orderedPaths[q[0]-1].pts.length-1]);
        startUp = dA<dB ? false : true;
    }
    else{
        dA = distancePointPoint(rotatedUtmEntrance,orderedPaths[q[q.length-1]-1].pts[0]);
        dB = distancePointPoint(rotatedUtmEntrance,orderedPaths[q[q.length-1]-1].pts[orderedPaths[q[q.length-1]-1].pts.length-1]);
        startUp = dA<dB ? false : true;
        if (orderedPaths.length % 2 == 1 ){
            startUp = !startUp;
        }
    }



    // if the selected starting point is on top, start the route on the upper side
    //var startUp = startingPt.vertical == 'top' ? true : false;
    //var s1 = startUp ? 0 : 1;

    //var startUp = false; // start on pt A
    var s1 = startUp ? 0 : 1;

    var direction = 1; //  1: left --> right  -1: left <-- right

    var swaths = [];


    var rotatedMainlandUtm; // borrar
    var polygonsUtm; // borrar

/*    
    for (var i=0; i<nSwaths; i++){

        //var xSwath = utmA.x + direction * (q[i]-1) * workingWidth; // + 0.5*workingWidth
        var xSwath = 0; //utmA.x + direction * i * workingWidth; // + 0.5*workingWidth

        var swath = getSwathWorkingModeLimits(xSwath, workingWidth, rotatedMainlandUtm);

        swaths.push(swath);

    }
*/

    for (var i=0; i<nSwaths; i++){


        var n = orderedPaths[q[i]-1].pts.length-1;

        var pUp = {x: orderedPaths[q[i]-1].pts[n].x, y: orderedPaths[q[i]-1].pts[n].y, vertex: q[i]-1,
                    prevPt: {x: orderedPaths[q[i]-1].pts[n-1].x, y: orderedPaths[q[i]-1].pts[n-1].y} };  // prev point is added in order to compute the angle the curved row reaches the headland

        var pDown = {x: orderedPaths[q[i]-1].pts[0].x, y: orderedPaths[q[i]-1].pts[0].y, vertex: q[i]-1,
                        prevPt: {x: orderedPaths[q[i]-1].pts[1].x, y: orderedPaths[q[i]-1].pts[1].y} };
 


        if (i==0){ // add starting point
            
            if (startUp){
                //regionRoute.push( { x: pUp.x, y: pUp.y, w: "on", speed: workingSpeed } );
                var l = orderedPaths[q[i]-1].pts.length;
                for (var p=l-1; p>0;p--){
                    regionRoute.push( { x: orderedPaths[q[i]-1].pts[p].x, y: orderedPaths[q[i]-1].pts[p].y, w: "on", speed: workingSpeed } );
                }
                firstTurningPoint = pDown;
            }
            else{
                // regionRoute.push( { x: pDown.x, y: pDown.y, w: "on", speed: workingSpeed } );
                var l = orderedPaths[q[i]-1].pts.length;
                for (var p=0; p<l-1;p++){
                    regionRoute.push( { x: orderedPaths[q[i]-1].pts[p].x, y: orderedPaths[q[i]-1].pts[p].y, w: "on", speed: workingSpeed } );
                }
                firstTurningPoint = pUp;
            }

        }
        else{ // add turning

            var turningPts = null;
            var turn = null;
            if ((i + s1) % 2 == 1){  // add turning down
                turnUpDown = "down";
                secondTurningPoint = pDown;
                // rotate points before compute turn
                var rotationAngle1 = Math.atan2(firstTurningPoint.x-firstTurningPoint.prevPt.x, -firstTurningPoint.y+firstTurningPoint.prevPt.y);
                var rotationAngle2 = Math.atan2(secondTurningPoint.x-secondTurningPoint.prevPt.x, -secondTurningPoint.y+secondTurningPoint.prevPt.y);
                var rotationAngle = (rotationAngle1 + rotationAngle2)/2; // check if this must be computed different: https://stackoverflow.com/a/18070139 https://stackoverflow.com/a/47106990
                var rotatedSecondTurningPoint = rotatePoint(secondTurningPoint, firstTurningPoint, -rotationAngle);

                var rotatedOrderedPaths = orderedPaths.map(x => {
                    return {
                        ...x,
                        pts: rotatePolygon(x.pts, firstTurningPoint, -rotationAngle) 
                    };
                });
                
                var rotatedPolygonFieldUtm2 = rotatePolygon(rotatedPolygonFieldUtm, firstTurningPoint, -rotationAngle);

                turn = getAutoTurningPoints2(firstTurningPoint, rotatedSecondTurningPoint, turningRadius, workingWidth, alignmentZone, "down", rotatedOrderedPaths, rotatedPolygonFieldUtm2, workingSpeed, turningSpeed); 
                turningPts = rotatePolygon(turn.pts, firstTurningPoint, rotationAngle); // rotate back turn
                firstTurningPoint = pUp;

                // add turn to the route
                regionRoute.push(... turningPts);

                // add curved row
                var l = orderedPaths[q[i]-1].pts.length;
                for (var p=1; p<l-1;p++){
                    regionRoute.push( { x: orderedPaths[q[i]-1].pts[p].x, y: orderedPaths[q[i]-1].pts[p].y, w: "on", speed: workingSpeed } );
                }
            }
            else{  // add turning up
                turnUpDown = "up";
                secondTurningPoint = pUp;
                // rotate points before compute turn
                var rotationAngle1 = Math.atan2(firstTurningPoint.x-firstTurningPoint.prevPt.x, firstTurningPoint.y-firstTurningPoint.prevPt.y);
                var rotationAngle2 = Math.atan2(secondTurningPoint.x-secondTurningPoint.prevPt.x, secondTurningPoint.y-secondTurningPoint.prevPt.y);
                var rotationAngle = -(rotationAngle1 + rotationAngle2)/2; // check if this must be computed different: https://stackoverflow.com/a/18070139 https://stackoverflow.com/a/47106990
                var rotatedSecondTurningPoint = rotatePoint(secondTurningPoint, firstTurningPoint, -rotationAngle);

                var rotatedOrderedPaths = orderedPaths.map(x => {
                    return {
                        ...x,
                        pts: rotatePolygon(x.pts, firstTurningPoint, -rotationAngle) 
                    };
                });

                var rotatedPolygonFieldUtm2 = rotatePolygon(rotatedPolygonFieldUtm, firstTurningPoint, -rotationAngle);

                turn = getAutoTurningPoints2(firstTurningPoint, rotatedSecondTurningPoint, turningRadius, workingWidth, alignmentZone, "up", rotatedOrderedPaths, rotatedPolygonFieldUtm2, workingSpeed, turningSpeed);
                turningPts = rotatePolygon(turn.pts, firstTurningPoint, rotationAngle); // rotate back turn
                firstTurningPoint = pDown;

                // add turn to the route
                regionRoute.push(... turningPts);

                // add curved row
                var l = orderedPaths[q[i]-1].pts.length;
                for (var p=l-2; p>0;p--){
                    regionRoute.push( { x: orderedPaths[q[i]-1].pts[p].x, y: orderedPaths[q[i]-1].pts[p].y, w: "on", speed: workingSpeed } );
                }
            }

            
        }

    }
    
    // add point to complete last swath
    if (turnUpDown == "up"){
        regionRoute.push( { ...pDown, speed: workingSpeed, w: "off"} ); 
    }
    else{
        regionRoute.push( { ...pUp, speed: workingSpeed, w: "off"} );
    }

/*    
    // raytrace the most inner headlandring with the last line added to the route
    // (identifying segment of the headland)

    var a1 = regionRoute[regionRoute.length-2];
    var a2 = regionRoute[regionRoute.length-1];


    var segmentNumber;  // for ex. p=3 means the segments is p3->p4 
    var i;
    for (var p=0; p<headlandRings[0].length;p++){
        var b1 = headlandRings[0][p];
        var b2 = headlandRings[0][p==headlandRings[0].length-1?0:p+1];        
        i = intersectLineSegment(a1,a2,b1,b2);
        if (i.length>0){ // length must be 1 
            segmentNumber = p;
            break;
        }
    }

    // for the most inner headland swath, joint the mainland route with a turn the same way that headland turns
    // create a polyline joining:
    // a) last point of mainland route
    // b) raytracing point of last segment of that route on most inner headland polygon ring
    // c) most inner headland polygon ring (after rotating point array so that segmentNumber second extreme is the first point)
    // d) add intersection point b) as last point

    var headlandPolyline = [];

    headlandPolyline.push(a2);
    headlandPolyline.push(i[0]);
    headlandPolyline.push(... arrayRotate(headlandRings[0].slice(0), segmentNumber+1)); // arrayRotate alters the original array. Clone it first using slice
    headlandPolyline.push(i[0]);

    var headlandSpiralRoute = headlandPolylineToRoute(headlandPolyline); 

    //var headlandRoute = [];
    //hheadlandRings.push(rr);

    // for the rest of the headland swaths, make a lane change first

    for (var n=1; n<headlandSwaths; n++){


        // add lane change 
        // for left->right swaths order, if the last turn was up, the last point is up => make reverse first

        var a = headlandSpiralRoute[headlandSpiralRoute.length-1]; // last point
        var b = headlandRings[n-1][segmentNumber==headlandRings[n-1].length-1?0:segmentNumber+1];
        if (turnUpDown == "down"){
            headlandSpiralRoute.push(... laneChange(a, b, true ) );
        }
        else{
            headlandSpiralRoute.push(... laneChange(a, b, false ) );
        }
        
        // take the last point of headlandSpiralRoute and find the closest segment on the next headland ring
        // (normally it should be on a segment)
        
        var minDist = Infinity;
        for (var p=0; p<headlandRings[n].length;p++){
            var b1 = headlandRings[n][p];
            var b2 = headlandRings[n][p==headlandRings[n].length-1?0:p+1]; 

            var res = closestPointInSegment(headlandSpiralRoute[headlandSpiralRoute.length-1], b1, b2);
            if (res.distance<minDist){  
                segmentNumber = p;
                minDist = res.distance;
            }
        }

        headlandPolyline = [];
        headlandPolyline.push(headlandSpiralRoute[headlandSpiralRoute.length-1]); // first point to give correct direction to first headland turn
        headlandPolyline.push(... arrayRotate(headlandRings[n].slice(0), segmentNumber+1)); // arrayRotate alters the original array. Clone it first using slice
        headlandPolyline.push(headlandSpiralRoute[headlandSpiralRoute.length-1]);

        headlandSpiralRoute.push(... headlandPolylineToRoute(headlandPolyline));

    }

    // add the path from the last spiral point to entrance

    // check if the shortest path is following the contour cw or ccw

    var lastSpiralPoints = [headlandSpiralRoute[headlandSpiralRoute.length-2], headlandSpiralRoute[headlandSpiralRoute.length-1]]; // last two points
    distAround = distanceAroundPolygon(lastSpiralPoints[1], rotatedUtmEntrance, headlandRings[travelHeadlandRing]);

    orientation = distAround.cw<distAround.ccw ? 'cw' : 'ccw';

    var polylineSpiralToEntrace = SpiralToEntrancePolyline(lastSpiralPoints, rotatedUtmEntrance, headlandRings[travelHeadlandRing], orientation, turningRadius);

    // delete last two points of headlandSpiralRoute to avoid repeated points
    //headlandSpiralRoute.pop();
    //headlandSpiralRoute.pop();

    headlandSpiralRoute.push(... polylineToRoute(polylineSpiralToEntrace));

    // polylineToRoute does not include the last point. Only the curve upto it
    headlandSpiralRoute.push( { ...polylineSpiralToEntrace[polylineSpiralToEntrace.length-1], speed: workingSpeed, w:"off"} );
    
    headlandSpiralRoute.push( { ...rotatedUtmEntrance, speed: workingSpeed, w:"off"} );

    //regionRoute.push(rotatedUtmEntrance); // headlandPolylineToRoute does not add the first point 
    //headlandSpiralRoute.push(... headlandPolylineToRoute(polylineSpiralToEntrace));


*/

    

    // convert back UTM to LatLon

    var routes = [];

    // routeWork.push( regionRoute.map(a => a.w) );

    // complete the whole route by adding headlandSpiralRoute to regionRoute

/*
    regionRoute.push( ...headlandSpiralRoute);
*/
    var rotatedRegionRoute = rotatePolygon(regionRoute, rotationPoint, -(Math.PI/2-workingAngle));

    // compute time
    var travelledDistance=0;
    var workedDistance=0;
    var travelledTime=0;
    var workedTime=0;

    rotatedRegionRoute[0].time = 0;
    for (var p=1; p<rotatedRegionRoute.length; p++){
        var dx = rotatedRegionRoute[p].x - rotatedRegionRoute[p-1].x;
        var dy = rotatedRegionRoute[p].y - rotatedRegionRoute[p-1].y;
        var distToPrevWp = Math.sqrt(dx*dx + dy*dy);
        var dt = distToPrevWp/Math.abs(rotatedRegionRoute[p-1].speed);
        travelledTime += dt;
        if (rotatedRegionRoute[p-1].w == "on") {
            workedTime += dt;
        }
        travelledDistance += distToPrevWp;
        if (rotatedRegionRoute[p-1].w == "on") {
            workedDistance += distToPrevWp;
        }
        rotatedRegionRoute[p].time = travelledTime;
    }



    // compute coverage areas before converting from UTM to LL

    var coverageAreas = [];
    var coverageAreasUtm = []; // covered rectangles in UTM to compute total covered area, uncovered area and overlap area
    
    for (var p=0; p<rotatedRegionRoute.length-1; p++){

        if (rotatedRegionRoute[p].w == "on"){
            // compute rectangle coords

            var dx = rotatedRegionRoute[p+1].x - rotatedRegionRoute[p].x;
            var dy = rotatedRegionRoute[p+1].y - rotatedRegionRoute[p].y;
            var angle = Math.atan2(dy,dx);

            // rectangle: P1->P2->P4->P3  
            var P1 = { x: Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p].x , y: -Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p].y };
            var P2 = { x: -Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p].x , y: Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p].y };
            var P3 = { x: Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p+1].x , y: -Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p+1].y };
            var P4 = { x: -Math.sin(angle) * workingWidth/2 + rotatedRegionRoute[p+1].x , y: Math.cos(angle) * workingWidth/2 + rotatedRegionRoute[p+1].y };

            coverageAreasUtm.push([P1,P2,P4,P3]);

            var rectLatLon = toLatLonArray2([P1,P2,P4,P3], zNum, zLet);

            var covArea = {wp: p, coords: rectLatLon, startTime: rotatedRegionRoute[p].time, endTime: rotatedRegionRoute[p+1].time };

            coverageAreas.push(covArea);

        }
    }


    var routeStats = {};

    /* computing areas doesn't make sense for the 'orchard' version

    try{
        var routeStats = computeAreas([polygonFieldUtm], coverageAreasUtm);
    }
    catch{
        return {error: "An error occurred while calculating the statistics areas"};
    }


    // convert different areas to LatLon
    // Each area is an array of polygons, each polygon is an array of ring coordinates: first element is the exterior ring, rest are holes

    var coveredAreaLatLon = []; 
    for (var pol=0; pol<routeStats.coveredArea.length; pol++){
        var ringsLatLon = [];
        for (var ring=0; ring<routeStats.coveredArea[pol].length; ring++){
            ringsLatLon.push(toLatLonArray2(routeStats.coveredArea[pol][ring], zNum, zLet));
        }
        coveredAreaLatLon.push(ringsLatLon);
    }

    var overlapAreaLatLon = null;
    if (routeStats.overlapArea){
        overlapAreaLatLon = []; 
        for (var pol=0; pol<routeStats.overlapArea.length; pol++){
            var ringsLatLon = [];
            for (var ring=0; ring<routeStats.overlapArea[pol].length; ring++){
                ringsLatLon.push(toLatLonArray2(routeStats.overlapArea[pol][ring], zNum, zLet));
            }
            overlapAreaLatLon.push(ringsLatLon);
        }
    }
    var uncoveredAreaLatLon = []; 
    for (var pol=0; pol<routeStats.uncoveredArea.length; pol++){
        var ringsLatLon = [];
        for (var ring=0; ring<routeStats.uncoveredArea[pol].length; ring++){
            if (routeStats.uncoveredArea[pol][ring].length > 0 ){
                ringsLatLon.push(toLatLonArray2(routeStats.uncoveredArea[pol][ring], zNum, zLet));
            }
        }
        if (ringsLatLon.length>0){
            uncoveredAreaLatLon.push(ringsLatLon);
        }
    }

    // replace areas by LatLon version
    routeStats.coveredArea = coveredAreaLatLon;
    routeStats.uncoveredArea = uncoveredAreaLatLon;
    routeStats.overlapArea = overlapAreaLatLon;
*/

    routeStats = { coveredArea:[],
        uncoveredArea:[],
        overlapArea:[],
        fieldSize:'',
        uncoveredAreaSize:'',
        coveredAreaSize:'',
        overlapAreaSize:''
    };


    routeStats.travelledDistance = travelledDistance;
    routeStats.workedDistance = workedDistance;
    routeStats.travelledTime = travelledTime;
    routeStats.workedTime = workedTime;

    var rLatLon = toLatLonArray2(rotatedRegionRoute, zNum, zLet);
    //routes.push(rLatLon); 

    //var rotatedSpiral = rotatePolygon(headlandSpiralRoute, rotationPoint, -(90-workingAngle) * Math.PI/180);
    //var rLatLon2 = toLatLonArray2(rotatedSpiral, zNum, zLet);

    //rLatLon.push(... rLatLon2);
    //var sarasa = graficar1(regionRoute);

    routes.push(rLatLon);
    /*
    for (var n=0; n<hheadlandRings.length; n++){
        if (hheadlandRings[n].length>=2){
            var routeLatLon = toLatLonArray2(hheadlandRings[n], zNum, zLet);
            // add last point equal to first one
            routeLatLon.push(routeLatLon[0]);
            routeLatLon.push(routeLatLon[1]);
            routes.push(routeLatLon);
        }
    }
    */

    var speedArray = rotatedRegionRoute.map(a => a.speed);
    var workArray = rotatedRegionRoute.map(a => a.w);
    var timeArray = rotatedRegionRoute.map(a => a.time);

    var routePlan = {routesCoord: routes,
        time: timeArray,
        speed: speedArray,
        work: workArray,
        coverageAreas: coverageAreas,
        routeStats: routeStats
    };

    return routePlan;





    function getAutoTurningPoints(deltaX_, deltaY, direction, headlandWidth){

        var deltaX = Math.abs(deltaX_);
        var type = null;
        var points = [];
    
        
        var theta = Math.abs(Math.atan(deltaX/deltaY));
        
        if (theta<0.05){
            theta=0.05;
        } 
        

   
    /*
        var A = null;
        var B = null;
        var M = null;
        var angleAB = null;
        var distAB = null;
        var lMO = null;
        var O = null;
        var alpha = null;
        var beta = null; 
    */
        var a = null;
        var h1 = (deltaX/2 + workingWidth/2) /Math.tan(theta);
        var h2 = (deltaX/2 - workingWidth/2) /Math.tan(theta);
    
        var A = {x: -deltaX/2-turningRadius, y: h1};
        var B = {x: deltaX/2+turningRadius, y: -h2};

        // determine point O equidistant of A and B at a distance = 2*r
    
        var M = {x:0, y: (A.y + B.y)/2}; // punto medio entre A y B
    
        //var angleAB = Math.atan(-h1/(2*A.x)); // angle A-B
        var angleAB = Math.atan((h1+h2)/(B.x-A.x)); // angle A-B

        var distAB = Math.sqrt((B.x-A.x)*(B.x-A.x) + (B.y-A.y)*(B.y-A.y));
    
        if ((2*turningRadius)*(2*turningRadius) - (distAB/2)*(distAB/2) > 0){
            var lMO = Math.sqrt((2*turningRadius)*(2*turningRadius) - (distAB/2)*(distAB/2));

            var O = {x: M.x + lMO*Math.sin(angleAB), y: M.y + lMO*Math.cos(angleAB)};
    
            //var alpha = Math.atan((O.y-h1)/(O.x-A.x));
            var alpha = Math.atan((O.y-A.y)/(O.x-A.x));
    
            //var beta = Math.atan(O.y/(B.x-O.x));
            var beta = Math.atan((O.y-B.y)/(B.x-O.x)); 

            a = turningRadius * (Math.sin(theta)*Math.sin(alpha) + Math.cos(theta)*Math.cos(alpha) - Math.cos(theta));
        }
    
        // revisar esta ecuacion está en los papers de Jin (gráfico de seleccion del tipo de turning)
        //if (  headlandWidth >= turningRadius*(1 + Math.cos(theta)) + (deltaX/2)*(1+Math.sin(theta)*Math.cos(theta))  ) {
    
        // la reemplazo por esta simple: headland width requerido para u-turn
        // le agrego || turningRadius < deltaX/2 porque cuando r < w/2 no puedo usar fishtail tal como está programado
        if ( headlandWidth - alignmentZone >= deltaX*(1+Math.cos(theta)) || turningRadius < deltaX/2 ) {
            if (turningRadius == deltaX/2)
                type = "u turn"
            else if ( turningRadius < deltaX/2)
                type = "copy headland" // "flat turn"
            else if ( turningRadius > deltaX/2){
                /*
                var h = deltaX/Math.tan(theta); // ó 2*p1.y
    
                var A = {x:-deltaX/2-turningRadius, y: h};
                var B = {x: -A.x, y: 0};
                
                // determine point O equidistant of A and B at a distance = 2*r
                
                var M = {x:0, y: h/2}; // punto medio entre A y B
                
                var angleAB = Math.atan(-h/(2*A.x)); // angle A-B
                
                var distAB = Math.sqrt((B.x-A.x)*(B.x-A.x) + (B.y-A.y)*(B.y-A.y));
                var lMO = Math.sqrt((2*turningRadius)*(2*turningRadius) - (distAB/2)*(distAB/2));
                
                var O = {x: M.x + lMO*Math.sin(angleAB), y: M.y + lMO*Math.cos(angleAB)};
    
                var alpha = Math.atan((O.y-h)/(O.x-A.x));
                var beta = Math.atan(O.y/(B.x-O.x)); 
                var a = turningRadius * (Math.sin(theta)*Math.sin(alpha) + Math.cos(theta)*Math.cos(alpha) - Math.cos(theta));
                */
    
                
                //if (a!=null && alpha>0){
                    // this equation uses the variable "a" as in the decision tree of Jin papers
                    if ( a!=null && alpha>0 && turningRadius < (headlandWidth- alignmentZone -2*a-deltaX/2)/(1+Math.cos(theta)) ){
                    //if (headlandWidth > turningRadius * ( 1 + 2*Math.sin(theta)*Math.sin(alpha) + 2*Math.cos(alpha)*Math.cos(alpha) - Math.cos(theta)) + deltaX/2){
                        if (turningRadius > deltaX/(2*Math.sin(theta)) ){
                            type = "bulb"; 
                        }
                        else{
                            type = "bulb"; // or "hook"
                        }
                    }
                    else{
                        if (turningRadius > deltaX/(2*Math.sin(theta)) ){ // condicion para poder hacer el hook
                            type = "fishtail"; 
                        }
                        else{
                            type = "hook";
                        }
                    }
                //}
    
            }
        }
        else{
            type = "fishtail";
        }
    
        // TODO: temporary until implementation of fishtail turn
        //if (type == "fishtail"){
        //    type = "hook";
        //}
    
        //console.log(type);
        
        var stepDegrees = 15;
    
        switch(type){
    
            case "bulb":
                
                // add alpha arc
                var steps = Math.round(alpha/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (alpha/steps);
                    points.push( { x: A.x + turningRadius*Math.cos(arc), y: A.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
        
                // add gamma arc
                var gammaFrom = -alpha;
                var gammaTo = Math.PI+beta;
                steps = Math.round((gammaTo-gammaFrom)/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
    
                for (s=0; s< steps; s++ ){
                    arc = gammaFrom + (s+1) * ((gammaTo-gammaFrom)/steps);
                    points.push( { x: O.x - turningRadius*Math.cos(arc), y: O.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                // add beta arc
                steps = Math.round(beta/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (s=0; s< steps; s++ ){
                    var arc = beta - (s+1) * (beta/steps);
                    points.push( { x: B.x - turningRadius*Math.cos(arc), y: B.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                break;
    
            case "hook":
    
                var O = {x:-deltaX/2+turningRadius, y:h1};
                var r2 = ( (deltaX*deltaX)*(1/Math.tan(theta))*(1/Math.tan(theta)) + deltaX*deltaX - 2*deltaX*turningRadius ) / (4*turningRadius - 2*deltaX);
                //var B = {x: deltaX/2 + r2, y: -(deltaX/2 - workingWidth/2) /Math.tan(theta)};
                var B = {x: deltaX/2 + r2, y: -h2};
                //var beta = Math.atan(O.y/(B.x-O.x));
                var beta = Math.atan((h1+h2)/(B.x-O.x));
                var alpha = Math.PI + beta;
                
                // add alpha arc
                var steps = Math.round(alpha/(stepDegrees*Math.PI/180));
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (alpha/steps);
                    points.push( { x: O.x - turningRadius*Math.cos(arc), y: O.y + turningRadius*Math.sin(arc), speed: turningSpeed } );
                }
                
                // add beta arc
                steps = Math.round(beta/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (s=0; s< steps; s++ ){
                    var arc = beta - (s+1) * (beta/steps);
                    points.push( { x: B.x - r2*Math.cos(arc), y: B.y + r2*Math.sin(arc), speed: turningSpeed} );
                }
    
                break;
    
            case "flat turn":
    
                var C1 = {x:-deltaX/2+turningRadius, y: h1}; // first center
                var C2 = {x: deltaX/2-turningRadius, y: h1};  // second center
                
                // add first arc
                var steps = Math.round(90/stepDegrees);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI/(2*steps));
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
                // add second arc
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI/(2*steps));
                    points.push( { x: C2.x + turningRadius*Math.sin(arc), y: C2.y + turningRadius*Math.cos(arc), speed: turningSpeed} );
                }
    
                // add extra point where vehicle re-enters field to change work to "on"
                points.push( { x: deltaX/2, y: -(deltaX/2 - workingWidth/2) /Math.tan(theta), speed: workingSpeed} );
    
                break;

            case "copy headland":
    
                var C1 = {x:-deltaX/2+turningRadius, y: h1}; // first center
                var C2 = {x: deltaX/2-turningRadius, y: C1.y - (deltaX-2*turningRadius)/Math.tan(theta) };  // second center
                
                // add first arc
                steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
                for (s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI-theta)/steps;
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }

                // add second arc

                steps = Math.round(theta/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = Math.PI-theta + s * (theta/steps);
                    points.push( { x: C2.x - turningRadius*Math.cos(arc), y: C2.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }

                // add extra point where vehicle re-enters field to change work to "on"
                points.push( { x: deltaX/2, y: -(deltaX/2 - workingWidth/2) /Math.tan(theta), speed: workingSpeed} );
    
                break;
    
            case "u turn":
    
                var C1 = {x:0, y: h1}; // center
                
                // add arc
                var steps = Math.round(180/stepDegrees);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * (Math.PI/steps);
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                // add extra point where vehicle re-enters field to change work to "on"
                points.push( { x: deltaX/2, y: 0, speed: workingSpeed} );
    
                break;
    
            case "fishtail":
                var C1 = {x:-deltaX/2+turningRadius, y:h1}; // first center
                var C3 = {x: C1.x+turningRadius*Math.cos(theta), y: C1.y+turningRadius*Math.sin(theta)};
                var C4 = {x: deltaX/2-turningRadius, y: (C3.x - (deltaX/2-turningRadius))/Math.tan(theta) + C3.y };
                var l = turningRadius/Math.cos(Math.PI/2-theta);
                var C2 = {x: deltaX/2-turningRadius, y: C4.y-l};
    
                // add first arc
                var steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
                for (var s=0; s<= steps; s++ ){
                    var arc = s * ((Math.PI-theta)/steps);
                    points.push( { x: C1.x - turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
                // change speed of last point to reverse
                points[points.length-1].speed = - turningSpeed; 

                // add second arc
                var gammaFrom = Math.PI-theta;
                var gammaTo = Math.PI;
                steps = Math.round((gammaTo-gammaFrom)/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = gammaFrom + s * ((gammaTo-gammaFrom)/steps);
                    points.push( { x: C2.x - turningRadius*Math.cos(arc), y: C2.y + turningRadius*Math.sin(arc), speed: turningSpeed} );
                }
    
                // add extra point where vehicle re-enters field to change work to "on" (TODO: review y value)
                points.push( { x: deltaX/2, y: 0, speed: workingSpeed} );
    
                break;
        }

        
        // add alignment zone: Insert two points to generate the alignment area.
        // One after the first and one before the last.
        // Shift the points between these two. (see alignment_zone.png).
        
        for (var p=1; p<points.length-1; p++){
            points[p].y = points[p].y + alignmentZone;
        }

        points.splice(1, 0, { x: points[0].x, y: points[0].y + alignmentZone, speed: turningSpeed});
        points.splice(points.length-1, 0, { x: points[points.length-1].x, y: points[points.length-1].y + alignmentZone, speed: turningSpeed});


        //if (type != "fishtail"){  // for now fishtail not implemented
        if (direction == "up"){
            if (deltaY>0){
                for (var p=0; p<points.length; p++){
                    points[p].x = -points[p].x;
                }
                points.reverse();
            }
        }
        if (direction == "down"){
            for (var p=0; p<points.length; p++){
                points[p].y = -points[p].y;
                if (deltaY<0){
                    points[p].x = -points[p].x;
                }
            }
            if (deltaY<0){
                points.reverse();
            }
        }

        if (deltaX_<0){
            for (var p=0; p<points.length; p++){
                points[p].x = -points[p].x;
            }
        }


        //}
    
        // Add work property
        for (var p=0; p<points.length-1; p++){
            points[p].w = "off"
        }
        points[points.length-1].w = "on";
        points[points.length-1].speed = workingSpeed;

        return points;
        
    }


    function headlandPolylineToRoute(pol){

        var route = [];

        /* version for headlandRingToRoute (circular route)
        for (var i=0; i<pol.length;i++){

            /* 
            var a = pol[i==0?pol.length-1:i-1];
            var b = pol[i];
            var c = pol[i==pol.length-1?0:i+1];
            */

        for (var i=0; i<pol.length-2;i++){

            var a = pol[i];
            var b = pol[i+1];
            var c = pol[i+2];

            // rotate points a-b-c so that ab is vertical
            var rotationAngle = Math.atan2(b.x-a.x, b.y-a.y); 
            var rotatedPoints = rotatePolygon([a,b,c], b, rotationAngle);
    
    
            // translate the points so that b is [0,0]
            var rot_a = {x: rotatedPoints[0].x-rotatedPoints[1].x, y: rotatedPoints[0].y-rotatedPoints[1].y};
            //var rot_b = rotatedPoints[1];
            var rot_c = {x: rotatedPoints[2].x-rotatedPoints[1].x, y: rotatedPoints[2].y-rotatedPoints[1].y};
    
            var points = [];

            if (rot_c.x>0){ // add concave turn
    
                var alpha = Math.atan(rot_c.y/rot_c.x);
                //var h = (workingWidth/2)*Math.sin(alpha); 
                var beta = (Math.PI/2-alpha)/2;

                var h;
                if (rot_c.y>0){  // obtuse angle
                    h = (workingWidth/2)*Math.tan(beta);
                }
                else{ // acute angle
                    h = (workingWidth/2)*Math.tan(-beta+Math.PI/2);
                }

                var C = {x:turningRadius, y: -turningRadius*Math.tan(beta)}; // center
                var p1 = {x:0, y:  h};
                var p2 = {x: -h*Math.cos(alpha), y: -h*Math.sin(alpha)}

                var stepDegrees = 15;
                
                points.push( {...p1, speed: -turningSpeed, w: "off" } );
                
                var arcLen = 2*beta;
                
                // add arc
                var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * arcLen/steps;
                    // last point of arc in reverse speed, rest forward
                    points.push( { x: C.x - turningRadius*Math.cos(arc), y: C.y + turningRadius*Math.sin(arc), speed: s==steps ? -turningSpeed : turningSpeed, w: "off" } );
                }

                // P2 forward (see concave turn drawing)
                points.push({ ...p2, speed: workingSpeed, w: "on"});
    
            }
            else{ // add convex turn

                var alpha = Math.atan(-rot_c.y/rot_c.x);
                var beta = (Math.PI/2-alpha)/2;
                var C = {x:turningRadius, y: turningRadius*Math.tan(beta)}; // center
                var stepDegrees = 15;
                var arcLen = 2*beta;

                // add arc
                var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));
                steps = Math.max(steps,1);
                for (var s=0; s<= steps; s++ ){
                    var arc = s * arcLen/steps;
                    // last point of arc in working speed, rest in reverse
                    points.push( { x: C.x - turningRadius*Math.cos(arc), y: C.y - turningRadius*Math.sin(arc), speed: s==steps ? workingSpeed : -turningSpeed, w: s==steps ? "on" : "off" } );
                }    
            }

            // rotate back points
            var rotatedTurn = rotatePolygon(points, {x:0,y:0}, -rotationAngle);

            for (var p=0;p<rotatedTurn.length;p++){
                // translate back and add to route
                route.push({x: rotatedTurn[p].x+rotatedPoints[1].x, y: rotatedTurn[p].y+rotatedPoints[1].y, speed: rotatedTurn[p].speed, w: rotatedTurn[p].w })
            }
    
        }

        // add last point
        route.push({ ...pol[pol.length-1], speed: workingSpeed, w:"on"} );

        return route;
    
    }




    function laneChange(a, b, reverseFirst){

        // a: origin point
        // b: next point in original lane
        // The function computes the lane change to left (lane width = working width)
    
        var points = [];
    
        var stepDegrees = 15;
        var shiftY = 0;
        var rotationAngle = Math.atan2(b.x-a.x, b.y-a.y);
        var firstPt = 1;

        if (turningRadius<=workingWidth/2){
          
          var arcLen = Math.PI/2;
          var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));

          if (reverseFirst){
            // add reverse point - not needed?
            //points.push( { x: 0, y: -2*turningRadius } );
            shiftY = -2*turningRadius;
            firstPt = 0;
          }
          
          var C1 = {x: -turningRadius, y:0};
          for (var s=firstPt; s<= steps; s++ ){
            var arc = s * arcLen/steps;
            points.push( { x: C1.x + turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
          var C2 = {x: -workingWidth+turningRadius, y:2*turningRadius};
          for (var s=0; s<= steps; s++ ){
            var arc = s * arcLen/steps;
            points.push( { x: C2.x - turningRadius*Math.sin(arc), y: C2.y - turningRadius*Math.cos(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
          //if (!reverseFirst){ 
            // add reverse point // not needed?
            //points.push( { x: -workingWidth, y: 0 } );
          //}
        }

        else{
          
          var h = Math.sqrt(turningRadius**2 - (turningRadius-workingWidth/2)**2);

          if (reverseFirst){
            // add reverse point - not needed?
            //points.push( { x: 0, y: -2*turningRadius } );
            shiftY = -2*turningRadius;
            firstPt = 0;
          }

          var arcLen = Math.acos((turningRadius-workingWidth/2)/turningRadius);
          var steps = Math.round(arcLen/(stepDegrees*Math.PI/180));
          
          var C1 = {x: -turningRadius, y:0};
          for (var s=firstPt; s<= steps; s++ ){
            var arc = s * arcLen/steps;
            points.push( { x: C1.x + turningRadius*Math.cos(arc), y: C1.y + turningRadius*Math.sin(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
         
          var C2 = {x: -workingWidth+turningRadius, y:2*h};
          for (var s=1; s<= steps; s++ ){
            var arc = (Math.PI/2 - arcLen) + s * arcLen/steps;
            points.push( { x: C2.x - turningRadius*Math.sin(arc), y: C2.y - turningRadius*Math.cos(arc) + shiftY, speed: turningSpeed, w: "off" } );
          };
          
          if (!reverseFirst){
            // add reverse point
            points.push( { x: -workingWidth, y: 0, speed: workingSpeed, w: "on" } );
          }

        }

        // rotate back points
        var rotatedTurn = rotatePolygon(points, {x:0,y:0}, -rotationAngle);

        var path = [];
        for (var p=0;p<rotatedTurn.length;p++){
            // translate back and add to route
            path.push({...rotatedTurn[p], x: rotatedTurn[p].x+a.x, y: rotatedTurn[p].y+a.y})
        }

        // correct speed to reverse and last points
        if (reverseFirst){
            // reverse speed and working=off is applied to object point 'a', passed as a parameter to the function.
            // Changing a member of the object modifies the object passed (https://stackoverflow.com/a/5314911)
            a.speed = -turningSpeed; 
            a.w = "off";
        }
        else{
            path[path.length-2].speed = -turningSpeed;
            path[path.length-2].w = "off";
        }
        path[path.length-1].speed = workingSpeed;
        path[path.length-1].w = "on";

        return path;
    }

    /*
    function getStartingPoint(){

        // Measure the distance from the entrance to each possible starting point of the route.
        // Depending on whether the number of swaths is odd or even, the end of the path can be in swath N or swath N-1 (SFP pattern)
        // So the 4 possible starting points can be different if the route goes from left to right or vice versa.
        // Both scenarios are evaluated, therefore two sets of 4 points are analyzed to find the one closest to the entrance.
        // The distance must be measured following the contour of the polygon

        var startingPtsCandidates = [];

        for (var i=0; i<2; i++){  // i=0 -> q left to right; i=1 -> q right to left

            var seq = q.slice();
            if (i==1){
                seq = q.map(function(x) {
                    return (q.length-x) + 1;
                 });
            }

            // compute 

            var xStartSwath = xMin - xOffset + 0.5*workingWidth + (seq[0]-1)*workingWidth;

            intersectionPoints = intersectLinePolygon( {x: xStartSwath, y:0}, {x: xStartSwath, y: 10000000}, rotatedMainlandUtm );

            if (intersectionPoints.length==0){
                if (i==0){  // The start swath is on the left side (q sequence left to right)

                    // compute y coordinate of up and down points by intersecting with line left extreme + working width
                    intersectionPoints = intersectLinePolygon( {x: xMin+workingWidth, y:0}, {x: xMin+workingWidth, y: 10000000}, rotatedMainlandUtm );
                }
                else{ // The start swath is on the right side

                    // compute y coordinate of up and down points by intersecting with line right extreme - working width
                    intersectionPoints = intersectLinePolygon( {x: xMax-workingWidth, y:0}, {x: xMax-workingWidth, y: 10000000}, rotatedMainlandUtm );
                }
                // restore x value of intersection
                intersectionPoints[0].x = xStartSwath;
                intersectionPoints[1].x = xStartSwath;
            }

            // add opposite point in swath. Useful to compute raytracing with some contour headlands later
            intersectionPoints[0].oppositePoint = {x:intersectionPoints[1].x, y: intersectionPoints[1].y};
            intersectionPoints[1].oppositePoint = {x:intersectionPoints[0].x, y: intersectionPoints[0].y};

            if (i==0){
                intersectionPoints[0].side = "left";
                intersectionPoints[1].side = "left";
            }
            else{
                intersectionPoints[0].side = "right";
                intersectionPoints[1].side = "right";
            }
            
            if (intersectionPoints[0].y > intersectionPoints[1].y){
                intersectionPoints[0].vertical = "top"; 
                intersectionPoints[1].vertical = "bottom";
            }
            else {
                intersectionPoints[0].vertical = "bottom"; 
                intersectionPoints[1].vertical = "top";
            }

            // add distance to entrance property
            // measure distance for each starting point candidate
            // ideally the distance must be measured following the contour of the polygon or a contour headland

            intersectionPoints[0].distanceToEntrance = Math.sqrt((intersectionPoints[0].x-rotatedUtmEntrance.x)**2 + (intersectionPoints[0].y-rotatedUtmEntrance.y)**2);
            intersectionPoints[1].distanceToEntrance = Math.sqrt((intersectionPoints[1].x-rotatedUtmEntrance.x)**2 + (intersectionPoints[1].y-rotatedUtmEntrance.y)**2);

            startingPtsCandidates.push(intersectionPoints[0]);
            startingPtsCandidates.push(intersectionPoints[1]);

        }

        var closestStaringPoint = startingPtsCandidates.reduce((min, pt) => pt.distanceToEntrance < min.distanceToEntrance ? pt : min);

        return closestStaringPoint;
    }
*/

}

function entranceToStartPolyline(entrance, start, swathEnd, pol, orientation, turningRadius){

    var polyline = [];

    polyline.push(entrance);

    // find closest point to entrance on polygon
    var aa = closestPointInPolygon2(entrance, pol);
    polyline.push(aa.point);

    var entranceSegmentNumber = aa.segmentNumber;

    var rotatedPts = arrayRotate(pol.slice(0), entranceSegmentNumber);


    // raytrace the swath to find the intersection with the pol

    var startSegmentNumber;  // for ex. p=3 means the segments is p3->p4 
    var inters;
    for (var p = 0; p < rotatedPts.length; p++) {
        var b1 = rotatedPts[p];
        var b2 = rotatedPts[p == rotatedPts.length - 1 ? 0 : p + 1];
        inters = intersectLineSegment(swathEnd, start, b1, b2);
        if (inters.length > 0) { // length must be 1 
            startSegmentNumber = p;
            break;
        }
    }

    // if both entrance and start point are on the same segment, don't compute intermediate points
    // It doesn't matter if it is cw or ccw. In this function obviously the path is direct (not around all the polygon)

    var intermediatePts = [];

    if (startSegmentNumber != 0) {

        if (orientation == 'ccw') {
            rotatedPts = arrayRotate(rotatedPts.slice(0), 2);
            rotatedPts.reverse();
            startSegmentNumber = pol.length - startSegmentNumber;
        }

        for (var i = 0; i < startSegmentNumber; i++) {
            intermediatePts.push(rotatedPts[i + 1]);
        }
    }

    // apply Douglas-Peucker simplification to intermediate points

    if (intermediatePts.length>1){
        var geoJsonLineString = polylineToGeojsonLineString(intermediatePts);
        intermediatePts = simplifyRoute(geoJsonLineString, turningRadius);
    }

    /*
    if (polyline[polyline.length-1].x == intermediatePts[0].x && polyline[polyline.length-1].y == intermediatePts[0].y)  {
        polyline.pop();
    }
    */

    if (intermediatePts.length>0){
        var dist = Math.sqrt( (polyline[polyline.length-1].x - intermediatePts[0].x)**2 + (polyline[polyline.length-1].y - intermediatePts[0].y)**2 );
        if (dist<2*turningRadius){
            polyline.pop();
        }
    }

    polyline.push(... intermediatePts);

    // add swath/polygon intersection point to polyline
    // (only if the distance with the previous point is > 2*turningRadius)

    
    var dist = Math.sqrt( (inters[0].x - polyline[polyline.length-1].x)**2 + (inters[0].y - polyline[polyline.length-1].y)**2 );

    if (dist>2*turningRadius){
        polyline.push(inters[0]);
    }

    polyline.push(start); 

    return polyline;

}

function SpiralToEntrancePolyline(lastSpiralPoints, entrance, pol, orientation, turningRadius){

    var polyline = [];
    
    polyline.push(lastSpiralPoints[1]);

    // end of covered area. Calculate the point in front of the vehicle
    // at a distance of one turning radius to calculate the perpendicular path

    var pt = pointFromEndOfLine(lastSpiralPoints[0], lastSpiralPoints[1], turningRadius);
    
    polyline.push(pt);

    // find closest point to last point on polygon
    var aa = closestPointInPolygon2(pt, pol);
    
    polyline.push(aa.point);
    
    var lastPointSegmentNumber = aa.segmentNumber;
    
    var rotatedPts = arrayRotate(pol.slice(0), lastPointSegmentNumber );
  
  
    // find closest point to entrance on polygon
    var bb = closestPointInPolygon2(entrance, rotatedPts);
    
    var entranceSegmentNumber = bb.segmentNumber;
    // if both lastpoint and entrance are on the same segment, don't compute intermediate points
    // It doesn't matter if it is cw or ccw. In this function obviously the path is direct (not around all the polygon)
    
    var intermediatePts = [];

    if (entranceSegmentNumber!=0){
    
      if (orientation == 'ccw'){
        rotatedPts = arrayRotate(rotatedPts.slice(0), 2);
        rotatedPts.reverse();
        entranceSegmentNumber = pol.length-entranceSegmentNumber;
      }
  
      for (var i=0; i<entranceSegmentNumber; i++){
        intermediatePts.push(rotatedPts[i+1]);
      }
    }

    // apply Douglas-Peucker simplification to intermediate points

    if (intermediatePts.length>1){
        var geoJsonLineString = polylineToGeojsonLineString(intermediatePts);
        intermediatePts = simplifyRoute(geoJsonLineString, turningRadius);
    }

    polyline.push(... intermediatePts);
    
    // add entrance intersection point to polyline
    polyline.push(bb.point);
    
    polyline.push(entrance);

    return polyline;

}

function getSwathWorkingModeLimits(xSwath, workingWidth, poly){

    // Function that determines in which points of the swath
    // the implement changes the working mode on <=> off before and after the turn
    // See figure "implement_entry_exit_points.odg"

    var limitCandidates = [];

    // intersect left limit of the swath with the polygon
    var intersectionPoints = intersectLinePolygon( {x: xSwath-workingWidth/2, y:0}, {x: xSwath-workingWidth/2, y: 10000000}, poly );
        
    //The intersection may not exist if the remainder is less than workingwidth / 2 (see swahts.odg)
    //This can happen on the first or last swath, depending on which side the adjacent swath is placed.
    for (var i=0; i<intersectionPoints.length; i++){
        limitCandidates.push(intersectionPoints[i].y); // only the y limit value matters
    }

    // intersect right limit of the swath with the polygon
    intersectionPoints = intersectLinePolygon( {x: xSwath+workingWidth/2, y:0}, {x: xSwath+workingWidth/2, y: 10000000}, poly );
    
    //The intersection may not exist if the remainder is less than workingwidth / 2 (see swahts.odg)
    //This can happen on the first or last swath, depending on which side the adjacent swath is placed.
    for (var i=0; i<intersectionPoints.length; i++){
        limitCandidates.push(intersectionPoints[i].y); // only the y limit value matters
    }

    // Add the vertices of the polygon that fall within the infinite swath
    for (var p=0; p<poly.length; p++){
        if (poly[p].x > xSwath-workingWidth/2 && poly[p].x < xSwath+workingWidth/2){
            limitCandidates.push(poly[p].y);
        }
    }

    // Get also the number of segment that the center of the swath intersects up and down
    intersectionPoints = intersectLinePolygon( {x: xSwath, y:0}, {x: xSwath, y: 10000000}, poly );
    var upperSegment, lowerSegment, upperAngle, lowerAngle;
    if (intersectionPoints[0].y>intersectionPoints[1].y){
        upperSegment = intersectionPoints[0].segmentN;
        upperAngle = intersectionPoints[0].angle;
        lowerSegment = intersectionPoints[1].segmentN;
        lowerAngle = intersectionPoints[1].angle;
    }
    else{
        upperSegment = intersectionPoints[1].segmentN;
        upperAngle = intersectionPoints[1].angle;
        lowerSegment = intersectionPoints[0].segmentN;
        lowerAngle = intersectionPoints[0].angle;
    }

    return { x: xSwath, upperLimit: Math.max(...limitCandidates), lowerLimit: Math.min(...limitCandidates),
        upperSegment: upperSegment, lowerSegment: lowerSegment, upperAngle: upperAngle, lowerAngle: lowerAngle };

}



function getAutoTurningPoints2(firstTurningPoint, secondTurningPoint, r, workingWidth, alignment, direction, orderedPaths, polygonField, workingSpeed, turningSpeed){

    // This function calculates the space available to make the turn.
    // Measures the distance from point O to the field boundary.
    // See figure "headland_space_calculation.odg"


    // Determine which of the two points will be used as origin.
    // If the turn is up, the highest. If it is down, the lowest.
    var origin, destination, alignmentOffset, directionUp;
    if (direction=="up"){
        if (firstTurningPoint.y>secondTurningPoint.y){
            origin = firstTurningPoint;
            destination = secondTurningPoint;
        }
        else{
            origin = secondTurningPoint;
            destination = firstTurningPoint;
        }
        alignmentOffset = alignment;
        directionUp = 1;
    }
    else{ // direction = "down"
        if (firstTurningPoint.y<secondTurningPoint.y){
            origin = firstTurningPoint;
            destination =secondTurningPoint;
        }
        else{
            origin = secondTurningPoint;
            destination = firstTurningPoint;
        }
        alignmentOffset = -alignment;
        directionUp = -1;
    }


    // compute point O in direction to destination
    // Este valor de O sirve para todos los turns excepto 'bulb'
    var O = { x: origin.x + r*Math.sign(destination.x-origin.x), y: origin.y };
    
    // compute distance d from point O + alignment to limit of boundary
    // the space condition is  d > r+w/2
    var d = Math.sqrt(distToPolygonSquared({x:O.x, y:O.y + alignmentOffset}, polygonField));

    // compute values for 'bulb'
    var A = {x: origin.x - r*Math.sign(destination.x-origin.x), y: origin.y};
    var B = {x: destination.x + r*Math.sign(destination.x-origin.x), y: destination.y};

    // determine point O_bulb equidistant of A and B at a distance = 2*r

    var M = {x:(A.x + B.x)/2, y: (A.y + B.y)/2}; // punto medio entre A y B
    var angleAB = Math.atan(Math.abs((B.y-A.y)/(B.x-A.x))); // angle A-B
    var distAB = Math.sqrt((B.x-A.x)*(B.x-A.x) + (B.y-A.y)*(B.y-A.y));

    var O_bulb;
    var d_bulb = 0;

    if ( (2*r)*(2*r) - (distAB/2)*(distAB/2) > 0 ){
        var lMO = Math.sqrt((2*r)*(2*r) - (distAB/2)*(distAB/2));
        O_bulb = {x: M.x + lMO*Math.sin(angleAB)*Math.sign(destination.x-origin.x), y: M.y + lMO*Math.cos(angleAB)*directionUp};
        d_bulb = Math.sqrt(distToPolygonSquared({x:O_bulb.x, y:O_bulb.y + alignmentOffset}, polygonField));
    }

    var type;
    var dx = Math.abs(destination.x-origin.x);
    var theta = Math.abs(Math.atan((destination.x-origin.x)/(destination.y-origin.y)));

    // le agrego || turningRadius < deltaX/2 porque cuando r < w/2 no puedo usar fishtail tal como está programado
    if (d_bulb > r+workingWidth/2 || r < dx/2 ){  // enough space to make any turn (even bulb)
        if (r == dx/2)
            type = "u turn"
        else if ( r < dx/2)
            type = Math.abs(firstTurningPoint.vertex - secondTurningPoint.vertex)==1 ? "flat turn" : "copy headland"; // "flat turn"
            //type = "copy headland";
        else if ( r > dx/2){
            if ((2*r)*(2*r) - (distAB/2)*(distAB/2) > 0 && (O_bulb.y-A.y)*directionUp > 0  ) { // conditions required for bulb. Second one: angle alpha>0
                type = "bulb";
            }
            else {
                type = "hook";
            }
        }
    }
    else{ // no headland space to turn

        // el fishtail implementado dispara el valor de l cuando el ángulo es muy pronunciado
        // Tambien podria reemplazarse por otro tipo de fishtail: "circle-back turning"
        // Hay que revisar la condición para hacerla más rigurosa chequeando el espacio requerido en cada caso
        if (r < dx / (2 * Math.sin(theta)) && (4*r - 2*dx)>0 ){ // condicion para poder hacer el hook
            type = "hook";
        }
        else{
            type = "fishtail";
        }
    }

    var stepDegrees = 15;
    //var turningSpeed = 2;
    //var workingSpeed = 12;
    var points = [];


    switch (type) {

        case "bulb":

            var alpha = Math.atan(Math.abs((O_bulb.y - A.y) / (O_bulb.x - A.x)));

            var beta = Math.atan(Math.abs((O_bulb.y - B.y) / (B.x - O_bulb.x)));

            // add alpha arc
            var steps = Math.round(alpha / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (var s = 0; s <= steps; s++) {
                var arc = s * (alpha / steps);
                points.push({ x: A.x + r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: A.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add gamma arc
            var gammaFrom = -alpha;
            var gammaTo = Math.PI + beta;
            steps = Math.round((gammaTo - gammaFrom) / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);

            for (s = 0; s < steps; s++) {
                arc = gammaFrom + (s + 1) * ((gammaTo - gammaFrom) / steps);
                points.push({ x: O_bulb.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: O_bulb.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add beta arc
            steps = Math.round(beta / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (s = 0; s < steps; s++) {
                var arc = beta - (s + 1) * (beta / steps);
                points.push({ x: B.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: B.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            break;

        case "hook":

            var r2 = ((dx*dx) * (1 / Math.tan(theta)) * (1 / Math.tan(theta)) + dx*dx - 2*dx*r) / (4*r - 2*dx);

            var B_hook = {x: destination.x + r2*Math.sign(destination.x-origin.x), y: destination.y};

            var beta = Math.atan(Math.abs((O.y - B_hook.y) / (B_hook.x - O.x)));
            
            var alpha = Math.PI + beta;

            // add alpha arc
            var steps = Math.round(alpha / (stepDegrees * Math.PI / 180));
            for (var s = 0; s <= steps; s++) {
                var arc = s * (alpha / steps);
                points.push({ x: O.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: O.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add beta arc
            steps = Math.round(beta / (stepDegrees * Math.PI / 180));
            steps = Math.max(steps, 1);
            for (s = 0; s < steps; s++) {
                var arc = beta - (s + 1) * (beta / steps);
                points.push({ x: B_hook.x - r2 * Math.cos(arc) * Math.sign(destination.x - origin.x), y: B_hook.y + r2 * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            break;

        case "u turn":

            // add arc (with center at previously calculated point O)
            var steps = Math.round(180/stepDegrees);
            for (var s=0; s<= steps; s++ ){
                var arc = s * (Math.PI/steps);
                points.push({ x: O.x - r * Math.cos(arc) * Math.sign(destination.x - origin.x), y: O.y + r * Math.sin(arc) * directionUp, speed: turningSpeed });
            }

            // add extra point where vehicle re-enters field to change work to "on"
            // points.push( { x: destination.x, y: destination.y, speed: workingSpeed} );

            break;

        case "copy headland":

          // compute minimum buffer distance required for each vertex and select the maximum of them
          // see "buffer_distance_for_each_vertex.odg"
  
            var m = 0.5; // minimum distance allowed between the vehicle and the vertex

            var leftVertex = Math.min(firstTurningPoint.vertex, secondTurningPoint.vertex);
            var rightVertex = Math.max(firstTurningPoint.vertex, secondTurningPoint.vertex);

            var bufferDistanceCandidates = [];

            // compute left extreme buffer distance

            var l = orderedPaths[leftVertex].pts.length - 1;
            var p1 = orderedPaths[leftVertex].pts[direction == "up" ? l : 0];
            l = orderedPaths[leftVertex+1].pts.length - 1;
            var p2 = orderedPaths[leftVertex+1].pts[direction == "up" ? l : 0];

            var alpha1 = Math.atan2( p2.y-p1.y, p2.x-p1.x );

            if (direction=="down") {
                alpha1 = -alpha1;
            }

            var d = r*(1-Math.tan(alpha1)*Math.cos(alpha1));

            bufferDistanceCandidates.push(Math.max(d,m));
            
            // compute right extreme buffer distance

            l = orderedPaths[rightVertex].pts.length - 1;
            p1 = orderedPaths[rightVertex].pts[direction == "up" ? l : 0];
            l = orderedPaths[rightVertex-1].pts.length - 1;
            p2 = orderedPaths[rightVertex-1].pts[direction == "up" ? l : 0];

            alpha1 = Math.atan2( p2.y-p1.y, p1.x-p2.x );

            if (direction=="down") {
                alpha1 = -alpha1;
            }

            d = r*(1-Math.tan(alpha1)*Math.cos(alpha1));

            bufferDistanceCandidates.push(Math.max(d,m));


            for (var v = leftVertex+1; v <= rightVertex-1; v++) {

                if (direction == "up" && orderedPaths[v].upperVertexIsConvex || direction == "down" && orderedPaths[v].lowerVertexIsConvex) {

                    var pL, p0, pR; // vertex at left and right

                    l = orderedPaths[v].pts.length - 1;
                    p0 = orderedPaths[v].pts[direction == "up" ? l : 0];

                    l = orderedPaths[v - 1].pts.length - 1;
                    pL = orderedPaths[v - 1].pts[direction == "up" ? l : 0];

                    l = orderedPaths[v + 1].pts.length - 1;
                    pR = orderedPaths[v + 1].pts[direction == "up" ? l : 0];

                    var interiorAngle = getInteriorAngle(pL, p0, pR, direction);

                    var alpha1 = interiorAngle / 2; // see "buffer_distance_for_each_vertex.odg"

                    var d = r - (r - m) * Math.cos(alpha1);

                    bufferDistanceCandidates.push(d);

                }

            }

            var bufferDistance = Math.max(...bufferDistanceCandidates);

            // generate polygon with vertices to buffer 
            var polygon = [];

            /* // line joining extremes on previous points in order to close the polygon
            l = orderedPaths[rightVertex].pts.length - 1;
            polygon.push(orderedPaths[rightVertex].pts[direction == "up" ? l-1 : 1]);

            l = orderedPaths[leftVertex].pts.length - 1;
            polygon.push(orderedPaths[leftVertex].pts[direction == "up" ? l-1 : 1]);
            */

            // line joining extremes several meters within the field in order to close the polygon
            l = orderedPaths[rightVertex].pts.length - 1;
            var rightVertexPoint = orderedPaths[rightVertex].pts[direction == "up" ? l : 0];
            polygon.push({x: rightVertexPoint.x, y: rightVertexPoint.y - (direction == "up" ? 100 : -100)});

            l = orderedPaths[leftVertex].pts.length - 1;
            var leftVertexPoint = orderedPaths[leftVertex].pts[direction == "up" ? l : 0];
            polygon.push({x: leftVertexPoint.x, y: leftVertexPoint.y - (direction == "up" ? 100 : -100)});

            for (var v = leftVertex; v <= rightVertex; v++) {
                l = orderedPaths[v].pts.length - 1;
                polygon.push( orderedPaths[v].pts[direction == "up" ? l : 0] );
            }

            var ringGeoJson = ringToGeojsonPolygon(polygon);

            var bufferedPolygon = polygonBuffer(ringGeoJson, -bufferDistance);

            // Important: the result of the polygon buffer is always a CW oriented polygon

            var xx = graficar1(polygon);
            var xxx = graficar1(bufferedPolygon);

            // intersect first and last swath center with buffered polygon

            var inter1 = intersectLinePolygon( firstTurningPoint, { x: firstTurningPoint.x, y: direction == "up" ? 10000000 : 0 }, bufferedPolygon );
            var inter2 = intersectLinePolygon( secondTurningPoint, {x: secondTurningPoint.x, y: direction == "up" ? 10000000 : 0 }, bufferedPolygon );

            if (!inter1[0] || !inter2[0]){
                console.log("error!");
            }



            var segmentInit = inter1[0].segmentN;
            var segmentEnd = inter2[0].segmentN;

            var pts = [];
            pts.push(firstTurningPoint);
            pts.push({x: inter1[0].x, y: inter1[0].y});

            // field polygon are normalized (cw).
            // check if turn is cw or ccw

            var orientation = (direction == "up" && firstTurningPoint.x<secondTurningPoint.x || 
                direction == "down" && firstTurningPoint.x>secondTurningPoint.x) ? "cw" : "ccw";

            if (orientation == "cw"){
                if (segmentEnd<segmentInit){
                    segmentEnd += bufferedPolygon.length;
                }
            }
            else{ // ccw
                if (segmentEnd>segmentInit){
                    segmentEnd -= bufferedPolygon.length;
                }
            }

            if (segmentEnd>=segmentInit){
                for (var i=segmentInit+1; i<=segmentEnd; i++){
                    pts.push(bufferedPolygon[i % bufferedPolygon.length]);
                }
            }
            else{
                for (var i=segmentInit; i>segmentEnd; i--){
                    pts.push(bufferedPolygon[(i + bufferedPolygon.length) % bufferedPolygon.length]); // add lenght just in case i<0
                }
            }

            pts.push({x: inter2[0].x, y: inter2[0].y});
            pts.push(secondTurningPoint);
            var geoJsonLineString = polylineToGeojsonLineString(pts);
            var pts2 = simplifyRoute(geoJsonLineString, r/2);
            points = polylineToRoute(pts2, workingSpeed, turningSpeed, r);

            //var bb = graficar1(aa);

            break;
        
        case "flat turn":

            // original copy headland (on unique segment)

            var C1 = { x: origin.x + r*Math.sign(destination.x-origin.x), y: origin.y }; 
            var C2 = { x: destination.x - r*Math.sign(destination.x-origin.x), y: C1.y - (dx-2*r)/Math.tan(theta) * directionUp }; 
            
            // add first arc
            steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
            for (s=0; s<= steps; s++ ){
                var arc = s * (Math.PI-theta)/steps;
                points.push( { x: C1.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C1.y + r*Math.sin(arc) * directionUp, speed: turningSpeed} );
            }

            // add second arc

            steps = Math.round(theta/(stepDegrees*Math.PI/180));
            steps = Math.max(steps,1);
            for (var s=0; s<= steps; s++ ){
                var arc = Math.PI-theta + s * (theta/steps);
                points.push( { x: C2.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C2.y + r*Math.sin(arc) * directionUp, speed: turningSpeed} );
            }

            // add extra point where vehicle re-enters field to change work to "on"
            points.push( { x: destination.x, y: destination.y, speed: workingSpeed} );


            break;

        case "fishtail":

            var C1 = O; // first center
            var C3 = {x: C1.x + r*Math.cos(theta)*Math.sign(destination.x-origin.x), y: C1.y + r*Math.sin(theta)* directionUp};
          //var C4 = {x: destination.x-r*Math.sign(destination.x-origin.x), y: (Math.abs(C3.x - destination.x)+r)/Math.tan(theta)* directionUp  + C3.y };
            var C4 = {x: destination.x-r*Math.sign(destination.x-origin.x), y: Math.abs(C3.x - (destination.x-r*Math.sign(destination.x-origin.x)))/Math.tan(theta)* directionUp  + C3.y };

            var l = r/Math.cos(Math.PI/2-theta);
            var C2 = {x: destination.x-r*Math.sign(destination.x-origin.x), y: C4.y-l*directionUp};

            // add first arc
            var steps = Math.round((Math.PI-theta)/(stepDegrees*Math.PI/180));
            for (var s=0; s<= steps; s++ ){
                var arc = s * ((Math.PI-theta)/steps);
                points.push( { x: C1.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C1.y + r*Math.sin(arc)* directionUp, speed: turningSpeed} );
            }
            // change speed of last point to reverse
            points[points.length-1].speed = -turningSpeed; 

            // add second arc
            var gammaFrom = Math.PI-theta;
            var gammaTo = Math.PI;
            steps = Math.round((gammaTo-gammaFrom)/(stepDegrees*Math.PI/180));
            steps = Math.max(steps,1);
            for (var s=0; s<= steps; s++ ){
                var arc = gammaFrom + s * ((gammaTo-gammaFrom)/steps);
                points.push( { x: C2.x - r*Math.cos(arc)*Math.sign(destination.x-origin.x), y: C2.y + r*Math.sin(arc)* directionUp, speed: turningSpeed} );
            }

            // add extra point where vehicle re-enters field to change work to "on" (TODO: review y value)
            points.push( { x: destination.x, y: destination.y, speed: workingSpeed} );

            break;

    }

    // add alignment zone: Insert two points to generate the alignment area.
    // One after the first and one before the last.
    // Shift the points between these two. (see alignment_zone.png).

    if (alignmentOffset != 0) {
        for (var p = 1; p < points.length - 1; p++) {
            points[p].y = points[p].y + alignmentOffset;
        }
        points.splice(1, 0, { x: points[0].x, y: points[0].y + alignmentOffset, speed: turningSpeed });
        points.splice(points.length - 1, 0, { x: points[points.length - 1].x, y: points[points.length - 1].y + alignmentOffset, speed: turningSpeed });
    }

    // the order of the turn points must be reversed when firstTurningPoint = destination point
    if ( firstTurningPoint.x == destination.x && type !="copy headland" ){
        points.reverse();
    }

    // Add work property
    for (var p = 0; p < points.length - 1; p++) {
        points[p].w = "off"
    }

    if (points.length > 0) {
        points[points.length - 1].w = "on";
        points[points.length - 1].speed = workingSpeed;
    }

    return {d:d,d_bulb:d_bulb,pts:points};
}

function polylineToRoute(pol, workingSpeed, turningSpeed, turningRadius) {

    // generates a route with simple turns according to turningRadius

    // this version includes first and last points of pol in the result

    var route = [];

    route.push( {...pol[0], speed: workingSpeed, w: "off"} );

    for (var i = 0; i < pol.length - 2; i++) {

        var a = pol[i];
        var b = pol[i + 1];
        var c = pol[i + 2];

        // rotate points a-b-c so that ab is vertical
        var rotationAngle = Math.atan2(b.x - a.x, b.y - a.y);
        var rotatedPoints = rotatePolygon([a, b, c], b, rotationAngle);


        // translate the points so that b is [0,0]

        var rot_c = { x: rotatedPoints[2].x - rotatedPoints[1].x, y: rotatedPoints[2].y - rotatedPoints[1].y };

        // if turn left, make an horizontal flip before compute

        var turnLeft = (rot_c.x < 0 ? true : false);

        if (turnLeft) {
            rot_c.x = -rot_c.x;
        }

        var points = [];


        var alpha = Math.atan(rot_c.y / rot_c.x);

        var beta = (Math.PI / 2 - alpha) / 2;


        var C = { x: turningRadius, y: -turningRadius * Math.tan(beta) }; // center

        var stepDegrees = 15;

        var arcLen = 2 * beta;

        // add arc
        var steps = Math.round(arcLen / (stepDegrees * Math.PI / 180));
        steps = Math.max(steps, 1);
        for (var s = 0; s <= steps; s++) {
            var arc = s * arcLen / steps;
            points.push({ x: C.x - turningRadius * Math.cos(arc), y: C.y + turningRadius * Math.sin(arc), speed: turningSpeed, w: "off" });
        }

        if (turnLeft) { // horizontal flip back
            for (var p = 0; p < points.length; p++) {
                points[p].x = -points[p].x;
            }
        }


        // rotate back points
        var rotatedTurn = rotatePolygon(points, { x: 0, y: 0 }, -rotationAngle);

        for (var p = 0; p < rotatedTurn.length; p++) {
            // translate back and add to route
            route.push({ ...rotatedTurn[p],  x: rotatedTurn[p].x + rotatedPoints[1].x, y: rotatedTurn[p].y + rotatedPoints[1].y })
        }

    }

    route.push( {...pol[pol.length-1], speed: workingSpeed, w: "off"} );

    return route;
}


function getPathsAverageAngle(paths) {

    var sumPathAngle = 0;
    for (var p = 0; p < paths.length; p++) {

        var n = paths[p].length - 1; // in case in future versions paths can be a polyline

        // compute angle without consider direction [-PI/2, +PI/2]

        var pathAngle = Math.atan((paths[p][n].y - paths[p][0].y) / (paths[p][n].x - paths[p][0].x));

        sumPathAngle += pathAngle
    }

    return sumPathAngle / paths.length;
}

function orderPaths(paths){

    for (var p = 0; p < paths.length; p++) {

        var n = paths[p].length - 1; // in case in future versions paths can be a polyline
        if (paths[p][n].y - paths[p][0].y < 0){   // last point must be the upper point 
            paths[p].reverse();
        }
    }

    // once directions have been normalized, sort paths by x coordinate of path first point

    var sortedPaths = paths.sort((a, b) => {
        return a[0].x - b[0].x
    });

    // create array of objects and add convex data for each vertex

    var objectPaths = [];

    for (var p = 0; p < sortedPaths.length; p++) {

        objectPaths.push({pts: sortedPaths[p]});

        if (p==0 || p==sortedPaths.length-1){
            objectPaths[p].upperVertexIsConvex = true;
            objectPaths[p].lowerVertexIsConvex = true;
        }
        else{

            var n = sortedPaths[p].length - 1;
            var nPrev = sortedPaths[p-1].length - 1;
            var nNext = sortedPaths[p+1].length - 1;

            // upper side

            var A = sortedPaths[p-1][nPrev];
            var B = sortedPaths[p][n];
            var C = sortedPaths[p+1][nNext];
            objectPaths[p].upperVertexIsConvex = area(A, B, C) >= 0;

            // lower side
            A = sortedPaths[p-1][0];
            B = sortedPaths[p][0];
            C = sortedPaths[p+1][0];
            objectPaths[p].lowerVertexIsConvex = area(A, B, C) < 0;
        }
    }

    return objectPaths;

}



// area: determines area of triangle formed by three points
// used to determine if a vertex is convex. See https://stackoverflow.com/questions/17173633/determine-if-vertex-is-convex-help-understanding 

function area(A, B, C) {
	var areaSum = 0;

	areaSum += A.x * (C.y - B.y);
	areaSum += B.x * (A.y - C.y);
	areaSum += C.x * (B.y - A.y);

	/* for actual area, we need to multiple areaSum * 0.5, but we are
	   only interested in the sign of the area (+/-)
	*/

	return areaSum;
}


// from http://stackoverflow.com/a/7869457
// converted to js code
// x and y are the angles in radians
function angleDifference(x, y) {
    var a = x - y;
    a = (function (i, j) { return i - Math.floor(i / j) * j })(a + Math.PI, Math.PI * 2); // (a+180) % 360; this ensures the correct sign
    a -= Math.PI;
    return a;
}

function getInteriorAngle(P1, P2, P3, direction) {

    var dx_in = P2.x - P1.x;
    var dy_in = P2.y - P1.y;

    var dx_out = P3.x - P2.x;
    var dy_out = P3.y - P2.y;

    var angle;

    if (direction == "up") {
        angle = Math.PI + Math.atan2(dx_in * dy_out - dx_out * dy_in, dx_in * dx_out + dy_in * dy_out);
    }
    else {
        angle = Math.PI - Math.atan2(dx_in * dy_out - dx_out * dy_in, dx_in * dx_out + dy_in * dy_out);
    }

    return angle;

}


function arrayRotate(arr, count) {
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));
    return arr;
}

function graficar1(pol){
    var str="";
    for (var j=0; j<pol.length; j++){

        str +=  pol[j].x + ',' + pol[j].y + '\n';

    }
    str +=  pol[0].x + ',' + pol[0].y + '\n';
    str += '\n';
    return str;
}





