export function readFile(filetype, geometrytype, txt) {

    // filetype: "shapefile", "geojson", "nmea"
    // geometrytype: "polygon", "polyline", "polylines"

    var res = {pts:[], err:""};

    switch(filetype + "," + geometrytype){

        case "geojson,polyline":

            const geoJson = JSON.parse(txt);

            // possible formats
            // 1) Feature Collection 
            // 1.a) length = 1  unique LineString
            // 1.b) length > 1  multiple Point
            // 2) Single Feature. Geometry type LineString
            // 3) Geometry Collection
            // 3.a) length = 1  unique LineString
            // 3.b) length > 1  multiple Point 
            // 4) Single Geometry. Type LineString

            if (geoJson.type != 'FeatureCollection' && geoJson.type != 'GeometryCollection' && geoJson.type != 'Feature' && geoJson.type != 'LineString'){
                res.err = "The GeoJSON type does not match a LineString or Point collection";
                return res;
            }
 
            if (geoJson.type == 'FeatureCollection' ){

                if (geoJson.features.length == 0){
                    res.err = "The geoJson is a FeatureCollection but has 0 features";
                    return res;
                }
                
                if (geoJson.features.length == 1){
                    if (geoJson.features[0].geometry.type != "LineString") {
                        res.err = "The geoJson is a FeatureCollection that has a unique feature but it is not of type LineString";
                        return res;
                    }
                }

                if (geoJson.features.length > 1){
                    // check all features are points
                    for (var g=0; g<geoJson.features.length; g++){
                        if (geoJson.features[g].geometry.type != "Point") {
                            res.err = "The Geojson is a FeatureCollection that includes geometries other than the Point type";
                            return res;
                        }
                    }
                }

                if (geoJson.features[0].geometry.type == "LineString") {

                    for (var j = 0; j < geoJson.features[0].geometry.coordinates.length; j++) {
                        res.pts.push(geoJson.features[0].geometry.coordinates[j][0]);
                        res.pts.push(geoJson.features[0].geometry.coordinates[j][1]);
                    }
                }
                else{  // collection of "Point" features
                    for (var g=0; g<geoJson.features.length; g++){
                        res.pts.push(geoJson.features[g].geometry.coordinates[0]);
                        res.pts.push(geoJson.features[g].geometry.coordinates[1]);
                    }
    
                }

            }


            if (geoJson.type == 'GeometryCollection' ){

                if (geoJson.geometries.length == 0){
                    res.err = "The geoJson is a GeometryCollection but has 0 geometries";
                    return res;
                }
                
                if (geoJson.geometries.length == 1){
                    if (geoJson.geometries[0].type != "LineString") {
                        res.err = "The geoJson is a GeometryCollection that has a unique geometry but it is not of type LineString";
                        return res;
                    }
                }

                if (geoJson.geometries.length > 1){
                    // check all geometries are points
                    for (var g=0; g<geoJson.geometries.length; g++){
                        if (geoJson.geometries[g].type != "Point") {
                            res.err = "The Geojson is a GeometryCollection that includes geometries other than the Point type";
                            return res;
                        }
                    }
                }

                if (geoJson.geometries[0].type == "LineString") {

                    for (var j = 0; j < geoJson.geometries[0].coordinates.length; j++) {
                        res.pts.push(geoJson.geometries[0].coordinates[j][0]);
                        res.pts.push(geoJson.geometries[0].coordinates[j][1]);
                    }
                }
                else{  // collection of "Point" geometries
                    for (var g=0; g<geoJson.geometries.length; g++){
                        res.pts.push(geoJson.geometries[g].coordinates[0]);
                        res.pts.push(geoJson.geometries[g].coordinates[1]);
                    }
    
                }

            }


            if (geoJson.type == 'Feature' ){ // single Feature

                if (geoJson.geometry.type != "LineString") {
                    res.err = "The geoJson is a single Feature but it is not of type LineString";
                    return res;
                }

                for (var j = 0; j < geoJson.geometry.coordinates.length; j++) {
                    res.pts.push(geoJson.geometry.coordinates[j][0]);
                    res.pts.push(geoJson.geometry.coordinates[j][1]);
                }

            }


            if (geoJson.type == 'LineString' ){  // single Geometry

                for (var j = 0; j < geoJson.coordinates.length; j++) {
                    res.pts.push(geoJson.coordinates[j][0]);
                    res.pts.push(geoJson.coordinates[j][1]);
                }

            }

            break;


        case "nmea,polyline":
            
            var count1 = (txt.match(/\$GPGGA/g) || []).length;
            var count2 = (txt.match(/\$GNGGA/g) || []).length;
            
            if (count1==0 && count2==0){
                res.err = "The NMEA log file contains no GPGGA or GNGGA sentences";
                return res;
            }

            if (count1<2 && count2<2){
                res.err = "The NMEA log file does not contain enough GPGGA or GNGGA sentences";
                return res;
            }

            const allLines = txt.split(/\r\n|\n/);
            // Reading line by line
            allLines.forEach((line) => {
                const words = line.split(",");
                if (words[0] == "$GPGGA" || words[0] == "$GNGGA"){
                    const lat = parseCoord(words[2], words[3]);
                    const lon = parseCoord(words[4], words[5]);
                    if (lat !==null && lon!==null){
                        res.pts.push(lon);
                        res.pts.push(lat);
                    }
                }
                
            });
            
            
            break;

    }

    return res;
}


export function writeFile(filetype, geometrytype, coords) {

    // filetype: "shapefile", "geojson", "nmea"
    // geometrytype: "polygon", "polyline", "polylines"

    var txt = "";

    switch (filetype + "," + geometrytype) {

        case "geojson,polygon":

            // A linear ring MUST follow the right-hand rule with respect to the
            // area it bounds, i.e., exterior rings are counterclockwise, and
            // holes are clockwise. (https://www.rfc-editor.org/rfc/rfc7946#section-3.1.6)

            var rings = [];

            for (var r=0; r<coords.length; r++){ 

                var ring = coords[r]; // [lon,lat,lon,lat...]

                var ring2 = []; // [[lon,lat],[lon,lat]...]

                for (var p = 0; p < ring.length/2; p++) {
                    ring2.push([ring[2*p], ring[2*p + 1]]);
                }
                ring2.push([ring[0], ring[1]]);

                if (isClockwise(ring2) == (r==0? true : false) ){
                    ring2 = ring2.reverse();
                }

                rings.push(ring2);

            }

            txt = `{"type": "Polygon","coordinates": ` + JSON.stringify(rings) + "}";

            break;

    }

    return txt;

}



function parseCoord(coord, dir) {

    // Latitude can go from 0 to 90; longitude can go from -180 to 180.

    if (coord === '')
        return null;

    var n, sgn = 1;

    switch (dir) {

        case 'S':
            sgn = -1;
        case 'N':
            n = 2;
            break;

        case 'W':
            sgn = -1;
        case 'E':
            n = 3;
            break;
    }
    /*
     * Mathematically, but more expensive and not numerical stable:
     *
     * raw = 4807.038
     * deg = Math.floor(raw / 100)
     *
     * dec = (raw - (100 * deg)) / 60
     * res = deg + dec // 48.1173
     */
    return sgn * (parseFloat(coord.slice(0, n)) + parseFloat(coord.slice(n)) / 60);
}

function isClockwise(poly) {
    var sum = 0
    for (var i = 0; i < poly.length - 1; i++) {
        var cur = poly[i],
            next = poly[i + 1]
        sum += (next[0] - cur[0]) * (next[1] + cur[1])
    }
    return sum > 0
}