import { LatLon } from 'geodesy/utm.js';
import Utm from 'geodesy/utm.js';

export function fromLatLon(latitude, longitude) {

  var latlon = new LatLon(latitude, longitude);

  var utm = latlon.toUtm();

  return {
    x: utm.easting,
    y: utm.northing,
    zoneNum: utm.zone,
    zoneLetter: utm.hemisphere
  };
}



export function fromLatLonArray(lonlat_arr) {

  // input array format(lonlat_arr): [-58.01563442714576,-34.94409752879433,-58.01541626170074,-34.943874622039246...]

  var utm_arr = [];

  for (var i = 0; i < lonlat_arr.length; i = i + 2) {
    var utm = fromLatLon(lonlat_arr[i + 1], lonlat_arr[i]);
    utm_arr.push(utm);
  }

  return utm_arr;

}


export function toLatLonArray2(utm_arr, zone, hemisphere) {

  //input array format (utm_arr):
  // [ {x: 407257.0068743818, y: 6132685.432991806},
  //   {x: 407257.432991806, y: 6132685.0068743818}...

  var lonlat_arr = [];

  for (var i = 0; i < utm_arr.length; i++) {
    var lonlat = toLatLon(utm_arr[i].x, utm_arr[i].y, zone, hemisphere);
    lonlat_arr.push(lonlat.longitude);
    lonlat_arr.push(lonlat.latitude);
  }

  return lonlat_arr;

}

function toLatLon(easting, northing, zone, hemisphere) {

  var utm = new Utm(zone, hemisphere, easting, northing);

  return utm.toLatLon();

}