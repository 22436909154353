export default {

    apiGateway: {
      REGION: "us-east-2",
      URL: "https://z2gd8xk23g.execute-api.us-east-2.amazonaws.com/prod"
    },

    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_rwGbj1VId",
      APP_CLIENT_ID: "6ipedrdbup50rauq40qpga6tk1",
      IDENTITY_POOL_ID: "us-east-2:fefbb729-f446-4dbf-b1b3-ba2b8b2d72ed"
    },
    social: {
      FB: "1008440330572511"
    }
  };