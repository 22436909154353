import { invokeApig } from '../libs/awsLib';

export function farmsHasErrored(bool) {
    return {
        type: 'FARMS_HAS_ERRORED',
        hasErrored: bool
    };
}

export function farmsIsLoading(bool) {
    return {
        type: 'FARMS_IS_LOADING',
        isLoading: bool
    };
}

export function farmsFetchDataSuccess(farms) {
    return {
        type: 'FARMS_FETCH_DATA_SUCCESS',
        farms
    };
}

export function currentFarmSet(farmId, farmName) {
    return {
        type: 'CURRENT_FARM_SET',
        payload: {currentFarmId: farmId, currentFarmName: farmName}
    };
}


export function farmsFetchData() {

    // this one's 'async' 
    return async dispatch => {

        dispatch(farmsIsLoading(true));

        // wrap in try to listen for Promise rejections - equivalent of '.catch()'
        try {
            // wait for the fetch to finish then dispatch the result
            const data = await farms();
            dispatch(farmsFetchDataSuccess(data));
        } catch (e) {
            // catch errors from fetch
            dispatch(farmsHasErrored(true));
        }

        dispatch(farmsIsLoading(false));
    };
}

var farms = function() {
    return invokeApig({ path: "/farms" });
}

export function createFarm(newFarmId, name, userSub, userEmail) {
    // TODO: make this function async (see fieldsFetchData below )
    // and check if the response is ok

    invokeApig({
        path: "/farms",
        method: "POST",
        body:{"name": name, "id": newFarmId, "userSub": userSub, "userEmail": userEmail}
    });

    return {
        type: 'CREATE_FARM',
        payload: {farmId: newFarmId, name: name, userSub: userSub, userEmail: userEmail}
    };
}

export function deleteFarm(farmId, isOpened){

    invokeApig({
        path: "/farms/" + farmId,
        method: "DELETE",
    });

    return {
        type: 'DELETE_FARM',
        payload: {farmId: farmId, isOpened: isOpened}
    };

}

