
// http://www.kevlindev.com/gui/math/intersection/Intersection.js

/*
    usage:
    import {intersectLineLine, intersectLinePolygon, intersectLinePolygons} from './geometry';

    var res = intersectLineLine({x: 0,y:0},{x: 4,y:4},{x: 3,y:0},{x: 0,y:3} );
    var res2 = intersectLinePolygon( {x: 3,y:0},{x: 3,y:7}, [ {x: 1,y:1},{x: 1,y:6},{x: 6,y:6},{x: 6,y:4},{x: 2,y:4},{x: 6,y:1} ]  )
    var res3 = intersectLinePolygons( {x: 3,y:0},{x: 3,y:7},
        [[ {x: 1,y:1},{x: 1,y:6},{x: 6,y:6},{x: 6,y:4},{x: 2,y:4},{x: 6,y:1} ], [ {x: 1,y:1},{x: 1,y:6},{x: 6,y:6},{x: 6,y:4},{x: 2,y:4},{x: 6,y:1} ]]
    );
    var res4 = intersectCircleLine({x:1,y:1},2,{x: 1,y:-2},{x: 1,y:13} );
    var res5 = intersectCirclePolygon({x:6,y:2},3, [ {x: 1,y:1},{x: 1,y:6},{x: 6,y:6},{x: 6,y:4},{x: 2,y:4},{x: 6,y:1} ] );
    var res6 = intersectCirclePolygons({x:6,y:2},3, 
        [[ {x: 1,y:1},{x: 1,y:6},{x: 6,y:6},{x: 6,y:4},{x: 2,y:4},{x: 6,y:1} ], [ {x: 1,y:1},{x: 1,y:6},{x: 6,y:6},{x: 6,y:4},{x: 2,y:4},{x: 6,y:1} ]]
    );

*/

//import * as jsts from 'jsts';

import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';
import GeoJSONWriter from 'jsts/org/locationtech/jts/io/GeoJSONWriter';
import {BufferOp, BufferParameters} from 'jsts/org/locationtech/jts/operation/buffer';
//import union from 'jsts/org/locationtech/jts/operation/union/UnaryUnionOp';
//import {UnaryUnionOp} from 'jsts/org/locationtech/jts/operation/union';
import Coordinate  from 'jsts/org/locationtech/jts/geom/Coordinate';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import GeometryCollection from 'jsts/org/locationtech/jts/geom/GeometryCollection'; 
import ConvexHull from 'jsts/org/locationtech/jts/algorithm/ConvexHull';
//import Geometry from 'jsts/org/locationtech/jts/geom/Geometry';
import "jsts/org/locationtech/jts/monkey.js";  // required to add some methods like 'union' to Geometry and GeometryCollection classes (https://github.com/bjornharrtell/jsts/issues/323#issue-290240908)
import DouglasPeuckerSimplifier from 'jsts/org/locationtech/jts/simplify/DouglasPeuckerSimplifier';
import TopologyPreservingSimplifier from 'jsts/org/locationtech/jts/simplify/TopologyPreservingSimplifier';
//import {Polygon} from 'jsts/org/locationtech/jts/geom/Polygon';
import { Edge } from './polygonTrapezoidation';
//import { combineReducers } from 'redux';

/*
import GeoJSONReader from '../jsts-master/src/org/locationtech/jts/io/GeoJSONReader';
import GeoJSONWriter from '../jsts-master/src/org/locationtech/jts/io/GeoJSONWriter';
import {BufferOp, BufferParameters} from '../jsts-master/src/org/locationtech/jts/operation/buffer';
*/

export function distancePointPoint(p1,p2){
    return Math.sqrt((Math.pow(p1.x-p2.x,2))+(Math.pow(p1.y-p2.y,2)));
}

export function intersectLinePolygons (a1, a2, polygons) {
    var points = [];

    for ( var i = 0; i < polygons.length; i++ ) {
        var inter = intersectLinePolygon(a1, a2, polygons[i]);
        points = points.concat(inter);
    }

    return points;
};

export function intersectLinePolygon (a1, a2, polygonPoints) {
    var points = [];
    var length = polygonPoints.length;

    for ( var i = 0; i < length; i++ ) {
        var b1 = {x: polygonPoints[i].x, y: polygonPoints[i].y};
        var b2 = {x: polygonPoints[(i+1) % length].x, y: polygonPoints[(i+1) % length].y};
        var inter = intersectLineLine(a1, a2, b1, b2);
        if (inter.length>0){
            inter[0].segmentN = i;
            // the angle always is computed between -pi/2 and pi/2 no matter if b1 is at left or right
            inter[0].angle = Math.atan((b2.y-b1.y)/(b2.x-b1.x));
        }
        points = points.concat(inter);
    }

    return points;
};

export function intersectLineLine (a1, a2, b1, b2) { 
    // segments intersection only
    var points = [];

    if (    (a1.x == b1.x && a1.y == b1.y) ||
            (a1.x == b2.x && a1.y == b2.y) ||
            (a2.x == b1.x && a2.y == b1.y) ||
            (a2.x == b2.x && a2.y == b2.y)
        )
        return points;
    
    var ua_t = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
    var ub_t = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
    var u_b  = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);

    if ( u_b != 0 ) {
        var ua = ua_t / u_b;
        var ub = ub_t / u_b;

        if ( 0 <= ua && ua <= 1 && 0 <= ub && ub <= 1 ) {
            points.push(
                    {x: a1.x + ua * (a2.x - a1.x),
                     y:a1.y + ua * (a2.y - a1.y)}
            );
        }
    } 

    return points;
};

export function intersectLineLine2 (a1, a2, b1, b2) { 
    // intersection of infinite lines determined by a1 a2 and b1 b2
    var points = [];
    
    var ua_t = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
    var ub_t = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
    var u_b  = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);

    if ( Math.abs(u_b) > 0.000001 ) {  // if ( u_b != 0 ) { 
        var ua = ua_t / u_b;
        var ub = ub_t / u_b;

        points.push(
                {x: a1.x + ua * (a2.x - a1.x),
                    y:a1.y + ua * (a2.y - a1.y)}
        );
    } 

    return points;
};


export function intersectLineSegment (a1, a2, b1, b2) { 
    // a1,a2 infinite line (with direction) - raytracing
    // b1,b2 segment
    // for a generic implementation: https://observablehq.com/@toja/line-box-intersection
  
    var points = [];

    if (    (a1.x == b1.x && a1.y == b1.y) ||
            (a1.x == b2.x && a1.y == b2.y) ||
            (a2.x == b1.x && a2.y == b1.y) ||
            (a2.x == b2.x && a2.y == b2.y)
        )
        return points;
    
    var ua_t = (b2.x - b1.x) * (a1.y - b1.y) - (b2.y - b1.y) * (a1.x - b1.x);
    var ub_t = (a2.x - a1.x) * (a1.y - b1.y) - (a2.y - a1.y) * (a1.x - b1.x);
    var u_b  = (b2.y - b1.y) * (a2.x - a1.x) - (b2.x - b1.x) * (a2.y - a1.y);

    if ( u_b != 0 ) {
        var ua = ua_t / u_b;
        var ub = ub_t / u_b;

         // 0<ub<1 because ub is a segment
         // ua>0 because only find point in infinite direction a1->a2 (raytracing)  
        if ( 0 <= ub && ub <= 1 && ua>0 ) {   
            points.push(
                    {x: a1.x + ua * (a2.x - a1.x),
                     y:a1.y + ua * (a2.y - a1.y)}
            );
        }
    } 

    return points;
};

export function intersectCirclePolygons (c, r, polygons) {
    var points = [];
    var inter;

    for ( var i = 0; i < polygons.length; i++ ) {
        inter = intersectCirclePolygon(c, r, polygons[i]);
        points = points.concat(inter);
    }

    return points;
};

export function intersectCirclePolygon (c, r, polygonPoints) {
    var points = [];
    var length = polygonPoints.length;
    var inter;

    for ( var i = 0; i < length; i++ ) {
        var a1 = {x: polygonPoints[i].x, y: polygonPoints[i].y};
        var a2 = {x: polygonPoints[(i+1) % length].x, y: polygonPoints[(i+1) % length].y};

        inter = intersectCircleLine(c, r, a1, a2);
        points = points.concat(inter);
    }

    return points;
};

export function intersectCircleLine (c, r, a1, a2) {
    var points = [];
    var a  = (a2.x - a1.x) * (a2.x - a1.x) +
             (a2.y - a1.y) * (a2.y - a1.y);
    var b  = 2 * ( (a2.x - a1.x) * (a1.x - c.x) +
                   (a2.y - a1.y) * (a1.y - c.y)   );
    var cc = c.x*c.x + c.y*c.y + a1.x*a1.x + a1.y*a1.y -
             2 * (c.x * a1.x + c.y * a1.y) - r*r;
    var deter = b*b - 4*a*cc;

    if ( deter < 0 ) {
        //result = new Intersection("Outside");
    } else if ( deter == 0 ) {
        //result = new Intersection("Tangent");
        // NOTE: should calculate this point
    } else {
        var e  = Math.sqrt(deter);
        var u1 = ( -b + e ) / ( 2*a );
        var u2 = ( -b - e ) / ( 2*a );

        if ( (u1 < 0 || u1 > 1) && (u2 < 0 || u2 > 1) ) {
            /*
            if ( (u1 < 0 && u2 < 0) || (u1 > 1 && u2 > 1) ) {
                result = new Intersection("Outside");
            } else {
                result = new Intersection("Inside");
            }*/
        } else {
            // result = new Intersection("Intersection");

            if ( 0 <= u1 && u1 <= 1)
                points.push( lerp(a1, a2, u1) );

            if ( 0 <= u2 && u2 <= 1)
                points.push( lerp(a1, a2, u2) );
        }
    }
    
    return points;
};

function lerp(p1, p2, t) {  // linear interpolation
    return {
      x: p1.x + (p2.x - p1.x) * t, 
      y: p1.y + (p2.y - p1.y) * t}
}

export function closestPointInArray (a, pointArray) {

    var d2 = Infinity;
    var index = null;
    for (var i=0; i<pointArray.length; i++){
        var d2i = (a.x - pointArray[i].x) ** 2 + (a.y - pointArray[i].y) ** 2;
        if (d2i < d2){
            index = i;
            d2 = d2i;
        }
    }
    return {x: pointArray[index].x, y: pointArray[index].y };
}

export function isPointInPolygon (point, polygon) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    // Note: This code doesn't work reliably when the point is a corner of the polygon or on an edge.
    // There is an improved version here: https://github.com/mikolalysenko/robust-point-in-polygon


    var x = point.x, y = point.y;

    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i].x, yi = polygon[i].y;
        var xj = polygon[j].x, yj = polygon[j].y;

        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
};


function dist2(v, w) { return (v.x - w.x)*(v.x - w.x) + (v.y - w.y)*(v.y - w.y) }

export function distToSegmentSquared(p, v, w) {
    // Return minimum distance (squared) between line segment vw and point p
    var l2 = dist2(v, w);
    if (l2 == 0) return dist2(p, v);
    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return dist2(p, { x: v.x + t * (w.x - v.x),
                y: v.y + t * (w.y - v.y) });
}
// function distToSegment(p, v, w) { return Math.sqrt(distToSegmentSquared(p, v, w)); }

export function distToPolygonSquared(p, polygonPoints) {
    var d = Infinity;
    var length = polygonPoints.length;

    for ( var i = 0; i < length; i++ ) {
        var b1 = {x: polygonPoints[i].x, y: polygonPoints[i].y};
        var b2 = {x: polygonPoints[(i+1) % length].x, y: polygonPoints[(i+1) % length].y};
        var di = distToSegmentSquared (p, b1, b2)
        if (di < d){
            d = di;
        }
    }
    return d;
}
/*
export function distToPolygonsSquared (p, polygons) {
    var d = Infinity;
    for ( var i = 0; i < polygons.length; i++ ) {
        var di = minDistToPolygonSquared(p, polygons[i]);
        if (di < d){
            d = di;
        }
    }
    return d;
};
*/
export function polygonArea(polygonPoints) {
    // returns area of a simple polygon (no holes)
    var area = 0;
    for (var i = 0; i < polygonPoints.length; i++)
    {
        var k = i + 1;
        if (k == polygonPoints.length)
            k = 0;
        var tmp = polygonPoints[i].x * polygonPoints[k].y - polygonPoints[k].x * polygonPoints[i].y;
        area += tmp;
    }
    area = Math.abs(area)*0.5;
    return area;
}

export function rotatePoint(point, center, angle)
{
            var rotatedX = Math.cos(angle) * (point.x - center.x) - Math.sin(angle) * (point.y-center.y) + center.x;
            var rotatedY = Math.sin(angle) * (point.x - center.x) + Math.cos(angle) * (point.y - center.y) + center.y;
            return { ...point, x:rotatedX,y:rotatedY};
}

export function rotatePolygon(polygonPoints, center, angle)
{
    var rotatedPolygon = [];
    for (var i = 0; i < polygonPoints.length; i++){
        var pt = rotatePoint(polygonPoints[i], center, angle);
        rotatedPolygon.push(pt);
    }
    return rotatedPolygon;
}

export function rotatePolygons(polygons, center, angle)
{
    var rotatedPolygons = [];
    for (var i = 0; i < polygons.length; i++){
        var rotPolygon = rotatePolygon(polygons[i], center, angle);
        rotatedPolygons.push(rotPolygon);
    }
    return rotatedPolygons;
}

/*
export function fixPointsOnEdges(multipolygon){

    for (var p=0; p<multipolygon.length; p++){ // polygons

        for (var ring=1; ring<multipolygon[p].length; ring++ ){ // only holes
            for (var pt=0; pt<multipolygon[p][pt].length; pt++){
                // check distance between hole point and outer polygon
                var length = multipolygon[0].length;
                for (var pto=0; pto<length; pto++){

                    var b1 = {x: multipolygon[0][pto].x, y: multipolygon[0][pto].y};
                    var b2 = {x: multipolygon[0][(pto+1) % length].x, y: multipolygon[0][(pto+1) % length].y};
                    var di = closestPointInSegment(multipolygon[p][pt], b1, b2);

                    if (di < 1){
                        var dx = p.x-i.x;
                        var dy = p.y-i.y;
                        //var dist = sqrt(dx*dx + dy*dy)
                        dx /= di;
                        dy /= di;
                        multipolygon[p][pt].x += 1*dx;
                        multipolygon[p][pt].y += 1*dy;
                        //x4 = x1 - 1*dy;
                        //y4 = y1 - 1*dx;

                    }

                }
            }
        } 
    }
}
*/

export function closestPointInSegment(c, a, b)
{

    // find the distance from the point (cx,cy) to the line
    // determined by the points (ax,ay) and (bx,by)
    //
    // distanceSegment = distance from the point to the line segment
    // distanceLine = distance from the point to the line (assuming
    //					infinite extent in both directions
    //

    var r_numerator = (c.x - a.x) * (b.x - a.x) + (c.y - a.y) * (b.y - a.y);
    var r_denomenator = (b.x - a.x) * (b.x - a.x) + (b.y - a.y) * (b.y - a.y);
    var r = r_numerator / r_denomenator;

    var px = a.x + r * (b.x - a.x);
    var py = a.y + r * (b.y - a.y);
    var dist; //dist*dist

    // (i.x,i.y) is the point on the lineSegment closest to (c.x,c.y)
    var i = {x: px, y: py};

    if ((r < 0) || (r > 1))
    {
        var dist1 = (c.x - a.x) * (c.x - a.x) + (c.y - a.y) * (c.y - a.y);
        var dist2 = (c.x - b.x) * (c.x - b.x) + (c.y - b.y) * (c.y - b.y);
        if (dist1 < dist2)
        {
            i.x = a.x;
            i.y = a.y;
            dist = Math.sqrt(dist1);
        }
        else
        {
            i.x = b.x;
            i.y = b.y;
            dist = Math.sqrt(dist2);
        }
    }
    else
    {
        var s = ((a.y - c.y) * (b.x - a.x) - (a.x - c.x) * (b.y - a.y)) / r_denomenator;
        dist = Math.abs(s) * Math.sqrt(r_denomenator);
    }
    return {distance: dist, i: {x: i.x, y: i.y}};
}

export function closestPointInPolygon(p, pol)
{
    // pol: polygon coordinates in Cesium format [lon,lat,lon,lat...]

    var dist = Infinity;
    var distTmp;

    var length = pol.length;
    var lat;
    var lng;
    for (var pt=0; pt<length; pt=pt+2){

        var b1 = {x: pol[pt], y: pol[pt+1]};
        var b2 = {x: pol[(pt+2) % length], y: pol[(pt+2) % length + 1 ]};
        var pTmp = null;
        var res = closestPointInSegment(p, b1, b2);
        pTmp = res.i;
        distTmp = res.distance;

        if (distTmp < dist)
        {
            lat = pTmp.y;
            lng = pTmp.x;
            dist = distTmp;
        }
    }

    return [lng, lat];
}

export function closestPointInPolygon2(pt, pol){

    // polygon format: array of {x: ,y: } points

    var minDist = Infinity;
    var segmentNumber=0;
    var closestPt=null;
    for (var p=0; p<pol.length;p++){
        var b1 = pol[p];
        var b2 = pol[p==pol.length-1?0:p+1]; 
      
        var res = closestPointInSegment(pt, b1, b2);
      
        if (res.distance<minDist){  
            segmentNumber = p;
            minDist = res.distance;
          	closestPt = res.i;
        }
    }

    return { point: closestPt, segmentNumber: segmentNumber  };

}

export function removeSpikes(mp, thresholdAngle){
    // see https://gasparesganga.com/labs/postgis-normalize-geometry/

    //var thresholdAngleRad = thresholdAngle * Math.PI/180;

    for (var p=0; p<mp.length; p++){
        do{
            var spikesRemoved = 0;
            var length = mp[p][0].length;
            for (var pt=0; pt<length; pt++ ){ // iterate only each outer ring
                var next = (pt + 1) % length;
                var prev = (pt + length - 1) % length;

                var pPrev = mp[p][0][prev];
                var pPt = mp[p][0][pt];
                var pNext = mp[p][0][next];

                //var angle = Math.atan2(pNext.y - p.y, pNext.x - p.x) + Math.atan2(pPrev.y - p.y, p.x - pPrev.x);
                var x1 = pPrev.x - pPt.x;
                var y1 = pPrev.y - pPt.y;
                var x2 = pNext.x - pPt.x;
                var y2 = pNext.y - pPt.y;
                var theta1 = Math.atan2(y1, x1)*180/Math.PI;
                var theta2 = Math.atan2(y2, x2)*180/Math.PI;
                var angle = theta2 - theta1;
                if (angle<0){
                    angle+=360;
                }

                if (angle < thresholdAngle || angle > (360 - thresholdAngle) ){
                    mp[p][0].splice(pt,1);
                    spikesRemoved = 1;
                    break;
                }
            }  

        }while(spikesRemoved>0) 

    }

    // after removing spikes, some polygons could have lenght = 0
    var i = mp.length;
    while (i--) { // iterate backwards to splice elements
        if (mp[i][0].length == 0) { // outer ring only. Check if the problem can occur with holes too
            mp.splice(i, 1);
        }
    }

}


export function removeCollinearPoints(poly, thresholdAngle){

    do{
        var spikesRemoved = 0;
        var length = poly.length;
        for (var pt=0; pt<length; pt++ ){ // iterate only each outer ring
            var next = (pt + 1) % length;
            var prev = (pt + length - 1) % length;

            var pPrev = poly[prev];
            var pPt = poly[pt];
            var pNext = poly[next];

            //var angle = Math.atan2(pNext.y - p.y, pNext.x - p.x) + Math.atan2(pPrev.y - p.y, p.x - pPrev.x);
            var x1 = pPrev.x - pPt.x;
            var y1 = pPrev.y - pPt.y;
            var x2 = pNext.x - pPt.x;
            var y2 = pNext.y - pPt.y;
            var theta1 = Math.atan2(y1, x1)*180/Math.PI;
            var theta2 = Math.atan2(y2, x2)*180/Math.PI;
            var angle = theta2 - theta1;
            if (angle<0){
                angle+=360;
            }

            if (angle >= (180 - thresholdAngle) && angle <= (180 + thresholdAngle) ){
                poly.splice(pt,1);
                spikesRemoved = 1;
                break;
            }
        }  

    }while(spikesRemoved>0) 

}

export function  removeNearPoints(ring, thresholdDistance){

    var i = ring.length;
    while (i--) { // iterate backwards to splice elements

        var prev = (i + ring.length - 1) % ring.length;

        var d2 = dist2(ring[prev],ring[i]);

        if (d2 < thresholdDistance*thresholdDistance){
            ring.splice(i, 1);
            //console.log("POINT REMOVED!!");
        }

    }

}

export function simplifyPolygonWithBuffer(poly, d){

    var reader = new GeoJSONReader();
    var geom = reader.read(poly);
    
    /*
    geom = geom.buffer(-d,-5); // If quadSegs < 0, joins are mitred, and the value of qs indicates the mitre ration limit
    geom = geom.buffer(d,-5);
    */
   
    var bufferParams = new BufferParameters(); //BufferParameters.DEFAULT_MITRE_LIMIT, BufferParameters.CAP_SQUARE, BufferParameters.JOIN_MITRE, 1);
    bufferParams.setQuadrantSegments(-5);  // If quadSegs < 0, joins are mitred, and the value of qs indicates the mitre ration limit
    geom = BufferOp.bufferOp(geom, -d, bufferParams);
    geom = BufferOp.bufferOp(geom, d, bufferParams);


    var writer = new GeoJSONWriter();
    var res = writer.write(geom);
    return res; 
}

export function simplifyAndNormalizePolygon(poly, distanceTolerance){

    var reader = new GeoJSONReader();
    var geom = reader.read(poly);

    // var geom = DouglasPeuckerSimplifier.simplify(geom, distanceTolerance);
    var geom = TopologyPreservingSimplifier.simplify(geom, distanceTolerance);

    geom.normalize(); // in case orientation normalization is required 
    // (For historical reasons the choice was to have the canonical orientations be CW for exterior rings and CCW for interior rings)

    if (geom.getNumGeometries()==1){

        var coord = geom.getCoordinates();
        if (coord.length>0)
            coord.pop();

            var coordinates = coord.map(function(pt) {
                return {x: pt.x,y: pt.y};
                });

        return coordinates;

    }
    else{
        return [];
    }

}

export function simplifyRoute(polyline, distanceTolerance){

    var reader = new GeoJSONReader();
    var geom = reader.read(polyline);

    var geom = DouglasPeuckerSimplifier.simplify(geom, distanceTolerance);
    //var geom = TopologyPreservingSimplifier.simplify(geom, distanceTolerance);

    if (geom.getNumGeometries()==1){

        var coord = geom.getCoordinates();
        if (coord.length>0)

            var coordinates = coord.map(function(pt) {
                return {x: pt.x,y: pt.y};
                });

        return coordinates;

    }
    else{
        return [];
    }

}

export function polygonBuffer(poly, d){


    var reader = new GeoJSONReader();
    var geom = reader.read(poly);
    
    var bufferParams = new BufferParameters(); //BufferParameters.DEFAULT_MITRE_LIMIT, BufferParameters.CAP_SQUARE, BufferParameters.JOIN_MITRE, 1);
    bufferParams.setQuadrantSegments(-5);  // If quadSegs < 0, joins are mitred, and the value of qs indicates the mitre ration limit
    geom = BufferOp.bufferOp(geom, -d, bufferParams);

    if (geom.getNumGeometries()==1){

        var coord = geom.getCoordinates();
        if (coord.length>0)
            coord.pop();

            var coordinates = coord.map(function(pt) {
                return {x: pt.x,y: pt.y};
                });

        return coordinates;

    }
    else{
        return [];
    }

}

export function trapezoidsToJsts(trap){

    var fact = new GeometryFactory();

    var jstsPolygons = [];

    for (var t=0; t<trap.length; t++){
        var jstsPolygon = trapezoidToJstsPolygon(trap[t], fact);
        if (jstsPolygon){
            jstsPolygons.push(jstsPolygon);
        }
    }

    var gc = new GeometryCollection(jstsPolygons, fact);

    var geom = BufferOp.bufferOp(gc, 0);

    // the resulting Geometry can be "Polygon" or "Multipolygon"

    return JstsGeometryToArrayOfPolygons(geom, true, fact);

}

function JstsGeometryToArrayOfPolygons(origGeom, bufferholes, fact){

    // convert a JSTS Geometry (which can be "Polygon" or "Multipolygon") to an array of cloudfarming format polygons
    // if bufferholes is true, the holes in the resulting polygon(s) are buffered


    var geom = origGeom.copy();

    var polygons = [];
    if (geom.getGeometryType() == "Polygon"){
        polygons.push(geom);
    }else if(geom.getGeometryType() == "MultiPolygon"){
        var nPol = geom.getNumGeometries();
        for (var pol=0; pol<nPol; pol++ ){
            polygons.push(geom.getGeometryN(pol));
        }
    }

    
    var wfCoords = [];

    for (var p=0; p<polygons.length; p++){

        // convert geom back to webFarming array format

        var coords = [];

        coords.push(polygons[p].getExteriorRing().getCoordinates());

        if (!bufferholes){ // without buffering holes
            var nHoles = polygons[p].getNumInteriorRing();
            for (var h=0; h<nHoles; h++){
                coords.push(polygons[p].getInteriorRingN(h).getCoordinates());
            }
        }
        else{ // negative buffer holes 
            var nHoles = polygons[p].getNumInteriorRing();
            for (var h=0; h<nHoles; h++){
                var holeToPolygon = fact.createPolygon(polygons[p].getInteriorRingN(h)); // convert the hole to a new simple polygon
                var bufferedHole = BufferOp.bufferOp(holeToPolygon,-0.01); // apply a buffer of -1 cm to the polygon
                if (bufferedHole.getGeometryType() == "Polygon"){
                    coords.push(bufferedHole.getCoordinates()); // add the buffered polygon as the new hole
                }
            }
        }

        wfCoords.push([]);
        for (var r=0; r<coords.length; r++){
            if (coords[r].length>0){
                coords[r].pop();
            }
            var coordinates = coords[r].map(function(pt) {
                return {x: pt.x,y: pt.y};
            });
            wfCoords[p].push(coordinates);
        }

    }
    return wfCoords;

}


function trapezoidToJstsPolygon(trapezoid, fact){

    var cs = [];

    // add one or two points depending on trapezoid starts with point or edge
    if (trapezoid.edge1 instanceof Edge){
        if (trapezoid.edge1.p1.y>trapezoid.edge1.p2.y){
            cs.push(new Coordinate(trapezoid.edge1.p2.x, trapezoid.edge1.p2.y));
            cs.push(new Coordinate(trapezoid.edge1.p1.x, trapezoid.edge1.p1.y));
        }else{
            cs.push(new Coordinate(trapezoid.edge1.p1.x, trapezoid.edge1.p1.y));
            cs.push(new Coordinate(trapezoid.edge1.p2.x, trapezoid.edge1.p2.y));
        }
    }
    else{
        cs.push(new Coordinate(trapezoid.edge1.x, trapezoid.edge1.y));
    }

    // right edge
    if (trapezoid.edge2 instanceof Edge){
        if (trapezoid.edge2.p1.y>trapezoid.edge2.p2.y){
            cs.push(new Coordinate(trapezoid.edge2.p1.x, trapezoid.edge2.p1.y));
            cs.push(new Coordinate(trapezoid.edge2.p2.x, trapezoid.edge2.p2.y));
        }else{
            cs.push(new Coordinate(trapezoid.edge2.p2.x, trapezoid.edge2.p2.y));
            cs.push(new Coordinate(trapezoid.edge2.p1.x, trapezoid.edge2.p1.y));
        }
    }
    else{
        cs.push(new Coordinate(trapezoid.edge2.x, trapezoid.edge2.y));
    }

    // close ring
    cs.push(new Coordinate(cs[0].x, cs[0].y));

    if (cs.length>3)  // some trapezoids can have only one point in both edges - check this
        return fact.createPolygon(cs);
    else
        return null;
}

export function ringToGeojsonPolygon(p){

    var geoJsonCoordinates = p.map(function(pt) {
        return [pt.x,pt.y];
        });
    // close ring for geojson
    geoJsonCoordinates.push([p[0].x, p[0].y ])

    return {
        "type": "Polygon",
        "coordinates": [geoJsonCoordinates]
    };

}

export function polylineToGeojsonLineString(p){

    var geoJsonCoordinates = p.map(function(pt) {
        return [pt.x,pt.y];
        });

    return {
        "type": "LineString",
        "coordinates": geoJsonCoordinates
    };

}

export function distanceAroundPolygon(a, b, pol) {

    // pol clockwise oriented
    // a and b can be outside or inside the polygon. The closest points on the polygon are computed
    // distance a->b cw and ccw are calculated

    var perim = 0;
    for (var p = 0; p < pol.length; p++) {
        perim += Math.sqrt((pol[p == pol.length - 1 ? 0 : p + 1].x - pol[p].x) ** 2 + (pol[p == pol.length - 1 ? 0 : p + 1].y - pol[p].y) ** 2);
    }

    // find closest point to a on polygon
    var aa = closestPointInPolygon2(a, pol);
    // find closest point to b on polygon
    var bb = closestPointInPolygon2(b, pol);

    // compute clockwise distance
    var rotatedPts = arrayRotate(pol.slice(0), aa.segmentNumber + 1);

    // distance aa to first vertex clockwise
    var dist = Math.sqrt((rotatedPts[0].x - aa.point.x) ** 2 + (rotatedPts[0].y - aa.point.y) ** 2);

    var nOfSegments = bb.segmentNumber - aa.segmentNumber;

    if (nOfSegments < 0) {
        nOfSegments += pol.length;
    }

    // add distance of each intermediate segment
    for (var i = 0; i < nOfSegments; i++) {
        dist += Math.sqrt((rotatedPts[i + 1].x - rotatedPts[i].x) ** 2 + (rotatedPts[i + 1].y - rotatedPts[i].y) ** 2);
    }

    // substract the distance between last point and subsequent vertex

    dist -= Math.sqrt((rotatedPts[nOfSegments].x - bb.point.x) ** 2 + (rotatedPts[nOfSegments].y - bb.point.y) ** 2);

    if (dist < 0) { // this happens when both points are on the same segment and b is first clockwise
        dist = perim + dist;
    }

    return { cw: dist, ccw: perim - dist };
}

function arrayRotate(arr, count) {
    count -= arr.length * Math.floor(count / arr.length);
    arr.push.apply(arr, arr.splice(0, count));
    return arr;
}

export function computeAreas(fieldUtm, coveredAreas){

    var fact = new GeometryFactory();

    // convert coveredAreas (rectangle polygons) to JSTS
    var jstsPolygons = [];
    var cs;

    for (var i=0; i<coveredAreas.length; i++){

        cs = [];

        cs.push(new Coordinate(coveredAreas[i][0].x, coveredAreas[i][0].y));
        cs.push(new Coordinate(coveredAreas[i][1].x, coveredAreas[i][1].y));
        cs.push(new Coordinate(coveredAreas[i][2].x, coveredAreas[i][2].y));
        cs.push(new Coordinate(coveredAreas[i][3].x, coveredAreas[i][3].y));
        cs.push(new Coordinate(coveredAreas[i][0].x, coveredAreas[i][0].y)); // close ring

        jstsPolygons.push(fact.createPolygon(cs)); 

    }

    // compute total covered area
    var gc = new GeometryCollection(jstsPolygons, fact);

    //var coveredAreaJsts = gc.union(); // union method requires importing monkey.js (https://github.com/bjornharrtell/jsts/issues/323#issue-290240908)
    var coveredAreaJsts = gc.buffer(0.00001);    

    var coveredArea = JstsGeometryToArrayOfPolygons(coveredAreaJsts, false, fact);
    var coveredAreaSize = coveredAreaJsts.getArea();

    // compute overlap area

    var overlapPolygons = [];


    // some intersections have an area near 0. This produces errors when computing the union (or buffer(0))
    // of all the intersection polygons. The solution is to filter polygons with area<epsilon 
 
    var inters;
    for (var i=0; i<jstsPolygons.length -1; i++){
        for (var j=i+1; j<jstsPolygons.length; j++ ){
            inters = jstsPolygons[i].intersection(jstsPolygons[j]);
            if (!inters.isEmpty() && inters.getGeometryType() == "Polygon" && inters.getArea()>0.0001){ // 1 cm2
                overlapPolygons.push(inters);
            }
        }
    }

    var overlapArea = [];
    var overlapAreaSize = 0;

    if (overlapPolygons.length > 0){

        gc = new GeometryCollection(overlapPolygons, fact);

        // problem with JSTS:
        // gc.union() sometimes throws error 'Uncaught IndexOutOfBoundsException'
        // gc.buffer(0) sometimes throws error 'Uncaught TypeError: Cannot read property 'getEdge' of null'
        // a tiny positive buffer does the trick
        try {
            var overlapAreaJsts = gc.buffer(0.00001);

            overlapArea = JstsGeometryToArrayOfPolygons(overlapAreaJsts, false, fact);
            overlapAreaSize = overlapAreaJsts.getArea();
        } catch { }
    }

    // convert Field to JSTS 
    // TODO: for now, consider field without holes;

    cs = [];
    for (var i=0; i<fieldUtm[0].length; i++){
        cs.push(new Coordinate(fieldUtm[0][i].x, fieldUtm[0][i].y));
    }
    // close ring
    cs.push(new Coordinate(fieldUtm[0][0].x, fieldUtm[0][0].y));
    var jstsFieldPolygon = fact.createPolygon(cs); 
    var fieldSize = jstsFieldPolygon.getArea();

    // compute uncovered area
    var uncoveredAreaJsts = jstsFieldPolygon.difference(coveredAreaJsts);
    var uncoveredArea = JstsGeometryToArrayOfPolygons(uncoveredAreaJsts, false, fact);
    var uncoveredAreaSize = uncoveredAreaJsts.getArea();

    return { coveredArea: coveredArea,
        uncoveredArea: uncoveredArea,
        overlapArea: overlapArea,
        coveredAreaSize: (100*coveredAreaSize/fieldSize).toFixed(2),
        uncoveredAreaSize: (100*uncoveredAreaSize/fieldSize).toFixed(2),
        overlapAreaSize: (100*overlapAreaSize/fieldSize).toFixed(2),
        coveredAreaSizeM2: coveredAreaSize,
        uncoveredAreaSizeM2: uncoveredAreaSize,
        overlapAreaSizeM2: overlapAreaSize,
        fieldSize: fieldSize,

    };

}


export function instersectPolygonPolyline(polygon, polyline, smooth){

    var fact = new GeometryFactory();

    // create Polygon Geometry

    var cs = [];
    for (var i=0; i<polygon.length; i++){
        cs.push(new Coordinate(polygon[i].x, polygon[i].y));
    }
    // close ring
    cs.push(new Coordinate(polygon[0].x, polygon[0].y));

    var jstsPolygon = fact.createPolygon(cs); 


    // create Polyline Geometry

    cs = [];
    for (var i=0; i<polyline.length; i++){
        cs.push(new Coordinate(polyline[i].x, polyline[i].y));
    }

    var jstsLineString = fact.createLineString(cs); 

    // intersection

    var inters = jstsPolygon.intersection(jstsLineString);

    var paths = [];

    if (!inters.isEmpty() && inters.getGeometryType() == "MultiLineString") {

        var nPolylines = inters.getNumGeometries();
        for (var pol = 0; pol < nPolylines; pol++) {
            paths.push([]);
            var coords = inters.getGeometryN(pol).getCoordinates();

            if (smooth) {
                var coords2 = coords.map(function (pt) {
                    return [pt.x, pt.y];
                });


                var smoothCoords = smoothPolyline(coords2, 0.15);

                coords = smoothCoords.map(function (pt) {
                    return { x: pt[0], y: pt[1] };
                });

            }

            for (var c = 0; c < coords.length; c++) {
                paths[pol].push({ x: coords[c].x, y: coords[c].y });
            }
        }
    }

    return paths;
}

export function pointFromEndOfLine(start, end, distance)
{
    // given a line determined by segment start->end
    // get the point on that line at certain distance from the end point
  
    var d = Math.sqrt((end.y - start.y)**2 + (end.x - start.x)**2);
    var x1 = end.x + (distance * ((end.x - start.x)/d));
    var y1 = end.y + (distance * ((end.y - start.y)/d));
  
    return {x: x1, y: y1};
  
}

function copy(out, a) {
    out[0] = a[0];
    out[1] = a[1];

    return out;
}

export function smoothPolyline(points, percent) {

    // percent = [0, 0.5]
    // https://github.com/RobinCK/smooth-polyline/blob/master/index.js
    var output = [];

    if (points.length > 0) {
        output.push(copy([0, 0], points[0]));
    }

    for (var i = 0; i < points.length - 1; i++) {
        var p0 = points[i];
        var p1 = points[i + 1];
        var p0x = p0[0];
        var p0y = p0[1];
        var p1x = p1[0];
        var p1y = p1[1];

        output.push([(1-percent) * p0x + percent * p1x, (1-percent) * p0y + percent * p1y]);
        output.push([percent * p0x + (1-percent) * p1x, percent * p0y + (1-percent) * p1y]);
    }

    if (points.length > 1) {
        output.push(copy([0, 0], points[points.length - 1]));
    }

    return output;
};

export function GetPolygonLongestSide(pol) {

    var len = pol.length;

    var maxLength = -1;
    var angle;
    for (var p = 0; p < len; p++) {

        var q = (p + 1) % len;  // next point. 0 if p==len

        var p1 = pol[p];
        var p2 = pol[q];

        var segmentLength = Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);

        if (segmentLength > maxLength) {
            maxLength = segmentLength;
            angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
        }
    }

    if (angle < 0) {
        angle += Math.PI;
    }

    return angle*180/Math.PI;
}

function isLess(V, i, j) {
    return (V[i].x < V[j].x || (V[i].x === V[j].x && i < j));
}

export function isXMonotone(V) {  
    // determine if a polygon is monotone with respect to the X axis
    let minim = 0;
    for (let i = 0; i < V.length; i++) {
        if (isLess(V, i, (i + 1) % V.length) && isLess(V, i, (i - 1 + V.length) % V.length)) {
            minim++;
        }
    }
    
    return minim === 1;
}

export function SubtractExclusionAreas(polygons){

    // polygons: array containing field boundary as first item, and then exclusion areas
    // exclusion areas can be holes or intersect field boundary

    var fact = new GeometryFactory();

    // convert all polygons to JSTS 

    var polygonsJsts = [];

    for (var p=0; p<polygons.length; p++){

        var cs = [];
        for (var i=0; i<polygons[p].length; i++){
            cs.push(new Coordinate(polygons[p][i].x, polygons[p][i].y));
        }
        // close ring
        cs.push(new Coordinate(polygons[p][0].x, polygons[p][0].y));
        polygonsJsts.push(fact.createPolygon(cs)); 

    }

    // subtract exclusion areas from outer boundary

    for (var p=1; p<polygons.length; p++){
        polygonsJsts[0] = polygonsJsts[0].difference(polygonsJsts[p]);
    }

    return JstsGeometryToArrayOfPolygons(polygonsJsts[0], false, fact);

}

export function getBufferedObstacles(obs, d, mainland){

    // First, the convex hull of each obstacle is taken.
    // They are then positively buffered a working_width/2 distance.
    // The JOIN_ROUND style is applied for outside corners between line segments,
    // so if the turning_radius is less than working_width/2, the result can be directly the path to avoid the obstacle

    var bufferedObstacles = [];

    var reader = new GeoJSONReader();
    
    var bufferParams = new BufferParameters(); //BufferParameters.DEFAULT_MITRE_LIMIT, BufferParameters.CAP_SQUARE, BufferParameters.JOIN_MITRE, 1);
    bufferParams.setJoinStyle(BufferParameters.JOIN_ROUND); 
    bufferParams.setQuadrantSegments(4); // "number of facets into which to divide a fillet of 90 degrees" JTS documentation
    
    for (var p=0; p<obs.length; p++){

        var ring = ringToGeojsonPolygon(obs[p]);
        var geom = reader.read(ring);

        var hull = new ConvexHull(geom);
        geom = hull.getConvexHull(); 

        geom = BufferOp.bufferOp(geom, d, bufferParams);


        if (geom.getNumGeometries() == 1) {

            var coord = geom.getCoordinates();
            if (coord.length > 0)
                coord.pop();

            var coordinates = coord.map(function (pt) {
                return { x: pt.x, y: pt.y };
            });

            // For now add only the obstacles that are within the mainland
            var isBufferedObstacleInMainland = true;
            for (var c=0; c<coordinates.length; c++){
                if (!isPointInPolygon(coordinates[c], mainland)){
                    isBufferedObstacleInMainland = false;
                }
            }
            
            if (isBufferedObstacleInMainland){
                bufferedObstacles.push(coordinates);
            }

        }

    }

    return bufferedObstacles;

}

export function findCircle(x1, y1, x2, y2, x3, y3) {

    // Function to find the circle on
    // which the given three points lie

    var x12 = (x1 - x2);
    var x13 = (x1 - x3);

    var y12 = (y1 - y2);
    var y13 = (y1 - y3);

    var y31 = (y3 - y1);
    var y21 = (y2 - y1);

    var x31 = (x3 - x1);
    var x21 = (x2 - x1);

    //x1^2 - x3^2
    var sx13 = Math.pow(x1, 2) - Math.pow(x3, 2);

    // y1^2 - y3^2
    var sy13 = Math.pow(y1, 2) - Math.pow(y3, 2);

    var sx21 = Math.pow(x2, 2) - Math.pow(x1, 2);
    var sy21 = Math.pow(y2, 2) - Math.pow(y1, 2);

    var f = ((sx13) * (x12)
        + (sy13) * (x12)
        + (sx21) * (x13)
        + (sy21) * (x13))
        / (2 * ((y31) * (x12) - (y21) * (x13)));
    var g = ((sx13) * (y12)
        + (sy13) * (y12)
        + (sx21) * (y13)
        + (sy21) * (y13))
        / (2 * ((x31) * (y12) - (x21) * (y13)));

    var c = -(Math.pow(x1, 2)) -
        Math.pow(y1, 2) - 2 * g * x1 - 2 * f * y1;

    // eqn of circle be 
    // x^2 + y^2 + 2*g*x + 2*f*y + c = 0
    // where centre is (h = -g, k = -f) and radius r
    // as r^2 = h^2 + k^2 - c
    var h = -g;
    var k = -f;
    var sqr_of_r = h * h + k * k - c;

    // r is the radius
    var r = Math.sqrt(sqr_of_r);

    return { center: { x: h, y: k }, radius: r };

}

/*
export function fromGeojson(pol){
    var res = [];
    
    if (pol.type == "Polygon"){
        res.push([]);
        for (var ring=0; ring<pol.coordinates.length; ring++){
            res[0].push([]);
            for (var pt=0; pt<pol.coordinates[ring].length-1; pt ++){
                res[0][ring].push({x:pol.coordinates[ring][pt][0],y:pol.coordinates[ring][pt][1]});
            }
        }
    }
    else{ // MultiPolygon
        for (var p=0; p<pol.coordinates.length;p++){
            res.push([])
            for (var ring=0; ring<pol.coordinates[p].length; ring++){
                res[p].push([]);
                for (var pt=0; pt<pol.coordinates[p][ring].length-1; pt ++){
                    res[p][ring].push({x:pol.coordinates[p][ring][pt][0],y:pol.coordinates[p][ring][pt][1]});
                }
            }
        }
    } 
    return res;
}

*/

