'use strict'

//import * as polybool from 'polybooljs';
import { Edge, trapezoidation } from './polygonTrapezoidation';
import { intersectLinePolygon, polygonArea, rotatePolygon, rotatePolygons, intersectLineLine2, removeSpikes, removeCollinearPoints, isPointInPolygon, simplifyPolygonWithBuffer, polygonBuffer, removeNearPoints, trapezoidsToJsts, simplifyAndNormalizePolygon, ringToGeojsonPolygon } from './geometry';
import {connectedComponents } from 'graphology-components';
import {Graph} from 'graphology';

//var simplepolygon = require('simplepolygon');


export default function getSubFields2(params){

    var polygonsUtm = params.polygonsUtm;
    var turningRadius = 1; // (m)
    var workingWidth = params.workingWidth; // (m)
    var workingSpeed = params.workingSpeed; // (m/s)
    var turningTime = params.turningTime; // (s)
    var headlandWidth = 1; // (m)   // -1 for variable headland width (depending on border/track angle)
    var areaCostCoefficient = params.areaCostCoefficient;
    var timeCostCoefficient = params.timeCostCoefficient;
    var thresholdRightAngle = params.thresholdRightAngle; // (degrees)
    var thresholdCollinearAngle = params.thresholdCollinearAngle; // (degrees)





    /* each polygon coordinates in Cesium format [lon,lat,lon,lat...]
    / first polygon: outer border - following polygons: holes

    polygonslatlon = [
        [-58.014035224914544,-34.94903469588605,-58.01261901855469,-34.95084624366132,-58.00973296165466,-34.9508726251293,-58.008756637573235,-34.949412837805205,-58.00855278968811,-34.94821684809537,-58.009260892868035,-34.94914901804555,-58.00969004631043,-34.947654023371236,-58.01260828971862,-34.947645229204255],
        [-58.0133056640625,-34.94906107793701,-58.012522459030144,-34.950098765207045,-58.01255464553833,-34.948383935941564],
        [-58.01321983337402,-34.94857740565382,-58.013198375701904,-34.94875328681423,-58.01301598548889,-34.948656552222694,-58.013026714324944,-34.94852464123212]
    ];
    */

    // polybool.epsilon(0.001);

    /*
    var thresholdRightAngle = 70; // (degrees)
    var thresholdCollinearAngle = 90; // (degrees)
    var workingWidth = 10; // (m)
    var headlandWidth = 15; // (m)   // -1 for variable headland width (depending on border/track angle)
    var workingSpeed = 2.2; // (m/s)
    var turningTime = 50; // (s)
    var turningRadius = 4; // (m)
    var areaCostCoefficient = 0.5;
    var timeCostCoefficient = 0.1;
    */

    //var polygonsUtm = [];



    // save zone letter and num to convert back later
    //var zNum = utmPoly[0].zoneNum; //polygonsUtm[0][0].zoneNum;
    //var zLet = utmPoly[0].zoneLetter; //polygonsUtm[0][0].zoneLetter;

    var rotatePoint = polygonsUtm[0][0];

    // polygon must be a multipolygon (array of polygons) because possibly in the process
    // the extraction of the best region divides the original field in two separated polygons

    var multipolygon = [];
    multipolygon.push(polygonsUtm); // init value of multipolygon array: only original field

    var fieldRegions = [];


    do{  // main loop: computes best subregion and then substracts it from the whole field
        
        var bestBlocks = []; // stores the best block for each rotation angle

        // compute area of remaining region
        var totalArea = 0;
        for (var p=0; p<multipolygon.length; p++){
            totalArea += polygonArea(multipolygon[p][0]);
            for (var i=1; i<multipolygon[p].length; i++){
                totalArea -= polygonArea(multipolygon[p][i]);
            }
        }

        for (var angle=0; angle<180; angle++){

            var rotatedPolygonsUtm = [];

            for (var p=0; p<multipolygon.length; p++){
                rotatedPolygonsUtm = rotatePolygons(multipolygon[p], rotatePoint, angle * Math.PI/180);

                var trapezoids = trapezoidation(rotatedPolygonsUtm);
                //var str = graficar();
                mergeTrapezoids(trapezoids, thresholdRightAngle, thresholdCollinearAngle);
                var uniqueGroups = [...new Set(trapezoids.map(item => item.group))];

                // iterate different groups of merged trapezoids and create blocks
                var blocks= [];

                for (var i=0; i<uniqueGroups.length; i++) {
                    var groupOfTrapezoids = trapezoids.filter(item => item.group == uniqueGroups[i] );
                    // remove extreme left and right triangle trapezoids if angle is almost vertical (narrow triangles) to avoid adding headlands
                    if (groupOfTrapezoids.length>1){
                        if (!(groupOfTrapezoids[0].edge1 instanceof Edge)){
                            if (Math.abs(groupOfTrapezoids[0].edge1.x - groupOfTrapezoids[0].edge2.p1.x) < workingWidth ) { // first trapezoid is a triangle with width < workingwidth => remove
                                groupOfTrapezoids.shift(); // remove first item
                            }
                        }
                    }
                    if (groupOfTrapezoids.length>1){
                        if (!(groupOfTrapezoids[groupOfTrapezoids.length-1].edge2 instanceof Edge)){
                            if (Math.abs(groupOfTrapezoids[groupOfTrapezoids.length-1].edge2.x - groupOfTrapezoids[groupOfTrapezoids.length-1].edge1.p1.x) < workingWidth ) { // first trapezoid is a triangle with width < workingwidth => remove
                                groupOfTrapezoids.pop(); // remove last item
                            }
                        }
                    }
                    if (groupOfTrapezoids.length>0){
                        var polygonPts = trapezoidsToPolygon(groupOfTrapezoids, false);
                        if (polygonPts.length>2){  // a veces hay un trapezoide solo y en los dos extremos es un punto. Revisar!
                            removeCollinearPoints(polygonPts, 0.5);
                            var polygonPtsUnionHeadland = trapezoidsToPolygon(groupOfTrapezoids, true);
                            removeCollinearPoints(polygonPtsUnionHeadland, 0.5);
                            removeNearPoints(polygonPtsUnionHeadland, 0.1);
                            if (polygonPtsUnionHeadland.length >2){
                                var polygonPtsHeadland = getMergedTrapezoidsOffsetHeadland(polygonPtsUnionHeadland);

                                if (polygonPtsHeadland.length>=3){
                                        var cost = computePolygonCost(polygonPtsHeadland, totalArea);
                                        if (cost>0)
                                            blocks.push({groupOfTrapezoids: groupOfTrapezoids, polygonPts: polygonPts, polygonPtsHeadland: polygonPtsHeadland, cost:cost })
                                }
                            }
                        }
                    }
                }
                if (blocks.length>0){
                    var bestBlock = blocks.reduce((max, b) => b.cost > max.cost ? b : max);
                    bestBlocks.push({block: bestBlock, angle: angle, polygonIndex: p, trapezoids: trapezoids});
                }
            }
            
        }

        if (bestBlocks.length == 0){ // Remaining area can't have an area with headlands. End process.
            console.log ("process finished: remaining area too small"); 
            multipolygon = [];  
        }else if (fieldRegions.length>=20){
            console.log ("process finished: too many subregions"); 
            multipolygon = [];
        }
        else{
            var winnerBestBlock = bestBlocks.reduce((max, b) => b.block.cost > max.block.cost ? b : max);

            // rotate back winner block to 0 degrees
            var rotatedWinnerBlock = rotatePolygon(winnerBestBlock.block.polygonPts, rotatePoint, -winnerBestBlock.angle * Math.PI/180);
            winnerBestBlock.rotatedBlock = rotatedWinnerBlock;
            fieldRegions.push(winnerBestBlock);

            // compute remaining trapezoids joined by graph adjancency
            var connectedTrapezoids = computeRemainingPolygon(winnerBestBlock.trapezoids, winnerBestBlock.block.groupOfTrapezoids);

            // remove polygon that contains best block from multipolygon
            multipolygon.splice(winnerBestBlock.polygonIndex, 1);

            for (var p=0; p<connectedTrapezoids.length; p++){
                // array with trapezoids of each group
                var trap = winnerBestBlock.trapezoids.filter(function(item){
                    return connectedTrapezoids[p].indexOf(item.id.toString()) !== -1;
                });

                var poly = trapezoidsToJsts(trap);
                if (poly){
                    for (var pol=0; pol<poly.length; pol++){
                        for (var ring=0; ring<poly[pol].length; ring++){
                            removeCollinearPoints(poly[pol][ring], 0.5);
                            removeNearPoints(poly[pol][ring], 0.1);
                        }
                        var rotatedPoly = rotatePolygons(poly[pol], rotatePoint, -winnerBestBlock.angle * Math.PI/180);
                        multipolygon.push(rotatedPoly);
                    }
                }
            }

            removeSpikes(multipolygon, 3);

        }

    } while(multipolygon.length>0 && fieldRegions.length<20);


    //var removedRegions = fieldRegions.map(function(obj) {
    //    return obj.rotatedBlock ;
    //});

    // var str2 = graficar2(removedRegions);

    // create route
/*
    var route = [];
    var routeWork = [];

    for (var r=0; r<fieldRegions.length; r++){
        var regionRoute=[];
        var polygonPoints = fieldRegions[r].block.polygonPtsHeadland;
        var xSwath = polygonPoints[0].x + workingWidth/2; // left limit of polygon + half working width
        var xMax =Math.max.apply(Math, polygonPoints.map(function(o) { return o.x; })) // maximum x value of all polygon points

        // TODO: set this variable to each region according to sequence:
        var startUp = true; // first turning at the top
        
        var s = 0; 
        var s1 = startUp ? 1 : 0;

        var firstTurningPoint = null;
        var secondTurningPoint = null;

        var prevUpHeadlandAngle = 1000;
        var prevDownHeadlandAngle = 1000;
        var prevUpTurningPoints = null;
        var prevDownTurningPoints = null;
        var center = null;

        while (true) {

            var intersectionPoints = intersectLinePolygon( {x: xSwath, y:0}, {x: xSwath, y: 10000000}, polygonPoints );


            if (intersectionPoints.length==0 || (xMax - xSwath)< workingWidth/2 )
            {
                // add last point and exit
                if (firstTurningPoint)
                    regionRoute.push( { x: firstTurningPoint.x, y: firstTurningPoint.y, w: "on" } );
                break; 
            }

            var pUp = null;
            var pDown = null;
            if (intersectionPoints[0].y > intersectionPoints[1].y){
                pUp = intersectionPoints[0];
                pDown = intersectionPoints[1];
            }
            else{
                pUp = intersectionPoints[1];
                pDown = intersectionPoints[0];
            } 

            if (s==0){ // add starting point

                if (startUp){
                    regionRoute.push( { x: pUp.x, y: pUp.y, w: "on" } );
                    firstTurningPoint = pDown;
                }
                else{
                    regionRoute.push( { x: pDown.x, y: pDown.y, w: "on" } );
                    firstTurningPoint = pUp;
                }

            }
            else{ // add turning

                var turningPts = null;
                if ((s + s1) % 2 == 0){  // add turning down
                    secondTurningPoint = pDown;
                    center = { x:(firstTurningPoint.x + secondTurningPoint.x)/2, y:(firstTurningPoint.y + secondTurningPoint.y)/2 }
                    if (Math.abs(prevDownHeadlandAngle - (secondTurningPoint.y - firstTurningPoint.y)/workingWidth) < 0.001 ){
                        // add same previous turningPts 
                        turningPts = [  ...prevDownTurningPoints].map(i => ({ ...i}));
                    }
                    else{
                        turningPts = getTurningPoints(secondTurningPoint.y - firstTurningPoint.y, "down");
                        prevDownHeadlandAngle = (secondTurningPoint.y - firstTurningPoint.y)/workingWidth;
                        prevDownTurningPoints = [  ...turningPts].map(i => ({ ...i}));
                    }
                    firstTurningPoint = pUp;
                }
                else{  // add turning up
                    secondTurningPoint = pUp;
                    center = { x:(firstTurningPoint.x + secondTurningPoint.x)/2, y:(firstTurningPoint.y + secondTurningPoint.y)/2 }
                    if (Math.abs(prevUpHeadlandAngle - (secondTurningPoint.y - firstTurningPoint.y)/workingWidth) < 0.001 ){
                        // add same previous turningPts
                        turningPts = [  ...prevUpTurningPoints].map(i => ({ ...i}));
                    }
                    else{
                        turningPts = getTurningPoints(secondTurningPoint.y - firstTurningPoint.y, "up");
                        prevUpHeadlandAngle = (secondTurningPoint.y - firstTurningPoint.y)/workingWidth;
                        prevUpTurningPoints = [  ...turningPts].map(i => ({ ...i}));
                    }
                    firstTurningPoint = pDown;
                }
                for (var p=0; p<turningPts.length; p++){
                    turningPts[p].x += center.x;
                    turningPts[p].y += center.y;
                }
                regionRoute.push(... turningPts);
            }
               
            xSwath += workingWidth;
            s++;
        };

        routeWork.push( regionRoute.map(a => a.w) );

        var rotatedRegionRoute = rotatePolygon(regionRoute, rotatePoint, -fieldRegions[r].angle * Math.PI/180);
        // route.push(...rotatedRegionRoute); // spread operator: concatenate regions routes coords in one route
        route.push(rotatedRegionRoute); // without spread op: array of routes
    }

    // convert back UTM to LatLon
    // var routeUtm = toLatLonArray2(route, zNum, zLet); // for spread operator (only one route)

    var routeUtm = [];
    /*
    for (var r=0; r<route.length; r++){
        if (route[r].length>=2){
            routeUtm.push(toLatLonArray2(route[r], zNum, zLet));
        }
    }
    */

    //var workableArea = []; // rotated headland points
    /*
    for (var a=0; a<fieldRegions.length; a++){
        var rotatedArea = rotatePolygon(fieldRegions[a].block.polygonPtsHeadland, rotatePoint, -fieldRegions[a].angle * Math.PI/180);
        workableArea.push(toLatLonArray2(rotatedArea, zNum, zLet));
    }
    */

    var subFields = fieldRegions.map(region => ({
        polygon: rotatePolygon(region.block.polygonPts, rotatePoint, -region.angle * Math.PI/180),
        workingAngle: (region.angle<90?90-region.angle:270-region.angle) })); // rotate angle 90º and normalize (0-180º)

    //var routePlan = {routesCoord: routeUtm, routesWork: routeWork, workableAreas: workableArea, subFields: subFields };

    return subFields;

    


    function computeRemainingPolygon(trapezoids, groupOfTrapezoids){
        
        var groupOfTrapezoidsIds = groupOfTrapezoids.map(a => a.id);

        var remainingTrapezoids = trapezoids.filter(function(item){
            return groupOfTrapezoidsIds.indexOf(item.id) === -1;
        });

        var graph = new Graph();

        for (var t=0; t<remainingTrapezoids.length; t++){
            graph.addNode(remainingTrapezoids[t].id);
        }

        for (var t=0; t<remainingTrapezoids.length; t++){
            for (var l=0; l<remainingTrapezoids[t].leftNeighbours.length; l++){
                if (graph.hasNode(remainingTrapezoids[t].leftNeighbours[l].id)){
                    graph.addEdge(remainingTrapezoids[t].id, remainingTrapezoids[t].leftNeighbours[l].id);
                }
            }
            for (var r=0; r<remainingTrapezoids[t].rightNeighbours.length; r++){
                if (graph.hasNode(remainingTrapezoids[t].rightNeighbours[r].id)){
                    graph.addEdge(remainingTrapezoids[t].id, remainingTrapezoids[t].rightNeighbours[r].id);
                }
            }

        }

        return connectedComponents(graph);

        // merge remaining trapezoids
        

    }


    /*
    function connectedComponents(adj) {
        
        // Find all connected components in an undirected graph
        // https://github.com/mikolalysenko/connected-components

        var numVertices = adj.length
        var visited = new Array(numVertices)
        for(var i=0; i<numVertices; ++i) {
          visited[i] = false
        }
        var components = []
        for(var i=0; i<numVertices; ++i) {
          if(visited[i]) {
            continue
          }
          var toVisit = [i]
          var cc = [i]
          visited[i] = true
          while(toVisit.length > 0) {
            var v = toVisit.pop()
            var nbhd = adj[v]
            for(var j=0; j<nbhd.length; ++j) {
              var u = nbhd[j]
              if(!visited[u]) {
                visited[u] = true
                toVisit.push(u)
                cc.push(u)
              }
            }
          }
          components.push(cc)
        }
        return components
    } */






    /*  solo para graficar en excel
    function graficar(){
        var str="";
        for (var i=0; i<trapezoids.length; i++){
            if (trapezoids[i].edge1 instanceof Edge){
                str +=  trapezoids[i].edge1.p1.x + ',' + trapezoids[i].edge1.p1.y + '\n';
                str +=  trapezoids[i].edge1.p2.x + ',' + trapezoids[i].edge1.p2.y + '\n';
            }
            else{
                str +=  trapezoids[i].edge1.x + ',' + trapezoids[i].edge1.y + '\n';
            }
            if (trapezoids[i].edge2 instanceof Edge){
                str +=  trapezoids[i].edge2.p1.x + ',' + trapezoids[i].edge2.p1.y + '\n';
                str +=  trapezoids[i].edge2.p2.x + ',' + trapezoids[i].edge2.p2.y + '\n';
            }
            else{
                str +=  trapezoids[i].edge2.x + ',' + trapezoids[i].edge2.y + '\n';
            }
            str += '\n';
        }
        return str;
    }

    function graficar1(pol){
        var str="";
        for (var j=0; j<pol.length; j++){

            str +=  pol[j].x + ',' + pol[j].y + '\n';

        }
        str +=  pol[0].x + ',' + pol[0].y + '\n';
        str += '\n';
        return str;
    }

    function graficar2(pol){
        var str="";
        for (var i=0; i<pol.length; i++){
            for (var j=0; j<pol[i].length; j++){
        
                str +=  pol[i][j].x + ',' + pol[i][j].y + '\n';
        
            }
            str +=  pol[i][0].x + ',' + pol[i][0].y + '\n';
            str += '\n';
        }
        return str;
    }
    */

/*
    function multipolygonToPolybool(multipol){
        var coord = [];
        for (var pol=0; pol<multipol.length; pol++){
            for (var ring=0; ring<multipol[pol].length; ring++){
                coord.push( multipol[pol][ring].map(r => ([r.x, r.y])) );
            }
        }
        return {regions: coord ,inverted:false};
    }

    function ringToPolybool(pol){
        var coord = [];
        coord.push( pol.map(r => ([r.x, r.y])) );
        return {regions: coord ,inverted:false};
    }
*/
    function mergeTrapezoids(trapezoids, rightAngle, collinearAngle){

        // this function assigns the property 'group' to each trapezoid

        var thresholdRightAngleRad = rightAngle * Math.PI/180;;
        var thresholdCollinearAngleRad = collinearAngle * Math.PI/180;

        // for each trapezoid check if right neighbour can be merged
        for (var i=0; i<trapezoids.length -1 ; i++){  

            if (trapezoids[i].edge2 instanceof Edge  // the trapezoid must have an Edge as right limit (not a point)
                && trapezoids[i].rightNeighbours.length == 1  // it must have only one right neighbour
                && trapezoids[i].rightNeighbours[0].leftNeighbours.length == 1) {// right neighbour must have only one left neighbour
                
                var pUpperLeft, pUpperMid, pUpperRight = null;
                var pLowerLeft, pLowerMid, pLowerRight = null;
                
                if (trapezoids[i].edge1 instanceof Edge){
                    if (trapezoids[i].edge1.p1.y>trapezoids[i].edge1.p2.y){
                        pUpperLeft = trapezoids[i].edge1.p1;
                        pLowerLeft = trapezoids[i].edge1.p2;
                    }else{
                        pUpperLeft = trapezoids[i].edge1.p2;
                        pLowerLeft = trapezoids[i].edge1.p1;                    
                    }
                }else {
                    pUpperLeft = trapezoids[i].edge1;
                    pLowerLeft = trapezoids[i].edge1;
                }

                if (trapezoids[i].rightNeighbours[0].edge2 instanceof Edge){
                    if (trapezoids[i].rightNeighbours[0].edge2.p1.y>trapezoids[i].rightNeighbours[0].edge2.p2.y){
                        pUpperRight = trapezoids[i].rightNeighbours[0].edge2.p1;
                        pLowerRight = trapezoids[i].rightNeighbours[0].edge2.p2;
                    }else{
                        pUpperRight = trapezoids[i].rightNeighbours[0].edge2.p2;
                        pLowerRight = trapezoids[i].rightNeighbours[0].edge2.p1;
                    }
                }else {
                    pUpperRight = trapezoids[i].rightNeighbours[0].edge2;
                    pLowerRight = trapezoids[i].rightNeighbours[0].edge2;
                }

                if (trapezoids[i].edge2.p1.y>trapezoids[i].edge2.p2.y){
                    pUpperMid = trapezoids[i].edge2.p1;
                    pLowerMid = trapezoids[i].edge2.p2;
                }else{
                    pUpperMid = trapezoids[i].edge2.p2;
                    pLowerMid = trapezoids[i].edge2.p1;
                }

                var upperLeftAngle = Math.atan((pUpperLeft.y-pUpperMid.y)/(pUpperLeft.x-pUpperMid.x));
                var lowerLeftAngle = Math.atan((pLowerLeft.y-pLowerMid.y)/(pLowerLeft.x-pLowerMid.x));

                var upperRightAngle = Math.atan((pUpperRight.y-pUpperMid.y)/(pUpperRight.x-pUpperMid.x));
                var lowerRightAngle = Math.atan((pLowerRight.y-pLowerMid.y)/(pLowerRight.x-pLowerMid.x));


                var upperAngle = Math.atan2(pUpperRight.y - pUpperMid.y, pUpperRight.x - pUpperMid.x) + Math.atan2(pUpperLeft.y - pUpperMid.y, pUpperMid.x - pUpperLeft.x);
                var lowerAngle = Math.atan2(pLowerRight.y - pLowerMid.y, pLowerRight.x - pLowerMid.x) + Math.atan2(pLowerLeft.y - pLowerMid.y, pLowerMid.x - pLowerLeft.x);

                upperAngle = Math.abs(upperAngle);
                lowerAngle = Math.abs(lowerAngle);

                // angle conditions to merge

                if ( Math.abs(upperLeftAngle) < thresholdRightAngleRad &&  
                        Math.abs(lowerLeftAngle) < thresholdRightAngleRad &&  
                        Math.abs(upperRightAngle) < thresholdRightAngleRad &&  
                        Math.abs(lowerRightAngle) < thresholdRightAngleRad ||
                        Math.abs(upperAngle) < thresholdCollinearAngleRad &&
                        Math.abs(lowerAngle) < thresholdCollinearAngleRad ){

                    // right neighbour takes group number from left trapezoid 
                    trapezoids[i].rightNeighbours[0].group = trapezoids[i].group;
                }
            }

        }
    }

    function trapezoidsToPolygon(trapezoids, addHeadland){
        // if addHeadland == true the function adds a strip of headland width on left and right sides
        // (only if the trapezoid extreme is an edge - not a point -) previous to the negative buffering 

        var polygonPoints = [];

        // add one or two points depending on left trapezoid starts with point or edge
        if (trapezoids[0].edge1 instanceof Edge){
            if (trapezoids[0].edge1.p1.y>trapezoids[0].edge1.p2.y){
                polygonPoints.push(trapezoids[0].edge1.p2);
                if (addHeadland){
                    polygonPoints.push({x: trapezoids[0].edge1.p2.x - headlandWidth, y: trapezoids[0].edge1.p2.y});
                    polygonPoints.push({x: trapezoids[0].edge1.p1.x - headlandWidth, y: trapezoids[0].edge1.p1.y});
                }
                polygonPoints.push(trapezoids[0].edge1.p1);
            }else{
                polygonPoints.push(trapezoids[0].edge1.p1);
                if (addHeadland){
                    polygonPoints.push({x: trapezoids[0].edge1.p1.x - headlandWidth, y: trapezoids[0].edge1.p1.y});
                    polygonPoints.push({x: trapezoids[0].edge1.p2.x - headlandWidth, y: trapezoids[0].edge1.p2.y});
                }
                polygonPoints.push(trapezoids[0].edge1.p2);
            }
        }
        else{
            polygonPoints.push(trapezoids[0].edge1);
        }
        // add intermediate upper points
        for (var i=0; i<trapezoids.length-1; i++){ 
            if (trapezoids[i].edge2.p1.y>trapezoids[i].edge2.p2.y)
                polygonPoints.push(trapezoids[i].edge2.p1);
            else
                polygonPoints.push(trapezoids[i].edge2.p2);
        }
        // add right extreme trapezoid point/points
        if (trapezoids[trapezoids.length-1].edge2 instanceof Edge){
            if (trapezoids[trapezoids.length-1].edge2.p1.y > trapezoids[trapezoids.length-1].edge2.p2.y){
                polygonPoints.push(trapezoids[trapezoids.length-1].edge2.p1);
                if (addHeadland){
                    polygonPoints.push({x: trapezoids[trapezoids.length-1].edge2.p1.x + headlandWidth, y: trapezoids[trapezoids.length-1].edge2.p1.y});
                    polygonPoints.push({x: trapezoids[trapezoids.length-1].edge2.p2.x + headlandWidth, y: trapezoids[trapezoids.length-1].edge2.p2.y});
                }
                polygonPoints.push(trapezoids[trapezoids.length-1].edge2.p2);
            }
            else{
                polygonPoints.push(trapezoids[trapezoids.length-1].edge2.p2);
                if (addHeadland){
                    polygonPoints.push({x: trapezoids[trapezoids.length-1].edge2.p2.x + headlandWidth, y: trapezoids[trapezoids.length-1].edge2.p2.y});
                    polygonPoints.push({x: trapezoids[trapezoids.length-1].edge2.p1.x + headlandWidth, y: trapezoids[trapezoids.length-1].edge2.p1.y});
                }
                polygonPoints.push(trapezoids[trapezoids.length-1].edge2.p1);
            }
        }
        else{
            polygonPoints.push(trapezoids[trapezoids.length-1].edge2);
        }
        // iterate trapezoids from right to left to add intermediate lower points
        for (var i=trapezoids.length-2; i>=0; i--){
            if (trapezoids[i].edge2.p1.y > trapezoids[i].edge2.p2.y)
                polygonPoints.push(trapezoids[i].edge2.p2);
            else
                polygonPoints.push(trapezoids[i].edge2.p1);
        }

        return polygonPoints;

    }

    function trapezoidsToHeadlandPolygon(polygonPts, trapezoids){
        
        var polygonPoints = [];
        var p1, p2, p3, p4;
        var noHeadland;
        var h1;
        var h2;
        var upperHeadland;
        var lowerHeadland;
        var upperHw, lowerHw;
        var prevHeadland;  
        var pRight;
        var pLeft;


        // 1) left extreme trapezoid

        noHeadland = false;
        if (trapezoids[0].edge1 instanceof Edge){ // left trapezoid starts with edge (not a point)
            //  p1--p3
            //  |    |
            //  p2--p4

            // set p1 = upper point
            if (trapezoids[0].edge1.p1.y>trapezoids[0].edge1.p2.y){
                p1 = trapezoids[0].edge1.p1;
                p2 = trapezoids[0].edge1.p2;
            }
            else{
                p1 = trapezoids[0].edge1.p2;
                p2 = trapezoids[0].edge1.p1;
            }
        }
        else{ // left trapezoid is a triangle: let p1=p2

            //          p3
            //  p1=p2<  |
            //          p4

            p1 = trapezoids[0].edge1;
            p2 = p1; 
        }

        if (trapezoids[0].edge2 instanceof Edge){ // left trapezoid ends with edge (not a point)

            // set p3 = upper point
            if (trapezoids[0].edge2.p1.y>trapezoids[0].edge2.p2.y){
                p3 = trapezoids[0].edge2.p1;
                p4 = trapezoids[0].edge2.p2;
            }
            else{
                p3 = trapezoids[0].edge2.p2;
                p4 = trapezoids[0].edge2.p1;
            }
        }
        else{ // left trapezoid is unique trapezoid. It can ends with a point
            p3 = trapezoids[0].edge2;
            p4 = p3; 
        }

        if (headlandWidth == -1){ // variable headland width depending on angle track-border
            upperHw = computeHeadlandWidth(p1, p3, turningRadius, workingWidth);
            lowerHw = computeHeadlandWidth(p2, p4, turningRadius, workingWidth);
        }
        else{
            upperHw = headlandWidth;
            lowerHw = headlandWidth;
        }

        if (trapezoids[0].edge1 instanceof Edge){ // left trapezoid starts with edge (not a point)
            // check if there should be a headland-zero at left (vertical edge)
            var l1 = Math.sqrt((p1.x-p3.x)*(p1.x-p3.x)+(p1.y-p3.y)*(p1.y-p3.y));
            h1 = {x: p1.x, y: p1.y + upperHw * l1/ (p1.x-p3.x)}; // intersection of upper headland line with left vertical segment

            var l2 = Math.sqrt((p2.x-p4.x)*(p2.x-p4.x)+(p2.y-p4.y)*(p2.y-p4.y));
            h2 = {x: p2.x, y: p2.y - lowerHw * l2/ (p2.x-p4.x)}; // intersection of lower headland line with left vertical segment

            if (h1.y > h2.y){  // there is a left parallel edge after substracting headland width above and below. Set headland width = 0
                noHeadland = true;
            }
        }

        upperHeadland = computeParallelLine(p1, p3, upperHw);
        lowerHeadland = computeParallelLine(p2, p4, -lowerHw);
        if (noHeadland){
            //if (isPointInPolygon(h2, polygonPts))
                polygonPoints.push(h2);
            //if (isPointInPolygon(h1, polygonPts))
                polygonPoints.push(h1);
        }
        else{
            var inter = intersectLineLine2(
                upperHeadland[0],
                upperHeadland[1],
                lowerHeadland[0],
                lowerHeadland[1]);
            if (inter.length>0)
                if (isPointInPolygon(inter[0], polygonPts))
                    polygonPoints.push(inter[0]);
        }

        // 2) add intermediate upper points

            //  ______pLeft___pRigth
            //  |       |       |
            //  | prev  |   i   |
            //  |_______|_______|

        prevHeadland = upperHeadland;  
        pLeft = p3;

        for (var i=1; i<trapezoids.length; i++){
            /*
            if (trapezoids[i].edge2.p1.y>trapezoids[i].edge2.p2.y)
                pRight=trapezoids[i].edge2.p1;
            else
                pRight=trapezoids[i].edge2.p2;
            */

            if (trapezoids[i].edge2 instanceof Edge){ // left trapezoid ends with edge (not a point)
                if (trapezoids[i].edge2.p1.y>trapezoids[i].edge2.p2.y)
                    pRight=trapezoids[i].edge2.p1;
                else
                    pRight=trapezoids[i].edge2.p2;
            }
            else{ // left trapezoid is unique trapezoid. It can ends with a point
                pRight = trapezoids[i].edge2;
            }

            // set headland width
            if (headlandWidth == -1){ // variable headland width depending on angle track-border
                upperHw = computeHeadlandWidth(pLeft, pRight, turningRadius, workingWidth);
            }
            else{
                upperHw = headlandWidth;
            }

            // compute headland line of current trapezoid i
            var upperHeadland = computeParallelLine(pLeft, pRight, upperHw);
            
            // compute prev and current trapez headland line intersection
            var inter = intersectLineLine2(
                prevHeadland[0],
                prevHeadland[1],
                upperHeadland[0],
                upperHeadland[1]);
            
            // add intersection point to polygon
            if (inter.length>0)
                if (isPointInPolygon(inter[0], polygonPts))
                    polygonPoints.push(inter[0]);

            // set values for next iteration
            prevHeadland = upperHeadland;
            pLeft = pRight;
        }


        // 3) right extreme trapezoid

        noHeadland = false;
        if (trapezoids[trapezoids.length-1].edge1 instanceof Edge){ // right trapezoid start with edge (not a point)

            // set p1 = upper point
            if (trapezoids[trapezoids.length-1].edge1.p1.y>trapezoids[trapezoids.length-1].edge1.p2.y){
                p1 = trapezoids[trapezoids.length-1].edge1.p1;
                p2 = trapezoids[trapezoids.length-1].edge1.p2;
            }
            else{
                p1 = trapezoids[trapezoids.length-1].edge1.p2;
                p2 = trapezoids[trapezoids.length-1].edge1.p1;
            }
        }
        else{ // right trapezoid is unique trapezoid. It can start with a point
            p1 = trapezoids[trapezoids.length-1].edge1;
            p2 = p1; 
        }

        if (trapezoids[trapezoids.length-1].edge2 instanceof Edge){ // right trapezoid ends with edge (not a point)
            //  p1--p3
            //  |    |
            //  p2--p4

            // set p3 = upper point
            if (trapezoids[trapezoids.length-1].edge2.p1.y>trapezoids[trapezoids.length-1].edge2.p2.y){
                p3 = trapezoids[trapezoids.length-1].edge2.p1;
                p4 = trapezoids[trapezoids.length-1].edge2.p2;
            }
            else{
                p3 = trapezoids[trapezoids.length-1].edge2.p2;
                p4 = trapezoids[trapezoids.length-1].edge2.p1;
            }
        }
        else{ // right trapezoid is a triangle: let p3=p4

            //    p1
            //    |  >p3=p4
            //    p2

            p3 = trapezoids[trapezoids.length-1].edge2;
            p4 = p3; 
        }

        if (headlandWidth == -1){ // variable headland width depending on angle track-border
            upperHw = computeHeadlandWidth(p1, p3, turningRadius, workingWidth);
            lowerHw = computeHeadlandWidth(p2, p4, turningRadius, workingWidth);
        }
        else{
            upperHw = headlandWidth;
            lowerHw = headlandWidth;
        }

        if (trapezoids[trapezoids.length-1].edge2 instanceof Edge){ // right trapezoid ends with edge (not a point)
            // check if there should be a headland-zero at right (vertical edge)
            var l1 = Math.sqrt((p1.x-p3.x)*(p1.x-p3.x)+(p1.y-p3.y)*(p1.y-p3.y));
            h1 = {x: p3.x, y: p3.y + upperHw * l1/ (p1.x-p3.x)}; // intersection of upper headland line with right vertical segment

            var l2 = Math.sqrt((p2.x-p4.x)*(p2.x-p4.x)+(p2.y-p4.y)*(p2.y-p4.y));
            h2 = {x: p4.x, y: p4.y - lowerHw * l2/ (p2.x-p4.x)}; // intersection of lower headland line with right vertical segment

            if (h1.y > h2.y){  // there is a right parallel edge after substracting headland width above and below. Set headland width = 0
                noHeadland = true;
            }
        }

        upperHeadland = computeParallelLine(p1, p3, upperHw);
        lowerHeadland = computeParallelLine(p2, p4, -lowerHw);
        if (noHeadland){
            //if (isPointInPolygon(h1, polygonPts))
                polygonPoints.push(h1);
            //if (isPointInPolygon(h2, polygonPts))
                polygonPoints.push(h2);
        }
        else{
            var inter = intersectLineLine2(
                upperHeadland[0],
                upperHeadland[1],
                lowerHeadland[0],
                lowerHeadland[1]);
            if (inter.length>0)
                if (isPointInPolygon(inter[0], polygonPts))
                    polygonPoints.push(inter[0]);
        }


        // 4) iterate trapezoids from right to left to add intermediate lower points

            //     _______________
            //    |       |       |
            //    |   i   | prev  |
            //    |       |       |
            //  pLeft___pRight____|

        prevHeadland = lowerHeadland;  
        pRight = p2;

        for (var i=trapezoids.length-2; i>=0; i--){
            /*
            if (trapezoids[i].edge1.p1.y>trapezoids[i].edge1.p2.y)
                pLeft=trapezoids[i].edge1.p2;
            else
                pLeft=trapezoids[i].edge1.p1;
            */

            if (trapezoids[i].edge1 instanceof Edge){
                if (trapezoids[i].edge1.p1.y>trapezoids[i].edge1.p2.y)
                    pLeft=trapezoids[i].edge1.p2;
                else
                    pLeft=trapezoids[i].edge1.p1;
            }
            else{
                pLeft=trapezoids[i].edge1;
            }

            // set headland width
            if (headlandWidth == -1){ // variable headland width depending on angle track-border
                lowerHw = computeHeadlandWidth(pLeft, pRight, turningRadius, workingWidth);
            }
            else{
                lowerHw = headlandWidth;
            }

            // compute headland line of current trapezoid i
            var lowerHeadland = computeParallelLine(pLeft, pRight, -lowerHw);
            
            // compute prev and current trapez headland line intersection
            var inter = intersectLineLine2(
                prevHeadland[0],
                prevHeadland[1],
                lowerHeadland[0],
                lowerHeadland[1]);
            
            // add intersection point to polygon
            if (inter.length>0)
                if (isPointInPolygon(inter[0], polygonPts))
                    polygonPoints.push(inter[0]);

            // set values for next iteration
            prevHeadland = lowerHeadland;
            pRight = pLeft;
        }
    /*
        // 5) close the polygon using lowerHeadland computed at the beginning

        var inter = intersectLineLine2(
            prevHeadland[0],
            prevHeadland[1],
            initLowerHeadland[0],
            initLowerHeadland[1]);

            // add intersection point to polygon
            if (inter.length>0)
                if (isPointInPolygon(inter[0], polygonPts))
                    polygonPoints.push(inter[0]);
    */
        return polygonPoints;
    }

    function getMergedTrapezoidsOffsetHeadland(polygonPts){
        // convert polygon to geojson
        var polygonPtsHeadlandGeoJson = ringToGeojsonPolygon(polygonPts);
        var res = polygonBuffer(polygonPtsHeadlandGeoJson, headlandWidth);
        return res;
    }


    function computeHeadlandWidth(p1, p2, turningRadius, workingWidth){
        var borderAngle = Math.atan((p2.y-p1.y)/(p2.x-p1.x));
        var w = ( Math.sin(borderAngle) + 1) * turningRadius + workingWidth/2; // pl_11_607_b3.pdf (pag 414) 
        return w;
    }

    function computeParallelLine(p1, p2, offset){
        // positive offset for line below the original one
        var l = Math.sqrt((p1.x-p2.x)*(p1.x-p2.x)+(p1.y-p2.y)*(p1.y-p2.y));
        var p3 = {x: p1.x + offset * (p2.y-p1.y) / l, y: p1.y + offset * (p1.x-p2.x) / l};
        var p4 = {x: p2.x + offset * (p2.y-p1.y) / l, y: p2.y + offset * (p1.x-p2.x) / l};
        return [p3, p4];
    }



/*
    function removeBowties(poly){

        var geoJsonPoly = ringToGeojsonPolygon(poly);

        var result = simplepolygon(geoJsonPoly);

        var resultFiltered = result.features.filter(function (el) {
            return el.properties.winding == -1;
            });

        if (resultFiltered.length > 1){
            console.log("Warning: more than one polygon with winding -1 after removing bowties");
            return [];
        }
        else if(resultFiltered.length == 0){
            return []; // area too small. Resulting polygon with headland becomes CCW
        }
        else{
            var resGeoJsonCoordinates = resultFiltered[0].geometry.coordinates[0];
            // convert geoJson back
            resGeoJsonCoordinates.pop(); // remove last element
            var res = resGeoJsonCoordinates.map(function(pt) { 
                return { 
                    x: pt[0], 
                    y: pt[1] 
                }; 
                });

            return res;
        }

    }
*/

    function computePolygonCost(polygonPoints, remainingArea){

        var routeLenght = 0;
        var numberOfTurns = 0;

        var xSwath = polygonPoints[0].x + workingWidth/2; // left limit of polygon + half working width
        
        var xMax =Math.max.apply(Math, polygonPoints.map(function(o) { return o.x; })) // maximum x value of all polygon points
        // var str="";
        while (true) {

            var intersectionPoints = intersectLinePolygon( {x: xSwath, y:0}, {x: xSwath, y: 10000000}, polygonPoints );

            if (intersectionPoints.length==0 || (xMax - xSwath)< workingWidth/2 )
                break;

            var swathLenght = Math.abs(intersectionPoints[0].y - intersectionPoints[1].y);
            routeLenght += swathLenght;
            numberOfTurns++;
            xSwath += workingWidth;
            //str +=  intersectionPoints[0].x + ',' + intersectionPoints[0].y + '\n';
            //str +=  intersectionPoints[1].x + ',' + intersectionPoints[1].y + '\n';
        };
        
        // Cost = α ∗ (Area of the block/Total area remaining in field) +
        //        β ∗ ((Effective distance covered/(Moving time + Turning time)) / Average Straightline Speed)

        var a = polygonArea(polygonPoints) / remainingArea;
        
        var cost = 0;
        if (routeLenght>0){
            var movingTime = routeLenght/workingSpeed;
            var totalTurningTime = numberOfTurns * turningTime;
            var b = (routeLenght/(movingTime + totalTurningTime))/ workingSpeed;
            cost = areaCostCoefficient*a + timeCostCoefficient*b;
        }

        return cost;
    }

}

