import React from "react";
import { connect } from 'react-redux';
import { FormGroup, FormControl, ControlLabel, Col, Row} from "react-bootstrap";
import { Button } from "react-bootstrap";
import ReactTable from 'react-table';
import NumericInput from 'react-numeric-input';
import Dialog from 'react-bootstrap-dialog';
import 'react-table/react-table.css';
import "./RoutePlanner.css";
import routePlannerOrchard from "../routeplanner/routePlannerOrchard2";
//import routePlannerOrchard from "../routeplanner/routePlannerOrchardBurro";
import {createFieldGeometry} from '../actions/fieldGeometries';
import {routePlanIsProcessing, routeWasPlanned} from '../actions/appStatus';
import uuid from "uuid";
import {fromLatLonArray} from '../utils/utm';
import {shallowEqual} from "../utils/utils";


class OrchardRoutePlanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            data: [ 
                {
                   parameter: 'Turning Radius',
                   value: 2.7,
                   minValue: 0,
                   maxValue: 100,
                   step: 0.1,
                   units: 'meters',
                   index: 0
                },  
/*                {
                   parameter: 'Row to row distance',
                   value: 3,
                   minValue: 0,
                   maxValue: 100,
                   step: 1,
                   units: 'meters',
                   index: 1
                },
*/
                {
                   parameter: 'Working Speed',
                   value: 6,
                   minValue: 0,
                   maxValue: 100,
                   step: 0.1,
                   units: 'speedunit',
                   index: 1
                },
                {
                    parameter: 'Turning Speed',
                    value: 50,
                    minValue: 0,
                    maxValue: 100,
                    step: 1,
                    units: '% of working speed',
                    index: 2
                 },
                {
                    parameter: 'Turn Offset',
                    value: 0,
                    minValue: 0,
                    maxValue: 100,
                    step: 0.1,
                    units: 'meters',
                    index: 3
                 },
 /*               {
                   parameter: 'Headland Swaths',
                   value: 7,
                   minValue: 1,
                   maxValue: 100,
                   step: 1,
                   units: '',
                   index: 3
                },
                {
                   parameter: 'Working Angle',
                   value: 90.0,
                   minValue: 0,
                   maxValue: 180,
                   step: 1,
                   units: 'degress',
                   precision: 6,
                   index: 4
                },
                {
                   parameter: 'Turning Type',
                   options:[{value: "auto"}, {value: "straight line"}],
                   value: "auto",
                   units: '',
                   index: 5
                }*/
            ],
            selectedField: '',
            pattern: "SAP",
            patternStart: "EndNearEntrance",
            vehicle: "e-robotiller",
            speedUnit: "km/h"
        };
        
        this.createRoute = this.createRoute.bind(this);

        //this.state.data = ;
    }

    
    componentDidUpdate(prevProps) {

        // el evento onChange del FormControl select (dropdown) para fields solo
        // se dispara cuando el usuario clickea en el control con el mouse
        // Por lo tanto necesito esto para detectar cuando se abre un farm al iniciar la app
        // o cuando se abre un nuevo Farm sobre otro ya abierto

        if(!shallowEqual(this.props.fields, prevProps.fields)) {
            this.handleFieldChange(null);
        }
    }
    

    async createRoute(){

        if (this.inputFieldId.value.length<1){
            this.dialog.showAlert("Please select a Field");
            return;
        }

        if (this.inputInterrowpathsId.value.length<1){
            this.dialog.showAlert("Please select a set of Inter-row paths");
            return;
        }

        var selectedField = this.props.fields.find(field => field.fieldId === this.inputFieldId.value);

        var selectedInterrowpaths = this.props.fieldGeometries.find(fg => fg.fieldGeometryId === this.inputInterrowpathsId .value);

        this.props.routePlanIsProcessing(true);

        var fieldExteriorRing = JSON.parse(selectedField.geometry); 

        var interrowpaths = JSON.parse(selectedInterrowpaths.geometry);

        var exclusionAreas = this.props.fieldGeometries
            .filter(fg => fg.fieldId == this.inputFieldId.value && fg.type.includes('EXCLUSION'))
            .map(function(fg) {
                return JSON.parse(fg.geometry);
              });

        var entranceCoords = JSON.parse(selectedField.entrance);

        // **************** 
        // VERSION DEBUG: llamo al RP directamente (ni web worker ni lambda) para depurar fácilmente

        try{


            var rr = routePlannerOrchard({
                polygonlatlonField: fieldExteriorRing,
                //polygonslatlon: polygons,
                interrowpaths: interrowpaths,
                turningRadius: this.state.data[0].value, // (m)
                //workingWidth: this.state.data[1].value, // (m)
                workingSpeed: (this.state.speedUnit == "km/h") ? this.state.data[1].value/3.6 : this.state.data[1].value, // if unit expressed in km/h, convert to m/s
                turningSpeed: this.state.data[2].value, // (percentage)
                // headlandSwaths: this.state.data[3].value, // (N)
                // workingAngle: this.state.data[4].value, // (degrees)
                // turningType: this.state.data[5].value,
                fieldName: selectedField.name,
                fieldId: selectedField.fieldId,
                fieldEntrance: entranceCoords,
                pattern: this.state.pattern,
                patternStart: this.state.patternStart,
                // speedUnit: this.state.speedUnit,
                alignmentZone: this.state.data[3].value
                
            });

            if (rr.error){
                this.props.routePlanIsProcessing(false);
                this.dialog.showAlert(rr.error);
                return;
            }

            /* version anterior al 'route preview' antes de guardarla en la db

            this.props.deleteRoutePlanner(selectedField.fieldId);
            
            // "await" is important to avoid sync problems. If it is removed, entities.add in "CesiumClickHandler" sometimes uses the same uuid
            for (var r=0; r<rr.routesCoord.length; r++){
                await this.props.createFieldGeometry( selectedField.fieldId,
                    uuid.v1(),
                    // JSON.stringify({pts: rr.routesCoord[r], work: rr.routesWork[r], workArea: rr.workableAreas[r] }),
                    JSON.stringify({pts: rr.routesCoord[r],
                        time: rr.time,
                        speed: rr.speed,
                        work: rr.work,
                        coverageAreas: rr.coverageAreas,
                        routeStats: rr.routeStats,
                        vehicle: this.state.vehicle
                    }),
                    "RP_" + r,
                    'ROUTE',
                    "RP_ORCHARD",

                );
            }
            */

            this.props.routeWasPlanned(selectedField.fieldId, uuid.v1(), "RP_ORCHARD", this.state.vehicle, rr );


        }
        catch(e){
            this.dialog.showAlert("An error occurred during the generation or the route. Please verify that the parameters are correct");
 
        }

        this.props.routePlanIsProcessing(false);
// */
        

        

/*        
        //  VERSION WEB WORKER: el RP se ejecuta localmente en el cliente. 
        //  Se usa un web worker para no bloquear la UI
        //  Requiere la linea import routePlannerOrchard from "../routeplanner/routePlannerOrchard2";

        const rpWorker = new Worker("../routeplanner/routeplannerworkerOrchard.js", { type: 'module' });
        rpWorker.onmessage = event => {

            if (event.data.error == "error"){
                this.props.routePlanIsProcessing(false);
                this.dialog.showAlert("At this stage, all lines parallel to the working angle must intersect the field boundary only at two points");
                return;
            }

            this.props.deleteRoutePlanner(selectedField.fieldId);
        console.log(JSON.stringify(event));
            for (var r=0; r<event.data.routesCoord.length; r++){
                this.props.createFieldGeometry( selectedField.fieldId,
                    uuid.v1(),
                    JSON.stringify({pts: event.data.routesCoord[r],
                        time: event.data.time,
                        speed: event.data.speed,
                        work: event.data.work,
                        coverageAreas: event.data.coverageAreas,
                        routeStats: event.data.routeStats,
                        vehicle: "e-robotiller" // this.state.vehicle
                    }),
                    "RP_" + r,
                    'ROUTE',
                    "RP_ORCHARD"
                );
            }
    
            this.props.routePlanIsProcessing(false);
        };

        var msg = {polygonlatlonField: fieldExteriorRing,
            interrowpaths: interrowpaths,
            turningRadius: this.state.data[0].value, // (m)
            workingSpeed: (this.state.speedUnit == "km/h") ? this.state.data[1].value/3.6 : this.state.data[1].value, // if unit expressed in km/h, convert to m/s
            turningSpeed: this.state.data[2].value, // (percentage)
            fieldName: selectedField.name,
            fieldId: selectedField.fieldId,
            fieldEntrance: entranceCoords,
            pattern: this.state.pattern,
            alignmentZone: this.state.data[3].value
        }

        rpWorker.postMessage(msg);

        /* /
        //  VERSION LAMBDA: el RP se ejecuta remotamente en AWS lambda. 
        //  Borrar la linea import routePlanner from "../routeplanner/routePlanner"; para no incluir el código en el cliente
        //  Requiere que esta función (createRoute) sea async

        var rr = await invokeApig({
            path: "/routeplanner",
            method: "POST",
            body: {polygonslatlon: polygons,
                turningRadius: this.state.data[0].value, // (m)
                workingWidth: this.state.data[1].value, // (m)
                workingSpeed: this.state.data[2].value, // (m/s)
                turningTime: this.state.data[3].value, // (s)
                headlandWidth: this.state.data[4].value, // (m)   // -1 for variable headland width (depending on border/track angle)
                areaCostCoefficient: this.state.data[5].value,
                timeCostCoefficient: this.state.data[6].value,
                thresholdRightAngle: this.state.data[7].value, // (degrees)
                thresholdCollinearAngle: this.state.data[8].value, // (degrees)
                turningType: this.state.data[9].value,
                fieldName: selectedField.name,
                fieldId: selectedField.fieldId}
            });

        this.props.deleteRoutePlanner(selectedField.fieldId);
    
        // "await" is important to avoid sync problems. If it is removed, entities.add in "CesiumClickHandler" sometimes uses the same uuid
        for (var r=0; r<rr.routesCoord.length; r++){
            await this.props.createFieldGeometry( selectedField.fieldId,
                uuid.v1(),
                JSON.stringify({pts: rr.routesCoord[r], work: rr.routesWork[r], workArea: rr.workableAreas[r] }),
                "RP_" + r,
                'ROUTE',
                "RP_ORCHARD"
            );
        }

        this.props.routePlanIsProcessing(false);

        // FIN VERSION LAMBDA
        */
    }


    handleChange(newValue, index){
        this.state.data[index].value =  newValue;
    }

    handleSpeedUnitChange(e){

        this.setState({ speedUnit: e.target.value });

        const newData = [...this.state.data];

        if (e.target.value == "km/h"){
            newData[1].value = newData[1].value * 3.6;
        }
        else if (e.target.value == "m/s"){
            newData[1].value = newData[1].value / 3.6;
        }
        
        this.setState({ data: newData });

    }

    handleFieldChange(e){
        this.setState({ selectedField: this.inputFieldId.value });
    }

    handleInterrowpathsChange(e){
        this.setState({ selectedInterrowpaths: this.inputInterrowpathsId.value });
    }

    render() {

        const columns = [{
            //Header: '',
            accessor: 'parameter' // String-based value accessors!
          }, {
            //Header: '',
            //accessor: 'values',
            //id: 'values1',
            //Cell: JSON.stringify(this.props.values.v)
            //Cell: props => <Button bsStyle="link" onClick={this.handleClick.bind(this,props.value)}>open</Button>
            // Cell: props => <NumericInput className="form-control" value={props.original.value} min={props.original.minValue} max={props.original.maxValue} step={props.original.step} onChange={(e) => this.handleChange(e, props.original.index)} />
            Cell: props => {
                if (props.original.parameter != 'Turning Type')
                    return <NumericInput className="form-control" value={props.original.value} min={props.original.minValue} max={props.original.maxValue} step={props.original.step} precision={props.original.precision} onChange={(e) => this.handleChange(e, props.original.index)} />
                else
                    return <FormControl componentClass="select" onChange={(e) => this.handleChange(e.target.value, props.original.index)} > 
                    {
                        props.original.options.map((option, index) => {
                            return (<option key={index} value={option.value}>{option.value}</option>)
                        })
                    }
                    </FormControl>
                           }
          },
            {
                //Header: '',
                accessor: 'units', // String-based value accessors!
                Cell: props => {
                    if (props.original.units == 'speedunit')
                        return <FormControl
                            componentClass="select"
                            value={this.state.speedUnit}
                            onChange={e => this.handleSpeedUnitChange(e)}>

                            <option value="km/h">km/h</option>
                            <option value="m/s">m/s</option>

                        </FormControl>
                    else
                        return props.original.units
                }
            }
        ]
        
        //this.state.data[0].value = JSON.stringify(this.props.fields);

        return (



            <div className="RoutePlanner">
                <form>
                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Field:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select" inputRef={el => this.inputFieldId = el} onChange={(e) => this.handleFieldChange(e)} >
                                    {
                                        this.props.fields.map((option, index) => {
                                            return (<option key={index} value={option.fieldId}>{option.name}</option>)
                                        })
                                    }
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Vehicle Model:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    value={this.state.vehicle}
                                    onChange={e => this.setState({ vehicle: e.target.value })}>
                                    <option value="e-robotiller">e-robotiller</option>
                                    {/* <option value="burro">Burro</option> */} 
                                    <option value="etrac">eTrac</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Inter-row paths:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select" inputRef={el => this.inputInterrowpathsId = el} onChange={(e) => this.handleInterrowpathsChange(e)} >
                                    {
                                        this.props.fieldGeometries
                                            .filter(fg => fg.type == 'INTER_ROW_PATHS' && fg.fieldId === this.inputFieldId.value)
                                            .map((option, index) => {
                                                return (<option key={index} value={option.fieldGeometryId}>{option.name}</option>)
                                            })

                                    }
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Fieldwork Pattern:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    value={this.state.pattern}
                                    onChange={e => this.setState({ pattern: e.target.value })}>
                                    <option value="SAP">Straight Alternation (no skipping)</option>
                                    <option value="SFP3">Skip and Fill Pattern (SFP) - skip 3 rows</option>
                                    <option value="SFP5">Skip and Fill Pattern (SFP) - skip 5 rows</option>
                                    <option value="SFP7">Skip and Fill Pattern (SFP) - skip 7 rows</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Row>
                            <Col componentClass={ControlLabel} sm={4}>
                                Pattern Start:
                            </Col>
                            <Col sm={8}>
                                <FormControl componentClass="select"
                                    value={this.state.patternStart}
                                    onChange={e => this.setState({ patternStart: e.target.value })}>
                                    <option value="StartNearEntrance">Start the pattern near the entrance</option>
                                    <option value="EndNearEntrance">End the pattern near the entrance</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <Col sm={12}>
                            <ReactTable
                                data={this.state.data}
                                sortable={false}
                                columns={columns}
                                className="-striped -highlight"
                                showPagination={false}
                                pageSize={this.state.data.length} />
                        </Col>
                    </FormGroup>

                    <FormGroup>
                        <Col sm={12}>
                            <div style={{ margin: "10px 0 10px 0" }}>
                                <Button
                                    bsStyle="primary"
                                    bsSize="small"
                                    onClick={this.createRoute} // {() =>  { this.props.routePlanIsProcessing(true); }}  
                                >
                                    Create Route
                                </Button>
                            </div>
                            <Dialog ref={(el) => { this.dialog = el }} />
                        </Col>
                    </FormGroup>
                </form>

            </div>
        )
    }
}
/*
RoutePlanner.propTypes = {
    fields: PropTypes.array.isRequired,
    routes: PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
    return {
        farms: state.farms,
        fields: state.fields,
        routes: state.routes
    };
};*/

const mapStateToProps = (state) => {
    return {
        fields: state.fields,
        fieldGeometries: state.fieldGeometries
        //rpIsProcessing: state.routePlanIsProcessing
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFieldGeometry: (fieldId, fieldGeometryId, geometry, name, type, origin) => dispatch(createFieldGeometry(fieldId, fieldGeometryId, geometry, name, type, origin)),
        routePlanIsProcessing: (status) => dispatch(routePlanIsProcessing(status)),
        routeWasPlanned: (parentFieldId, id, origin, vehicle, routeObject) => dispatch(routeWasPlanned(parentFieldId, id, origin, vehicle, routeObject))

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrchardRoutePlanner);